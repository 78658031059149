import { Button, Card, CardHeader, Checkbox, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Snackbar, TextField } from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import countryData from '../../../data/country.json';
import errorMessages from '../../../error_messages.json';
import stateData from '../../../models/states.json';
import ManageErrorType from '../../../services/errorHelper';
import { AppState } from '../../../store';
import { addPaymentWeb, clearState, detailPaymentPromise, validatePaymentItem } from '../../../store/clerk-payment/actions';
import * as types from '../../../store/clerk-payment/types';
import { calculateFeeProccesingWebsite } from '../../../store/manage-fee/actions';
import { navigate, toggleRouteBack } from '../../../store/system/actions';
import { ERROR_ACTION } from '../../../store/system/types';
import { TransactionDetailResponse } from '../../../swagger-client';
import CustomSeparator from '../../bread-crumbs/BreadCrumbs';
import CreditCards from '../../clerk-payment/components/CreditCards/CreditCards';
import { EmailInput } from '../../inputs/email/EmailInput';
import { PhoneInput } from '../../inputs/phone/PhoneInput';
import WebsitePaymentLayout from '../WebsitePaymentLayout';
import TermAndConditions from './components/TermAndConditions/TermAndConditions';
import TotalCard from './components/TotalCard/TotalCard';
import styles from './WebistePaymentForm.module.scss';

const CreditCardInput = require('react-credit-card-input'); // Require because we have no type declaration.

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  clerkPayment: state.clerkPayment,
  fee: state.manageFee,
  manageOrg: state.manageOrg,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
      validatePaymentItem,
      calculateFeeProccesingWebsite,
      detailPaymentPromise,
      clearState,
    },
    dispatch
  );

const WebistePaymentForm = (props: any) => {
  const formRef: any = React.createRef<HTMLFormElement>();
  let history = useHistory();
  const { urlIdentifier } = useParams<{ urlIdentifier: string }>();
  const dispatch = useDispatch();
  const org = useSelector((state: any) => state?.manageOrg?.orgByUrl);

  useEffect(() => {
    creditCardAccepted();
  }, []);

  useEffect(() => {
    return () => {
      clearState();
    };
  }, []);

  useEffect(() => {
    if (amount > 0) {
      const feeProcessingFetch = props.fee?.feeProcessing as any;
      if (feeProcessingFetch.data !== undefined) {
        setFeeProcessing(feeProcessingFetch.data?.feeProcessing);
      } else {
        setFeeProcessing(0);
      }
    }

    return () => { };
  }, [props.fee?.feeProcessing]);

  const getDefaultProduct = () => {
    if (props.clerkPayment.productsWebsite) {
      const defaultProduct = props.clerkPayment.productsWebsite.data?.filter((prod: any) => prod.is_default === true)
      return defaultProduct && defaultProduct.length > 0 ? defaultProduct[0].id : ''
    } else {
      return ''
    }
  }


  const [accepted, setaccepted] = useState([]);
  const [ccNumber, setCcNumber] = useState('');
  const [ccExpiry, setCcExpiry] = useState('');
  const [ccCvv, setCcCvv] = useState('');
  const [errors, setError] = useState<Array<string>>([]);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [liscenseNumber, setLiscenseNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [amount, setAmount] = useState(0);
  const [firstNameB, setFirstNameB] = useState('');
  const [lastNameB, setLastNameB] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [sendConfrim, setSendConfrim] = useState(true);
  const [termAndConditions, setTermAndConditions] = useState(false);
  const [countryDatas, setCountryDatas] = useState('');
  const [products, setProducts] = useState(getDefaultProduct());
  const [stateDatas, setStateDatas] = useState('');
  const [canPay, setCanPay] = useState(false);
  const [notFirst, setNotFirst] = useState(false);
  const [feeProcessing, setFeeProcessing] = useState(0);
  const [isLoadingAddPayment, setIsLoadingAddPayment] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [severity, setSeverity] = useState('' as Color | undefined);
  const [inJIS, setinJIS] = useState(false);
  const [loadingJISDate, setloadingJISDate] = useState(false);
  const [transactionDetails, settransactionDetails] = useState<TransactionDetailResponse | any>(undefined);
  const defaultProduct = getDefaultProduct();
  const handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  let product = [];
  if (props.clerkPayment.productsWebsite) {
    product = props.clerkPayment.productsWebsite.data?.map((product: any, index: any) => {
      return (
        <MenuItem key={index} className={styles.inputField} value={product.id}>
          {product.name}
        </MenuItem>
      );
    });
  }


  const stateProvince = stateData.map((state, index) => {
    return (
      <MenuItem key={index} className={styles.inputField} value={state.Code}>
        {state.State}
      </MenuItem>
    );
  });

  const country = countryData.map((country, index) => {
    return (
      <MenuItem key={index} className={styles.inputField} value={country.name}>
        {country.name}
      </MenuItem>
    );
  });

  const checkJISAmount = async (event: any) => {
    const referenceNumber = event.target.value;
    const checkAgain = transactionDetails?.caseNumber !== referenceNumber ? true : false;

    if (referenceNumber && checkAgain) {
      if (org?.useJis) {
        setloadingJISDate(true);
        setOpenError(true);
        setSeverity('info');
        setSnackMessage('Retrieving reference number...');
        const detailPayment = {
          caseNumber: referenceNumber,
          court: org?.jisInformation ? Number(org?.jisInformation[0]?.secondCourtId) : 0,
          caseStatus: 'Open',
        };
        try {
          const detailsTransactions = await detailPaymentPromise(detailPayment);
          const detailsTransaction = detailsTransactions[0];
          settransactionDetails(detailsTransaction);

          setAmount(Number(detailsTransaction.amountDue));
          setinJIS(true);
          setloadingJISDate(false);
          setOpenError(false);
        } catch (execption) {
          setAmount(0);
          setinJIS(false);
          setloadingJISDate(false);
          setOpenError(false);
        }
      }
    }
  };

  const onContinueClick = async () => {
    if (canPay) {
      if (formRef.current?.checkValidity()) {
        if (amount <= 10000 && amount > 0 && ccNumber !== '' && ccExpiry !== '' && ccCvv !== '') {
          const t = cc_brand_id(ccNumber);
          const accepted = acceptCreditCardOrg(t);
          if (accepted) {
            const paymentRef = {
              referenceNumber,
              urlIdentifier,
            };
            try {
              const paymentReference = await validatePaymentItem(paymentRef);
              dispatch({
                type: types.GET_PAYMENT_BY_REFERENCE,
                payload: paymentReference,
              });

              let isCapturePayment = false;

              if (inJIS) {
                if (transactionDetails.amountDue === Number(amount)) {
                  isCapturePayment = true;
                }
              }

              const paymentDataSend: any = {
                strategy: 'court',
                source: 'website',
                urlIdentifier: urlIdentifier,
                paymentItem: {
                  referenceNumber: referenceNumber,
                  firstName: firstName,
                  lastName: lastName,
                  productId: products,
                  liscenseNumber: liscenseNumber,
                  amount: amount,
                  inJIS: isCapturePayment,
                },
                billingInformation: {
                  firstName: firstNameB,
                  lastName: lastNameB,
                  address: address,
                  city: city,
                  state: stateDatas,
                  zip: zip,
                  country: countryDatas,
                },
                contactInformation: {
                  phoneNumber: phoneNumber,
                  email: email,
                  sendConfirmation: sendConfrim,
                },
                cardInformation: {
                  cardNumber: ccNumber.replace(/ /g, ''),
                  expiration: ccExpiry.replace(/[^0-9]/g, ''),
                  cvv: ccCvv,
                },
                feeProcessing: feeProcessing,
              };
              if (paymentReference.data.length !== 0) {
                history.push({ pathname: `/web/${urlIdentifier}/payments`, state: { propOne: paymentDataSend } });
              } else {
                try {
                  setIsLoadingAddPayment(true);
                  const pay = await addPaymentWeb(paymentDataSend);
                  dispatch({
                    type: types.ADD_PAYMENT_WEBSITE,
                    payload: pay,
                  });
                  dispatch(toggleRouteBack());
                  history.push({
                    pathname: `/web/${urlIdentifier}/confirm`,
                    state: {
                      propOne: pay,
                    },
                  });
                  setIsLoadingAddPayment(false);
                } catch (error) {
                  const err = ManageErrorType(error as any);

                  // setSnackMessage('Your payment was declined. Please contact the court for additional information.');
                  setSnackMessage(err);
                  setOpenError(true);
                  setSeverity('error');
                  dispatch({
                    type: ERROR_ACTION,
                    payload: 'No citation Case Found',
                  });
                  setIsLoadingAddPayment(false);
                }
              }
            } catch (error) {
              dispatch({
                type: ERROR_ACTION,
                payload: errorMessages.editCourtPayment,
              });
            }
          } else {
            setOpenError(true);
            setSeverity('error');
            setSnackMessage('This credit card is not accepted in this organization');
          }
        } else {
          setOpenError(true);
          setSeverity('error');
          setSnackMessage('Required fields are not set. Please check the amount and credit cards fields');
          setIsLoadingAddPayment(false);
        }
      } else {
        setOpenError(true);
        setSeverity('error');
        setSnackMessage('Required fields are not set. Please check the amount and credit cards fields');
        const elementsWithInvalid = formRef?.current?.querySelectorAll(':invalid');
        const errorsString: Array<string> = [];
        elementsWithInvalid?.forEach((element: any) => {
          errorsString.push((element as any).name as string);
        });
        setError(errorsString);
        setNotFirst(true);
      }
    } else {
      const elementsWithInvalid = formRef?.current?.querySelectorAll(':invalid');
      const errorsString: Array<string> = [];
      elementsWithInvalid?.forEach((element: any) => {
        errorsString.push((element as any).name as string);
      });
      setError(errorsString);
      setNotFirst(true);
    }
  };

  const handleOnChangeCitation = (event: ChangeEvent<any>) => {
    if (event.target.name === 'referenceNumber') {
      setReferenceNumber(event.target.value);
    }
    if (event.target.name === 'liscenseNumber') {
      setLiscenseNumber(event.target.value);
    }
    if (event.target.name === 'firstName') {
      setFirstName(event.target.value);
    }
    if (event.target.name === 'lastName') {
      setLastName(event.target.value);
    }
    if (event.target.name === 'amount') {
      setAmount(event.target.value);
    }
    if (event.target.name === 'productId') {
      setProducts(event.target.value);
    }
    const newError = errors.filter((item: any) => item !== event.target.name);
    setError(newError);
    onHandleBlurAmount(event);
  };

  const handleOnChangeBilling = (event: ChangeEvent<any>) => {
    if (event.target.name === 'firstNameB') {
      setFirstNameB(event.target.value);
    }
    if (event.target.name === 'lastNameB') {
      setLastNameB(event.target.value);
    }
    if (event.target.name === 'address') {
      setAddress(event.target.value);
    }
    if (event.target.name === 'city') {
      setCity(event.target.value);
    }
    if (event.target.name === 'zip') {
      setZip(event.target.value);
    }
    if (event.target.name === 'countryId') {
      setCountryDatas(event.target.value);
    }
    if (event.target.name === 'stateId') {
      setStateDatas(event.target.value);
    }
    const newError = errors.filter((item: any) => item !== event.target.name);
    setError(newError);
  };

  const handleOnChangeContact = (event: ChangeEvent<any>) => {
    if (event.target.name === 'phoneNumber') {
      setPhoneNumber(event.target.value);
    }
    if (event.target.name === 'email') {
      setEmail(event.target.value);
    }
    if (event.target.name === 'sendConfirmation') {
      setSendConfrim(!sendConfrim);
    }
    const newError = errors.filter((item: any) => item !== event.target.name);
    setError(newError);
  };

  const handleOnChange = (event: ChangeEvent<any>) => {
    if (event.target.name === 'ccNumber') {
      setCcNumber(event.target.value);
    }
    if (event.target.name === 'ccExpiry') {
      setCcExpiry(event.target.value);
    }
    if (event.target.name === 'ccCvv') {
      setCcCvv(event.target.value);
    }

    if (event.target.name === 'termAndConditions') {
      setTermAndConditions(!termAndConditions);
      setCanPay(!canPay);
      setNotFirst(!notFirst);
    }
  };
  const totalValue = () => {
    if (amount !== 0) {
      return +amount + +feeProcessing;
    } else {
      return +0;
    }
  };
  const total = totalValue();

  const onHandleBlurAmount = async (event: ChangeEvent<any>) => {
    if (amount > 0) {
      const calculateAmount = {
        paymentItems: [
          {
            referenceNumber: referenceNumber,
            firstName: firstName,
            lastName: lastName,
            productId: products,
            liscenseNumber: liscenseNumber,
            amount: amount,
          },
        ],
        urlIdentifier: urlIdentifier,
        paymentSource: 'website',
      };
      await props.calculateFeeProccesingWebsite(calculateAmount);
      const feeProcessingFetch = props.fee?.feeProcessing as any;
      setFeeProcessing(feeProcessingFetch.data?.feeProcessing);
    } else {
      setFeeProcessing(0);
    }
  };

  const acceptCreditCardOrg = (type: any) => {
    let accepts = false;
    if (type === 'Visa' && org?.ccAcceptVisa) {
      accepts = true;
    }
    if (type === 'AmericanExpress' && org?.ccAcceptAmericanExpress) {
      accepts = true;
    }
    if (type === 'Mastercard' && org?.ccAcceptMasterCard) {
      accepts = true;
    }
    if (type === 'Discover' && org?.ccAcceptDiscover) {
      accepts = true;
    }
    return accepts;
  };

  const cc_brand_id = (cur_val: any) => {
    //JCB
    const jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$');
    // American Express
    const amex_regex = new RegExp('^3[47][0-9]{0,}$');
    // Diners Club
    const diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$');
    // Visa
    const visa_regex = new RegExp('^4[0-9]{0,}$'); //4
    // MasterCard
    const mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$');
    const maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$');
    const discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');
    cur_val = cur_val.replace(/\D/g, '');

    var sel_brand = 'unknown';
    if (cur_val.match(jcb_regex)) {
      sel_brand = 'Jcb';
    } else if (cur_val.match(amex_regex)) {
      sel_brand = 'AmericanExpress';
    } else if (cur_val.match(diners_regex)) {
      sel_brand = 'Diners_club';
    } else if (cur_val.match(visa_regex)) {
      sel_brand = 'Visa';
    } else if (cur_val.match(mastercard_regex)) {
      sel_brand = 'Mastercard';
    } else if (cur_val.match(discover_regex)) {
      sel_brand = 'Discover';
    } else if (cur_val.match(maestro_regex)) {
      if (cur_val[0] == '5') {
        sel_brand = 'Mastercard';
      } else {
        sel_brand = 'Maestro';
      }
    }

    return sel_brand;
  };

  const creditCardAccepted = () => {
    const creditCards = [
      {
        img: 'creditcards/visa.png',
        title: 'Visa',
      },
      {
        img: 'creditcards/mastercard.png',
        title: 'MasterCard',
      },
      {
        img: 'creditcards/amex.png',
        title: 'American Express',
      },
      {
        img: 'creditcards/discover.png',
        title: 'Discover',
      },
    ];

    const accepted = [] as any;
    const accpetCard = (card: any) => {
      if (org?.ccAcceptVisa && card.title === 'Visa') {
        accepted.push(card);
      }
      if (org?.ccAcceptAmericanExpress && card.title === 'American Express') {
        accepted.push(card);
      }
      if (org?.ccAcceptMasterCard && card.title === 'MasterCard') {
        accepted.push(card);
      }
      if (org?.ccAcceptDiscover && card.title === 'Discover') {
        accepted.push(card);
      }
    };

    creditCards.map((card: any, index: number) => accpetCard(card));

    setaccepted(accepted);
  };

  return (
    <WebsitePaymentLayout>
      <form ref={formRef} noValidate autoComplete="off">
        <Card className={styles.header}>
          <CardHeader title="Make a Payment Online" />
        </Card>
        <Card className={styles.header}>
          <CustomSeparator menuList={['Enter Information', 'Receipt']} />
        </Card>
        <div className={styles.container}>
          <div className={styles.columnLeft}>
            <div className={[styles.row, styles.totalRow].join(' ')}>
              <Card className={styles.cardContainer}>
                <CardHeader title="Citation/Case Information" className={styles.cardHeader} />
                <div className={styles.inputFieldDiv}>
                  <TextField
                    value={referenceNumber}
                    className={styles.inputFieldSelect}
                    variant="outlined"
                    id="referenceNumber"
                    label={props.manageOrg.orgByUrl?.referenceNumberLabel}
                    name="referenceNumber"
                    disabled={loadingJISDate}
                    required
                    error={errors.findIndex((fieldName: any) => fieldName === 'referenceNumber') > -1}
                    onChange={(e) => handleOnChangeCitation(e)}
                    onBlur={(e) => checkJISAmount(e)}
                  />
                  <TextField
                    value={liscenseNumber}
                    className={styles.inputFieldSelect}
                    variant="outlined"
                    id="liscenseNumber"
                    label="Drivers License Number"
                    name="liscenseNumber"
                    error={errors.findIndex((fieldName: any) => fieldName === 'liscenseNumber') > -1}
                    onChange={(e) => handleOnChangeCitation(e)}
                  />
                </div>
                <div className={styles.inputFieldDiv}>
                  <TextField value={firstName} className={styles.inputFieldSelect} variant="outlined" id="firstName" label="First Name" name="firstName" required error={errors.findIndex((fieldName: any) => fieldName === 'firstName') > -1} onChange={(e) => handleOnChangeCitation(e)} />
                  <TextField value={lastName} className={styles.inputFieldSelect} variant="outlined" id="lastName" label="Last Name" name="lastName" error={errors.findIndex((fieldName: any) => fieldName === 'lastName') > -1} required onChange={(e) => handleOnChangeCitation(e)} />
                </div>
                <div className={styles.inputFieldDiv}>
                  {defaultProduct == "" && (
                    <FormControl variant="outlined" required className={styles.inputFieldSelect}>
                      <InputLabel id="productLabel">Product</InputLabel>
                      <Select value={products} labelId="productLabel" label="Product" id="productId" name="productId" required onChange={(e) => handleOnChangeCitation(e)} error={errors.findIndex((fieldName: any) => fieldName === 'productId') > -1} variant="outlined">
                        {product !== undefined && product}
                      </Select>
                    </FormControl>
                  )}
                  <TextField
                    onBlur={(e) => onHandleBlurAmount(e)}
                    value={amount}
                    className={styles.inputFieldSelect}
                    helperText={amount > 10000 && 'Amount must be lower than 10000'}
                    variant="outlined"
                    id="amount"
                    label={loadingJISDate ? ' Loading amount' : 'Amount'}
                    name="amount"
                    type="number"
                    disabled={loadingJISDate}
                    required
                    error={errors.findIndex((fieldName: any) => fieldName === 'amount') > -1 || amount > 10000}
                    onChange={(e) => handleOnChangeCitation(e)}
                  />
                </div>
              </Card>
            </div>
            <div className={[styles.row, styles.totalRow].join(' ')}>
              <Card className={styles.cardContainer}>
                <CardHeader title="Contact Information" className={styles.cardHeader} />
                <div className={styles.inputFieldDiv}>
                  <TextField
                    value={phoneNumber}
                    className={styles.inputFieldSelect}
                    variant="outlined"
                    id="phone_number"
                    label="Phone Number"
                    name="phoneNumber"
                    required
                    error={errors.findIndex((fieldName: any) => fieldName === 'phoneNumber') > -1}
                    InputProps={{
                      inputComponent: PhoneInput as any,
                    }}
                    onChange={(e) => handleOnChangeContact(e)}
                  />
                  <TextField
                    value={email}
                    className={styles.inputFieldSelect}
                    variant="outlined"
                    id="email"
                    label="E-mail"
                    name="email"
                    error={errors.findIndex((fieldName: any) => fieldName === 'email') > -1}
                    InputProps={{
                      inputComponent: EmailInput as any,
                    }}
                    required
                    onChange={(e) => handleOnChangeContact(e)}
                  />
                </div>
                <FormControlLabel control={<Checkbox checked={sendConfrim} onChange={(e: any) => handleOnChangeContact(e)} name="sendConfirmation" color="primary" />} label="Send Confirmation" className={styles.inputFieldCheck} />
              </Card>
            </div>
            <div className={[styles.row, styles.totalRow].join(' ')}>
              <TotalCard total={total} subTotal={amount} feeProcessing={feeProcessing} />
            </div>
          </div>
          <div className={styles.columnRight}>
            <div className={[styles.row, styles.totalRow].join(' ')}>
              <Card className={styles.cardContainer}>
                <CardHeader title="Billing Information" className={styles.cardHeader} />
                <div className={styles.inputFieldDiv}>
                  <TextField value={firstNameB} className={styles.inputFieldSelect} variant="outlined" id="firstNameB" label="First Name" name="firstNameB" required onChange={(e) => handleOnChangeBilling(e)} error={errors.findIndex((fieldName: any) => fieldName === 'firstNameB') > -1} />
                  <TextField value={lastNameB} className={styles.inputFieldSelect} variant="outlined" id="lastNameB" label="Last Name" name="lastNameB" required onChange={(e) => handleOnChangeBilling(e)} error={errors.findIndex((fieldName: any) => fieldName === 'lastNameB') > -1} />
                </div>
                <TextField value={address} className={styles.inputFieldSelect} variant="outlined" id="address" label="Address" name="address" required onChange={(e) => handleOnChangeBilling(e)} error={errors.findIndex((fieldName: any) => fieldName === 'address') > -1} />
                <div className={styles.inputFieldDiv}>
                  <TextField value={city} className={styles.inputFieldSelect} variant="outlined" id="city" label="City" name="city" required onChange={(e) => handleOnChangeBilling(e)} error={errors.findIndex((fieldName: any) => fieldName === 'city') > -1} />
                  <FormControl variant="outlined" required className={styles.inputFieldSelect}>
                    <InputLabel id="stateLabel">State / Province</InputLabel>
                    <Select value={stateDatas} labelId="stateLabel" label="State / Province" id="stateId" name="stateId" required onChange={(e) => handleOnChangeBilling(e)} variant="outlined" error={errors.findIndex((fieldName: any) => fieldName === 'stateId') > -1}>
                      {stateProvince}
                    </Select>
                  </FormControl>
                </div>
                <div className={styles.inputFieldDiv}>
                  <TextField value={zip} className={styles.inputFieldSelect} variant="outlined" id="zip" label="Zip" name="zip" required onChange={(e) => handleOnChangeBilling(e)} error={errors.findIndex((fieldName: any) => fieldName === 'zip') > -1} />
                  <FormControl variant="outlined" required className={styles.inputFieldSelect}>
                    <InputLabel id="countryLabel">Country</InputLabel>
                    <Select value={countryDatas} labelId="countryLabel" label="Country" id="countryId" name="countryId" required error={errors.findIndex((fieldName: any) => fieldName === 'countryId') > -1} onChange={(e) => handleOnChangeBilling(e)} variant="outlined">
                      {country}
                    </Select>
                  </FormControl>
                </div>
              </Card>
            </div>
            <div className={[styles.row, styles.totalRow].join(' ')}>
              <Card className={[styles.cardContainer].join(' ')}>
                <CardHeader title="Credit Card" className={styles.cardHeader} />
                {accepted.length > 0 && (
                  <CreditCardInput
                    cardNumberInputProps={{ value: ccNumber, name: 'ccNumber', onChange: (e: React.ChangeEvent<any>) => handleOnChange(e) }}
                    cardExpiryInputProps={{ value: ccExpiry, name: 'ccExpiry', onChange: (e: React.ChangeEvent<any>) => handleOnChange(e) }}
                    cardCVCInputProps={{ value: ccCvv, name: 'ccCvv', onChange: (e: React.ChangeEvent<any>) => handleOnChange(e) }}
                    containerClassName={styles.inputField}
                    fieldClassName={styles.inputFieldCard}
                  />
                )}
                <CreditCards org={props.manageOrg.orgByUrl as any} />
              </Card>
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <Card className={styles.cardContainer}>
            <TermAndConditions />
            {notFirst && !canPay && <span>You need to accept Term and conditions first</span>}
            <div className={styles.buttons}>
              <FormControlLabel control={<Checkbox checked={termAndConditions} onChange={(e: any) => handleOnChange(e)} name="termAndConditions" color="secondary" />} label="Accept Terms & Conditions" className={styles.inputFieldSelect} />
              <div className={styles.continueButtonDiv}>
                {isLoadingAddPayment ? (
                  <CircularProgress />
                ) : (
                  <Button variant="contained" disabled={loadingJISDate} color="primary" onClick={onContinueClick} className={styles.continueButton}>
                    Pay Now
                  </Button>
                )}
              </div>
            </div>
          </Card>
        </div>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openError} autoHideDuration={10000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={severity}>
            {snackMessage}
          </Alert>
        </Snackbar>
      </form>
    </WebsitePaymentLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WebistePaymentForm));
