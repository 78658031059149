import { CircularProgress, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
// const CreditCardInput = require('react-credit-card-input'); // Require because we have no type declaration.
import { useForm } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import * as typesProducts from '../../../../src/store/clerk-payment/types';
import * as typesOrgs from '../../../../src/store/manage-org/types';
import errorMessages from '../../../error_messages.json';
import ManageErrorType from '../../../services/errorHelper';
import { AppState } from '../../../store';
import { addPaymentWeb, detailPayment, getProductsByUrl, validatePaymentItem } from '../../../store/clerk-payment/actions';
import * as types from '../../../store/clerk-payment/types';
import { calculateFeeProccesingWebsite, calculateFeeProccesingWebsitePromise } from '../../../store/manage-fee/actions';
import { getOrganizationByUrlIdentifier } from '../../../store/manage-org/actions';
import { navigate, toggleRouteBack } from '../../../store/system/actions';
import { ERROR_ACTION } from '../../../store/system/types';
import { ReferenceNumberModel, TransactionDetailResponse, WebsitePaymentModel } from '../../../swagger-client/api';
import WebsitePaymentLayoutDevice from '../WebsitePaymentLayoutDevice';
import BillingInfoDevice from './components/BillingInfo/BillingInfoDevice';
import CitationCaseInfo from './components/CitationCaseInfo/CitationCaseInfo';
import ContactInfoDevice from './components/ContactInfo/ContactInfoDevice';
import CreditCardDevice from './components/CreditCardDevice/CreditCardDevice';
import TotalCard from './components/TotalCard/TotalCard';
import styles from './WebistePaymentFormDevice.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  clerkPayment: state.clerkPayment,
  fee: state.manageFee,
  manageOrg: state.manageOrg,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
      validatePaymentItem,
      calculateFeeProccesingWebsite,
      detailPayment,
    },
    dispatch
  );

const WebistePaymentFormDevice = (props: any) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const { urlIdentifier } = useParams<{ urlIdentifier: string }>();
  const products = useSelector((state: any) => state.clerkPayment.productsWebsite);
  const org = useSelector((state: any) => state?.manageOrg?.orgByUrl);
  let history = useHistory();
  const [notFirst, setNotFirst] = useState(false);
  const [canPay, setCanPay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [openError, setOpenError] = useState(false);
  const [loadingFetchJIS, setloadingFetchJIS] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    // formState: { errors },
  } = useForm();

  useEffect(() => {
    getProductsByUrl(urlIdentifier)
      .then((result: any) => {
        dispatch({
          type: typesProducts.GET_PRODUCTS_WEBSITE,
          payload: result,
        });
      })
      .catch((err) => {
        // goToLogin();
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.clerkPaymentGetProducts,
        });
      });
  }, []);

  useEffect(() => {
    getOrganizationByUrlIdentifier(urlIdentifier)
      .then((org) => {
        dispatch({
          type: typesOrgs.GET_ORG_BY_URL_IDENTIFIER,
          payload: org,
        });
      })
      .catch((err) => {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.manageOrgUpdate + '--' + error,
        });
      });
  }, []);

  const getCanPay = (value: any) => {
    setCanPay(!canPay);
  };

  const getNotFirst = (value: any) => {
    setNotFirst(!notFirst);
  };

  const handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  const onSubmit = async (data: any) => {
    const model: WebsitePaymentModel = data;
    const toValidate: ReferenceNumberModel = {
      referenceNumber: model.paymentItem.referenceNumber,
      urlIdentifier: urlIdentifier,
    };
    model.urlIdentifier = urlIdentifier;
    model.source = 'website';

    if (step === 0) {
      setLoading(true);
      const calculateAmount = {
        paymentItems: [
          {
            referenceNumber: 'asd',
            firstName: 'asd',
            lastName: 'asd',
            productId: getValues('paymentItem.productId'),
            liscenseNumber: '',
            amount: getValues('paymentItem.amount'),
          },
        ],
        urlIdentifier: urlIdentifier,
        paymentSource: 'website',
      };
      const proccessFee: any = await calculateFeeProccesingWebsitePromise(calculateAmount);

      const feeProcessingFetch = proccessFee.data.feeProcessing;

      setValue('paymentItem.feeProcessing', feeProcessingFetch.toString());
      setLoading(false);
    }

    if (step < 4) {
      setStep(step + 1);
    } else {
      //submit form
      // let inJIS = true;
      if (canPay) {
        const inJIS = model.paymentItem.inJIS;
        const transactionDetails: TransactionDetailResponse = data.transactionDetails;
        let isCapturePayment = false;
        if (inJIS) {
          if (transactionDetails.amountDue === Number(model.paymentItem.amount)) {
            isCapturePayment = true;
          }
        }
        model.paymentItem.inJIS = isCapturePayment;

        const t = cc_brand_id(getValues('cardInformation.cardNumber'));
        const accepted = acceptCreditCardOrg(t);
        if (accepted) {
          setLoading(true);
          const paymentReference = await validatePaymentItem(toValidate);
          dispatch({
            type: types.GET_PAYMENT_BY_REFERENCE,
            payload: paymentReference,
          });
          if (paymentReference.data.length !== 0) {
            history.push({ pathname: `/web/${model.urlIdentifier}/payments`, state: { propOne: model } });
          } else {
            try {
              const pay = await addPaymentWeb(model);
              dispatch({
                type: types.ADD_PAYMENT_WEBSITE,
                payload: pay,
              });
              dispatch(toggleRouteBack());
              history.push({
                pathname: `/web/${model.urlIdentifier}/confirm`,
                state: {
                  propOne: pay,
                },
              });
              setNotFirst(false);
              setLoading(false);
            } catch (error) {
              dispatch({
                type: ERROR_ACTION,
                payload: errorMessages.addPaymentWebsite,
              });
              setLoading(false);
              setSnackMessage('Error while creating payment');
              setOpenError(true);
            }
          }
        } else {
          setSnackMessage('This credit card is not accepted in this organization');
          setOpenError(true);
        }
      } else {
        setNotFirst(true);
      }
    }
  };

  const acceptCreditCardOrg = (type: any) => {
    let accepts = false;
    if (type === 'Visa' && org?.ccAcceptVisa) {
      accepts = true;
    }
    if (type === 'AmericanExpress' && org?.ccAcceptAmericanExpress) {
      accepts = true;
    }
    if (type === 'Mastercard' && org?.ccAcceptMasterCard) {
      accepts = true;
    }
    if (type === 'Discover' && org?.ccAcceptDiscover) {
      accepts = true;
    }
    return accepts;
  };

  const cc_brand_id = (cur_val: any) => {
    //JCB
    const jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$');
    // American Express
    const amex_regex = new RegExp('^3[47][0-9]{0,}$');
    // Diners Club
    const diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$');
    // Visa
    const visa_regex = new RegExp('^4[0-9]{0,}$'); //4
    // MasterCard
    const mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$');
    const maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$');
    const discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');
    cur_val = cur_val.replace(/\D/g, '');

    var sel_brand = 'unknown';
    if (cur_val.match(jcb_regex)) {
      sel_brand = 'Jcb';
    } else if (cur_val.match(amex_regex)) {
      sel_brand = 'AmericanExpress';
    } else if (cur_val.match(diners_regex)) {
      sel_brand = 'Diners_club';
    } else if (cur_val.match(visa_regex)) {
      sel_brand = 'Visa';
    } else if (cur_val.match(mastercard_regex)) {
      sel_brand = 'Mastercard';
    } else if (cur_val.match(discover_regex)) {
      sel_brand = 'Discover';
    } else if (cur_val.match(maestro_regex)) {
      if (cur_val[0] === '5') {
        sel_brand = 'Mastercard';
      } else {
        sel_brand = 'Maestro';
      }
    }

    return sel_brand;
  };

  const handleLoadingJIS = (value: boolean) => {
    setloadingFetchJIS(value);
  };

  return (
    <WebsitePaymentLayoutDevice step={step}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        {step === 0 ? (
          <CitationCaseInfo handleLoadingJIS={handleLoadingJIS} products={products} register={register} setValue={setValue} getValues={getValues} org={org} />
        ) : step === 1 ? (
          <ContactInfoDevice register={register} confrimation={true} getValues={getValues} setValue={setValue} />
        ) : step === 2 ? (
          <TotalCard total={2} subTotal={2} feeProcessing={2} getValues={getValues} />
        ) : step === 3 ? (
          <BillingInfoDevice register={register} />
        ) : (
          <CreditCardDevice setValue={setValue} getCanPay={getCanPay} getNotFirst={getNotFirst} />
        )}
        {notFirst && !canPay && <span>You need to accept Term and conditions first</span>}
        {step <= 4 && (
          <div className={styles.containerButtons}>
            {loading ? (
              <CircularProgress className={styles.loading} />
            ) : (
              <button disabled={loadingFetchJIS} type="submit" className={loadingFetchJIS ? styles.continuePrimaryDisabled : styles.continuePrimary}>
                {step === 4 ? <>Pay Now</> : <>Continue</>}
              </button>
            )}
            {step > 0 && (
              <button
                className={styles.backPrimary}
                onClick={(e) => {
                  e.preventDefault();
                  setStep(step - 1);
                }}
              >
                Back
              </button>
            )}
          </div>
        )}
      </form>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {snackMessage}
        </Alert>
      </Snackbar>
    </WebsitePaymentLayoutDevice>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WebistePaymentFormDevice));
