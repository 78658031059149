import { Action } from 'redux';
import { Court, Organization } from '../../swagger-client';

export const GET_ORG_DETAIL_SUCCESSFUL = 'GET_ORG_DETAIL_SUCCESSFUL_MANAGE_ORG';
export const UPDATE_ORG_DETAIL_SUCCESSFUL = 'UPDATE_ORG_DETAIL_SUCCESSFUL_MANAGE_ORG';
export const ADD_ORG_DETAIL_SUCCESSFUL = 'ADD_ORG_DETAIL_SUCCESSFUL_MANAGE_ORG';
export const CLEAR_STATE = 'CLEAR_STATE_MANAGE_ORG';
export const GET_ORG_BY_URL_IDENTIFIER = 'GET_ORG_BY_URL_IDENTIFIER';
export const GET_COURT_BY_NAME = 'GET_COURT_BY_NAME';

// Action types

export interface IGetOrgDetailSuccessful extends Action {
  type: typeof GET_ORG_DETAIL_SUCCESSFUL;
  payload: Organization;
}

export interface IAddOrgDetailSuccessful extends Action {
  type: typeof ADD_ORG_DETAIL_SUCCESSFUL;
  payload: Organization;
}

export interface IUpdateOrgDetailSuccessful extends Action {
  type: typeof UPDATE_ORG_DETAIL_SUCCESSFUL;
  payload: Organization;
}

export interface IClearStateAction extends Action {
  type: typeof CLEAR_STATE;
}

export interface IGetOrgByUrlIdentifier extends Action {
  type: typeof GET_ORG_BY_URL_IDENTIFIER;
  payload: Organization;
}

export interface IGetCourtByName extends Action {
  type: typeof GET_COURT_BY_NAME;
  payload: Array<Court>;
}

export type ManageOrgsActionTypes = IGetOrgDetailSuccessful | IAddOrgDetailSuccessful | IUpdateOrgDetailSuccessful | IClearStateAction | IGetOrgByUrlIdentifier | IGetCourtByName;

// Store state

export interface IManageOrgState {
  org: Organization | null;
  orgByUrl: Organization | null;
  courts: Array<Court> | null;
}
