import { Button, Card, CardHeader } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../store';
import { filterPaymentById } from '../../store/clerk-payment/actions';
import { getProductById } from '../../store/manage-product/actions';
import { navigate } from '../../store/system/actions';
import { Product } from '../../swagger-client';
import CustomSeparator from '../bread-crumbs/BreadCrumbs';
import PaymentInfo from '../clerk-payment-authorize/components/PaymentInfo/PaymentInfo';
import TableApp from '../table-app/TableApp';
import styles from './ClerkPaymentReceipt.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  clerkPayment: state.clerkPayment,
  manageProduct: state.manageProduct,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
      filterPaymentById,
      getProductById,
    },
    dispatch
  );

const ClerkPaymentReceipt = (props: any) => {
  const history = useHistory();
  const { paymentId } = useParams<{ paymentId: string }>();
  const [rows, setRows] = useState([]);
  const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
  const [invoiceTaxes, setInvoiceTaxes] = useState(0);
  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [ccNumber, setCcNumber] = useState('');
  const [date, setDate] = useState('');

  const [paymentDeclined, setPaymentDeclined] = useState(false);

  const payfiltered = useSelector((state: any) => state.clerkPayment.paymentFiltredById);

  useEffect(() => {
    const filter = { status: 'Authorized', rowLength: 25 };
    props.filterPaymentById(filter, paymentId);
  }, []);

  useEffect(() => {
    if (payfiltered.data) {
      const rows = payfiltered?.data[0].paymentItems?.map((payment: { product: Product; referenceNumber: string; firstName: string; lastName: string; amount: number }) => {
        return createRow(payment.product.name, payment.referenceNumber, payment.firstName, payment.lastName, payment.amount);
      });
      const invoiceSubtotal = payfiltered?.data[0].subTotal;
      const invoiceTaxes = payfiltered?.data[0].feeAmount;
      const invoiceTotal = payfiltered?.data[0].total;
      const ccNumber = payfiltered?.data[0].cardLast;
      const date = payfiltered?.data[0].paymentItems[0].createdAt;
      setRows(rows);
      setInvoiceSubtotal(invoiceSubtotal);
      setInvoiceTaxes(invoiceTaxes);
      setInvoiceTotal(invoiceTotal);
      setCcNumber(ccNumber);
      setDate(date);
      if (payfiltered.data[0].status === 'cancel') {
        setPaymentDeclined(true);
      } else {
        setPaymentDeclined(false);
      }
    }
  }, [payfiltered]);

  function createRow(productId: string, referenceNumber: string, firstName: string, lastName: string, amount: number) {
    return { productId, referenceNumber, firstName, lastName, amount };
  }
  const ccExpiry = 2222;

  const onNewPaymentClick = () => {
    history.push('/payments/create');
  };

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    documentTitle: 'Civil Payments Solutions - The Easy Way To Pay',
    content: () => componentRef.current,
  });
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  } as any;
  let today = date === '' ? new Date().toLocaleDateString('en-US') : moment(date.split('T')[0]).format('MM/DD/YYYY');

  let date_string = today;
  date_string = moment(date_string).tz('America/New_York').format('MM/DD/YYYY hh:mm');

  return (
    <>
      <Card className={styles.header}>
        <CardHeader title="Receipt" />
      </Card>
      <Card className={styles.header}>
        <CustomSeparator menuList={['Enter Information', 'Confirm', 'Receipt']} />
      </Card>
      <Card className={styles.cardContainer}>
        <div ref={componentRef} className={styles.cardContainer}>
          {
            <div className={styles.headerText}>
              <span className={styles.printable}>{date_string}</span>
              <span className={styles.printable}>{'Civil Payments Solutions - The Easy Way To Pay'}</span>
            </div>
          }
          {
            <div>
              <h4 className={styles.printable}>{props.system.user?.organization.name + '- Online Payment System - ' + props.system.user?.organization.addressLine1}</h4>
            </div>
          }
          {paymentDeclined ? (
            <Alert className={styles.alertButton} severity="error">
              Your payment was declined
            </Alert>
          ) : (
            <Alert className={styles.alertButton} severity="success">
              Your payment has been processed successfully on {date_string} Confirmation code: {paymentId}
            </Alert>
          )}

          {<h4 className={styles.printable}>{'This service has been provided by ' + props.system.user?.organization.name + ' online payment system. Please keep this receipt for future reference. For questions, please contact us ' + props.system.user?.organization.phone1}</h4>}
          <TableApp rows={rows} invoiceSubtotal={invoiceSubtotal} invoiceTaxes={invoiceTaxes} invoiceTotal={invoiceTotal} />
          <PaymentInfo ccNumber={ccNumber} ccExpiry={ccExpiry} />
          {
            <div>
              <h4 className={styles.printable}>{'Signature: ______________________________________________________ Date: ____/____/___________'}</h4>
              <h4 className={styles.printable}>{'By signing this receipt you agree to the terms and conditions of this service.'}</h4>
            </div>
          }
        </div>
        <div className={styles.Buttons}>
          <div className={styles.button}>
            <Button variant="contained" onClick={handlePrint}>
              Print
            </Button>
          </div>
          <div className={styles.button}>
            <Button variant="contained" color="secondary" onClick={onNewPaymentClick}>
              New Payment
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClerkPaymentReceipt));
