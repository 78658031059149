import { Action } from 'redux';
import { PagedProductResults, Product } from '../../swagger-client';

export const GET_PRODUCTS_SUCCESSFUL = 'GET_PRODUCTS_SUCCESSFUL_PRODUCT_GRID';
export const DELETE_PRODUCT_SUCCESSFUL = 'DELETE_PRODUCT_SUCCESSFUL_PRODUCT_GRID';
export const CLEAR_STATE = 'CLEAR_STATE_PRODUCT_GRID';
// Action types


export interface IGetProductsSuccessful extends Action {
    type: typeof GET_PRODUCTS_SUCCESSFUL,
    payload: PagedProductResults
}

export interface IDeleteProductSuccessful extends Action {
    type: typeof DELETE_PRODUCT_SUCCESSFUL,
    payload: number
}

export interface IClearStateAction extends Action {
    type: typeof CLEAR_STATE
}

export type ManageProductActionTypes = IGetProductsSuccessful| IDeleteProductSuccessful | IClearStateAction;

// Store state 

export interface IProductsGridState {
    products: Array<Product>;
    count: number;
}