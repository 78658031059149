import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { whiteLabelStrategy } from '../../../services/whiteLabelStrategy';
import { AppState } from '../../../store';
import { navigate } from '../../../store/system/actions';
import styles from './WebistePayment.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const WebistePaymentDevice = () => {
  let history = useHistory();
  const { urlIdentifier } = useParams<{ urlIdentifier: string }>();

  const onContinueClick = () => {
    history.push(`/web/${urlIdentifier}/pay`);
  };
  const logo = whiteLabelStrategy().logo;

  return (
    <div className={styles.container}>
      <img className={styles.logo} src={logo} alt="Logo" />
      <button onClick={onContinueClick} className={styles.continuePrimary}>
        Continue
      </button>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WebistePaymentDevice));
