import { RouteComponentProps } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IGeneratedMenuItem } from '../../models/IGeneratedMenuData';
import AxiosConfigurator from '../../services/AxiosConfigurator';
import * as swagger from '../../swagger-client';
import { Organization, OrganizationApi, Product } from '../../swagger-client';
import { AppState } from '../index';
import { getUserPreferences } from '../user-preferences/actions';
import * as types from './types';

export const logIn = (email: string, password: string): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<any>) => {
    const loginApi = new swagger.LoginApi(AxiosConfigurator.getConfig());

    try {
      const result = await loginApi.login({
        email,
        password,
      });

      dispatch({
        type: types.LOGIN_SUCCESSFUL,
        payload: {
          id: result.data.id,
          token: result.data.token,
          firstName: result.data.firstName,
          lastName: result.data.lastName,
          organizationId: result.data.organizationId,
          organization: result.data.organization,
          organizations: result.data.organizations,
          email: email,
          roles: result.data.roles,
        },
      });

      dispatch(getUserPreferences());
    } catch (err) {
      dispatch({
        type: types.LOGIN_FAILED,
      });
    }
  };
};

export const changeOrganization = (organization: Organization, user: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const loginApi = new swagger.LoginApi(AxiosConfigurator.getConfig());
    try {
      const result = await loginApi.changeOrganization(user.token, {
        organization: Number(organization.id),
      });
      resolve(result);
    } catch (err) {
      reject(err);
    }
  });
};

export const activateOrgForSuperUser = (orgId: number): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<any>, getState) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null) {
      const user = state.system?.user;

      const userApi = new swagger.UserApi(AxiosConfigurator.getConfig(state.system.user.token));
      let token = state.system.user.token as any;

      try {
        const result = await userApi.updateUser(token, {
          id: user?.id,
          organizationId: orgId,
          firstName: user?.firstName as string,
          lastName: user?.lastName as string,
          email: user?.email as string,
        });

        dispatch({
          type: types.ACTIVATE_ORG_FOR_SUPER_USER,
          payload: result.data,
        });
      } catch {
        dispatch({
          type: types.ERROR_ACTION,
          payload: 'Could not log in as organization!',
        });
      }
    }
  };
};

export const sendForgotPasswordEmail = (email: string): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<any>) => {
    const forgotPasswordApi = new swagger.ForgotPasswordApi(AxiosConfigurator.getConfig());

    try {
      await forgotPasswordApi.forgotPassword({ email });

      dispatch({
        type: types.SEND_PASSWORD_RESET_EMAIL_SUCCESSFUL,
      });

      dispatch(toggleRouteBack());
    } catch (err) {
      dispatch({
        type: types.ERROR_ACTION,
        payload: 'Error in sending password reset email',
      });
    }
  };
};

export const changePassword = (token: string, password: string, confirmPassword: string): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<any>) => {
    const forgotPasswordValidationApi = new swagger.ForgotPasswordApi(AxiosConfigurator.getConfig());

    try {
      await forgotPasswordValidationApi.validateToken({ token, password, confirmPassword });

      dispatch({
        type: types.CHANGE_PASSWORD_SUCCESSFUL,
      });

      dispatch(toggleRouteBack());
    } catch (err) {
      dispatch({
        type: types.ERROR_ACTION,
        payload: 'Error in validating token',
      });
    }
  };
};

export const logOut = (): types.LogOutAction => {
  return {
    type: types.LOG_OUT,
  };
};

export const setActiveView = (path: string): types.ActiveViewAction => {
  return {
    type: types.ACTIVE_VIEW,
    payload: path,
  };
};

export function navigate<TProps extends RouteComponentProps<any>>(menuItem: IGeneratedMenuItem, ownProps: TProps, initialLoad?: boolean): ThunkAction<void, AppState, null, AnyAction> {
  return async (dispatch: Dispatch<any>, getState) => {
    const state = getState();

    let menu = menuItem;

    if (typeof menuItem.path !== 'undefined' && menuItem.path !== null && menuItem.path !== '') {
      let samePath = false;

      if (menuItem.path === state.system.navigationHistory[state.system.navigationHistory.length - 1].path) {
        samePath = true;
      }

      if (samePath) {
        const oldParams = state.system.navigationHistory[state.system.navigationHistory.length - 1].urlParams;
        const newParams = menuItem.urlParams;

        if (oldParams !== newParams) {
          samePath = false;
        } else if (oldParams !== undefined && newParams !== undefined) {
          if (oldParams.length !== newParams.length) {
            samePath = false;
          } else {
            for (let i = 0; i < oldParams.length; i++) {
              samePath = oldParams[i].key === newParams[i].key && oldParams[i].value === newParams[i].value;
            }
          }
        }
      }

      if (!samePath || initialLoad) {
        let path = menuItem.path;

        if (menuItem?.urlParams !== undefined && menuItem?.urlParams !== null) {
          let params = '';

          menuItem.urlParams.forEach((param, index) => {
            params += `${param.key}=${param.value}`;

            if (index < (menuItem?.urlParams?.length as number) - 1) {
              params += '&';
            }
          });

          path = `${path}?${params}`;
        }

        ownProps.history.push(path);
      }
    }

    if (menuItem.path === undefined && menuItem.parentNavigationScope !== undefined) {
      menu = state.system.menuDataByNavigationScope[menu.parentNavigationScope as number];
    }

    dispatch(updateCurrentMenuItem(menu));
  };
}

export const updateCurrentMenuItem = (currentMenuItem: IGeneratedMenuItem): types.UpdateNavigationCurrentMenuItemAction => {
  return {
    type: types.UPDATE_NAVIGATION_CURRENT_MENU_ITEM,
    payload: currentMenuItem,
  };
};

export const errorAction = (errorMessage: string): types.ErrorAction => {
  return {
    type: types.ERROR_ACTION,
    payload: errorMessage,
  };
};

export const clearErrorsAction = (): types.ClearErrorsAction => {
  return {
    type: types.CLEAR_ERRORS_ACTION,
  };
};

export const clearState = (): types.IClearStateAction => {
  return {
    type: types.CLEAR_STATE,
  };
};

export const toggleRouteBack = (): types.ToggleRouteBack => {
  return {
    type: types.TOGGLE_ROUTE_BACK,
  };
};

export const addProductToState = (products: Product): types.UpdateProduct => {
  return {
    type: types.UPDATE_PRODUCT,
    payload: products,
  };
};

export const addNewOrganizationToMySuspription = (organization: Organization): types.UpdateOrganizationsUser => {
  return {
    type: types.UPDATE_ORGANIZATIONS_USER,
    payload: organization,
  };
};

