import { Card } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../store';
import { getProductById } from '../../../store/manage-product/actions';
import { navigate } from '../../../store/system/actions';
import PrintNewButtons from '../../clerk-payment-authorize/components/PrintNewButtons/PrintNewButtons';
import WebsitePaymentLayoutDevice from '../WebsitePaymentLayoutDevice';
import styles from './WebistePaymentConfirm.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
      getProductById,
    },
    dispatch
  );

const WebistePaymentConfirmDevice = (props: any) => {
  let history = useHistory();

  const { urlIdentifier } = useParams<{ urlIdentifier: string }>();
  const [state, setState] = useState({
    strategy: '',
    source: '',
    urlIdentifier: '',
    orgId: 1,
    paymentItem: { referenceNumber: '', organizationId: 1, firstName: '', lastName: '', productId: '', amount: 0, product: { id: null, name: '', description: '', variablePrice: '', price: '', isCounter: true, isWeb: true } },
    billingInformation: { firstName: '', lastName: '', address: '', city: '', state: [], zip: '', country: [] },
    cardInformation: { cardNumber: '', expiration: '', cvv: '' },
    contactInformation: {
      phoneNumber: '',
      email: '',
      sendConfirmation: false,
    },
    feeProcessing: 0,
    subTotal: 0,
    total: 0,
    id: null,
  });

  useEffect(() => {
    if (props.location.state) {
      setState(props.location.state.propOne);
      props.getProductById(props.location.state.propOne.paymentItem.productId);
    }
  }, []);

  useEffect(() => {
    if (props.location.state === undefined) {
      history.push(`/web/${urlIdentifier}`);
    }
  }, []);

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  } as any;
  let today = new Date();
  const dateToday = new Intl.DateTimeFormat('en-US', options).format(today);
  let date_string = dateToday.toString();
  date_string = moment(date_string).tz('America/New_York').format('MM/DD/YYYY hh:mm A z');

  function ccyFormat(num: number) {
    return `${num.toFixed(2)}`;
  }

  const subTotal = +state.subTotal;
  const feeProcessing = +state.feeProcessing;
  const total = +state.total;

  return (
    <WebsitePaymentLayoutDevice>
      <div className={styles.container}>
        <h3>Recipt</h3>
        <Card className={styles.cardContainer}>
          <Alert className={styles.alertButton} severity="success">
            Your payment has been processed successfully on {date_string} Confirmation code: {state.id}
          </Alert>

          <div className={styles.cardData}>
            <div className={styles.header}>Receipt</div>

            <div className={styles.body}>
              <div className={styles.item}>
                <div className={styles.title}>Product </div>
                <div className={styles.value}>{state.paymentItem.product.name}</div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>Citation/Case</div>
                <div className={styles.value}>{state.paymentItem.referenceNumber}</div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>First Name</div>
                <div className={styles.value}>{state.paymentItem.firstName}</div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>Last Name</div>
                <div className={styles.value}>{state.paymentItem.lastName}</div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>Phone</div>
                <div className={styles.value}>{state.contactInformation.phoneNumber}</div>
              </div>
              {/* <div className={styles.item}>
                <div className={styles.title}>Amount</div>
                <div className={styles.value}>{state.paymentItem.amount}</div>
              </div> */}
              <div className={styles.item}>
                <div className={styles.title}>Subtotal</div>
                <div className={styles.value}>
                  <span>{`$ ${ccyFormat(+subTotal)}`}</span>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>Processing Fees</div>
                <div className={styles.value}>
                  <span>{`$ ${ccyFormat(+feeProcessing)}`}</span>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>Total</div>
                <div className={styles.value}>
                  <span>{`$ ${ccyFormat(+total)}`}</span>
                </div>
              </div>
            </div>
          </div>

          <PrintNewButtons url={`/web/${urlIdentifier}/pay`} />
        </Card>
      </div>
    </WebsitePaymentLayoutDevice>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WebistePaymentConfirmDevice));
