export enum NavigationScopes {
  Home,
  Back,
  Terminals,
  NewTerminals,
  ArmoredCash,
  ArmoredCashData,
  Disputes,
  Admin,
  LoadUploads,
  Reports,
}
