import { Button, Card, CardHeader, FormGroup, TextField } from '@material-ui/core';
import queryString from 'query-string';
import React from 'react';
// Begin Redux init
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { VariantEnum } from '../../models/VariantEnum';
import { whiteLabelStrategy } from '../../services/whiteLabelStrategy';
import { AppState } from '../../store';
import { changePassword } from '../../store/system/actions';
import { SystemState } from '../../store/system/types';
// import { Form, Button, Card, Container, Row, Col, Toast } from 'react-bootstrap';
import AlertMessage from '../alert-message/AlertMessage';
import BaseComponent from '../BaseComponent';
import styles from './ChangePassword.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, any>) =>
  bindActionCreators(
    {
      changePassword,
    },
    dispatch
  );

interface IChangePasswordProps extends RouteComponentProps {
  system: SystemState;
  changePassword: (token: string, password: string, confirmPassword: string) => void;
}

interface IChangePasswordState {
  token: string;
  password: string;
  confirmPassword: string;
  failPassword: boolean;
}
// End Redux init

class ChangePassword extends BaseComponent<IChangePasswordProps, IChangePasswordState> {
  constructor(props: IChangePasswordProps) {
    super(props);

    this.state = {
      failPassword: false,
      password: '',
      confirmPassword: '',
      token: queryString.parse(this.props.location.search).token as string,
    };

    this.changePassword = this.changePassword.bind(this);
    this.passwordChangedMessage = this.passwordChangedMessage.bind(this);
  }

  private changePassword(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (this.validator.allValid()) {
      if (this.state.password === this.state.confirmPassword) {
        this.props.changePassword(this.state.token, this.state.password, this.state.confirmPassword);
        this.passwordChangedMessage();
      } else {
        this.setState({ failPassword: true });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  private passwordChangedMessage() {
    this.props.history.push('/passwordchanged');
  }

  private changePasswordFormRef = React.createRef<HTMLFormElement>();

  render() {
    const whiteLabelValues = whiteLabelStrategy();

    return (
      <div className={styles.loginContainer}>
        <div className={styles.headerOptions}>
          <Card className={styles.loginCard}>
            <CardHeader title={'Change Password'} className={styles.headerTitle} />
            <form ref={this.changePasswordFormRef} noValidate onSubmit={this.changePassword}>
              <FormGroup row={true}>
                <img alt={'Company logo'} className={styles.logo} src={whiteLabelValues.loginLogo} />
              </FormGroup>
              <FormGroup row={true}>
                <FormGroup>
                  <div className={styles.inputFieldDiv}>
                    <TextField className={styles.inputField} variant="outlined" type="password" name="password" id="password" required onChange={this.handleInputChange} placeholder="Password..." />
                    <TextField className={styles.inputField} variant="outlined" type="password" name="confirmPassword" id="confirmPassword" required onChange={this.handleInputChange} placeholder="Re enter Password..." />
                    <div className={styles.inputErrorAlert}>
                      {this.validator.message('password', this.state.password, 'required|min:6')}
                      {this.validator.message('confirmPassword', this.state.confirmPassword, 'required|min:6')}
                    </div>
                    {/* <div className={styles.inputErrorAlert}>
                      <AlertMessage shown={this.props.system.loginFailed} variant={VariantEnum.danger} message={'Your password must be 6 characters long!'} />
                    </div> */}
                  </div>
                </FormGroup>
              </FormGroup>
              <FormGroup>
                <AlertMessage shown={this.props.system.loginFailed} variant={VariantEnum.danger} message={'Your password must be 6 characters long!'} />
                {this.state.failPassword && <span>Passwords dont match!</span>}
                <FormGroup row={true} className={styles.buttonRow}>
                  <Button type="submit" style={{ backgroundColor: whiteLabelValues.color1 }} className={styles.loginViewButton}>
                    Reset
                  </Button>
                </FormGroup>
              </FormGroup>
            </form>
          </Card>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
