import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import errorMessages from '../../error_messages.json';
import { FilterOperation } from '../../models/FilterOperation';
import AxiosConfigurator from '../../services/AxiosConfigurator';
import ManageErrorType from '../../services/errorHelper';
import * as swagger from '../../swagger-client';
import { AppState } from '../index';
import { ERROR_ACTION } from '../system/types';
import * as types from './types';

export const getFeesForGrid = (skip?: number | null, take?: number | null, sortOptions?: Array<{ field: string; direction: string }> | null, filterOptions?: Array<{ field: string; value: any; operation: FilterOperation }> | null): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null) {
      const feeApi = new swagger.FeeApi(AxiosConfigurator.getConfig(state.system.user.token));
      let token = state.system.user.token as any;
      let query: any = {};

      if (typeof skip !== 'undefined' && skip !== null) {
        query.skip = skip;
      }
      // if (typeof take !== 'undefined' && take !== null) {
      //   query.take = take;
      // }

      // if (sortOptions === undefined || sortOptions === null) {
      //   sortOptions = [];
      // }

      if (filterOptions === undefined || filterOptions === null) {
        filterOptions = [];
      }

      // filterOptions.push({
      //   field: 'deletedAt',
      //   value: null,
      //   operation: FilterOperation.Equal,
      // });

      query.filterOptions = window.btoa(JSON.stringify(filterOptions));

      // if (sortOptions.length > 0) {
      //   query.sortOptions = window.btoa(JSON.stringify(sortOptions));
      // }

      try {
        const result = await feeApi.get(token, { query });
        dispatch({
          type: types.GET_FEES_SUCCESSFUL,
          payload: result.data,
        });
      } catch {
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.getFees,
        });
      }
    }
  };
};

export const deleteFee = (idToDelete: number): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null) {
      let token = state.system.user.token as any;
      const feeApi = new swagger.FeeApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        await feeApi._delete(token, idToDelete, {
          query: {
            id: idToDelete,
          },
        });

        dispatch({
          type: types.DELETE_FEE_SUCCESSFUL,
          payload: idToDelete,
        });
      } catch (err) {
        const error = ManageErrorType(err as any)
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.deleteFee + '--' + error,
        });
      }
    }
  };
};

export const clearState = (): types.IClearStateAction => {
  return {
    type: types.CLEAR_STATE,
  };
};
