export enum FilterOperation {
  Contains = 'contains',
  NotContains = 'notContains',
  StartsWith = 'startsWith',
  EndsWith = 'endsWith',
  Equal = 'equal',
  NotEqual = 'notEqual',
  GreaterThan = 'greaterThan',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NumberRange = 'numberRange',
  DateEqual = 'dateEqual',
  DateGreaterThan = 'dateGreaterThan',
  DateLessThan = 'dateLessThan',
  DateGreaterThanOrEqual = 'dateGreaterThanOrEqual',
  DateLessThanOrEqual = 'dateLessThanOrEqual',
  DateRange = 'dateRange',
  In = 'in',
  OrEquals = 'OrEquals',
}
