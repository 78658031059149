import React from 'react';
import { TableCell, Input } from "@material-ui/core";
import styles from './DateFilterCell.module.scss';

const DateCellBase = ({ filter, children, onFilter, ...restProps }: any) => {
    return (
      <TableCell >
        <div className={styles.filterCell}>
          <div>
            {children[0]}
          </div>
          <Input className={styles.filterDateInput}
            type="date"
            value={filter ? filter.value : ''}
            onChange={e => {
              onFilter(e.target.value ? { value: e.target.value, operation: children[0].props.value } : null);
            }}
            placeholder="Filter..."
            inputProps={{
              style: { height: 'inherit' },
              min: 1,
              max: 4,
            }}
          />
        </div>
      </TableCell>
    );
  }

  export const DateFilterCell = (props: any) => (
    <DateCellBase {...props} />
  );