import { RehydrateAction } from 'redux-persist';
import * as types from './types';

const initialState: types.IManageOrgsState = {
  orgs: [],
  orgCount: 0,
};

export function manageOrgsReducer(state = initialState, action: types.ManageOrgsActionTypes | RehydrateAction): types.IManageOrgsState {
  switch (action.type) {
    case types.GET_ALL_ORGS_SUCCESSFUL:
      return {
        ...state,
        orgs: action.payload.values,
        orgCount: action.payload.count,
      };
    default:
      return state;
  }
}
