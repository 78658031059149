import { RehydrateAction } from 'redux-persist';
import * as types from './types';

const initialState: types.IFeesGridState = {
  fees: [],
  count: 0,
};

export function feesGridReducer(state = initialState, action: types.FeesGridActionTypes | RehydrateAction): types.IFeesGridState {
  switch (action.type) {
    case types.GET_FEES_SUCCESSFUL:
      return {
        ...state,
        fees: action.payload.values,
        count: action.payload.count,
      };
    case types.DELETE_FEE_SUCCESSFUL:
      return {
        ...state,
        fees: state.fees.filter((fee) => fee.id !== action.payload),
        count: state.count - 1,
      };
    case types.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
}
