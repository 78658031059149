import { Action } from 'redux';
import { Fee, PagedFeeResults } from '../../swagger-client';

export const GET_FEES_SUCCESSFUL = 'GET_FEES_SUCCESSFUL_FEES_GRID';
export const DELETE_FEE_SUCCESSFUL = 'DELETE_FEE_SUCCESSFUL_FEES_GRID';
export const CLEAR_STATE = 'CLEAR_STATE_FEES_GRID';
// Action types

export interface IGetFeesSuccessful extends Action {
  type: typeof GET_FEES_SUCCESSFUL;
  payload: PagedFeeResults;
}

export interface IDeleteFeeSuccessful extends Action {
  type: typeof DELETE_FEE_SUCCESSFUL;
  payload: number;
}

export interface IClearStateAction extends Action {
  type: typeof CLEAR_STATE;
}

export type FeesGridActionTypes = IGetFeesSuccessful | IDeleteFeeSuccessful | IClearStateAction;

// Store state

export interface IFeesGridState {
  fees: Array<Fee>;
  count: number;
}
