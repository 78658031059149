import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { systemReducer } from './system/reducers';
import { currentUserReducer } from './current-user/reducers';
import { usersReducer } from './users/reducers';
import { userPreferenceReducer } from './user-preferences/reducers';
import { manageOrgsReducer } from './manage-orgs/reducers';
import { manageOrgReducer } from './manage-org/reducers';
import { manageProductReducer } from './manage-product/reducers';
import { manageFeeReducer } from './manage-fee/reducers';
import { feesGridReducer } from './fees-grid/reducers';
import { productsGridReducer } from './product-grid/reducers';
import { clerkPaymentReducer } from './clerk-payment/reducers';

const rootReducer = combineReducers({
  system: systemReducer,
  currentUser: currentUserReducer,
  users: usersReducer,
  userPreferences: userPreferenceReducer,
  manageOrgs: manageOrgsReducer,
  manageOrg: manageOrgReducer,
  productsGrid: productsGridReducer,
  manageProduct: manageProductReducer,
  manageFee: manageFeeReducer,
  feesGrid: feesGridReducer,
  clerkPayment: clerkPaymentReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'firstISOReport',
    'terminalDetail',
    'locationDetail',
    'flmProviderDetail',
    'armoredCarrierDetail',
    'armoredCarrierBranchDetail',
    'armoredCarrierBranchContactDetail',
    'partnerDetail',
    'terminalDetail',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type AppState = ReturnType<typeof rootReducer>;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default (() => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
  );
  const persistor = persistStore(store);

  return { store, persistor };
})();
