import { Card, CardHeader, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../../store';
import { detailPaymentPromise } from '../../../../../store/clerk-payment/actions';
import { calculateFeeProccesingWebsite } from '../../../../../store/manage-fee/actions';
import { TransactionDetailResponse } from '../../../../../swagger-client';
import styles from './CitationCaseInfo.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  fee: state.manageFee,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      calculateFeeProccesingWebsite,
    },
    dispatch
  );

const CitationCaseInfo = (props: any) => {
  const [loadingJIS, setloadingJIS] = useState(false);
  const [amount, setAmount] = useState(0);
  const [transactionDetail, setTransactionDetail] = useState<TransactionDetailResponse | undefined>(undefined);

  const products = useSelector((state: any) => state.clerkPayment.productsWebsite.data);
  const defaultProduct = products?.find((prod: any) => prod.is_default)?.id

  const handleChange = (e: React.ChangeEvent<any>) => {
    if (e.target.name === 'paymentItem.amount') {
      const value = e.target.value;
      setAmount(value);
      props.setValue('paymentItem.subTotal', value);
      props.setValue('paymentItem.amount', value);
    }
    if (e.target.name === 'paymentItem.productId') {
      const value = e.target.value;
      props.setValue('paymentItem.productId', value);
    }
  };

  const checkJISAmount = async (event: any) => {
    const referenceNumber = event.target.value;
    const { org } = props;
    const checkAgain = transactionDetail?.caseNumber !== referenceNumber ? true : false;
    if (referenceNumber && checkAgain) {
      if (org?.useJis) {
        setloadingJIS(true);
        props.handleLoadingJIS(true);
        const detailPayment = {
          caseNumber: referenceNumber,
          court: org?.courtId ? org?.courtId : 0,
          caseStatus: 'Open',
        };

        try {
          const detailsTransactions = await detailPaymentPromise(detailPayment);
          const detailsTransactionsFetch = detailsTransactions[0];
          setTransactionDetail(detailsTransactionsFetch);
          props.setValue('paymentItem.referenceNumber', referenceNumber);
          props.setValue('paymentItem.amount', detailsTransactionsFetch?.amountDue);
          setAmount(Number(detailsTransactionsFetch?.amountDue));
          props.setValue('transactionDetails', detailsTransactionsFetch);
          props.setValue('paymentItem.inJIS', true);
          setloadingJIS(false);
          props.handleLoadingJIS(false);
        } catch (error) {
          props.setValue('paymentItem.referenceNumber', referenceNumber);
          props.setValue('paymentItem.amount', 0);
          props.setValue('paymentItem.inJIS', false);
          setloadingJIS(false);
          props.handleLoadingJIS(false);
        }
      } else {
        props.setValue('paymentItem.referenceNumber', referenceNumber);
      }
    }
  };

  return (
    <Card className={styles.cardContainer}>
      <CardHeader title="Citation/Case Information" className={styles.cardHeader} />
      <div className={styles.inputFieldDiv}>
        <TextField
          disabled={loadingJIS}
          {...props.register('paymentItem.referenceNumber')}
          onBlur={checkJISAmount}
          onChange={(e) => handleChange(e)}
          className={styles.inputField}
          variant="outlined"
          id="referenceNumber"
          label="Citation/Case"
          name="paymentItem.referenceNumber"
          required
          inputProps={{ maxLength: 22 }}
        />
        <TextField {...props.register('paymentItem.liscenseNumber')} onChange={(e) => handleChange(e)} className={styles.inputField} variant="outlined" id="liscenseNumber" label="Drivers License Number" name="paymentItem.liscenseNumber" inputProps={{ maxLength: 25 }} />
      </div>
      <div className={styles.inputFieldDiv}>
        <TextField {...props.register('paymentItem.firstName')} onChange={(e) => handleChange(e)} className={styles.inputField} variant="outlined" id="firstName" label="First Name" name="paymentItem.firstName" required inputProps={{ maxLength: 25 }} />
        <TextField {...props.register('paymentItem.lastName')} onChange={(e) => handleChange(e)} className={styles.inputField} variant="outlined" id="lastName" label="Last Name" name="paymentItem.lastName" required inputProps={{ maxLength: 25 }} />
      </div>
      {!defaultProduct && (
        <FormControl variant="outlined" required className={styles.inputFieldSelect}>
          <InputLabel id="productLabel">Product</InputLabel>
          <Select defaultValue={defaultProduct} {...props.register('paymentItem.productId')} onChange={(e) => handleChange(e)} labelId="productLabel" label="Product" id="productId" name="paymentItem.productId" required variant="outlined">
            {products &&
              products?.map((product: any, index: any) => (
                <MenuItem value={product.id} key={index} className={styles.inputField}>
                  {product.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      <TextField
        {...props.register('paymentItem.amount')}
        onChange={(e) => handleChange(e)}
        className={styles.inputFieldSelect}
        value={amount}
        disabled={loadingJIS}
        variant="outlined"
        id="amount"
        label={loadingJIS ? 'Loading...' : 'Amount'}
        name="paymentItem.amount"
        type="number"
        required
        inputProps={{
          inputProps: {
            max: 1,
            min: 10000,
          },
        }}
      />
    </Card >
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CitationCaseInfo));
