import { Card, CardHeader, Checkbox, FormControlLabel } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import errorMessages from '../../../../../error_messages.json';
import ManageErrorType from '../../../../../services/errorHelper';
import { AppState } from '../../../../../store';
import { getOrganizationByUrlIdentifier } from '../../../../../store/manage-org/actions';
import * as types from '../../../../../store/manage-org/types';
import { navigate } from '../../../../../store/system/actions';
import { ERROR_ACTION } from '../../../../../store/system/types';
import CreditCards from '../../../../clerk-payment/components/CreditCards/CreditCards';
import TermAndConditions from '../TermAndConditions/TermAndConditions';
import styles from './CreditCardDevice.module.scss';
const CreditCardInput = require('react-credit-card-input'); // Require because we have no type declaration.

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  manageOrg: state.manageOrg,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const CreditCardDevice = (props: any) => {
  const { urlIdentifier } = useParams<{ urlIdentifier: string }>();
  const [cardNumber, setccNumber] = useState('');
  const [expiration, setccExpiry] = useState('');
  const dispatch = useDispatch();
  const [cvv, setccCvv] = useState('');
  const [notFirst, setNotFirst] = useState(false);
  const [canPay, setCanPay] = useState(false);
  const [termAndConditions, setTermAndConditions] = useState(false);

  const handleOnChange = (event: ChangeEvent<any>) => {
    if (event.target.name === 'termAndConditions') {
      setTermAndConditions(!termAndConditions);
      props.getCanPay(!canPay);
      props.getNotFirst(!notFirst);
      setCanPay(!canPay);
      setNotFirst(!notFirst);
    }
  };

  useEffect(() => {
    getOrganizationByUrlIdentifier(urlIdentifier)
      .then((org) => {
        dispatch({
          type: types.GET_ORG_BY_URL_IDENTIFIER,
          payload: org,
        });
      })
      .catch((err) => {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.manageOrgUpdate + '--' + error,
        });
      });
    return () => {};
  }, []);

  return (
    <Card className={[styles.cardContainer].join(' ')}>
      <CardHeader title="Credit Card" className={styles.cardHeader} />
      <CreditCardInput
        cardNumberInputProps={{
          value: cardNumber,
          name: 'cardNumber',
          onChange: (e: React.ChangeEvent<any>) => {
            const value = e.target.value;
            setccNumber(value.replace(/ /g, ''));
            props.setValue('cardInformation.cardNumber', value.replace(/ /g, ''));
          },
        }}
        cardExpiryInputProps={{
          value: expiration,
          name: 'expiration',
          onChange: (e: React.ChangeEvent<any>) => {
            const value = e.target.value;
            setccExpiry(e.target.value.replace(/[^0-9]/g, ''));
            props.setValue('cardInformation.expiration', value.replace(/[^0-9]/g, ''));
          },
        }}
        cardCVCInputProps={{
          value: cvv,
          name: 'cvv',
          onChange: (e: React.ChangeEvent<any>) => {
            setccCvv(e.target.value);
            props.setValue('cardInformation.cvv', e.target.value);
          },
        }}
        containerClassName={styles.inputField}
        fieldClassName={styles.inputFieldCard}
      />
      <CreditCards org={props.manageOrg.orgByUrl as any} />
      <TermAndConditions />
      <div className={styles.buttons}>
        <FormControlLabel control={<Checkbox checked={termAndConditions} onChange={(e: any) => handleOnChange(e)} name="termAndConditions" color="secondary" />} label="Accept Terms & Conditions" className={styles.inputFieldSelect} />
      </div>
    </Card>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreditCardDevice));
