import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import errorMessages from '../../error_messages.json';
import AxiosConfigurator from '../../services/AxiosConfigurator';
import ManageErrorType from '../../services/errorHelper';
import * as swagger from '../../swagger-client';
import { Fee, FeeByAmountRequest, PagedFeeResults } from '../../swagger-client';
import { AppState } from '../index';
import { toggleRouteBack } from '../system/actions';
import { ERROR_ACTION } from '../system/types';
import * as types from './types';

export const getFeeById = (feeId: number): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null) {
      const feeApi = new swagger.FeeApi(AxiosConfigurator.getConfig(state.system.user.token));
      let fee = null;
      let token = state.system.user.token as any;

      let query: any = {
        filterOptions: window.btoa(
          JSON.stringify([
            {
              field: 'id',
              value: feeId,
              operation: 'equal',
            },
          ])
        ),
        sortOptions: window.btoa(
          JSON.stringify([
            {
              field: 'conditionMax',
              direction: 'ASC',
            },
          ])
        ),
      };

      try {
        const result = await feeApi.get(token, {
          query,
        });

        if ((result.data as PagedFeeResults).count > 0) {
          fee = (result.data as PagedFeeResults).values[0];
        } else {
          dispatch({
            type: ERROR_ACTION,
            payload: errorMessages.getFee,
          });
        }

        dispatch({
          type: types.GET_FEE_DETAIL_SUCCESSFUL,
          payload: fee,
        });
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.getFee + '--' + error,
        });
      }
    }
  };
};

export const updateFee = (fee: Fee): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();
    if (state.system !== null && state.system.user !== null) {
      let token = state.system.user.token as any;
      const feeApi = new swagger.FeeApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        const result = await feeApi.update(token, fee);

        dispatch({
          type: types.UPDATE_FEE_DETAIL_SUCCESSFUL,
          payload: result.data,
        });
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.updateFee + '--' + error,
        });
        throw err;
      }
    }
  };
};

export const addFee = (fee: Fee): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();
    if (state.system !== null && state.system.user !== null) {
      let token = state.system.user.token as any;
      const feeApi = new swagger.FeeApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        await feeApi.create(token, fee);
        dispatch({
          type: types.ADD_FEE_DETAIL_SUCCESSFUL,
        });
        dispatch(toggleRouteBack());
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.addFee + '--' + error,
        });
        throw err;
      }
    }
  };
};

export const calculateFeeProccesing = (calculateAmount: FeeByAmountRequest): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null) {
      const feeApi = new swagger.FeeAmountApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        const result = await feeApi.calculateFeeProccesing(calculateAmount);

        dispatch({
          type: types.GET_CALCULATE_FEE_PROCESSING,
          payload: result,
        });
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.addFee + '--' + error,
        });
      }
    }
  };
};

export const calculateFeeProccesingWebsite = (calculateAmount: FeeByAmountRequest): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const feeApi = new swagger.FeeAmountApi(AxiosConfigurator.getConfig());

    try {
      const result = await feeApi.calculateFeeProccesing(calculateAmount);

      dispatch({
        type: types.GET_CALCULATE_FEE_PROCESSING,
        payload: result,
      });
    } catch (err) {
      const error = ManageErrorType(err as any);
      dispatch({
        type: ERROR_ACTION,
        payload: errorMessages.addFee + '--' + error,
      });
    }
  };
};

export const calculateFeeProccesingWebsitePromise = (calculateAmount: FeeByAmountRequest) => {
  const feeApi = new swagger.FeeAmountApi(AxiosConfigurator.getConfig());
  return new Promise(async (res, rej) => {
    try {
      const result = await feeApi.calculateFeeProccesing(calculateAmount);

      res(result);
    } catch (err) {
      const error = ManageErrorType(err as any);
      rej(errorMessages.addFee + '--' + error);
    }
  });
};

export const clearState = (): types.IClearStateAction => {
  return {
    type: types.CLEAR_STATE,
  };
};
