import { Button, Card, CardHeader, FormGroup, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { logIn, navigate } from '../..//store/system/actions';
import { SystemState } from '../..//store/system/types';
import { IGeneratedMenuItem } from '../../models/IGeneratedMenuData';
import { whiteLabelStrategy } from '../../services/whiteLabelStrategy';
import { AppState } from '../../store';
import { clearState } from '../../store/system/actions';
import BaseComponent from '../BaseComponent';
import styles from './Login.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, any>) =>
  bindActionCreators(
    {
      logIn,
      navigate,
      clearState,
    },
    dispatch
  );

interface ILoginProps extends RouteComponentProps {
  system: SystemState;
  clearState: () => void;
  logIn: (email: string, password: string) => void;
  navigate: <TProps extends RouteComponentProps<any>>(menuItem: IGeneratedMenuItem, ownProps: TProps) => ThunkAction<void, AppState, null, AnyAction>;
}

interface ILoginState {
  email: string;
  password: string;
  emailFieldError: boolean;
  passwordFieldError: boolean;
  loginError: boolean;
}

class Login extends BaseComponent<ILoginProps, ILoginState> {
  constructor(props: ILoginProps) {
    super(props);

    this.state = {
      email: '',
      password: '',
      emailFieldError: false,
      passwordFieldError: false,
      loginError: false,
    };

    this.login = this.login.bind(this);
    this.navigateToForgotPassword = this.navigateToForgotPassword.bind(this);
    this.onSignInClick = this.onSignInClick.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
  }

  componentWillMount() {
    this.props.clearState();
  }

  private login(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (this.loginFormRef.current?.checkValidity()) {
      this.props.logIn(this.state.email, this.state.password);
    } else {
      const elementsWithInvalid = this.loginFormRef.current?.querySelectorAll(':invalid');

      elementsWithInvalid?.forEach((element) => {
        (element as any).error = true;
        switch ((element as any).name) {
          case 'email':
            this.setState({ emailFieldError: !this.state.emailFieldError });
            break;

          case 'password':
            this.setState({ passwordFieldError: !this.state.passwordFieldError });
            break;
        }
      });
    }
  }

  private navigateToForgotPassword() {
    this.props.history.push('/forgotpassword');
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.system.loginFailed) {
      if (prevProps.system.loginFailed !== prevState.loginError) {
        this.setState({ loginError: prevProps.system.loginFailed });
      }
    }
  }
  private onSignInClick(e: any) {
    this.login(e);
  }

  private onChangeInput(e: any) {
    this.setState({ loginError: false });
    this.handleInputChange(e);
  }

  private loginFormRef = React.createRef<HTMLFormElement>();

  render() {
    const whiteLabelValues = whiteLabelStrategy();

    return (
      <div className={styles.loginContainer}>
        <div className={styles.headerOptions}>
          <Card className={styles.loginCard} style={{ backgroundColor: whiteLabelValues.color5 }}>
            {console.log('s', this.props.system.loginFailed)}
            <CardHeader>Sign In</CardHeader>
            <FormGroup row={false}>
              <img className={styles.logo} alt="Logo of company" src={whiteLabelValues.loginLogo} />
              <h2 className={styles.title}>LOG IN</h2>
            </FormGroup>
            {this.state.loginError && <Alert severity="error">The email address or password you provided is incorrect!</Alert>}

            <form key="update-form" ref={this.loginFormRef} className={styles.inputForm} noValidate autoComplete="off">
              <FormGroup row={true} className={styles.formRow}>
                {this.validator.message('email', this.state.email, 'required|email')}
                <TextField className={styles.inputField} variant="outlined" name="email" id="name" required onChange={this.onChangeInput} placeholder="Email..." error={this.state.emailFieldError} />
              </FormGroup>
              <FormGroup row={true} className={styles.formRow}>
                {this.validator.message('password', this.state.password, 'required')}
                <TextField className={styles.inputField} variant="outlined" type="password" name="password" id="password" required onChange={this.onChangeInput} placeholder="Password..." error={this.state.passwordFieldError} />
                <FormGroup className={styles.forgotRow}>
                  <Link className={styles.forgotPassword} to="/forgotpassword">
                    Forgot Password?
                  </Link>
                </FormGroup>
              </FormGroup>
              <FormGroup row={true} className={styles.buttonRow}>
                <Button type="submit" className={styles.loginViewButton} onClick={this.onSignInClick} style={{ backgroundColor: whiteLabelValues.color1 }}>
                  LOG IN
                </Button>
              </FormGroup>
            </form>
          </Card>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
