import { Card, CardHeader, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import countryData from '../../../../../data/country.json';
import stateData from '../../../../../models/states.json';
import { AppState } from '../../../../../store';
import { navigate } from '../../../../../store/system/actions';
import styles from './BillingInfo.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const BillingInfoDevice = (props: any) => {
  return (
    <Card className={styles.cardContainer}>
      <CardHeader title="Billing Information" className={styles.cardHeader} />
      <TextField {...props.register('billingInformation.firstName')} className={styles.inputFieldSelect} variant="outlined" id="firstName" label="First Name" name="billingInformation.firstName" required />
      <TextField {...props.register('billingInformation.lastName')} className={styles.inputFieldSelect} variant="outlined" id="lastName" label="Last Name" name="billingInformation.lastName" required />
      <TextField {...props.register('billingInformation.address')} className={styles.inputFieldSelect} variant="outlined" id="address" label="Address" name="billingInformation.address" required />
      <TextField {...props.register('billingInformation.city')} className={styles.inputFieldSelect} variant="outlined" id="city" label="City" name="billingInformation.city" required />
      <FormControl variant="outlined" required className={styles.inputFieldSelect}>
        <InputLabel id="stateLabel">State / Province</InputLabel>
        <Select {...props.register('billingInformation.state')} labelId="stateLabel" label="State / Province" id="state" name="billingInformation.state" required variant="outlined">
          {stateData.map((state, index) => (
            <MenuItem key={index} className={styles.inputField} value={state.Code}>
              {state.State}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField {...props.register('billingInformation.zip')} className={styles.inputFieldSelect} variant="outlined" id="zip" label="Zip" name="billingInformation.zip" required />
      <FormControl variant="outlined" required className={styles.inputFieldSelect}>
        <InputLabel id="countryLabel">Country</InputLabel>
        <Select {...props.register('billingInformation.country')} labelId="countryLabel" label="Country" id="country" name="billingInformation.country" required variant="outlined">
          {countryData.map((country, index) => (
            <MenuItem key={index} className={styles.inputField} value={country.name}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Card>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BillingInfoDevice));
