import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';

interface IPhoneInputProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

export class PhoneInput extends Component<IPhoneInputProps> {
  render() {
    const { inputRef, ...other } = this.props;

    return (<MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
      pattern="^\(\d\d\d\) \d\d\d-\d\d\d\d$"
    />)
  }
}

