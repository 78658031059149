import { RehydrateAction } from 'redux-persist';
import * as types from './types';

const initialState: types.IManageProductState = {
  product: null,
};

export function manageProductReducer(
    state = initialState,
    action: types.ManageProductActionTypes | RehydrateAction
): types.IManageProductState {
    switch (action.type) {
        case types.ADD_PRODUCT_DETAIL_SUCCESSFUL:
            return { 
                ...state, 
                product: action.payload 
            }
        case types.UPDATE_PRODUCT_DETAIL_SUCCESSFUL:
        case types.GET_PRODUCT_DETAIL_SUCCESSFUL:
            return { 
                ...state, 
                product: action.payload 
            }
        case types.CLEAR_STATE: 
            return initialState;
        default:
            return state;
    }
}
