import { Card, CardHeader } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../store';
import { navigate } from '../../../../store/system/actions';
import styles from './TotalCard.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

function ccyFormat(num: number) {
  return `${num.toFixed(2)}`;
}

const TotalCard = ({ total, subTotal, feeProcessing }: any) => {
  return (
    <Card className={styles.cardContainer}>
      <CardHeader title="Total Amount" className={styles.cardHeader} />
      <div className={styles.totalCardContent}>
        <div className={styles.totalCardContentValue}>
          <span>Subtotal</span>
          <span>{`$ ${ccyFormat(+subTotal)}`}</span>
        </div>
        <div className={styles.totalCardContentValue}>
          <span>Processing Fee</span>
          <span>{`$ ${ccyFormat(+feeProcessing)}`}</span>
        </div>
        <div className={styles.totalCardContentValue}>
          <span>Total</span>
          <span>{`$ ${ccyFormat(total)}`}</span>
        </div>
      </div>
    </Card>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TotalCard));
