import dotenv from 'dotenv';
import { Configuration } from '../swagger-client/configuration';

dotenv.config();

export default class AxiosConfigurator {
  public static getConfig(token: string | null = null): Configuration {
    const config = new Configuration();
    config.basePath = process.env.REACT_APP_API_URL;
    if (token) {
      config.apiKey = token;
    }

    return config;
  }
}
