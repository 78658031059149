import { CustomPaging, DataTypeProvider, Filter, FilteringState, GroupingState, IntegratedFiltering, IntegratedGrouping, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SearchState, SelectionState, Sorting, SortingState, TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { ColumnChooser, DragDropProvider, ExportPanel, Grid, PagingPanel, SearchPanel, Table, TableColumnReordering, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableGroupRow, TableHeaderRow, TableSelection, Toolbar, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { Badge, Button, Chip, CircularProgress, IconButton } from '@material-ui/core';
// import { faPlus } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@material-ui/icons/Add';
import saveAs from 'file-saver';
import 'jspdf-autotable';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as underscore from 'underscore';
import { FilterOperation } from '../../models/FilterOperation';
import { dateTimeFormatter } from '../../services/dateTimeFormatter';
import { AppState } from '../../store';
import { createOrUpdateUserPreference } from '../../store/user-preferences/actions';
import { UserPreferenceState } from '../../store/user-preferences/types';
import BaseComponent from '../BaseComponent';
import { DateFilterCell } from './components/date-filter-cell/DateFilterCell';
import { NumberFilterCell } from './components/number-filter-cell/NumberFilterCell';
import { SelectFilterCell } from './components/select-filter-cell/SelectFilterCell';
import styles from './DataGrid.module.scss';

const mapStateToProps = (state: AppState) => ({
  userPreferences: state.userPreferences,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, any>) =>
  bindActionCreators(
    {
      createOrUpdateUserPreference,
    },
    dispatch
  );

const getRowId = (row: { id: any }) => row.id;

interface IDataGridProps<T> {
  rows: Array<T>;
  cardTitle?: string;
  columns: Array<{
    name: string;
    title: string;
    getCellValue?: (row: any, columnName: string) => any;
    type?: string;
    label?: (row: T) => string;
    options?: Array<string>;
    action?: (row: T) => void;
    actionReference?: string;
  }>;
  scopeId?: number;
  secondaryScopeId?: number;
  loadData?: (skip?: number | null, take?: number | null, sortOptions?: Array<{ field: string; direction: string }> | null, filterOptions?: Array<{ field: string; value: any; operation: FilterOperation }> | null, primaryId?: number, secondaryId?: number) => any;
  dataLoaded?: () => void;
  preferenceGroup: string;
  groupBy?: string;
  groupByContent?: ({ row }: { column: any; row: any }) => JSX.Element;
  totalRows?: number;
  pageSizes?: Array<number>;
  userPreferences?: UserPreferenceState;
  createOrUpdateUserPreference?: (key: string, subkey: string, value: string) => void;
  disableColumnFiltering?: boolean;
  uncontrolledColumnFiltering?: boolean;
  disableColumnSelection?: boolean;
  disableCoumnSorting?: boolean;
  uncontrolledColumnSorting?: boolean;
  disablePaging?: boolean;
  addButton?: boolean;
  onAddClick?: () => void;
  setSelectionState?: (selection: any) => void;
  getRowBackground?: (row: any) => string;
  enableReset?: boolean;
  enableSortPersist?: boolean;
  enableFilterPersist?: boolean;
  enablePagingPersist?: boolean;
  refreshInterval?: number;
  ref?: React.RefObject<any>;
  resetViewCallback?: () => void;
  filterChanged?: (filters: Array<Filter>) => void;
  externalFilters?: boolean;
  loading?: boolean;
  hiddenColumnNames?: any;
  searchEnable?: boolean;
  columnOrderEnable?: boolean;
  columnOrder?: Array<string>;
  disableColumnChooser?: boolean;
  columnWidthsGrid?: number;
  exportColumns?: any;
}

interface IDataGridState<T> {
  filters: Array<Filter>;
  sorting: Array<Sorting>;
  columnWidths: Array<TableColumnWidthInfo>;
  columnOrder: Array<string>;
  currentPage: number;
  countPerPage: number;
  hiddenColumns: Array<string> | any;
  dateColumns: Array<string>;
  numberColumns: Array<string>;
  buttonColumns: Array<string>;
  linkColumns: Array<string>;
  selectColumns: Array<string>;
  selectColumnValues: object;
  hasFilters: boolean;
  initializing: boolean;
  loading: boolean;
  lastRows: string;
  selection: Array<string | number>;
}

class InternalDataGrid<T> extends BaseComponent<IDataGridProps<T>, IDataGridState<T>> {
  private refreshTimer: number = -1;
  private exporterRef = React.createRef<any>();

  private debouncedLoadData: any;
  private debouncedUserPreference: any;

  constructor(props: IDataGridProps<T>) {
    super(props);

    this.state = {
      filters: [],
      sorting: [],
      columnWidths: [],
      columnOrder: [],
      currentPage: 0,
      countPerPage: this?.props?.pageSizes ? (this?.props?.pageSizes[0] as number) : 0,
      hiddenColumns: this.props.hiddenColumnNames ? this.props.hiddenColumnNames : [], //
      dateColumns: [],
      numberColumns: [],
      buttonColumns: [],
      linkColumns: [],
      selectColumns: [],
      selectColumnValues: {},
      hasFilters: this.props?.externalFilters ? this.props?.externalFilters : false,
      initializing: true,
      loading: this.props?.loading ? this.props?.loading : true,
      lastRows: '',
      selection: [],
    };
    this.init();
    this.columnWidthChanged = this.columnWidthChanged.bind(this);
    this.columnOrderChanged = this.columnOrderChanged.bind(this);
    this.currentPageChanged = this.currentPageChanged.bind(this);
    this.pageSizeChanged = this.pageSizeChanged.bind(this);
    this.setHiddenColumnNames = this.setHiddenColumnNames.bind(this);
    this.onExcelSave = this.onExcelSave.bind(this);
    this.filterChanged = this.filterChanged.bind(this);
    this.sortingChanged = this.sortingChanged.bind(this);
    this.setSelection = this.setSelection.bind(this);
    this.debouncedLoadData = underscore.debounce(this.loadData, 1000);

    if (this?.props?.createOrUpdateUserPreference) {
      this.debouncedUserPreference = underscore.debounce(this.props.createOrUpdateUserPreference, 1000);
    }
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate() {
    if (this.props.hiddenColumnNames && this.props.hiddenColumnNames.length > 0 && this.state.hiddenColumns.length === 0 && this.props.hiddenColumnNames.length !== this.state.hiddenColumns.length) {
      this.setState({ hiddenColumns: this.props.hiddenColumnNames });
    }
  }

  static getDerivedStateFromProps(newProps: IDataGridProps<any>, prevState: IDataGridState<any>) {
    let result: any = {};

    const newRows = JSON.stringify(newProps.rows);

    if (newProps?.loading !== undefined && newProps?.loading !== null && newProps?.loading !== prevState.loading) {
      result.loading = newProps.loading;
    } else if (prevState.initializing) {
      result.lastRows = newRows;
      result.initializing = false;
    } else if (prevState.loading && (newRows !== prevState.lastRows || newRows === '' || newRows === '[]')) {
      result.loading = false;
      result.lastRows = newRows;
    }

    if (newProps.externalFilters !== undefined && newProps?.externalFilters === true && newProps?.externalFilters !== prevState?.hasFilters) {
      result.hasFilters = newProps?.externalFilters;
    }

    if (Object.keys(result).length > 0) {
      return result;
    } else {
      return null;
    }
  }

  init() {
    // const preferences = (this.props.userPreferences as UserPreferenceState).userPreferences; // Override undefined check because it's populated by redux.

    // const sortingPreference = preferences.find((pref) => pref.key === this.props.preferenceGroup && pref.subkey === 'sorting');
    // const filterPreference = preferences.find((pref) => pref.key === this.props.preferenceGroup && pref.subkey === 'filters');
    // const columnWidthsPreference = preferences.find((pref) => pref.key === this.props.preferenceGroup && pref.subkey === 'columnWidths');
    // const columnOrderPreference = preferences.find((pref) => pref.key === this.props.preferenceGroup && pref.subkey === 'columnOrder');
    // const currentPagePreference = preferences.find((pref) => pref.key === this.props.preferenceGroup && pref.subkey === 'currentPage');
    // const countPerPagePreference = preferences.find((pref) => pref.key === this.props.preferenceGroup && pref.subkey === 'countPerPage');

    // const newSorting: Array<Sorting> = sortingPreference === undefined || !this.props.enableSortPersist ? [] : JSON.parse(sortingPreference.value);
    // const newFilters: Array<Filter> = filterPreference === undefined || !this.props.enableFilterPersist ? [] : JSON.parse(filterPreference.value);
    const newColumnWidths = [] as any;
    // const newColumnOrder: Array<string> = columnOrderPreference === undefined ? [] : JSON.parse(columnOrderPreference.value);
    // const newCurrentPage: number = currentPagePreference === undefined || !this.props.enablePagingPersist ? 0 : JSON.parse(currentPagePreference.value);
    // const newCountPerPage: number = countPerPagePreference === undefined || !this.props.enablePagingPersist ? this.state.countPerPage : JSON.parse(countPerPagePreference.value);

    const dateColumns: Array<string> = [];
    const numberColumns: Array<string> = [];
    const buttonColumns: Array<string> = [];
    const linkColumns: Array<string> = [];
    const selectColumns: Array<string> = [];

    this.props.columns.forEach((column) => {
      if (typeof column.type !== 'undefined') {
        switch (column.type) {
          case 'date':
            dateColumns.push(column.name);
            break;

          case 'number':
            numberColumns.push(column.name);
            break;

          case 'button':
            buttonColumns.push(column.name);
            break;

          case 'link':
            buttonColumns.push(column.name);
            break;

          case 'select':
            selectColumns.push(column.name);
            break;
        }
      }
      if (this.props.columnWidthsGrid) {
        newColumnWidths.push({
          columnName: column.name,
          width: this.props.columnWidthsGrid,
        });
      } else {
        newColumnWidths.push({
          columnName: column.name,
          width: 140,
        });
      }

      // if (columnOrderPreference === undefined) {
      //   if (column.name !== '') {
      //     newColumnOrder.push(column.name);
      //   } else {
      //     newColumnOrder.push(column.title);
      //   }
      // }
    });

    this.state = {
      ...this.state,
      hasFilters: this.props?.externalFilters || false,
      sorting: [],
      filters: [],
      columnWidths: newColumnWidths,
      columnOrder: [],
      currentPage: 0,
      countPerPage: this.state.countPerPage,
      hiddenColumns: this.props?.hiddenColumnNames ? this.props?.hiddenColumnNames : [], // hide collmuns without this code
      numberColumns,
      dateColumns,
      buttonColumns,
      linkColumns,
      selectColumns,
      loading: true,
    };

    this.loadData(true);

    if (this.props.refreshInterval) {
      this.refreshTimer = window.setInterval(() => {
        this.loadData();
      }, this.props.refreshInterval);
    }
  }

  componentWillUnmount() {
    if (this.props.refreshInterval) {
      clearInterval(this.refreshTimer);
    }
  }

  loadData(initial = false): void {
    if (!initial) {
      this.setState({ loading: true }); // Ignore warning as this is a no op when called from constructor.
    }

    if (!this.props.loadData && this.props.rows.length > 0) {
      if (this.state.sorting.length > 0 || this.state.filters.length > 0) {
        this.setState({ hasFilters: true });
      }
    }
    if (this.props.loadData) {
      const skip = this.state.countPerPage * this.state.currentPage;
      const take = this.state.countPerPage;

      if (this.state.sorting.length > 0 || this.state.filters.length > 0) {
        this.setState({ hasFilters: true });
      }

      const translatedSorting = this.state.sorting.map((sort) => {
        return {
          field: sort.columnName,
          direction: sort.direction,
        };
      });

      const translatedFilters = this.state.filters.map((filter) => {
        return {
          field: filter.columnName,
          operation: filter.operation as FilterOperation,
          value: filter.value,
        };
      });

      this.props.loadData(skip, take, translatedSorting, translatedFilters, this.props.scopeId, this.props.secondaryScopeId);
    }
  }

  currentPageChanged(page: number) {
    this.setState({ currentPage: page });
    this.loadData();
  }
  setSelection = (selection: any[]) => {
    this.setState({
      selection,
    });
    if (this.props.setSelectionState) {
      this.props.setSelectionState(selection);
    }
  };

  pageSizeChanged(pageSize: number) {
    this.setState({ countPerPage: pageSize });
    this.loadData();
  }

  columnWidthChanged(nextColumnWidths: Array<TableColumnWidthInfo>) {
    this.setState({ columnWidths: nextColumnWidths });
  }

  columnOrderChanged(nextOrder: Array<string>) {
    this.setState({ columnOrder: nextOrder });
  }

  setHiddenColumnNames(nextHiddenColumns: Array<string>) {
    this.setState({ hiddenColumns: nextHiddenColumns });
  }

  filterChanged(filters: Array<Filter>) {
    if (filters.length > 0) {
      this.setState({
        hasFilters: true,
      });
    } else {
      this.setState({
        hasFilters: false,
      });
    }

    this.setState({ filters }, () => {
      if (this.debouncedUserPreference) {
        this.debouncedUserPreference(this.props.preferenceGroup, 'filters', JSON.stringify(this.state.filters));
      }

      // BOOKMARK
      this.debouncedLoadData();
    });
  }

  sortingChanged(sorting: Array<Sorting>) {
    if (sorting.length > 0) {
      this.setState({
        hasFilters: true,
      });
    } else {
      this.setState({
        hasFilters: false,
      });
    }

    this.setState({ sorting }, () => {
      if (this.props.createOrUpdateUserPreference !== undefined && this.props.createOrUpdateUserPreference !== null) {
        this.props.createOrUpdateUserPreference(this.props.preferenceGroup, 'sorting', JSON.stringify(this.state.sorting));
      }

      this.loadData();
    });
  }

  private onExcelSave(workbook: any) {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      (saveAs as any)(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    });
  }

  groupBy() {
    if (typeof this.props.groupBy !== 'undefined') {
      let groupCellContent = null;

      if (this.props.groupByContent !== undefined) {
        groupCellContent = this.props.groupByContent;
      } else {
        groupCellContent = ({ row }: { column: any; row: any }) => {
          const alikeRows = this.props.rows.filter((currentRow) => row.value === (currentRow as any)[this.props.groupBy as string]);

          return (
            <span>
              <strong className={styles.rowTitle}>{row.value}</strong> <Badge className={styles.rowBadge} badgeContent={alikeRows.length} color="error"></Badge>
            </span>
          );
        };
      }

      const groupingState = <GroupingState defaultGrouping={[{ columnName: this.props.groupBy }]} />;
      const tableGroupRow = <TableGroupRow contentComponent={groupCellContent} />;
      const integratedGrouping = <IntegratedGrouping />;

      return { groupingState, tableGroupRow, integratedGrouping };
    }

    return { groupingState: null, tableGroupRow: null, integratedGrouping: null };
  }

  /**
   * BUTTON
   */

  private ButtonFormater = (args: any) => {
    if ((args.column.actionReference === undefined || (args.column.actionReference !== undefined && args.row[args.column.actionReference] !== undefined)) && args.column.action !== undefined) {
      if (args.column.type === 'link') {
        return (
          <button
            className={styles.addCitationButton}
            onClick={() => {
              args.column.action(args.row);
            }}
          >
            {args.column.label(args.row)}
          </button>
        );
      } else if (args.column.type === 'button') {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              args.column.action(args.row);
            }}
          >
            {args.column.label(args.row)}
          </Button>
        );
      }
    } else {
      return '';
    }
  };

  private ButtonTypeProvider = (props: any) => <DataTypeProvider formatterComponent={this.ButtonFormater} {...props} />;

  /**
   * DATE
   */

  private DateFormater = (args: any) => dateTimeFormatter(args.value);

  private DateTypeProvider = (props: any) => <DataTypeProvider formatterComponent={this.DateFormater} {...props} />;

  /**
   *  Number field
   */

  private NumberTypeProvider = (props: any) => <DataTypeProvider {...props} />;

  /**
   * GENERAL GRID CUSTOMIZTIONS
   */

  private TableRow = (props: Table.DataRowProps) => {
    let styleObject: any = undefined;

    if (this.props.getRowBackground !== undefined) {
      styleObject = {
        backgroundColor: this.props.getRowBackground(props.row),
      };
    }

    return <Table.Row {...props} style={styleObject} />;
  };

  private SearchPanel = (props: any) => {
    let styleObject: any = undefined;

    styleObject = {
      color: 'white',
    };

    return <SearchPanel.Input {...props} style={styleObject} />;
  };

  private TableCell = (props: Table.DataCellProps) => {
    let styleObject: any = undefined;
    styleObject = {
      textAlign: 'center',
    };

    return <Table.Cell className="text-center" {...props} style={styleObject} />;
  };

  private rootComponent = (props: Grid.RootProps) => <Grid.Root {...props} style={{ flex: 1, justifyContent: 'center' }} />;

  private FilterIcon = ({ type, ...restProps }: any) => {
    switch (type) {
      case 'dateEqual':
        return <TableFilterRow.Icon type={'equal'} {...restProps} />;
      case 'dateGreaterThan':
        return <TableFilterRow.Icon type={'greaterThan'} {...restProps} />;
      case 'dateLessThan':
        return <TableFilterRow.Icon type={'lessThan'} {...restProps} />;
      case 'dateGreaterThanOrEqual':
        return <TableFilterRow.Icon type={'greaterThanOrEqual'} {...restProps} />;
      case 'dateLessThanOrEqual':
        return <TableFilterRow.Icon type={'lessThanOrEqual'} {...restProps} />;
      default:
        return <TableFilterRow.Icon type={type} {...restProps} />;
    }
  };

  private filterMessages = {
    dateEqual: 'Equals',
    dateGreaterThan: 'Greater than',
    dateLessThan: 'Less than',
    dateLessThanOrEqual: 'Less than or equals',
    dateGreaterThanOrEqual: 'Greater than or equals',
  };

  private FilterCell = (props: any) => {
    const { column } = props;
    // RWN

    if (column.type !== undefined) {
      switch (column.type) {
        case 'date':
          return <DateFilterCell {...props} />;

        case 'number':
          return <NumberFilterCell {...props} />;

        case 'select':
          return <SelectFilterCell {...props} />;
      }
    }

    return <TableFilterRow.Cell {...props} />;
  };

  // RWN

  private ToolbarRoot = () => (props: any) => {
    const { children } = props;

    let cardTitle = null;
    let addButton = null;
    let resetGroup = null;
    let changesWarning = null;
    let loading = null;

    if (this.props.cardTitle) {
      cardTitle = <span className={styles.gridTitle}>{this.props.cardTitle}</span>;
    }

    if (this.props.addButton) {
      addButton = (
        <IconButton onClick={this.props.onAddClick}>
          <AddIcon color="secondary" />
        </IconButton>
      );
      // <FontAwesomeIcon className={styles.addButton} icon={faPlus} onClick={this.props.onAddClick} />;
    }

    if (this.state.hasFilters) {
      changesWarning = <Chip label="Filters active!" color="secondary" />;
    }
    //!this.props.uncontrolledColumnFiltering && !this.props.uncontrolledColumnSorting &&
    if (this.props.enableReset) {
      resetGroup = (
        <div className={styles.resetButton}>
          <Button
            variant="contained"
            color="primary"
            disabled={!this.state.hasFilters}
            onClick={() => {
              if (this.props.resetViewCallback) {
                this.props.resetViewCallback();
              }

              if (!this.props.disableColumnFiltering) {
                this.filterChanged([]);
              }

              if (!this.props.disableCoumnSorting) {
                this.sortingChanged([]);
              }
            }}
          >
            Reset View
          </Button>
          &nbsp; &nbsp; &nbsp;
          {changesWarning}
        </div>
      );
    }

    if (this.state.loading) {
      loading = (
        <div className={styles.loadingIndicator}>
          <CircularProgress color="inherit" />
        </div>
      );
    }

    return (
      <Toolbar.Root className={styles.cardHeader}>
        {cardTitle}
        {resetGroup}
        {loading}
        {children}
        {addButton}
      </Toolbar.Root>
    );
  };

  private selectPredicate = (value: string, filter: any) => {
    if (Array.isArray(filter.value)) {
      const success =
        filter.value.findIndex((filterValue: string) => {
          return filterValue === value;
        }) > -1;
      return success;
    }
    return false;
  };

  render() {
    // doc.save('a4.pdf');
    let toolbar = this.ToolbarRoot();
    let moddedRows: any = this.props.rows;
    let ignoreFilterColumns: Array<{
      columnName: string;
      filteringEnabled: false;
    }> = [];

    if (this.state.buttonColumns.length > 0) {
      ignoreFilterColumns = [];

      moddedRows = this.props.rows.map((row: any) => {
        this.state.buttonColumns.forEach((columnName) => {
          const buttonColumn = this.props.columns.find((col) => col.name === columnName);

          if (buttonColumn) {
            row[columnName] = { action: buttonColumn.action as (row: any) => void };
          }

          ignoreFilterColumns.push({
            columnName,
            filteringEnabled: false,
          });
        });

        return row;
      });
    }
    if (this.state.linkColumns.length > 0) {
      ignoreFilterColumns = [];

      moddedRows = this.props.rows.map((row: any) => {
        this.state.linkColumns.forEach((columnName) => {
          const linkColumn = this.props.columns.find((col) => col.name === columnName);

          if (linkColumn) {
            row[columnName] = { action: linkColumn.action as (row: any) => void };
          }

          ignoreFilterColumns.push({
            columnName,
            filteringEnabled: false,
          });
        });

        return row;
      });
    }
    const { groupingState, tableGroupRow, integratedGrouping } = this.groupBy();

    let dateProvider: any = null;
    let numberProvider: any = null;
    let buttonProvider: any = null;
    let filteringState: any = null;
    let integratedFiltering: any = null;
    let tableFilterRow: any = null;
    let sortingState: any = null;
    let integratedSorting: any = null;
    let columnVisibility: any = null;
    let columnChooser: any = null;
    let pagingState: any = null;
    let pagingPanel: any = null;
    let customPaging: any = null;
    let searchState: any = null;

    if (!this.props.disablePaging) {
      pagingState = <PagingState currentPage={this.state.currentPage} pageSize={this.state.countPerPage} onCurrentPageChange={this.currentPageChanged} onPageSizeChange={this.pageSizeChanged} />;

      pagingPanel = <PagingPanel pageSizes={this.props.pageSizes} />;

      customPaging = <CustomPaging totalCount={this.props.totalRows} />;
    }

    if (!this.props.disableColumnSelection) {
      columnVisibility = <TableColumnVisibility hiddenColumnNames={this.props.hiddenColumnNames} onHiddenColumnNamesChange={this.setHiddenColumnNames} />;
      if (!this.props.disableColumnChooser) {
        columnChooser = <ColumnChooser />;
      }
    }

    if (!this.props?.disableColumnFiltering && !this.props?.uncontrolledColumnFiltering) {
      filteringState = (
        // RWN Debounce
        // <FilteringState onFiltersChange={this.filterChanged} columnExtensions={ignoreFilterColumns} />
        <FilteringState onFiltersChange={this.filterChanged} filters={this.state.filters} columnExtensions={ignoreFilterColumns} />
      );

      //RWN
      tableFilterRow = <TableFilterRow showFilterSelector cellComponent={this.FilterCell} iconComponent={this.FilterIcon} messages={this.filterMessages as any} />;
    }

    if (this.props.uncontrolledColumnFiltering) {
      filteringState = <FilteringState onFiltersChange={this.filterChanged} defaultFilters={[]} filters={this.state.filters} columnExtensions={ignoreFilterColumns} />;

      let columnExtensions: Array<{ columnName: string; predicate: (value: string, filter: any) => boolean }> = [];

      columnExtensions = this.state.selectColumns.map((selectColumn) => {
        return {
          columnName: selectColumn,
          predicate: this.selectPredicate,
        };
      });

      integratedFiltering = <IntegratedFiltering columnExtensions={columnExtensions} />;

      tableFilterRow = <TableFilterRow showFilterSelector cellComponent={this.FilterCell} iconComponent={this.FilterIcon} messages={this.filterMessages as any} />;
    }

    if (!this.props?.disableCoumnSorting && !this.props?.uncontrolledColumnSorting) {
      const disableColumns = this.props.columns.filter((column) => {
        return column.name.includes('.') || column.type === 'button';
      });

      const sortingStateColumnExtensions = disableColumns.map((column) => {
        return {
          columnName: column.name,
          sortingEnabled: false,
        };
      });

      sortingState = <SortingState onSortingChange={this.sortingChanged} columnExtensions={sortingStateColumnExtensions} />;
    }

    if (this.props?.uncontrolledColumnSorting) {
      const disableColumns = this.props.columns.filter((column) => {
        return column.name.includes('.') || column.type === 'button';
      });

      const sortingStateColumnExtensions = disableColumns.map((column) => {
        return {
          columnName: column.name,
          sortingEnabled: false,
        };
      });

      sortingState = <SortingState defaultSorting={[]} onSortingChange={this.sortingChanged} sorting={this.state.sorting} />;

      integratedSorting = <IntegratedSorting columnExtensions={sortingStateColumnExtensions} />;
    }

    if (this.state.dateColumns.length > 0) {
      dateProvider = <this.DateTypeProvider for={this.state.dateColumns} availableFilterOperations={['dateEqual', 'dateGreaterThan', 'dateGreaterThanOrEqual', 'dateLessThan', 'dateLessThanOrEqual']} />;
    }

    if (this.state.numberColumns.length > 0) {
      numberProvider = <this.NumberTypeProvider for={this.state.numberColumns} availableFilterOperations={['equal', 'notEqual', 'greaterThan', 'lessThan', 'greaterThanOrEqual', 'lessThanOrEqual']} />;
    }

    if (this.state.buttonColumns.length > 0) {
      buttonProvider = <this.ButtonTypeProvider for={this.state.buttonColumns} availableFilterOperations={[]} />;
    }

    if (this.props.searchEnable) {
      searchState = (
        <div className={styles.searchInput}>
          <SearchPanel inputComponent={this.SearchPanel} />;
        </div>
      );
    }

    return (
      <div className={styles.paperWrapper}>
        {/* <Button onClick={exportGrid} /> */}
        <Grid getRowId={getRowId} rows={moddedRows} columns={this.props.columns} rootComponent={this.rootComponent}>
          {dateProvider}
          {numberProvider}
          {buttonProvider}
          {pagingState}
          {sortingState}
          {integratedSorting}
          {customPaging}
          <SearchState />
          {filteringState}
          {integratedFiltering}
          <IntegratedPaging />
          <DragDropProvider />
          <SelectionState selection={this.state.selection} onSelectionChange={this.setSelection} />
          {groupingState}
          {integratedGrouping}
          <GridExporter ref={this.exporterRef} columns={this.props.exportColumns ? this.props.exportColumns : this.props.columns} rows={moddedRows} onSave={this.onExcelSave} />
          <VirtualTable height="auto" rowComponent={this.TableRow} cellComponent={this.TableCell} />
          <TableColumnReordering defaultOrder={this.props.columnOrder} />
          <TableColumnResizing columnWidths={this.state.columnWidths} onColumnWidthsChange={this.columnWidthChanged} resizingMode={'widget'} />
          <IntegratedSelection />
          <TableSelection showSelectAll showSelectionColumn />
          <TableHeaderRow showSortingControls={!this.props?.disableCoumnSorting} />
          {columnVisibility}
          <Toolbar rootComponent={toolbar} />
          {columnChooser}
          {tableFilterRow}
          {tableGroupRow}
          {pagingPanel}
          <ExportPanel startExport={(options) => this.exporterRef.current.exportGrid(options)} />
          {searchState}
        </Grid>
      </div>
    );
  }
}

export default function DataGrid<T>() {
  return connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(InternalDataGrid as new (props: IDataGridProps<T>) => InternalDataGrid<T>);
}
