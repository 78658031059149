import { Breadcrumbs } from '@material-ui/core';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './BreadCrumbs.module.scss';

export default function CustomSeparator(props: any) {
  const { menuList } = props;

  const { pathname } = useLocation();
  const lastItem = pathname.substring(pathname.lastIndexOf('/') + 1);

  let color = {};

  return (
    <div className={styles.container}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {menuList.map((item: any, index: any) => {
          if ((lastItem === 'create' && item === 'Enter Information') || (lastItem === 'pay' && item === 'Enter Information')) {
            color = { color: 'red' };
          } else if (lastItem === 'confirm' && item === 'Confirm') {
            color = { color: 'red' };
          } else if (lastItem === 'receipt' && item === 'Receipt') {
            color = { color: 'red' };
          } else if (lastItem === 'payments' && item === 'Make a Payment') {
            color = { color: 'red' };
          } else if (lastItem === 'payments' && item === 'Possible Duplicate') {
            color = { color: 'red' };
          } else {
            color = { color: 'black' };
          }
          return (
            <span style={color} key={index}>
              {item}
            </span>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
