import * as types from './types';
import { Dispatch, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../index';
import * as swagger from '../../swagger-client';
import AxiosConfigurator from '../../services/AxiosConfigurator';

export const updatePassword = (password: string): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null) {
      const userApi = new swagger.UserApi(AxiosConfigurator.getConfig(state.system.user.token));
      let token = state.system.user.token as any

      const user: swagger.User = {
        id: state.system.user.id as any,
        email: state.system.user.email as string,
        firstName: state.system.user.firstName as string,
        lastName: state.system.user.lastName as string,
        organizationId: state.system.user.organizationId as number,
        password,
      };

      try {
        await userApi.updateUser(token, user);

        dispatch({
          type: types.PASSWORD_UPDATE_SUCCESSFUL,
        });
      } catch {
        dispatch({
          type: types.PASSWORD_UPDATE_FAILED,
        });
      }
    }
  };
};

export const clearSuccessFail = (): types.ClearSuccessFailAction => {
  return {
    type: types.CLEAR_SUCCESS_FAIL,
  };
};
