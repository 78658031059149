import { Card, CardHeader, FormControlLabel, Switch, TextField } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../../store';
import { navigate } from '../../../../../store/system/actions';
import { PhoneInput } from '../../../../inputs/phone/PhoneInput';
import styles from './ContactInfo.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const ContactInfoDevice = (props: any) => {
  const onChangeSend = (e: React.ChangeEvent<any>) => {
    if (e.target.name === 'contactInformation.sendConfirmation') {
      const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      props.setValue('contactInformation.sendConfirmation', value);
    }
  };

  return (
    <Card className={styles.cardContainer}>
      <CardHeader title="Contact Information" className={styles.cardHeader} />
      <TextField
        {...props.register('contactInformation.phoneNumber')}
        InputProps={{
          inputComponent: PhoneInput as any,
        }}
        className={styles.inputFieldSelect}
        variant="outlined"
        id="phone_number"
        label="Phone Number"
        name="contactInformation.phoneNumber"
        required
      />
      <TextField {...props.register('contactInformation.email')} className={styles.inputFieldSelect} variant="outlined" id="email" label="E-mail" name="contactInformation.email" type="email" required />
      <FormControlLabel {...props.register('contactInformation.sendConfirmation')} control={<Switch defaultChecked={true} name="contactInformation.sendConfirmation" color="primary" />} label="Send Confirmation" className={styles.inputFieldSelect} onChange={(e) => onChangeSend(e)} />
    </Card>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactInfoDevice));
