export enum UserRoles {
  SuperAdmin = 'super_admin',
  AcceptPayment = 'accept_payment',
  ManagePayments = 'manage_payments',
  Reporting = 'reporting',
  Admin = 'admin_organization',
}

// ManageOrganization = 'manage-organization',
// ManageProducts = 'manage-products',
// ManageFees = 'manage-fees',
