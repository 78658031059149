import globalAxios, { AxiosResponse } from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './index.scss';
import { whiteLabelStrategy } from './services/whiteLabelStrategy';
import * as serviceWorker from './serviceWorker';
import redux from './store';
import { logOut } from './store/system/actions';

const whiteLabel = whiteLabelStrategy();

// Apply color variables to CSS
let root = document.documentElement;

root.style.setProperty('--mobile-break-width', '1300');
root.style.setProperty('--color-1', whiteLabel.color1);
root.style.setProperty('--color-2', whiteLabel.color2);
root.style.setProperty('--color-3', whiteLabel.color3);
root.style.setProperty('--color-4', whiteLabel.color4);
root.style.setProperty('--color-5', whiteLabel.color5);
root.style.setProperty('--color-6', whiteLabel.color6);
root.style.setProperty('--login-background', whiteLabel.loginBackgroundColor);

// Apply whitelable title
document.title = whiteLabel.title;

// Apply whitelable favicon
const head = document.head || ((document as any).head = document.getElementsByTagName('head')[0]);

var link = document.createElement('link');
var oldLink = document.getElementById('favicon');
link.id = 'favicon';
link.rel = 'icon';
link.href = `favicons/${whiteLabel.favicon}`;
if (oldLink) {
  head.removeChild(oldLink);
}

head.appendChild(link);

const onResponse = (response: AxiosResponse) => {
  if (response.status === 401) {
    redux.store.dispatch(logOut());
    return Promise.reject(response);
  } else {
    return Promise.resolve(response);
  }
};

globalAxios.interceptors.response.use(onResponse);

ReactDOM.render(
  <Provider store={redux.store}>
    <PersistGate loading={null} persistor={redux.persistor}>
      <Router basename="/">
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
