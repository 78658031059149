import { Step, StepLabel, Stepper } from '@material-ui/core';
import React, { FC, ReactElement, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import * as typesOrgs from '../../../src/store/manage-org/types';
import errorMessages from '../../error_messages.json';
import ManageErrorType from '../../services/errorHelper';
import { AppState } from '../../store';
import { getOrganizationByUrlIdentifier } from '../../store/manage-org/actions';
import { navigate } from '../../store/system/actions';
import { ERROR_ACTION } from '../../store/system/types';
import styles from './WebsitePaymentLayout.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  manageOrg: state.manageOrg,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const WebsitePaymentLayoutDevice: FC<any> = ({ children, step }): ReactElement => {
  // let history = useHistory();
  const steps = ['Citation/Case', 'Contact', 'Total', 'Billing', 'Credit Card'];
  const dispatch = useDispatch();
  const { urlIdentifier } = useParams<{ urlIdentifier: string }>();
  const org = useSelector((state: any) => state?.manageOrg?.orgByUrl);
  const name = org?.name;
  const addressLine1 = org?.addressLine1;

  const ivr = org?.ivrPhone;

  const formatPhoneNumber = (ivr: any) => {
    var cleaned = ('' + ivr).replace(/\D/g, '');
    const clean = cleaned.slice(1, 12);
    var match = clean.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  };

  useEffect(() => {
    getOrganizationByUrlIdentifier(urlIdentifier)
      .then((org) => {
        dispatch({
          type: typesOrgs.GET_ORG_BY_URL_IDENTIFIER,
          payload: org,
        });
      })
      .catch((err) => {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.manageOrgUpdate + '--' + error,
        });
      });
  }, []);

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <div className="w-100">
          <div className={styles.header}>
            <div className={styles.headerOptions}>
              <div className={styles.headerInfo}>
                <p>Welcome to the </p>
                <p className={styles.titleCourt}>{name}</p>
                <p>Online Payment System</p>
                <p>{addressLine1}</p>
                <p className={styles.phone}>Pay by Phone Call: </p>
                <p className={styles.phone}>{formatPhoneNumber(ivr) ? formatPhoneNumber(ivr) : ''}</p>
              </div>
            </div>
          </div>
        </div>
        {step !== undefined && (
          <Stepper activeStep={step} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        {children}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WebsitePaymentLayoutDevice));
