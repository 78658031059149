import { Button, Card, CardHeader, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { mapperToUpperCase } from '../../services/currencyFormatter';
import { AppState } from '../../store';
import { clearState, getBatches, getProducts, getTransactionDetails } from '../../store/clerk-payment/actions';
import { navigate } from '../../store/system/actions';
import { Batch, Product } from '../../swagger-client';
import styles from './ExportReport.module.scss';
const mapStateToProps = (state: AppState) => ({
  system: state.system,
  clerkPayment: state.clerkPayment,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
      clearState,
      getProducts,
      getBatches,
    },
    dispatch
  );
// End Redux init
const ExportReport = (props: any) => {
  const [report, setReport] = useState(null);
  const [batch, setBatch] = useState(null);
  const user = useSelector((state: any) => state.system.user);
  const [loading, setloading] = useState(false);
  const [loadingBatches, setloadingBatches] = useState(false);
  const handleChange = async (event: ChangeEvent<any>) => {
    if (event.target.name === 'productId') {
      setloadingBatches(true);
      setReport(event.target.value);
      let to = new Date();
      let from = new Date();
      from.setMonth(to.getMonth() - 1);
      await props.getBatches(from.toISOString(), to.toISOString(), Number(event.target.value));
      setloadingBatches(false);
    }
    if (event.target.name === 'batchId') {
      setBatch(event.target.value);
    }
  };
  const addFooters = (totalArray: any, doc: any) => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont('helvetica', 'italic');
    doc.setFontSize(8);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 10, { align: 'right' });
      // autoTable(doc, {
      //   head: [['', '', '', '', '', 'Total']],
      //   body: totalArray,
      //   theme: 'grid',
      //   pageBreak: 'auto',
      //   styles: {
      //     halign: 'right',
      //   },
      //   margin: { top: 250, bottom: 10 },
      // });
    }
  };
  const addHeader = (totalArray: any, doc: any) => {
    const pageCount = doc.internal.getNumberOfPages();
    const dateToday = new Date();
    doc.setFont('helvetica', 'italic');
    doc.setFontSize(8);
    let date_string = null;
    date_string = dateToday.toString();
    date_string = moment(date_string).tz('America/New_York').format('MM/DD/YYYY hh:mm z');
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(date_string, 18, 18);
      doc.text('Civil Payments - The Easy Way To Pay', 100, 18);
      autoTable(doc, {
        head: [['Total']],
        body: totalArray,
        theme: 'grid',
        pageBreak: 'auto',
        styles: {
          halign: 'right',
        },
        margin: { top: 20, bottom: 10 },
        startY: 20,
      });
    }
  };
  useEffect(() => {
    props.getProducts();
  }, []);
  const onClickExportPDf = async () => {
    setloading(true);
    let transactions = await getTransactionDetails(Number(batch), Number(report), user);
    let product = props.clerkPayment.products.find((prod: Product) => prod.id === report);
    const today = new Date();
    let today_string = today.toString();
    today_string = moment(today_string).tz('America/New_York').format('MM_DD_YYYY');
    const doc = new jsPDF();
    let a = [] as any;
    transactions?.map((row: any) => {
      const total = `$ ${row.settleAmount.toFixed(2)}`;
      let createdAt = moment(row.createdAt).tz('America/New_York').format('MM/DD/YYYY hh:mm z');
      let dateSettled = moment(row.submitTimeUTC).tz('America/New_York').format('MM/DD/YYYY hh:mm z');
      const source = mapperToUpperCase(row.source);
      const status = row.transactionStatus;
      a.push([createdAt, dateSettled, status, source, product.name, row.referenceNumber, row.accountNumber, total]);
    });
    const totalAmount = transactions.reduce((total: any, currentItem: { settleAmount: any }) => (total = total + +currentItem.settleAmount), 0);
    const total2 = `$ ${totalAmount.toFixed(2)}`;
    let totalArray = [] as any;
    totalArray.push([total2]);
    addHeader(totalArray, doc);
    autoTable(doc, { html: '#my-table' });
    autoTable(doc, {
      head: [['Date Payment Made', 'Date Settled', 'Status', 'Source', 'Product', 'Citation', 'Last 4 of Card', 'Amount']],
      body: a,
      theme: 'grid',
      pageBreak: 'auto',
      showFoot: 'everyPage',
      margin: { top: 40, bottom: 50 },
      startY: 40,
    });
    addFooters(totalArray, doc);
    doc.save(`PaymentBreakdown_${today_string}.pdf`);
    setloading(false);
  };
  let products = [] as any;
  if (props.clerkPayment.products) {
    products = props.clerkPayment.products?.map((product: any, index: any) => {
      return (
        <MenuItem key={index} className={styles.inputField} value={product.id}>
          {product.name}
        </MenuItem>
      );
    });
  }
  let batches: Array<Batch> = [];
  if (props.clerkPayment.batches) {
    batches = props.clerkPayment.batches?.map((batch: any, index: any) => {
      let date_string = null;
      date_string = batch.settlementTimeLocal.toString();
      date_string = moment(date_string).format('DD-MMM-YYYY HH:mm:ss');
      return (
        <MenuItem key={index} className={styles.inputField} value={batch.batchId}>
          {date_string}
        </MenuItem>
      );
    });
  }
  return (
    <>
      <CardHeader className={styles.titleHeader} title="Export Reports" />
      <Card className={styles.cardContainer}>
        <Card className={styles.cardContainer}>
          <div className={styles.dateContainer}>
            <FormControl variant="outlined" required className={styles.dateInput}>
              <InputLabel id="productId">Choose product</InputLabel>
              <Select labelId="productId" label="Choose Report" id="productId" name="productId" required onChange={(e) => handleChange(e)} variant="outlined" value={report}>
                {products}
              </Select>
            </FormControl>
            {props.clerkPayment.batches && props.clerkPayment.batches.length > 0 && report && (
              <FormControl variant="outlined" required className={styles.dateInput}>
                <InputLabel id="batchLabel">Choose date</InputLabel>
                <Select disabled={loadingBatches} labelId="batchLabel" placeholder="Choose date" label="Choose date" id="batchId" name="batchId" required onChange={(e) => handleChange(e)} variant="outlined" value={batch}>
                  {batches}
                </Select>
              </FormControl>
            )}
            <Button variant="contained" color="primary" disabled={!batch} className={styles.dateInput} onClick={onClickExportPDf}>
              {loading ? (
                <CircularProgress size={20} style={{ marginRight: 10 }} />
              ) : (
                <IconButton>
                  <DownloadIcon style={{ fill: 'white' }} />
                </IconButton>
              )}
              Export PDF
            </Button>
          </div>
        </Card>
      </Card>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExportReport));
