import { Button, Card, CardHeader, FormGroup, TextField } from '@material-ui/core';
import React from 'react';
// Begin Redux init
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { sendForgotPasswordEmail } from '../..//store/system/actions';
import { SystemState } from '../..//store/system/types';
import { VariantEnum } from '../../models/VariantEnum';
import { whiteLabelStrategy } from '../../services/whiteLabelStrategy';
import { AppState } from '../../store';
import AlertMessage from '../alert-message/AlertMessage';
import BaseComponent from '../BaseComponent';
import styles from './ForgotPassword.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, any>) =>
  bindActionCreators(
    {
      sendForgotPasswordEmail,
    },
    dispatch
  );

interface IForgotPasswordProps extends RouteComponentProps {
  system: SystemState;
  sendForgotPasswordEmail: (email: string) => void;
}

interface IForgotPasswordState {
  email: string;
  emailFieldError: boolean;
}
// End Redux init

class ForgotPassword extends BaseComponent<IForgotPasswordProps, IForgotPasswordState> {
  constructor(props: IForgotPasswordProps) {
    super(props);

    this.state = {
      email: '',
      emailFieldError: false,
    };

    this.sendEmail = this.sendEmail.bind(this);
    this.showEmailSentMessage = this.showEmailSentMessage.bind(this);
    this.backToLogin = this.backToLogin.bind(this);
    this.sendEmailButtonHandler = this.sendEmailButtonHandler.bind(this);
  }

  private sendEmail(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (this.forgotPasswordFormRef.current?.checkValidity()) {
      this.props.sendForgotPasswordEmail(this.state.email);
      this.showEmailSentMessage();
    } else {
      const elementsWithInvalid = this.forgotPasswordFormRef.current?.querySelectorAll(':invalid');

      elementsWithInvalid?.forEach((element) => {
        if ((element as any).name === 'email') {
          this.setState({ emailFieldError: !this.state.emailFieldError });
        }
      });
    }
  }

  private showEmailSentMessage() {
    this.props.history.push('/forgotpasswordemailsent');
  }

  private sendEmailButtonHandler(e: any) {
    this.sendEmail(e);
  }

  private backToLogin() {
    this.props.history.push('/');
  }

  private forgotPasswordFormRef = React.createRef<HTMLFormElement>();

  render() {
    const whiteLabelValues = whiteLabelStrategy();

    return (
      <div className={styles.loginContainer}>
        <div className={styles.headerOptions}>
          <AlertMessage shown={this.props.system.loginFailed} variant={VariantEnum.danger} message={'The email address is incrorrect!'} />
          <Card className={styles.loginCard} style={{ backgroundColor: whiteLabelValues.color5 }}>
            <CardHeader title={'Forgot Password'} className={styles.headerTitle} />
            <form noValidate ref={this.forgotPasswordFormRef} onSubmit={this.sendEmail}>
              <FormGroup row={true}>
                <img className={styles.logo} alt="Company logo" src={whiteLabelValues.loginLogo} />
              </FormGroup>
              <FormGroup row={true} className={styles.formRow}>
                {this.validator.message('email', this.state.email, 'required|email')}
                <TextField className={styles.formTextInput} variant="outlined" name="email" id="email" required onChange={this.handleInputChange} placeholder="Email..." error={this.state.emailFieldError} />
                {/* <Input className={styles.formTextInput} type="text" name="email" required value={this.state.email} onChange={this.handleInputChange} placeholder="Email..." error={this.state.emailFieldError} /> */}
              </FormGroup>
              <FormGroup row={true} className={styles.buttonRow}>
                <Link className={styles.forgotPasswordViewLink} to="/">
                  Back
                </Link>
                {/* <Button onClick={this.backToLogin} className={styles.forgotPasswordViewButton} style={{ backgroundColor: whiteLabelValues.color1 }}>
                  Back
                </Button> */}
                <Button type="submit" className={styles.forgotPasswordViewButton} onClick={this.sendEmailButtonHandler} style={{ backgroundColor: whiteLabelValues.color1 }}>
                  Send Reset Email
                </Button>
              </FormGroup>
            </form>
          </Card>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
