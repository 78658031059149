import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import errorMessages from '../../error_messages.json';
import { FilterOperation } from '../../models/FilterOperation';
import AxiosConfigurator from '../../services/AxiosConfigurator';
import ManageErrorType from '../../services/errorHelper';
import * as swagger from '../../swagger-client';
import { PagedProductResults } from '../../swagger-client';
import { AppState } from '../index';
import { ERROR_ACTION } from '../system/types';
import * as types from './types';

export const getProducts = (skip?: number | null, take?: number | null, sortOptions?: Array<{ field: string; direction: string }> | null, filterOptions?: Array<{ field: string; value: any; operation: FilterOperation }> | null): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null) {
      // const terminalApi = new swagger.
      const productApi = new swagger.ProductApi(AxiosConfigurator.getConfig(state.system.user.token));
      let products = null;

      let query: any = {
        filterOptions: window.btoa(
          JSON.stringify([
            {
              field: 'deletedAt',
              value: null,
              operation: 'equal',
            },
          ])
        ),
      };

      try {
        if (typeof skip !== 'undefined' && skip !== null) {
          query.skip = skip;
        }
        if (typeof take !== 'undefined' && take !== null) {
          query.take = take;
        }

        if (sortOptions === undefined || sortOptions === null) {
          sortOptions = [];
        }

        if (filterOptions === undefined || filterOptions === null) {
          filterOptions = [];
        }

        filterOptions.push({
          field: 'deletedAt',
          value: null,
          operation: FilterOperation.Equal,
        });

        query.filterOptions = window.btoa(JSON.stringify(filterOptions));

        if (sortOptions.length > 0) {
          query.sortOptions = window.btoa(JSON.stringify(sortOptions));
        }
        let token = state.system.user.token as any;
        const result = await productApi.get(token);

        products = result.data as PagedProductResults;

        dispatch({
          type: types.GET_PRODUCTS_SUCCESSFUL,
          payload: products,
        });
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.getProduct + '--' + error,
        });
      }
    }
  };
};

export const deleteProduct = (idToDelete: number): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null) {
      // const terminalApi = new swagger.
      const productApi = new swagger.ProductApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        let token = state.system.user.token as any;

        await productApi._delete(token, idToDelete, {
          query: {
            id: idToDelete,
          },
        });

        dispatch({
          type: types.DELETE_PRODUCT_SUCCESSFUL,
          payload: idToDelete,
        });
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.deleteProduct + '--' + error,
        });
      }
    }
  };
};

export const clearState = (): types.IClearStateAction => {
  return {
    type: types.CLEAR_STATE,
  };
};
