import { Action } from 'redux';
import { Batch, MakePayment, MakePaymentItem, Payment, Product, TransactionDetailResponse, WebsitePaymentModel } from '../../swagger-client';

export const GET_PRODUCTS_SUCCESSFUL = 'GET_PRODUCTS_CLERK_PAYMENT';
export const CLEAR_STATE = 'CLEAR_STATE_CLERK_PAYMENT';
export const ADD_PAYMENT = 'ADD_CLERK_PAYMENT';
export const CONFIRM_PAYMENT = 'CONFIRM_CLERK_PAYMENT';
export const DETAIL_PAYMENT = 'DETAIL_CLERK_PAYMENT';
export const FILTER_PAYMENT = 'FILTER_CLERK_PAYMENT';
export const FILTER_PAYMENT_BY_ID = 'FILTER_CLERK_PAYMENT_BY_ID';
export const CAPTURE_PAYMENT = 'CAPTURE_PAYMENT';
export const ADD_PAYMENT_WEBSITE = 'ADD_WEBSITE_PAYMENT';
export const GET_PRODUCTS_WEBSITE = 'GET_PRODUCTS_WEBSITE';
export const VOID_PAYMENT = 'VOID_PAYMENT';
export const EDIT_WEBSITE_PAYMENT = 'EDIT_WEBSITE_PAYMENT';
export const EDIT_COUNTER_PAYMENT = 'EDIT_COUNTER_PAYMENT';
export const GET_PAYMENT_BY_REFERENCE = 'GET_PAYMENT_BY_REFERENCE';
export const APPROVE_PAYMENT_HELD = 'APPROVE_PAYMENT_HELD';
export const DECLINE_PAYMENT_HELD = 'DECLINE_PAYMENT_HELD';
export const GET_BATCHES = 'GET_BATCHES';

// Action types

export interface IGetProducts extends Action {
  type: typeof GET_PRODUCTS_SUCCESSFUL;
  payload: Array<Product>;
}

export interface IClearStateAction extends Action {
  type: typeof CLEAR_STATE;
}

export interface IAddPaymentAction extends Action {
  type: typeof ADD_PAYMENT;
  payload: MakePayment;
}

export interface IConfirmPaymentAction extends Action {
  type: typeof CONFIRM_PAYMENT;
  payload: Payment;
}
export interface IDetailPaymentAction extends Action {
  type: typeof DETAIL_PAYMENT;
  payload: Array<TransactionDetailResponse>;
}
export interface IFilterPaymentAction extends Action {
  type: typeof FILTER_PAYMENT;
  payload: Array<Payment>;
}

export interface IFilterPaymentByIdAction extends Action {
  type: typeof FILTER_PAYMENT_BY_ID;
  payload: Array<Payment>;
}

export interface IAddPaymentWebsiteAction extends Action {
  type: typeof ADD_PAYMENT_WEBSITE;
  payload: WebsitePaymentModel;
}

export interface ICapturePaymentAction extends Action {
  type: typeof CAPTURE_PAYMENT;
}

export interface IVoidPaymentAction extends Action {
  type: typeof VOID_PAYMENT;
}
export interface IEditWebsitePaymentAction extends Action {
  type: typeof EDIT_WEBSITE_PAYMENT;
}
export interface IEditCounterPaymentAction extends Action {
  type: typeof EDIT_COUNTER_PAYMENT;
}

export interface IGetProductsWebsite extends Action {
  type: typeof GET_PRODUCTS_WEBSITE;
  payload: Array<Product>;
}

export interface IGetPaymentByReference extends Action {
  type: typeof GET_PAYMENT_BY_REFERENCE;
  payload: Array<MakePaymentItem>;
}

export interface IApprovePaymentAction extends Action {
  type: typeof APPROVE_PAYMENT_HELD;
}

export interface IDeclinePaymentAction extends Action {
  type: typeof DECLINE_PAYMENT_HELD;
}

export interface IGetBatchesAction extends Action {
  type: typeof GET_BATCHES;
  payload: Array<Batch>;
}

export type ClerkPaymentActionTypes =
  | IGetProducts
  | IClearStateAction
  | IAddPaymentAction
  | IConfirmPaymentAction
  | IDetailPaymentAction
  | IFilterPaymentAction
  | ICapturePaymentAction
  | IAddPaymentWebsiteAction
  | IGetProductsWebsite
  | IFilterPaymentByIdAction
  | IVoidPaymentAction
  | IEditWebsitePaymentAction
  | IEditCounterPaymentAction
  | IGetPaymentByReference
  | IApprovePaymentAction
  | IDeclinePaymentAction
  | IGetBatchesAction;

// Store state

export interface IClerkPaymentState {
  products: Array<Product>;
  productsWebsite: Array<Product>;
  payment: MakePayment;
  paymentId: Payment;
  detailPayment: Array<TransactionDetailResponse>;
  paymentsFiltered: Array<Payment>;
  paymentFiltredById: Array<Payment>;
  paymentWebsite: WebsitePaymentModel;
  paymentsByReference: Array<MakePaymentItem>;
  batches: Array<Batch>;
}
