import { Card, CardHeader } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../store';
import { navigate } from '../../../../store/system/actions';
import styles from './PaymentInfo.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const PaymentInfo = (props: any) => {
  return (
    <Card className={styles.cardContainer}>
      <CardHeader className={styles.cardHeader} title="Payment Information" />
      <Divider />
      <List className={styles.root}>
        {props.ccNumber && (
          <ListItem>
            <ListItemText primary="CardNumber:" />
            <span>************{props.ccNumber.length > 4 ? props.ccNumber.slice(12, 16) : props.ccNumber}</span>
          </ListItem>
        )}
        {!props.ccNumber && (
          <ListItem>
            <ListItemText primary="CardNumber: " />
            <span> ************</span>
          </ListItem>
        )}
        <Divider component="li" />
        <ListItem>
          <ListItemText primary="Expiration Date" />
          <span>**/**</span>
        </ListItem>
      </List>
    </Card>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PaymentInfo));
