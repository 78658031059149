import { Button, Card, CardHeader } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../store';
import { navigate } from '../../../store/system/actions';
import CustomSeparator from '../../bread-crumbs/BreadCrumbs';
import PaymentInfo from '../../clerk-payment-authorize/components/PaymentInfo/PaymentInfo';
import TableApp from '../../table-app/TableApp';
import WebsitePaymentLayout from '../WebsitePaymentLayout';
import CustomerInfo from './components/customerInfo/CustomerInfo';
import styles from './WebistePaymentConfirm.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  manageOrg: state.manageOrg,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const WebistePaymentConfirm = (props: any) => {
  let history = useHistory();
  const { urlIdentifier } = useParams<{ urlIdentifier: string }>();
  const [state, setState] = useState({
    strategy: '',
    source: '',
    urlIdentifier: '',
    orgId: 1,
    paymentItem: { referenceNumber: '', organizationId: 1, firstName: '', lastName: '', productId: '', amount: 0, product: { id: null, name: '', description: '', variablePrice: '', price: '', isCounter: true, isWeb: true }, inJIS: false },
    billingInformation: { firstName: '', lastName: '', address: '', city: '', state: [], zip: '', country: [] },
    cardInformation: { cardNumber: '', expiration: '', cvv: '' },
    feeProcessing: 0,
    id: null,
  });

  useEffect(() => {
    if (props.location.state) {
      setState(props.location.state.propOne);
    }
  }, []);

  useEffect(() => {
    if (props.location.state === undefined) {
      history.push(`/web/${urlIdentifier}`);
    }
  }, []);

  function createRow(referenceNumber: string, firstName: string, lastName: string, productId: string, amount: number) {
    return { referenceNumber, firstName, lastName, productId, amount };
  }

  const rows = [createRow(state.paymentItem.referenceNumber, state.paymentItem.firstName, state.paymentItem.lastName, state.paymentItem.product.name, state.paymentItem.amount)];
  const customerInfo = {
    firstName: state.billingInformation.firstName,
    lastName: state.billingInformation.lastName,
    address: state.billingInformation.address,
    city: state.billingInformation.city,
    state: state.billingInformation.state,
    zip: state.billingInformation.zip,
    country: state.billingInformation.country,
  };
  const subtotal = state.paymentItem.amount;
  const feeProcessing = state.feeProcessing;
  const total = +state.paymentItem.amount + +feeProcessing;

  const onNewPaymentClick = () => {
    history.push(`/web/${urlIdentifier}/pay`);
  };
  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    documentTitle: 'Civil Payments Solutions - The Easy Way To Pay',
    content: () => componentRef.current,
  });
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  } as any;
  let today = new Date();
  const dateToday = new Intl.DateTimeFormat('en-US', options).format(today);
  let date_string = dateToday.toString();
  date_string = moment(date_string).tz('America/New_York').format('MM/DD/YYYY hh:mm A z');

  return (
    <WebsitePaymentLayout>
      <Card className={styles.header}>
        <CardHeader title="Receipt" />
      </Card>
      <Card className={styles.header}>
        <CustomSeparator menuList={['Enter Information', 'Receipt']} />
      </Card>
      <Card className={styles.cardContainer}>
        <div ref={componentRef} className={styles.cardContainer}>
          {
            <div className={styles.headerText}>
              <span className={styles.printable}>{date_string}</span>
              <span className={styles.printable}>{'Civil Payments Solutions - The Easy Way To Pay'}</span>
            </div>
          }
          {
            <div>
              <h4 className={styles.printable}>{props.manageOrg.orgByUrl?.name + '- Online Payment System - ' + props.manageOrg.orgByUrl?.addressLine1}</h4>
            </div>
          }
          <Alert className={styles.alertButton} severity="success">
            Your payment has been processed successfully on {date_string} Confirmation code: {state.id}
          </Alert>
          <TableApp rows={rows} invoiceSubtotal={subtotal} invoiceTaxes={feeProcessing} invoiceTotal={total} />
          {<h4 className={styles.printable}>{'This service has been provided by ' + props.manageOrg.orgByUrl?.name + ' online payment system. Please keep this receipt for future reference. For questions, please contact us ' + props.manageOrg.orgByUrl?.phone1}</h4>}
          <div className={styles.container}>
            <CustomerInfo customerInfo={customerInfo} />
            <PaymentInfo ccNumber={state.cardInformation.cardNumber.slice(12, 16)} ccExpiry={state.cardInformation.expiration} />
          </div>
          {
            <div>
              <h4 className={styles.printable}>{'Signature: ______________________________________________________ Date: ____/____/___________'}</h4>
              <h4 className={styles.printable}>{'By signing this receipt you agree to the terms and conditions of this service.'}</h4>
            </div>
          }
        </div>
        <div className={styles.Buttons}>
          <div className={styles.button}>
            <Button variant="contained" onClick={handlePrint}>
              Print
            </Button>
          </div>
          <div className={styles.button}>
            <Button variant="contained" color="secondary" onClick={onNewPaymentClick}>
              New Payment
            </Button>
          </div>
        </div>
      </Card>
    </WebsitePaymentLayout>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WebistePaymentConfirm));
