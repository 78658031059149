import { RehydrateAction } from 'redux-persist';
import * as types from './types';


const initialState: types.IProductsGridState = {
    products: [],
    count: 0
}

export function productsGridReducer(
    state = initialState,
    action: types.ManageProductActionTypes | RehydrateAction
): types.IProductsGridState {
    switch (action.type) {
        case types.GET_PRODUCTS_SUCCESSFUL:
            return { 
                ...state, 
                products: action.payload.values,
                count: action.payload.count
            }
        case types.DELETE_PRODUCT_SUCCESSFUL: 
            return {
                ...state,
                products: state.products.filter(product => product.id !== action.payload),
                count: (state.count - 1)
            }
        case types.CLEAR_STATE: 
            return initialState;
        default:
            return state;
    }
}