import { Button, Card, CardHeader, CircularProgress, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Prompt, useHistory, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../store';
import { confirmPayment } from '../../store/clerk-payment/actions';
import { getProductById } from '../../store/manage-product/actions';
import { navigate } from '../../store/system/actions';
import CustomSeparator from '../bread-crumbs/BreadCrumbs';
import TableApp from '../table-app/TableApp';
import styles from './ClerkPaymentAuthorize.module.scss';
import PaymentInfo from './components/PaymentInfo/PaymentInfo';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  clerkPayment: state.clerkPayment,
  manageProduct: state.manageProduct,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
      confirmPayment,
      getProductById,
    },
    dispatch
  );

const ClerkPaymentAuthorize = (props: any) => {
  const [state, setState] = useState({ propValue: { paymentId: 1, organizationId: 1, paymentItems: [], subTotal: 1, feeProcessing: 0, ccExpiry: '2222', CardLast: null, ccNumber: 'test', ccCvv: 'test', ccData: '', swipedTransaction: false } });
  const [isLoadingConfirm, setIsloadingConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [notInJis, setNotInJis] = useState(false);
  const [rows, setRows] = useState([] as any);
  const [info, setInfo] = useState({ openSuccess: false, snackMessage: '' });

  useEffect(() => {
    if (props.location.state) {
      setState({ propValue: props.location.state.propOne });
      setInfo(props.location.state.propTree);
      // setNotInJis(props.location.state.propOne.paymentItems[0].inJIS);
    }
    if (props.location.state === undefined) {
      setSuccess(true);
      history.push('/payments/create');
    }
  }, []);

  useEffect(() => {
    const createRow = (productId: string, referenceNumber: string, firstName: string, lastName: string, amount: number) => {
      return { productId, referenceNumber, firstName, lastName, amount };
    };

    if (state.propValue.paymentItems !== []) {
      const rows = state.propValue.paymentItems.map((payment: { productId: string; referenceNumber: string; firstName: string; lastName: string; amount: number; productName: string }) => {
        return createRow(payment.productName, payment.referenceNumber, payment.firstName, payment.lastName, payment.amount);
      });
      setRows(rows);
    }
  }, [state.propValue.paymentItems]);

  let history = useHistory();

  const invoiceSubtotal = +state.propValue.subTotal;
  const invoiceTaxes = +state.propValue.feeProcessing;
  const invoiceTotal = invoiceTaxes + invoiceSubtotal;
  const ccNumber = state.propValue.ccNumber.replace(/ /g, '');
  const ccExpiry = state.propValue.ccExpiry.replace(/[^0-9]/g, '');
  const ccCvv = state.propValue.ccCvv;
  const isSwiped = state.propValue.swipedTransaction;
  const ccDataArray = state.propValue.ccData.split(';');

  const onConfrimClick = async () => {
    if (state.propValue.paymentId !== null) {
      if (!isSwiped) {
        // swiped transaction is desabled
        const confirmDataSend = {
          orgId: state.propValue.organizationId,
          strategy: 'court',
          source: 'counter',
          paymentItems: state.propValue.paymentItems,
          billingInformation: {
            isSwiped: isSwiped,
            cardInformation: {
              cardNumber: ccNumber,
              expiration: ccExpiry,
              cvv: ccCvv,
            },
          },
        };
        setIsloadingConfirm(true);
        await props.confirmPayment(confirmDataSend);
        setIsloadingConfirm(false);
        setSuccess(true);
      } else {
        const confirmDataSend = {
          orgId: state.propValue.organizationId,
          strategy: 'court',
          source: 'counter',
          paymentItems: state.propValue.paymentItems,
          billingInformation: {
            isSwiped: isSwiped,
            track1: ccDataArray[0],
            track2: ccDataArray[1],
          },
        };
        setIsloadingConfirm(true);
        await props.confirmPayment(confirmDataSend);
        setIsloadingConfirm(false);
        setSuccess(true);
      }
    }
  };

  const handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setInfo({ openSuccess: false, snackMessage: '' });
  };

  const onDeleteClick = () => {
    history.push('create');
  };

  return (
    <>
      <Prompt when={!success} message={() => 'If you leave this page, payment while be lost.'} />
      <Card className={styles.header}>
        <CardHeader title="Receipt" />
      </Card>
      <Card className={styles.header}>
        <CustomSeparator menuList={['Enter Information', 'Confirm', 'Receipt']} />
      </Card>
      <Card className={styles.cardContainer}>
        <TableApp rows={rows} invoiceSubtotal={invoiceSubtotal} invoiceTaxes={invoiceTaxes} invoiceTotal={invoiceTotal} />
        <PaymentInfo ccNumber={ccNumber} ccExpiry={ccExpiry} />
        <div className={styles.cancelButton}>
          <Button variant="contained" color="secondary" className={styles.continueButton} onClick={onDeleteClick}>
            Cancel
          </Button>
          {isLoadingConfirm ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" color="secondary" className={styles.continueButton} onClick={onConfrimClick}>
              Confirm
            </Button>
          )}
        </div>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={info?.openSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="info">
            {info?.snackMessage}
          </Alert>
        </Snackbar>
      </Card>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClerkPaymentAuthorize));
