import { Button, Card, CardHeader, Collapse, IconButton } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../store';
import { navigate } from '../../store/system/actions';
import styles from './Help.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const Help = () => {
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleExpand2Click = () => {
    setExpanded2(!expanded2);
  };
  const onLiveClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Card className={styles.header}>
        <CardHeader title="Help" className={styles.header} />
      </Card>
      <div className={styles.container}>
        <div className={styles.columnLeft}>
          <Card className={styles.cardContainer}>
            <CardHeader title="Talk to Us" className={styles.cardHeader} />
            <div className={styles.textField}>
              <p>{'For assistance you may email, call or talk to us via Live Chat'}</p>
              <p>
                <b>Email:</b>
                {' info@cardnetwork.com'}
              </p>
              <p>
                <b>Call us toll free:</b>
                {' 855-855-6864'}
              </p>
            </div>
            <div className={styles.liveButton}>
              <Button variant="contained" color="secondary" className={styles.continueButton} onClick={onLiveClick}>
                Live Chat Now!
              </Button>
            </div>
          </Card>
        </div>
        <div className={styles.columnRight}>
          <Card className={styles.cardContainer}>
            <CardHeader title="Frequently Asked Questions" className={styles.cardHeader} />
            <div>
              <IconButton onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                <ExpandMore />
                <p>Why was my credit card declined?</p>
              </IconButton>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div className={styles.textField}>
                  <span>
                    <p>Your credit card may be declined by our credit card processing system for a number of reasons. Only your credit card issuer or financial institution can confirm the specific reason for a declined credit card. Possible reasons include:</p>
                    <ul>
                      <li>Not having available credit on the card.</li>
                      <li>The card has expired.</li>
                      <li>The credit card information including the billing address was not entered accurately.</li>
                      <li>The CVV number was not entered correctly (the 3 or 4 digit on the back of your card).</li>
                      <li>As a security measure, some credit card companies reject transactions when a large number of online purchases are attempted within a short time.</li>
                    </ul>
                  </span>
                </div>
              </Collapse>
            </div>
            <div>
              <IconButton onClick={handleExpand2Click} aria-expanded={expanded2} aria-label="show more">
                <ExpandMore />
                <p>Is my payment secure?</p>
              </IconButton>
              <Collapse in={expanded2} timeout="auto" unmountOnExit>
                <div className={styles.textField}>
                  <p>
                    We make every effort to ensure that your transaction will be secure and safe. We use the industry standard encryption protocol (SSL) to safeguard all of your personal information. Your information cannot be accessed by any unauthorized individual. We are fully compliant with PCI
                    requirements. In the event of unauthorized use of your credit card, you must first notify your credit card provider in accordance with its reporting rules and procedures.
                  </p>
                </div>
              </Collapse>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Help));
