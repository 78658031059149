import { RehydrateAction } from 'redux-persist';
import * as types from './types';

const initialState: types.IManageOrgState = {
  org: null,
  orgByUrl: null,
  courts: null,
};

export function manageOrgReducer(state = initialState, action: types.ManageOrgsActionTypes | RehydrateAction): types.IManageOrgState {
  switch (action.type) {
    case types.ADD_ORG_DETAIL_SUCCESSFUL:
    case types.UPDATE_ORG_DETAIL_SUCCESSFUL:
    case types.GET_ORG_DETAIL_SUCCESSFUL:
      return {
        ...state,
        org: action.payload,
      };
    case types.GET_ORG_BY_URL_IDENTIFIER:
      return {
        ...state,
        orgByUrl: action.payload,
      };
    case types.GET_COURT_BY_NAME:
      return {
        ...state,
        courts: action.payload,
      };
    case types.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
}
