import { makeStyles, TableCell, TextField } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { useEffect } from 'react-transition-group/node_modules/@types/react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../store';
import { getOrganizationByUrlIdentifier } from '../../../../store/manage-org/actions';
import { navigate } from '../../../../store/system/actions';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  manageOrg: state.manageOrg,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
      getOrganizationByUrlIdentifier,
    },
    dispatch
  );

const CustomTableCell = (props: any) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    selectTableCell: {
      width: 60,
    },
    tableCell: {
      width: 130,
      height: 40,
    },
    input: {
      width: 130,
      height: 40,
    },
  }));
  const classes = useStyles();

  return (
    <TableCell align="right" className={classes.tableCell}>
      {props.name === 'referenceNumber' && <TextField disabled={props.loading} id="referenceNumber" value={props.value} name="referenceNumber" onChange={props.onChange} className={classes.input} />}
      {props.name === 'firstName' && <TextField disabled={props.loading} id="firstName" value={props.value} name="firstName" onChange={props.onChange} className={classes.input} />}
      {props.name === 'lastName' && <TextField disabled={props.loading} id="lastName" value={props.value} name="lastName" onChange={props.onChange} className={classes.input} />}
      {props.name === 'amount' && <TextField disabled={props.loading} id="amount" value={props.value} name="amount" onChange={props.onChange} className={classes.input} />}
    </TableCell>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomTableCell));
