import { Button } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../store';
import { navigate } from '../../../../store/system/actions';
import styles from './PrintNewButtons.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const PrintNewButtons = (props: any) => {
  const history = useHistory();

  const onNewPaymentClick = () => {
    history.push(props.url);
  };
  return (
    <div className={styles.Buttons}>
      <div className={styles.button}>
        <ReactToPrint trigger={() => <Button variant="contained">Print</Button>} content={() => props.reactTable} />
      </div>
      <Button className={styles.button} variant="contained" color="primary" onClick={onNewPaymentClick}>
        New Payment
      </Button>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrintNewButtons));
