import React, { useEffect } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams, withRouter } from 'react-router-dom';
import { FC, ReactElement } from 'react-transition-group/node_modules/@types/react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import * as typesProducts from '../../../src/store/clerk-payment/types';
import * as typesOrgs from '../../../src/store/manage-org/types';
import errorMessages from '../../error_messages.json';
import ManageErrorType from '../../services/errorHelper';
import { AppState } from '../../store';
import { getProductsByUrl } from '../../store/clerk-payment/actions';
import { getOrganizationByUrlIdentifier } from '../../store/manage-org/actions';
import { navigate } from '../../store/system/actions';
import { ERROR_ACTION } from '../../store/system/types';
import styles from './WebsitePaymentLayout.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const WebsitePaymentLayout: FC<any> = (props: any): ReactElement => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { urlIdentifier } = useParams<{ urlIdentifier: string }>();
  const org = useSelector((state: any) => state?.manageOrg?.orgByUrl);

  useEffect(() => {
    getOrganizationByUrlIdentifier(urlIdentifier)
      .then((org) => {
        dispatch({
          type: typesOrgs.GET_ORG_BY_URL_IDENTIFIER,
          payload: org,
        });
      })
      .catch((err) => {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.manageOrgUpdate + '--' + error,
        });
      });
  }, []);

  useEffect(() => {
    getProductsByUrl(urlIdentifier)
      .then((result: any) => {
        dispatch({
          type: typesProducts.GET_PRODUCTS_WEBSITE,
          payload: result,
        });
      })
      .catch(() => {
        goToLogin();
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.clerkPaymentGetProducts,
        });
      });
  }, []);

  const goToLogin = () => {
    history.push(`/`);
  };
  const goToHome = () => {
    history.push(`/web/${urlIdentifier}`);
  };
  const goToMakePayment = () => {
    history.push(`/web/${urlIdentifier}/pay`);
  };
  const goToHelp = () => {
    history.push(`/web/${urlIdentifier}/help`);
  };
  const ivr = org?.ivrPhone;

  const formatPhoneNumber = (ivr: any) => {
    var cleaned = ('' + ivr).replace(/\D/g, '');
    const clean = cleaned.slice(1, 12);
    var match = clean.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  };
  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <div className="w-100">
          <div className={styles.header}>
            <div className={styles.headerOptions}>
              {org && (
                <div className={styles.headerInfo}>
                  <p>Welcome to the </p>
                  <p className={styles.titleCourt}>{org.name}</p>
                  <p>Online Payment System</p>
                  <p>{org.addressLine1}</p>
                  <p className={styles.phone}>Pay by Phone Call: </p>
                  <p className={styles.phone}>{formatPhoneNumber(ivr) ? formatPhoneNumber(ivr) : ''}</p>
                </div>
              )}
              <div className={styles.options}>
                <ButtonGroup className="mb-2">
                  <Button onClick={goToHome} variant="primary">
                    Home
                  </Button>
                  <Button onClick={goToMakePayment} variant="primary">
                    Make a Payment
                  </Button>
                  <Button onClick={goToHelp} variant="primary">
                    Help
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
        {props.children}
        <div className={styles.footer}>
          <div className={styles.rigth}>
            <img width="90px" height="30px" src={'logos/mps-logo-eagle.png'} />
            <div>
              {'© 2022 • Powered by '}
              <a href="http://www.munipaysol.com/" target="_blank">
                Civil Payments
              </a>
              <br />
              The unavailability of this Web site does not excuse late payments and/or missed payments.
            </div>
          </div>
          <img className={styles.credibilityLogo} width="140" height="85" src={require('../../static/credibility_pci-logo.png')} />
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WebsitePaymentLayout));
