import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../store';
import { getOrganizationByUrlIdentifier } from '../../../../store/manage-org/actions';
import { navigate } from '../../../../store/system/actions';
import styles from './CreditCards.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  manageOrg: state.manageOrg,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
      getOrganizationByUrlIdentifier,
    },
    dispatch
  );

const creditCards = [
  {
    img: 'creditcards/visa.png',
    title: 'Visa',
  },
  {
    img: 'creditcards/mastercard.png',
    title: 'MasterCard',
  },
  {
    img: 'creditcards/amex.png',
    title: 'American Express',
  },
  {
    img: 'creditcards/discover.png',
    title: 'Discover',
  },
];

const CreditCards = (props: any) => {
  const accepted = [] as any;
  const org = props.org;

  const accpetCard = (card: any) => {
    if (org?.ccAcceptVisa && card.title === 'Visa') {
      accepted.push(card);
    }
    if (org?.ccAcceptAmericanExpress && card.title === 'American Express') {
      accepted.push(card);
    }
    if (org?.ccAcceptMasterCard && card.title === 'MasterCard') {
      accepted.push(card);
    }
    if (org?.ccAcceptDiscover && card.title === 'Discover') {
      accepted.push(card);
    }
  };

  creditCards.map((card: any, index: number) => accpetCard(card));

  return (
    <div className={styles.container}>
      {accepted.length === 0 ? (
        <span>This organization not accept any credit card</span>
      ) : (
        <>
          <div className={styles.inputAccept}>Accepting</div>
          <div className={styles.images}>
            {accepted.map((card: any, index: any) => (
              <img key={index} src={card.img} alt={card.title} className={styles.imageitem} />
            ))}
          </div>
          <div className={styles.inputAcceptSpan}>
            <span>Please note: Gift cards are not accepted</span>
          </div>
        </>
      )}
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreditCards));
