import ClerkPaymentCourt from "../components/clerk-payment/ClerkPaymentCourt";
// import NoStrategySelected from "../components/no-strategy-selected/NoStrategySelected";
import { Strategies } from "../models/Strategies";

export class ClerkPaymentStrategy {
    public static strategise(strategyIdentefier: string) {

        switch (strategyIdentefier) {
            case Strategies.court:
                return ClerkPaymentCourt;
            default: 
                return ClerkPaymentCourt;
        }
    }
}