import * as types from './types';

const initialState: types.UserState = {
  passwordUpdatedSuccessfuly: null,
};

export function currentUserReducer(state = initialState, action: types.UserActionTypes): types.UserState {
  switch (action.type) {
    case types.PASSWORD_UPDATE_SUCCESSFUL:
      return {
        ...state,
        passwordUpdatedSuccessfuly: true,
      };
    case types.PASSWORD_UPDATE_FAILED:
      return {
        ...state,
        passwordUpdatedSuccessfuly: false,
      };
    case types.CLEAR_SUCCESS_FAIL:
      return {
        ...state,
        passwordUpdatedSuccessfuly: null,
      };
    default:
      return state;
  }
}
