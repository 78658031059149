// import { Form } from 'react-bootstrap';
import { Button, Card, CardHeader, FormControlLabel, Switch, TextField } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-bootstrap';
// Begin Redux init
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import SimpleReactValidator from 'simple-react-validator';
import { AppState } from '../../../../store';
import { SystemState } from '../../../../store/system/types';
import { clearSuccessFail, createUser, getUsers, updateUser } from '../../../../store/users/actions';
import { UserState } from '../../../../store/users/types';
import { User, UserRole } from '../../../../swagger-client';
import styles from './UserCreate.module.scss';

const mapStateToProps = (state: AppState) => ({
  users: state.users,
  system: state.system,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, any>) =>
  bindActionCreators(
    {
      createUser,
      updateUser,
      getUsers,
      clearSuccessFail,
    },
    dispatch
  );

interface IUserCreateProps extends RouteComponentProps {
  users: UserState;
  system: SystemState;
  getUsers: () => void;
  createUser: (user: User) => void;
  updateUser: (user: User) => void;
  clearSuccessFail: () => void;
}

interface selectedRole {
  role: UserRole;
  checked: boolean;
}

interface IUserCreateState {
  errors: Array<string>;
  selectedUser: User | null;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordRepeat: string;
  active: boolean;
  organizationId: number;
  roles: Array<selectedRole>;
  isLoading: boolean;
  openSuccess: boolean | undefined;
}

class UserCreate extends React.Component<IUserCreateProps, IUserCreateState> {
  protected validator: SimpleReactValidator;
  constructor(props: IUserCreateProps, validatorOptions: object | undefined = undefined) {
    super(props);

    this.state = {
      isLoading: false,
      errors: [],
      selectedUser: null,
      email: '',
      password: '',
      passwordRepeat: '',
      active: true,
      firstName: '',
      lastName: '',
      organizationId: 0,
      openSuccess: false,
      roles: this.props.users.availableRoles.map((role) => {
        return {
          role,
          checked: false,
        };
      }),
    };
    this.validator = new SimpleReactValidator(validatorOptions);
    this.props.getUsers();

    this.userSelected = this.userSelected.bind(this);
    this.newUserClick = this.newUserClick.bind(this);
    this.saveOrUpdate = this.saveOrUpdate.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillUnmount() {
    this.props.clearSuccessFail();
  }

  userSelected(user: User) {
    this.setState({
      selectedUser: user,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      active: user.active as boolean,
      password: '',
      passwordRepeat: '',
      roles: this.props.users.availableRoles.map((role) => {
        return {
          role,
          checked: typeof user.roles !== 'undefined' && user.roles !== null && user.roles.findIndex((r) => r.name === role.name) > -1,
        };
      }),
    });
  }

  private newUserClick() {
    this.props.clearSuccessFail();
    this.setState({
      selectedUser: null,
      email: '',
      password: '',
      passwordRepeat: '',
      firstName: '',
      lastName: '',
      active: true,
    });
  }

  protected handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value } as unknown as any);
  }

  private async saveOrUpdate(event: any) {
    event.preventDefault();

    if (this.validator.allValid()) {
      this.props.clearSuccessFail();

      const { user } = this.props.system;

      const roles = this.state.roles.filter((r) => r.checked).map((r) => r.role);
      const userToCreate = {
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        password: this.state.password,
        passwordRepeat: this.state.passwordRepeat,
        active: this.state.active,
        organizationId: user === null ? -1 : (user.organizationId as number), // We'll always have a value at this point so it's safe to cast.
        roles,
      };
      this.setState({ isLoading: true });
      try {
        await this.props.createUser(userToCreate);
        this.setState({ isLoading: false });
        this.props.history.push({ pathname: '/organization_users', state: { openSuccess: true, snackMessage: 'User Create Successfully', severity: 'success' } });
        this.setState({ isLoading: false });
      } catch (excepcion) {
        this.props.history.push({ pathname: '/organization_users', state: { openSuccess: true, snackMessage: 'Error while creating User', severity: 'error' } });
        this.setState({ isLoading: false });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  protected handleRoleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      roles: this.state.roles.map((role) => {
        if (role.role.name === event.target.name) {
          role.checked = event.target.checked;
        }

        return role;
      }),
    });
  }

  render() {
    const buttonText = this.state.selectedUser === null ? 'Create' : 'Update';

    const newUserButton =
      this.state.selectedUser === null ? null : (
        <Button variant="contained" color="primary" className={styles.newUserButton} onClick={() => this.newUserClick()}>
          New User
        </Button>
      );

    const userRoles = this.state.roles.map((role, index) => <Form.Check key={index} className={styles.inputField} type="checkbox" name={role.role.name} checked={role.checked} onChange={this.handleRoleChange} label={role.role.displayName} />);

    return (
      <>
        <Card className={styles.header}>
          <CardHeader title="Create a User" />
        </Card>
        <div className={styles.container}>
          <div className={styles.columnLeft}>
            <Card className={styles.cardContainer}>
              <CardHeader title="General Information" className={styles.cardHeader} />
              <TextField
                className={styles.inputFieldSelect}
                id="email"
                label="Email" // TODO: Get this label from the org details.
                name="email"
                error={this.state.errors.findIndex((fieldName) => fieldName === 'email') > -1}
                value={this.state.email}
                variant="outlined"
                required
                onChange={this.handleInputChange}
              />
              <TextField
                className={styles.inputFieldSelect}
                id="firstName"
                label="First Name" // TODO: Get this label from the org details.
                name="firstName"
                error={this.state.errors.findIndex((fieldName) => fieldName === 'firstName') > -1}
                value={this.state.firstName}
                variant="outlined"
                required
                onChange={this.handleInputChange}
              />
              <TextField
                className={styles.inputFieldSelect}
                id="lastName"
                label="Last Name" // TODO: Get this label from the org details.
                name="lastName"
                error={this.state.errors.findIndex((fieldName) => fieldName === 'lastName') > -1}
                value={this.state.lastName}
                variant="outlined"
                required
                // value={org?.name}
                onChange={this.handleInputChange}
              />
              <TextField
                className={styles.inputFieldSelect}
                id="password"
                label="Password" // TODO: Get this label from the org details.
                name="password"
                type="password"
                error={this.state.errors.findIndex((fieldName) => fieldName === 'password') > -1}
                value={this.state.password}
                variant="outlined"
                required
                onChange={this.handleInputChange}
              />
              <TextField className={styles.inputFieldSelect} id="passwordRepeat" name="passwordRepeat" label="Re-Enter Password" type="password" value={this.state.passwordRepeat} onChange={this.handleInputChange} variant="outlined" />
              <FormControlLabel className={styles.inputField} control={<Switch checked={this.state.active} onChange={this.handleInputChange} name="active" color="primary" />} label="Active" />
            </Card>
          </div>
          <div className={styles.columnRight}>
            <Card className={styles.cardContainer}>
              <CardHeader title="Permissions" className={styles.cardHeader} />
              <Form.Label className={styles.inputField}>Roles</Form.Label>
              <div className={styles.rolesContainer}>{userRoles}</div>
              {newUserButton}
              <div className={styles.continueButton}>
                <Button variant="contained" color="primary" onClick={this.saveOrUpdate}>
                  {buttonText}
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserCreate));
