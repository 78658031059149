import { RehydrateAction } from 'redux-persist';
import * as types from './types';

const initialState: types.IManageFeeState = {
  fee: null,
  feeProcessing: 0,
};

export function manageFeeReducer(state = initialState, action: types.ManageFeeActionTypes | RehydrateAction): types.IManageFeeState {
  switch (action.type) {
    case types.ADD_FEE_DETAIL_SUCCESSFUL:
    case types.UPDATE_FEE_DETAIL_SUCCESSFUL:
    case types.GET_FEE_DETAIL_SUCCESSFUL:
      return {
        ...state,
        fee: action.payload,
      };
    case types.GET_CALCULATE_FEE_PROCESSING:
      return {
        ...state,
        feeProcessing: action.payload,
      };
    case types.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
}
