import { Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { FilterOperation } from '../../models/FilterOperation';
import { IGeneratedMenuItem } from '../../models/IGeneratedMenuData';
// Begin Redux init
import { AppState } from '../../store';
import { getAllOrganizations } from '../../store/manage-orgs/actions';
import { IManageOrgsState as ReduxManageOrgProps } from '../../store/manage-orgs/types';
import { activateOrgForSuperUser, navigate } from '../../store/system/actions';
import { SystemState } from '../../store/system/types';
import { Organization } from '../../swagger-client';
import DataGrid from '../data-grid/DataGrid';
import styles from './ManageOrgs.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  manageOrgs: state.manageOrgs,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
      getAllOrganizations,
      activateOrgForSuperUser,
    },
    dispatch
  );

interface IManageOrgsProps extends RouteComponentProps {
  system: SystemState;
  manageOrgs: ReduxManageOrgProps;
  navigate: <TProps extends RouteComponentProps<any>>(menuItem: IGeneratedMenuItem, ownProps: TProps) => ThunkAction<void, AppState, null, AnyAction>;
  getAllOrganizations: (skip?: number | null, take?: number | null, sortOptions?: Array<{ field: string; direction: string }> | null, filterOptions?: Array<{ field: string; value: any; operation: FilterOperation }> | null) => ThunkAction<void, AppState, null, AnyAction>;
  activateOrgForSuperUser: (orgId: number) => ThunkAction<void, AppState, null, AnyAction>;
}

interface IManageOrgsState {
  loading: boolean;
  openSuccess: boolean | undefined;
  snackMessage: string;
}
// End Redux init

class ManageOrgs extends React.Component<IManageOrgsProps, IManageOrgsState> {
  constructor(props: IManageOrgsProps) {
    super(props);

    this.state = {
      loading: true,
      openSuccess: false,
      snackMessage: '',
    };

    this.onAddClicked = this.onAddClicked.bind(this);
    this.onEditClicked = this.onEditClicked.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  private onEditClicked(row: Organization) {
    this.props.history.push(`/organization?id=${row.id}&referer=organizations`);
  }

  private onAddClicked() {
    this.props.history.push(`/organization?new=true&referer=organizations`);
  }

  handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSuccess: false });
  };

  async componentDidMount() {
    const loc = this.props.location.state as any;
    this.setState({ openSuccess: loc?.openSuccess ? loc?.openSuccess : false, snackMessage: loc?.snackMessage ? loc?.snackMessage : '' });
  }

  private DataGrid = DataGrid<Organization>();

  // private async loadData() {
  //   await this.props.getAllOrganizations;
  //   this.setState({ loading: false });
  // }

  render() {
    let columns: Array<any> = [
      { name: 'name', title: 'Name' },
      {
        name: 'goToDetailsButton',
        title: 'Edit',
        label: () => 'Edit',
        type: 'button',
        action: this.onEditClicked,
      },
    ];

    return (
      <div className={styles.gridContainer}>
        <this.DataGrid
          preferenceGroup={'ManageOrgs'}
          cardTitle="Organizations"
          // loading={this.state.loading}
          rows={this.props.manageOrgs.orgs}
          loadData={this.props.getAllOrganizations}
          totalRows={this.props.manageOrgs.orgs.length ? this.props.manageOrgs.orgs.length : 0}
          columns={columns}
          pageSizes={[10, 25, 50, 150, 500]}
          columnWidthsGrid={250}
        />
        <Button variant="contained" color="primary" className={styles.continueButton} onClick={this.onAddClicked}>
          New Organization
        </Button>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.openSuccess} autoHideDuration={4000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="success">
            {this.state.snackMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageOrgs));
