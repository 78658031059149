import * as types from './types';

const initialState: types.UserState = {
  users: [],
  availableRoles: [],
  gotUsersSuccessfully: null,
  gotRolesSuccessfully: null,
  userCreatedSuccessfully: null,
  userUpdatedSuccessfully: null,
  user: {email: '', firstName: '', lastName: ''},
};

export function usersReducer(
  state = initialState,
  action: types.UserActionTypes
): types.UserState {
  switch (action.type) {
    case types.GET_USERS_SUCCESSFUL:
      return {
        ...state,
        users: action.payload,
      };
    case types.GET_USER_BY_ID:
      return {
        ...state,
        user: action.payload,
      };

    case types.GET_USERS_FAIL:
      return {
        ...state,
        gotUsersSuccessfully: false,
      };

    case types.GET_AVAILABLE_ROLES_SUCCESSFUL:
      return {
        ...state,
        gotRolesSuccessfully: true,
        availableRoles: action.payload,
      };

    case types.GET_AVAILABLE_ROLES_FAILED:
      return {
        ...state,
        gotRolesSuccessfully: false,
      };

    case types.CREATE_USER_SUCCESSFUL:
      return {
        ...state,
        userCreatedSuccessfully: true,
        users: [...state.users, action.payload],
      };

    case types.CREATE_USER_FAILED: {
      return {
        ...state,
        userCreatedSuccessfully: false,
      };
    }
    
    case types.UPDATE_USER_SUCCESSFUL: {
      return {
        ...state,
        userUpdatedSuccessfully: true,
        users: state.users.map((user) => {
          if (user.email === action.payload.email) {
            return action.payload;
          } else {
            return user;
          }
        }),
      };
    }
    case types.UPDATE_USER_FAILED: {
      return {
        ...state,
        userUpdatedSuccessfully: true,
      };
    }

    case types.CLEAR_SUCCESS_FAIL:
      return {
        ...state,
        gotUsersSuccessfully: null,
        userCreatedSuccessfully: null,
        userUpdatedSuccessfully: null,
      };
    case types.DELETE_USER:
    default:
      return state;
  }
}
