import { Action } from 'redux';
import { User, UserRole } from '../../swagger-client';

export const GET_USERS_SUCCESSFUL = 'GET_USERS_SUCCESSFUL_USERS';
export const GET_USERS_FAIL = 'GET_USERS_FAILED_USERS';
export const GET_AVAILABLE_ROLES_SUCCESSFUL = 'GET_AVAILABLE_ROLES_SUCCESSFUL_USERS';
export const GET_AVAILABLE_ROLES_FAILED = 'GET_AVAILABLE_ROLES_FAILED_USERS';
export const CREATE_USER_SUCCESSFUL = 'CREATE_USER_SUCCESSFUL_USERS';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED_USERS';
export const UPDATE_USER_SUCCESSFUL = 'UPDATE_USER_SUCCESSFUL_USERS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED_USERS';
export const CLEAR_SUCCESS_FAIL = 'CLEAR_SUCCESS_FAIL_USERS';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const DELETE_USER = 'DELETE_USER';

// Action types

export interface GetUsersSuccessAction extends Action {
  type: typeof GET_USERS_SUCCESSFUL;
  payload: Array<User>;
}

export interface GetUsersFailedAction extends Action {
  type: typeof GET_USERS_FAIL;
}

export interface GetAvailableRolesSuccessfulAction extends Action {
  type: typeof GET_AVAILABLE_ROLES_SUCCESSFUL;
  payload: Array<UserRole>;
}

export interface GetAvailableRolesFailedAction extends Action {
  type: typeof GET_AVAILABLE_ROLES_FAILED;
}

export interface CreateUserSuccessAction extends Action {
  type: typeof CREATE_USER_SUCCESSFUL;
  payload: User;
}

export interface CreateUserFailedAction extends Action {
  type: typeof CREATE_USER_FAILED;
}

export interface UpdateUserSuccessAction extends Action {
  type: typeof UPDATE_USER_SUCCESSFUL;
  payload: User;
}

export interface UpdateUserFailedAction extends Action {
  type: typeof UPDATE_USER_FAILED;
}

export interface ClearSuccessFailAction extends Action {
  type: typeof CLEAR_SUCCESS_FAIL;
}

export interface GetUserByIdAction extends Action {
  type: typeof GET_USER_BY_ID;
  payload: User;
}

export interface DeleteUserAction extends Action {
  type: typeof DELETE_USER;
}

export type UserActionTypes = GetUsersSuccessAction | GetUsersFailedAction | GetUsersSuccessAction | GetAvailableRolesSuccessfulAction | GetAvailableRolesFailedAction | CreateUserSuccessAction | CreateUserFailedAction | UpdateUserSuccessAction | UpdateUserFailedAction | ClearSuccessFailAction | GetUserByIdAction | DeleteUserAction;

// Store state

export interface UserState {
  users: Array<User>;
  availableRoles: Array<UserRole>;
  gotUsersSuccessfully: boolean | null;
  gotRolesSuccessfully: boolean | null;
  userCreatedSuccessfully: boolean | null;
  userUpdatedSuccessfully: boolean | null;
  user: User;
}
