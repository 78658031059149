import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import errorMessages from '../../error_messages.json';
import { UserRoles } from '../../models/UserRoles';
import AxiosConfigurator from '../../services/AxiosConfigurator';
import ManageErrorType from '../../services/errorHelper';
import * as swagger from '../../swagger-client/index';
import { User } from '../../swagger-client/index';
import { AppState } from '../index';
import { toggleRouteBack } from '../system/actions';
import { ERROR_ACTION } from '../system/types';
import * as types from './types';

export const getUsers = (): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState: any) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null && state.system.user.roles.findIndex((role: any) => role.name === UserRoles.SuperAdmin) > -1) {
      const userApi = new swagger.UserApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        const result = await userApi.getUsersByOrganization(state.system.user.token);
        dispatch({
          type: types.GET_USERS_SUCCESSFUL,
          payload: result.data,
        });
      } catch {
        dispatch({
          type: types.GET_USERS_FAIL,
        });
      }
    }
  };
};

export const getUser = (id: number): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState: any) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null && state.system.user.roles.findIndex((role: any) => role.name === UserRoles.SuperAdmin || role.name === UserRoles.Admin) > -1) {
      const userApi = new swagger.UserApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        const result = await userApi.getUser(state.system.user.token, id);

        dispatch({
          type: types.GET_USER_BY_ID,
          payload: result.data,
        });
      } catch {
        dispatch({
          type: types.GET_USERS_FAIL,
        });
      }
    }
  };
};
export const getAvailableRoles = (): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null && (state.system.user.roles && state.system.user.roles.findIndex((role) => role.name === UserRoles.SuperAdmin) > -1)) {
      const userRoleApi = new swagger.UserRolesApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        const result = await userRoleApi.getUserRoles();

        dispatch({
          type: types.GET_AVAILABLE_ROLES_SUCCESSFUL,
          payload: result.data,
        });
      } catch {
        dispatch({
          type: types.GET_AVAILABLE_ROLES_FAILED,
        });
      }
    }
  };
};

export const createUser = (user: User): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null && state.system.user.roles && state.system.user.roles.findIndex((role) => role.name === UserRoles.SuperAdmin) > -1) {
      const userApi = new swagger.UserApi(AxiosConfigurator.getConfig(state.system.user.token));
      let token = state.system.user.token as any;

      try {
        await userApi.createUser(token, user);

        dispatch({
          type: types.CREATE_USER_SUCCESSFUL,
          payload: user,
        });
      } catch (err) {
        dispatch({
          type: types.CREATE_USER_FAILED,
        });
        throw err;
      }
    } else {
      dispatch({
        type: types.CREATE_USER_FAILED,
      });
    }
  };
};

export const updateUser = (user: User): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null && state.system.user.roles && state.system.user.roles.findIndex((role) => role.name === UserRoles.SuperAdmin) > -1) {
      const userApi = new swagger.UserApi(AxiosConfigurator.getConfig(state.system.user.token));
      let token = state.system.user.token as any;

      try {
        if (typeof user.roles !== 'undefined' && user.roles !== null && user.roles.hasOwnProperty('length')) {
          const deCircled = user.roles.map((role) => {
            if (typeof role.users !== 'undefined') {
              delete role.users;
            }

            return role;
          });

          user.roles = deCircled;
        }

        await userApi.updateUser(token, user);

        dispatch({
          type: types.UPDATE_USER_SUCCESSFUL,
          payload: user,
        });
      } catch (err) {
        dispatch({
          type: types.UPDATE_USER_FAILED,
        });
        throw err;
      }
    } else {
      dispatch({
        type: types.UPDATE_USER_FAILED,
      });
    }
  };
};
export const deleteUser = (id: number): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState: any) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null && state.system.user.roles.findIndex((role: any) => role.name === UserRoles.SuperAdmin) > -1) {
      const userApi = new swagger.UserApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        await userApi.deleteUser(state.system.user.token, id);
        dispatch(toggleRouteBack());
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.deleteUser + '--' + error,
        });
      }
    }
  };
};

export const clearSuccessFail = (): types.ClearSuccessFailAction => {
  return {
    type: types.CLEAR_SUCCESS_FAIL,
  };
};
