import { Button, Card, CardHeader, CircularProgress, Snackbar, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { currencyFormatter } from '../../services/currencyFormatter';
import { AppState } from '../../store';
import { capturePayment, clearState, filterPayment, filterPaymentById } from '../../store/clerk-payment/actions';
import { IClerkPaymentState as IClerkPaymentStateRedux } from '../../store/clerk-payment/types';
import { getProductById } from '../../store/manage-product/actions';
import { IManageProductState as ReduxManageProductState } from '../../store/manage-product/types';
import { navigate } from '../../store/system/actions';
import { SystemState } from '../../store/system/types';
import { CapturepaymentModel, FilterPaymentModel, Payment } from '../../swagger-client';
import CustomSeparator from '../bread-crumbs/BreadCrumbs';
import DataGrid from '../data-grid/DataGrid';
import styles from './ManagePayments.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  clerkPayment: state.clerkPayment,
  manageProduct: state.manageProduct,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
      filterPaymentById,
      capturePayment,
      clearState,
      getProductById,
    },
    dispatch
  );

interface IPaymentGridProps extends RouteComponentProps {
  system: SystemState;
  clerkPayment: IClerkPaymentStateRedux;
  manageProduct: ReduxManageProductState;
  getProductById: (id: number) => ThunkAction<void, AppState, null, AnyAction>;
  clearState: () => void;
  filterPaymentById: (filterPayment: FilterPaymentModel, id?: any) => ThunkAction<void, AppState, null, AnyAction>;
  capturePayment: (paymentId: CapturepaymentModel) => ThunkAction<void, AppState, null, AnyAction>;
}

interface IPaymentGridState {
  isLoading: boolean;
  filter: FilterPaymentModel;
  paymentsFiltered: any[];
  selection: any[];
  loading: boolean;
  dateFrom: Date | any;
  dateTo: Date | any;
  openSuccess: boolean | undefined;
  snackMessage: string;
  referenceNumber: string;
}
// End Redux init

class ManagePayments extends React.Component<IPaymentGridProps, IPaymentGridState> {
  constructor(props: IPaymentGridProps) {
    super(props);

    this.state = {
      isLoading: true,
      filter: { status1: 'Authorized' },
      paymentsFiltered: [],
      selection: [],
      loading: false,
      dateFrom: new Date(),
      dateTo: new Date(),
      openSuccess: false,
      snackMessage: '',
      referenceNumber: '',
    };

    this.onAddClicked = this.onAddClicked.bind(this);
    this.onEditClicked = this.onEditClicked.bind(this);
    this.onReceiptClicked = this.onReceiptClicked.bind(this);
    this.onCaputreClicked = this.onCaputreClicked.bind(this);
    this.onCaptureAllClicked = this.onCaptureAllClicked.bind(this);
    this.setSelectionState = this.setSelectionState.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  async componentDidMount() {
    let dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 7);
    this.setState({ dateFrom: dateFrom });
    const filter = { status1: 'Authorized', fromDate: dateFrom, toDate: new Date() };
    filterPayment(filter, this.props.system.user).then((payments) => {
      this.setState({ paymentsFiltered: payments, isLoading: false, filter: filter });
    });
    const loc = this.props.location.state as any;
    this.setState({ openSuccess: loc?.openSuccess ? loc?.openSuccess : false, snackMessage: loc?.snackMessage ? loc?.snackMessage : '' });
  }

  private onReceiptClicked(row: Payment) {
    this.props.filterPaymentById(this.state.filter, row.id);
    this.props.history.push(`/payments/${row.id}/receipt`);
  }

  private onEditClicked(row: Payment) {
    this.props.filterPaymentById(this.state.filter, row.id);
    this.props.history.push(`/payments/${row.id}/edit`);
  }

  private async onCaputreClicked(row: Payment) {
    const payment = { paymentId: row.id } as CapturepaymentModel;
    this.setState({ isLoading: true });
    try {
      await this.props.capturePayment(payment);
      this.setState({ openSuccess: true, snackMessage: 'Payment captured successfully' });
    } catch (excepcion) {
      this.setState({ openSuccess: false, snackMessage: '' });
    }
    this.setState({ isLoading: false });
    filterPayment(this.state.filter, this.props.system.user).then((payments) => {
      this.setState({ paymentsFiltered: payments });
    });
  }

  private onAddClicked() {
    this.props.history.push(`/payments/create`);
  }

  private async onUpdateClick() {
    const filter = { status1: 'Authorized', fromDate: this.state.dateFrom, toDate: this.state.dateTo, referenceNumber: this.state.referenceNumber };
    this.setState({ loading: true });
    await filterPayment(filter, this.props.system.user).then((payments) => {
      this.setState({ paymentsFiltered: payments });
    });
    this.setState({ loading: false });
  }

  private setSelectionState(selection: any[]) {
    this.setState({
      selection,
    });
  }

  handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSuccess: false });
  };

  private async onCaptureAllClicked() {
    const selection = this.state.selection;
    this.setState({ isLoading: true });

    let promise = selection.map(async (paymentId: number, indexL: number) => {
      const payment = { paymentId: paymentId } as CapturepaymentModel;
      await this.props.capturePayment(payment);
    });

    Promise.all(promise)
      .then((value: any) => {
        filterPayment(this.state.filter, this.props.system.user).then((payments) => {
          this.setState({ paymentsFiltered: payments });
        });
        this.setState({ isLoading: false });
        this.setState({ openSuccess: true, snackMessage: 'Payment captured successfully' });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        this.setState({ openSuccess: false });
      });
  }

  private DataGrid = DataGrid<Payment>();

  private capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  render() {
    let filtred = this.state.paymentsFiltered as any;
    let date_string = null;
    const dataSend = filtred?.map((row: any) => {
      date_string = row.date.toString();
      date_string = moment(date_string).tz('America/New_York').format('MM/DD/YYYY hh:mm A z');

      let name = row.source === 'phone' ? (row.firstName ? `Phone: ${row.firstName}` : 'Phone: undefined') : row.paymentItems[0]?.firstName + ' ' + row.paymentItems[0]?.lastName;

      const referenceNumbers = row.paymentItems.map((item: any) => item.referenceNumber).join(' | ');

      return {
        id: row.id,
        date: date_string,
        name: name,
        source: row.source.toUpperCase(),
        referenceNumber: referenceNumbers,
        createdById: row.createdBy ? row.createdBy.firstName + ' ' + row.createdBy.lastName : 'System',
        feeAmount: row.feeAmount,
        total: row.subTotal,
        subTotal: row.subTotal,
        cardLast: row.cardLast ? row.cardLast : undefined,
        productId: row.paymentItems[0]?.product?.name ? row.paymentItems[0]?.product?.name : undefined,
      };
    });
    let columns: Array<any> = [
      { name: 'date', title: 'Date' },
      {
        name: 'name',
        title: 'Name',
        label: (row: any) => {
          return this.capitalizeFirstLetter(row.name);
        },
      },
      {
        name: 'citation',
        title: 'Citation/Case',
        type: 'link',
        label: (row: any) => {
          return row.referenceNumber;
        },
        action: this.onReceiptClicked,
      },
      { name: 'cardLast', title: 'Card Last 4 digits' },
      { name: 'createdById', title: 'Created by' },
      {
        name: 'source',
        title: 'Source',
        type: 'select',
        options: ['counter', 'website', 'kiosk', 'phone'],
      },
      {
        name: 'subTotal',
        title: 'Subtotal',
        getCellValue: (row: any, columnName: string) => {
          return currencyFormatter.format(row.subTotal as number);
        },
      },
      {
        name: 'feeAmount',
        title: 'Fees',
        getCellValue: (row: any, columnName: string) => {
          return currencyFormatter.format(row.feeAmount as number);
        },
      },
      {
        name: 'editButtonPay',
        title: 'Capture',
        type: 'button',
        label: () => 'Capture',
        action: this.onCaputreClicked,
      },
      {
        name: 'captureButtonPay',
        title: 'Edit',
        type: 'button',
        label: () => 'Edit',
        action: this.onEditClicked,
      },
    ];

    return (
      <>
        <Card className={styles.header}>
          <CardHeader title="Manage Payments" />
        </Card>
        <Card className={styles.header}>
          <CustomSeparator menuList={['Make a Payment']} />
        </Card>
        <Card className={styles.cardContainer}>
          <div className={styles.dateContainer}>
            <KeyboardDatePicker
              className={styles.dateInput}
              disableToolbar
              variant="dialog"
              inputVariant="outlined"
              format="MM/DD/YYYY"
              margin="normal"
              id="DateFrom"
              label={'Date from'}
              value={this.state.dateFrom}
              onChange={(date) => this.setState({ dateFrom: date })}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              className={styles.dateInput}
              disableToolbar
              variant="dialog"
              inputVariant="outlined"
              format="MM/DD/YYYY"
              margin="normal"
              id="dateTo"
              label={'Date To'}
              value={this.state.dateTo}
              onChange={(date) => this.setState({ dateTo: date })}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <TextField value={this.state.referenceNumber} className={styles.inputFieldSelect} variant="outlined" id="reference_number" label="Citation Number" name="referenceNumber" onChange={(e) => this.setState({ referenceNumber: e.target.value })} />{' '}
            <Button variant="contained" color="primary" className={styles.dateInput} onClick={this.onUpdateClick}>
              {this.state.loading ? <CircularProgress color="inherit" /> : 'Update'}
            </Button>
          </div>
        </Card>
        <div className={styles.gridContainer}>
          <this.DataGrid
            preferenceGroup={'Payments'}
            cardTitle={'Payments'}
            rows={dataSend ? dataSend : []}
            totalRows={dataSend ? dataSend.length : 10}
            columns={columns}
            pageSizes={[10, 25, 50, 150, 500]}
            // addButton={true}
            // onAddClick={this.onAddClicked}
            loading={this.state.isLoading}
            uncontrolledColumnFiltering
            uncontrolledColumnSorting
            disableColumnChooser={false}
            setSelectionState={this.setSelectionState}
          />
          <Button disabled={this.state.selection.length === 0} variant="contained" color="primary" className={styles.continueButton} onClick={this.onCaptureAllClicked}>
            Capture All
          </Button>
        </div>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.openSuccess} autoHideDuration={6000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="success">
            {this.state.snackMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManagePayments));
