import { ValidationError } from 'yup';

export default function ManageErrorType(err: any): string {
  if (err.response && err.response.data.name === ValidationError.name) {
    return err.response.data.message;
  }
  if (err.response && err.response.data.args) {
    return err.response.data.args[0].details;
  }
  return '';
}
