import { Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { FilterOperation } from '../../models/FilterOperation';
import { IGeneratedMenuItem } from '../../models/IGeneratedMenuData';
import { currencyFormatter } from '../../services/currencyFormatter';
// Begin Redux init
import { AppState } from '../../store';
import { deleteProduct, getProducts } from '../../store/product-grid/actions';
import { IProductsGridState as IProductsGridStateRedux } from '../../store/product-grid/types';
import { navigate } from '../../store/system/actions';
import { SystemState } from '../../store/system/types';
import { Product } from '../../swagger-client';
import DataGrid from '../data-grid/DataGrid';
import styles from './ProductsGrid.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  productsGrid: state.productsGrid,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getProducts,
      deleteProduct,
      navigate,
    },
    dispatch
  );

interface IProductsGridProps extends RouteComponentProps {
  system: SystemState;
  productsGrid: IProductsGridStateRedux;
  navigate: <TProps extends RouteComponentProps<any>>(menuItem: IGeneratedMenuItem, ownProps: TProps) => ThunkAction<void, AppState, null, AnyAction>;
  getProducts: (skip?: number | null, take?: number | null, sortOptions?: Array<{ field: string; direction: string }> | null, filterOptions?: Array<{ field: string; value: any; operation: FilterOperation }> | null) => ThunkAction<void, AppState, null, AnyAction>;
  deleteProduct: (idToDelete: number) => ThunkAction<void, AppState, null, AnyAction>;
}

interface IProductsGridState {
  openSuccess: boolean | undefined;
  snackMessage: string;
}
// End Redux init

class ProductsGrid extends React.Component<IProductsGridProps, IProductsGridState> {
  constructor(props: IProductsGridProps) {
    super(props);

    this.state = {
      openSuccess: false,
      snackMessage: '',
    };

    this.props.getProducts();

    this.onAddClicked = this.onAddClicked.bind(this);
    this.onEditClicked = this.onEditClicked.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  private onEditClicked(row: Product) {
    this.props.history.push(`/product?id=${row.id}&referer=products`);
  }

  private onDeleteClicked(row: Product) {
    this.props.deleteProduct(row.id as number);
  }

  private onAddClicked() {
    this.props.history.push(`/product?new=true&referer=products`);
  }
  handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSuccess: false });
  };

  async componentDidMount() {
    const loc = this.props.location.state as any;
    this.setState({ openSuccess: loc?.openSuccess ? loc?.openSuccess : false, snackMessage: loc?.snackMessage ? loc?.snackMessage : '' });
  }

  private DataGrid = DataGrid<Product>();

  render() {
    let columns: Array<any> = [
      { name: 'name', title: 'Name' },
      { name: 'description', title: 'Description' },
      {
        name: 'price',
        title: 'Price',
        getCellValue: (row: any, columnName: string) => {
          if (row.variablePrice) {
            return 'Variable';
          } else {
            return currencyFormatter.format(row.price as number);
          }
        },
      },
      // { name: 'isCounter', title: 'Counter?' },
      // { name: 'isWeb', title: 'Web?' },
      {
        name: 'goToDetailsButton',
        title: 'Edit',
        label: () => 'Edit',
        type: 'button',
        action: this.onEditClicked,
      },
      {
        name: 'deleteButton',
        title: 'Delete',
        type: 'button',
        label: () => 'Delete',
        action: this.onDeleteClicked,
      },
    ];

    return (
      <div className={styles.gridContainer}>
        <this.DataGrid
          preferenceGroup={'Products'}
          cardTitle={'Products'}
          rows={this.props.productsGrid?.products ? this.props.productsGrid.products : []}
          totalRows={this.props.productsGrid.count ? this.props.productsGrid.count : 0}
          columns={columns}
          pageSizes={[10, 25, 50, 150, 500]}
          // addButton={true}
          // onAddClick={this.onAddClicked}
          uncontrolledColumnFiltering
          uncontrolledColumnSorting
          columnWidthsGrid={250}
        />
        <Button variant="contained" color="primary" className={styles.continueButton} onClick={this.onAddClicked}>
          New Product
        </Button>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.openSuccess} autoHideDuration={4000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="success">
            {this.state.snackMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductsGrid));
