import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
// import AxiosConfigurator from '../../services/AxiosConfigurator';
// import * as swagger from '../../swagger-client';
// import { UserPreference } from '../../swagger-client';
import { AppState } from '../index';
// import * as types from './types';

export const getUserPreferences = (): ThunkAction<
  void,
  AppState,
  null,
  AnyAction
> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    // const state = getState();

    // if (state.system !== null && state.system.user !== null) {
    //   const userPreferenceApi = new swagger.UserPreferenceApi(
    //     AxiosConfigurator.getConfig(state.system.user.token)
    //   );
    //   let token = state.system.user.token as any

    //   try {
    //     const result = await userPreferenceApi.getUserPreferences(token);

    //     dispatch({
    //       type: types.GET_USER_PREFERENCE_SUCCESSFUL,
    //       payload: result.data.values,
    //     });
    //   } catch {
    //     console.error('Get user preferences failed!');
    //     // TODO: Dispatch system wide error event.
    //   }
    // }
  };
};

export const createOrUpdateUserPreference = (
  key: string,
  subkey: string,
  value: string
): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    // const state = getState();

    // if (state.system !== null && state.system.user !== null) {
    //   const userPreferenceApi = new swagger.UserPreferenceApi(
    //     AxiosConfigurator.getConfig(state.system.user.token)
    //   );
    //   let token = state.system.user.token as any

    //   try {
    //     let result: UserPreference | null = null;

    //     const foundIndex = state.userPreferences.userPreferences.findIndex(
    //       (up) => up.key === key && up.subkey === subkey
    //     );

    //     if (foundIndex > -1) {
    //       const preferenceToUpdate =
    //         state.userPreferences.userPreferences[foundIndex];

    //       preferenceToUpdate.value = value;

    //       result = (
    //         await userPreferenceApi.updateUserPreference(token,preferenceToUpdate)
    //       ).data;

    //       dispatch({
    //         type: types.UPDATE_USER_PREFERENCE_SUCCESSFUL,
    //         payload: result,
    //       });
    //     } else {
    //       const userPreference: UserPreference = {
    //         key,
    //         subkey,
    //         value,
    //       };

    //       result = (
    //         await userPreferenceApi.createUserPreference(token,userPreference)
    //       ).data;

    //       dispatch({
    //         type: types.CREATE_USER_PREFERENCE_SUCCESSFUL,
    //         payload: result,
    //       });
    //     }
    //   } catch {
    //     console.error('Create or update user preferences failed!');
    //     // TODO: Dispatch system wide error event.
    //   }
    // }
  };
};
