import { Action } from 'redux';
export const PASSWORD_UPDATE_SUCCESSFUL = 'PASSWORD_UPDATE_SUCCESSFUL';
export const PASSWORD_UPDATE_FAILED = 'PASSWORD_UPDATE_FAILED';
export const CLEAR_SUCCESS_FAIL = 'CLEAR_SUCCESS_FAIL';

// Action types

export interface PasswordUpdateSuccessAction extends Action {
  type: typeof PASSWORD_UPDATE_SUCCESSFUL;
}

export interface PasswordUpdateFailedAction extends Action {
  type: typeof PASSWORD_UPDATE_FAILED;
}

export interface ClearSuccessFailAction extends Action {
  type: typeof CLEAR_SUCCESS_FAIL;
}

export type UserActionTypes = PasswordUpdateSuccessAction | PasswordUpdateFailedAction | ClearSuccessFailAction;

// Store state

export interface UserState {
  passwordUpdatedSuccessfuly: boolean | null;
}
