import { REHYDRATE, RehydrateAction } from 'redux-persist';
import { IGeneratedMenuItem } from '../../models/IGeneratedMenuData';
import { LoggedInUser } from '../../models/LoggedInUser';
import { NavigationScopes } from '../../models/NavigationScopes';
import menu from '../../services/getMenuItems';
import MenuService from '../../services/MenuService';
import { Organization, UserRole } from '../../swagger-client';
import * as types from './types';

const initialState: types.SystemState = {
  user: {
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    organizationId: undefined,
    organization: undefined,
    organizations: undefined,
    token: undefined,
    roles: [],
  },
  userLoggedIn: false,
  loginFailed: false,
  menuData: { items: [] },
  menuDataByName: {},
  activeView: '',
  menuDataByPath: {},
  menuDataByNavigationScope: {},
  navigationHistory: [],
  errors: [],
  routeBack: false,
  passwordResetMessage: undefined,
  products: [],
};

export function systemReducer(state = initialState, action: types.SystemActionTypes | RehydrateAction): types.SystemState {
  switch (action.type) {
    case REHYDRATE: // This is necessary because redux persist tries to store the class as an object which breaks the router.
      if (typeof action.payload !== 'undefined' && action.payload !== null) {
        const persistedState = (action.payload as any).system;

        if (persistedState.user !== null && persistedState.user.roles !== null && persistedState.user.roles.hasOwnProperty('length')) {
          const rehydrateMenuData = menu(persistedState.user);

          return {
            ...persistedState,
            menuData: MenuService.generateMenuData(rehydrateMenuData, persistedState.user.roles),
          };
        } else {
          return persistedState;
        }
      } else {
        return state;
      }

    case types.LOGIN_SUCCESSFUL:
      const user: LoggedInUser = {
        id: action.payload.id,
        email: action.payload.email,
        token: action.payload.token,
        roles: action.payload.roles,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        organizationId: action.payload.organizationId,
        organization: action.payload.organization,
        organizations: action.payload.organizations,
      };

      const loginSuccessfulMenuData = menu(user);

      const menuData = MenuService.generateMenuData(loginSuccessfulMenuData, action.payload.roles);
      const menuDataByName = MenuService.generateMenuItemsByName(loginSuccessfulMenuData, action.payload.roles);
      const menuDataByPath = MenuService.generateMenuItemsByPath(loginSuccessfulMenuData, action.payload.roles);
      const menuDataByNavigationScope = MenuService.generateMenuItemsByNavigationScope(loginSuccessfulMenuData, action.payload.roles);

      return {
        ...state,
        user,
        userLoggedIn: true,
        loginFailed: false,
        menuData,
        menuDataByNavigationScope,
        menuDataByName,
        menuDataByPath,
        products: action.payload?.organization?.products,
      };

    case types.UPDATE_ORGANIZATIONS_USER:
      const newOrgs = state.user?.organizations?.concat(action.payload as any);
      return {
        ...state,
        user: {
          ...state.user,
          organizations: newOrgs
        }
      }

    case types.LOGIN_FAILED:
      return {
        ...state,
        user: null,
        userLoggedIn: false,
        loginFailed: true,
      };
    case types.LOG_OUT:
      return {
        ...state,
        user: null,
        userLoggedIn: false,
        loginFailed: false,
      };
    case types.UPDATE_NAVIGATION_CURRENT_MENU_ITEM:
      const { navigationScope } = action.payload;
      const currentMenuItem: IGeneratedMenuItem | null = state.navigationHistory.length > 0 ? state.navigationHistory[state.navigationHistory.length - 1] : null;

      if (navigationScope === NavigationScopes.Home) {
        return {
          ...state,
          navigationHistory: [],
        };
      } else if (navigationScope === NavigationScopes.Back) {
        return {
          ...state,
          navigationHistory: state.navigationHistory.slice(0, state.navigationHistory.length - 1),
        };
      } else if (currentMenuItem !== null && currentMenuItem.title === action.payload.title) {
        return state; // No need to navigate. We have the same menuitem as current.
      } else {
        const foundIndex = state.navigationHistory.findIndex((menuItem) => menuItem.title === action.payload.title);

        if (foundIndex > -1) {
          // If it exists in history move back to it.
          return {
            ...state,
            navigationHistory: state.navigationHistory.slice(0, foundIndex + 1),
          };
        } else if (
          currentMenuItem !== null &&
          currentMenuItem.parentNavigationScope !== undefined &&
          currentMenuItem.parentNavigationScope !== null &&
          action.payload.parentNavigationScope !== undefined &&
          action.payload.parentNavigationScope !== null &&
          action.payload.parentNavigationScope === currentMenuItem.parentNavigationScope
        ) {
          return {
            ...state,
            navigationHistory: [...state.navigationHistory.slice(0, state.navigationHistory.length - 1), action.payload],
          };
        } else if (currentMenuItem?.navOnly !== undefined) {
          let foundIndexNavigationScope = -1;

          let navigationHistory = state.navigationHistory;

          for (let i = navigationHistory.length - 1; i >= 0; i--) {
            if (navigationHistory[i]?.navigationScope !== undefined && navigationHistory[i]?.navigationScope !== null) {
              foundIndexNavigationScope = i;
              break;
            }
          }

          if (foundIndexNavigationScope > -1) {
            return {
              ...state,
              navigationHistory: [...state.navigationHistory.slice(0, foundIndexNavigationScope + 1), action.payload],
            };
          } else {
            throw new Error('Watcha doin in my waters?');
          }
        } else if (currentMenuItem !== null && currentMenuItem.navOnly !== undefined && currentMenuItem.navOnly !== null && action.payload.navigationScope !== undefined && action.payload.navigationScope !== null) {
          let lastNavigationScope = -1;

          for (let i = state.navigationHistory.length - 1; i >= 0; i--) {
            if (state.navigationHistory[i]?.navigationScope !== undefined) {
              lastNavigationScope = i;
              break;
            }
          }

          if (lastNavigationScope > -1) {
            return {
              ...state,
              navigationHistory: [...state.navigationHistory.slice(0, lastNavigationScope + 1), action.payload],
            };
          } else {
            return {
              ...state,
              navigationHistory: [action.payload],
            };
          }
        } else {
          return {
            ...state,
            navigationHistory: [...state.navigationHistory, action.payload],
          };
        }
      }

    case types.ACTIVATE_ORG_FOR_SUPER_USER:
      const activateOrgUser: LoggedInUser = {
        ...(state.user as LoggedInUser),
        organizationId: action.payload?.organizationId,
        organization: action.payload?.organization,
        roles: state.user?.roles as Array<UserRole>,
      };

      const activateOrgMenuData = menu(activateOrgUser);

      return {
        ...state,
        user: activateOrgUser,
        menuData: MenuService.generateMenuData(activateOrgMenuData, state.user?.roles as Array<UserRole>),
        products: action.payload?.organization?.products,
      };

    case types.ACTIVE_VIEW:
      return {
        ...state,
        activeView: action.payload,
      };

    case types.ERROR_ACTION:
      return {
        ...state,
        errors: [...state.errors, action.payload],
      };

    case types.CLEAR_ERRORS_ACTION:
      return {
        ...state,
        errors: [],
      };

    case types.SEND_PASSWORD_RESET_EMAIL_SUCCESSFUL:
      return {
        ...state,
        passwordResetMessage: 'Email sent!',
      };

    case types.CHANGE_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        passwordResetMessage: action.payload,
      };

    case types.TOGGLE_ROUTE_BACK:
      return {
        ...state,
        routeBack: !state.routeBack,
      };

    case types.UPDATE_PRODUCT:
      const updatedProductsArray = state.products?.map((product) => {
        if (product.id === action.payload.id) {
          return action.payload;
        }

        return product;
      });

      return {
        ...state,
        products: updatedProductsArray,
      };

    case types.ADD_PRODUCT:
      return {
        ...state,
        products: state.products?.concat(action.payload),
      };

    case types.UPDATE_ORGANIZATION:
      const userToUpdate: any = state.user ? state.user : {};
      userToUpdate.organization = action.payload;
      return {
        ...state,
        user: userToUpdate,
      };
    case types.CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
