import MomentUtils from '@date-io/moment';
import { AppBar, Collapse, CssBaseline, Divider, Drawer, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Toolbar, withStyles } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Shadows } from '@material-ui/core/styles/shadows';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import CreateIcon from '@material-ui/icons/Create';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LogoutOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import WarningAmberOutlinedIcon from '@material-ui/icons/WarningOutlined';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'bootstrap/dist/css/bootstrap.min.css';
import { default as React, ReactElement, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as types from '../src/store/system/types';
import styles from './App.module.scss';
import ErrorModal from './components/error/ErrorModal';
import IGeneratedMenuData, { IGeneratedMenuItem } from './models/IGeneratedMenuData';
import { UserRoles } from './models/UserRoles';
import { buildRoutes } from './Routes/AppRoutes';
import { LoginRoutes } from './Routes/AuthRoutes';
import { whiteLabelStrategy } from './services/whiteLabelStrategy';
// Begin Redux init
import { AppState } from './store';
import { changeOrganization, clearErrorsAction, logOut, navigate, setActiveView, updateCurrentMenuItem } from './store/system/actions';
import { SystemState } from './store/system/types';
import { getAvailableRoles } from './store/users/actions';

const whiteLabel = whiteLabelStrategy();

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getAvailableRoles,
      clearErrorsAction,
      logOut,
      navigate,
      updateCurrentMenuItem,
      dispatch,
      setActiveView,
    },
    dispatch
  );

interface IAppProps extends RouteComponentProps {
  getAvailableRoles: () => void;
  clearErrorsAction: () => void;
  classes: any;
  system: SystemState;
  logOut: () => void;
  dispatch: (data: any) => void;
  setActiveView: (path: string) => void;
  navigate?: <TProps extends RouteComponentProps<any>>(currentMenuItem: IGeneratedMenuItem, ownProps: TProps, initialLoad?: boolean) => ThunkAction<void, AppState, null, AnyAction>;
  updateCurrentMenuItem?: (menuItem: IGeneratedMenuItem) => void;
}

interface IAppState {
  menuData: IGeneratedMenuData | null;
  mobileOpen: boolean;
  userMenuExpanded: boolean;
  userMenuOrganizationExpanded: boolean;
  drawerMenuExpanded: boolean;
  clickedOutside: boolean;
}
// End Redux init

// Material UI
const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: whiteLabel.color1,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#3B3888',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffffff',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  shadows: Array(25).fill('none') as Shadows,
});

const drawerWidth = 240;

const navStyles = (theme: any) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('lg')]: {
      zIndex: theme.zIndex.drawer + 1,
    },
    background: '#CD2D39',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('lg')]: {
      paddingTop: '15vh',
    },
    background: 'linear-gradient(355.65deg, #383889 47.15%, #A33050 70.04%, #CD2D39 86.77%)',
    // box-shadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',

    color: '#F5F5F5',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: '10vh',
  },
});

class App extends React.Component<IAppProps, IAppState> {
  private wrapperRef = React.createRef<HTMLDivElement>();

  constructor(props: IAppProps) {
    super(props);

    this.state = {
      menuData: null,
      mobileOpen: false,
      userMenuExpanded: false,
      drawerMenuExpanded: false,
      userMenuOrganizationExpanded: false,
      clickedOutside: false,
    };
    this.props.getAvailableRoles();
  }
  private renderLogin() {
    const routes = LoginRoutes();

    return (
      <>
        <Switch>{routes}</Switch>
      </>
    );
  }

  userMenuHandler = () => {
    this.setState({ userMenuExpanded: !this.state.userMenuExpanded, userMenuOrganizationExpanded: false });
  };
  userMenuOrganizationHandler = () => {
    this.setState({ userMenuOrganizationExpanded: !this.state.userMenuOrganizationExpanded, userMenuExpanded: false });
  };

  drawerMenuHandler = () => {
    this.setState({ drawerMenuExpanded: !this.state.drawerMenuExpanded });
  };

  private buildOrgsMenu() {
    const user = this.props?.system?.user;
    return (
      <div
        className={styles.userMenuContainer}
        tabIndex={0}
        onBlur={(e) => {
          this.setState({ userMenuExpanded: false });
        }}
      >
        {user?.organizations?.map((organization: any, index: number) => (
          <List key={index} className={styles.listItem}>
            <ListItem
              button
              className={styles.menuItem}
              key={'UserMenuManageOrgs'}
              onClick={async () => {
                changeOrganization(organization, user)
                  .then((result: any) => {
                    this.props.dispatch({
                      type: types.LOGIN_SUCCESSFUL,
                      payload: {
                        id: result.data.id,
                        token: result.data.token,
                        firstName: result.data.firstName,
                        lastName: result.data.lastName,
                        organizationId: result.data.organizationId,
                        organization: result.data.organization,
                        organizations: result.data.organizations,
                        email: result.data.email,
                        roles: result.data.roles,
                      },
                    });
                    this.userMenuOrganizationHandler();
                    this.props.history.push('/');
                    this.props.setActiveView('/payments/create');
                  })
                  .catch((err: any) => {
                    console.log(err);
                  });
              }}
            >
              <ListItemIcon className={styles.icon}>
                <BusinessOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={`${organization?.name}`} />
            </ListItem>
          </List>
        ))}
      </div>
    );
  }

  private buildUserMenu() {
    let manageOrgs: ReactElement | null = null;
    let manageProducts: ReactElement | null = null;
    let manageUsers: ReactElement | null = null;
    let manageFees: ReactElement | null = null;
    let manageProfile: ReactElement | null = null;
    let website: ReactElement | null = null;
    const user = this.props?.system?.user;

    if (user !== undefined && user !== null) {
      if (user?.roles && user?.roles.findIndex((role) => role.name === UserRoles.SuperAdmin) > -1) {
        manageOrgs = (
          <List className={styles.listItem}>
            <ListSubheader className={styles.subHeader}>Super Admin</ListSubheader>
            <ListItem
              button
              className={styles.menuItem}
              key={'UserMenuManageOrgs'}
              onClick={() => {
                this.props.history.push('/organizations');
                this.setState({ userMenuExpanded: false });
                this.props.setActiveView('');
              }}
            >
              <ListItemIcon className={styles.icon}>
                <BusinessOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Manage Orgs'} />
            </ListItem>
          </List>
        );
      }
      if (user?.roles && user?.roles.findIndex((role) => role.name === UserRoles.SuperAdmin || role.name === UserRoles.Admin) > -1) {
        manageProducts = (
          <List className={styles.listItem}>
            <ListSubheader className={styles.subHeader}>Admin</ListSubheader>
            <ListItem
              button
              className={styles.menuItem}
              key={'UserMenuManageProducts'}
              onClick={() => {
                this.props.history.push('/products');
                this.setState({ userMenuExpanded: false });
                this.props.setActiveView('');
              }}
            >
              <ListItemIcon className={styles.icon}>
                <CategoryOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Manage Products'} />
            </ListItem>
          </List>
        );
      }

      if (user?.roles && user?.roles.findIndex((role) => role.name === UserRoles.SuperAdmin || role.name === UserRoles.Admin) > -1) {
        manageUsers = (
          <List className={styles.listItem}>
            <ListItem
              button
              className={styles.menuItem}
              key={'Users'}
              onClick={() => {
                this.props.history.push('/organization_users');
                this.setState({ userMenuExpanded: false });
                this.props.setActiveView('');
              }}
            >
              <ListItemIcon className={styles.icon}>
                <PeopleAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Users'} />
            </ListItem>
          </List>
        );
      }

      if (user?.roles && user?.roles.findIndex((role) => role.name === UserRoles.SuperAdmin || role.name === UserRoles.Admin) > -1) {
        manageFees = (
          <List className={styles.listItem}>
            <ListItem
              button
              className={styles.menuItem}
              key={'UserMenuManageFees'}
              onClick={() => {
                this.props.history.push('/fees');
                this.setState({ userMenuExpanded: false });
                this.props.setActiveView('');
              }}
            >
              <ListItemIcon className={styles.icon}>
                <WarningAmberOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Manage Fees'} />
            </ListItem>
          </List>
        );
      }
      if (user?.roles && (
        user?.roles.findIndex((role) => role.name === UserRoles.SuperAdmin) > -1 ||
        user?.roles.findIndex((role) => role.name === UserRoles.AcceptPayment) > -1 ||
        user?.roles.findIndex((role) => role.name === UserRoles.ManagePayments) > -1 ||
        user?.roles.findIndex((role) => role.name === UserRoles.Reporting) > -1 ||
        user?.roles.findIndex((role) => role.name === UserRoles.Admin) > -1)
      ) {
        manageProfile = (
          <List className={styles.listItem}>
            <ListItem
              button
              className={styles.menuItem}
              key={'ManageCurrentUser'}
              onClick={() => {
                this.props.history.push('/account');
                this.setState({ userMenuExpanded: false });
                this.props.setActiveView('');
              }}
            >
              <ListItemIcon className={styles.icon}>
                <AccountBoxOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Profile'} />
            </ListItem>
          </List>
        );
      }
      // if (user?.roles.findIndex((role) => role.name === UserRoles.SuperAdmin) > -1) {
      website = (
        <List className={styles.listItem}>
          <ListItem
            button
            className={styles.menuItem}
            key={'Website'}
            onClick={() => {
              this.props.logOut();
              this.props.history.push(`/web/${user?.organization?.urlIdentifier}`);
              this.setState({ userMenuExpanded: false });
              this.props.setActiveView('');
            }}
          >
            <ListItemIcon className={styles.icon}>
              <AttachFileOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Website'} />
          </ListItem>
        </List>
      );
    }
    // }

    let userName = null;
    let activeOrg = null;

    if (user !== null) {
      let name = `${user.firstName} ${user.lastName}`;

      const organizationName = user.organization !== undefined ? user.organization?.name : 'No Organization Selected';

      userName = <ListItemText>{name}</ListItemText>;
      activeOrg = <ListItemText>{organizationName}</ListItemText>;
    }

    return (
      <div
        className={styles.userMenuContainer}
        tabIndex={0}
        onBlur={(event: any) => {
          this.setState({ userMenuExpanded: false });
        }}
      >
        <List className={styles.userMenuUserInfo}>
          <ListSubheader className={styles.subHeader}>User Info</ListSubheader>
          <ListItem button key={'UserMenuUserInfo'}>
            {userName}
          </ListItem>
          <ListItem>{activeOrg}</ListItem>
        </List>

        <Divider />

        {manageOrgs}

        {manageProducts}

        {manageUsers}

        {manageFees}

        {manageProfile}

        {website}

        <List className={styles.listItem}>
          <ListItem
            button
            className={styles.menuItem}
            key={'UserMenuLogout'}
            onClick={() => {
              this.props.logOut();
              this.props.setActiveView('/payments/create');
              this.setState({ userMenuExpanded: false });
              this.props.history.push('/');
            }}
          >
            <ListItemIcon className={styles.icon}>
              <LogoutOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Logout'} />
          </ListItem>
        </List>
      </div>
    );
  }

  private renderMainApp() {
    const { classes } = this.props;
    const container = window !== undefined ? () => window.document.body : undefined;
    const routes = buildRoutes(this.props.system.userLoggedIn, this.props.system.menuData.items);
    const logo = whiteLabelStrategy().logo;
    let errorModal = null;

    if (this.props?.system?.errors?.length !== 0) {
      errorModal = <ErrorModal key={'error-modal'} errors={this.props.system.errors} dismissOnClick={this.props.clearErrorsAction} />;
    }

    return (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <>
            <div ref={this.wrapperRef} className={styles.contentContainer}>
              <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                  <Toolbar>
                    <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={() => this.setState({ mobileOpen: !this.state.mobileOpen })} className={classes.menuButton}>
                      <MenuIcon />
                    </IconButton>
                    <Link to="/payments/create">
                      <img
                        className={styles.logo}
                        src={logo}
                        alt="Logo"
                        onClick={() => {
                          this.props.setActiveView('/payments/create');
                        }}
                      />
                    </Link>

                    <div className={styles.userMenu}>
                      <div className={styles.userContainerRight}>
                        <IconButton name="businessIcon" onClick={this.userMenuOrganizationHandler}>
                          <BusinessOutlinedIcon className={styles.userMenuIcon} style={{ fontSize: 30 }} />
                        </IconButton>
                        {this.state.userMenuExpanded ? this.buildUserMenu() : null}

                        <IconButton name="accoutIcon" onClick={this.userMenuHandler}>
                          <AccountCircleIcon className={styles.userMenuIcon} style={{ fontSize: 60 }} />
                        </IconButton>
                        {this.state.userMenuOrganizationExpanded ? this.buildOrgsMenu() : null}
                      </div>
                    </div>
                  </Toolbar>
                </AppBar>
                <nav className={classes.drawer} aria-label="mailbox folders">
                  {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                  <Hidden lgUp implementation="css">
                    <Drawer
                      container={container}
                      variant="temporary"
                      anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                      open={this.state.mobileOpen}
                      onClose={() => this.setState({ mobileOpen: !this.state.mobileOpen })}
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                      ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                      }}
                    >
                      <MyDrawer {...this.props} />
                    </Drawer>
                  </Hidden>
                  <Hidden mdDown implementation="css">
                    <Drawer
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                      variant="permanent"
                      open
                    >
                      <MyDrawer {...this.props} />
                    </Drawer>
                  </Hidden>
                </nav>
                <main className={classes.content}>
                  <div className={classes.toolbar} />
                  <Switch>{routes}</Switch>
                </main>
              </div>
            </div>
            {errorModal}
          </>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }

  render() {
    const login = this.renderLogin();
    const mainApp = this.renderMainApp();

    if (this.props.system.userLoggedIn) {
      return <>{mainApp}</>;
    } else {
      return <>{login}</>;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(navStyles, { withTheme: true })(App)));

const MyDrawer = (props: any) => {
  const { classes, system } = props;
  // const drawerMenuExpanded = true;
  const dispatch = useDispatch();
  const menuItems = system.menuData.items.filter((item: any) => item.navOnly === undefined);
  const logo = whiteLabelStrategy().logo;
  const [drawerMenuExpanded, setDrawerMenuExpanded] = useState(false);
  // const [expand, setExpand] = useState(false);

  const drawerMenuHandler = (itemName: any) => {
    if (itemName === 'reports') {
      setDrawerMenuExpanded(!drawerMenuExpanded);
    }
  };

  return (
    <div key={'drawerContainer'} className={classes.drawerContainer}>
      <img className={styles.logoDrawer} src={logo} alt="Logo" />
      {props.system.user.organization && (
        <div className={styles.activeOrganizationContainer}>
          {props.system.user.organization.name} <br />
          {props.system.user.firstName + ' ' + props.system.user.lastName}
        </div>
      )}

      <List className={styles.listMenuItems} key={'list'}>
        {menuItems.map((item: any) => {
          const active = system.activeView === item.path ? true : false;
          const colorIcon = active ? '#E32C2D' : 'white';
          return (
            <div key={item.name}>
              <ListItem
                button
                className={active ? styles.active : ''}
                key={`menu-entry-${item.name}`}
                onClick={() => {
                  dispatch(setActiveView(item.path));
                  drawerMenuHandler(item.name);
                  props.history.push(item.path as string);
                }}
              >
                {item.icon === 'CreditCardIcon' && (
                  <ListItemIcon>
                    <CreditCardIcon style={{ fill: colorIcon }} />
                  </ListItemIcon>
                )}
                {item.icon === 'TrendingUpIcon' && (
                  <ListItemIcon>
                    <TrendingUpIcon style={{ fill: colorIcon }} />
                  </ListItemIcon>
                )}
                {item.icon === 'HelpOutlineIcon' && (
                  <ListItemIcon>
                    <HelpOutlineIcon style={{ fill: colorIcon }} />
                  </ListItemIcon>
                )}
                {item.icon === 'CreateIcon' && (
                  <ListItemIcon>
                    <CreateIcon style={{ fill: colorIcon }} />
                  </ListItemIcon>
                )}
                <ListItemText primary={item.title} />
                {item.name === 'reports' && (drawerMenuExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
              </ListItem>
              <Collapse in={drawerMenuExpanded} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.items.map((subitem: any) => {
                    return (
                      <ListItem
                        button
                        key={`menu-entry-${subitem.name}`}
                        onClick={() => {
                          dispatch(setActiveView(subitem.path));
                          props.history.push(subitem.path as string);
                        }}
                      >
                        <ListItemText primary={subitem.title} />
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </div>
          );
        })}
      </List>
      <Divider />
    </div>
  );
};
