import { Button, Card, CardHeader, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import _ from 'lodash';
import * as queryString from 'query-string';
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IGeneratedMenuItem } from '../../models/IGeneratedMenuData';
// Begin Redux init
import { AppState } from '../../store';
import { addFee, clearState, getFeeById, updateFee } from '../../store/manage-fee/actions';
import { IManageFeeState as ReduxManageFeeState } from '../../store/manage-fee/types';
import { navigate } from '../../store/system/actions';
import { SystemState } from '../../store/system/types';
import { Fee } from '../../swagger-client';
import styles from './ManageFee.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  manageFee: state.manageFee,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
      getFeeById,
      addFee,
      updateFee,
      clearState,
    },
    dispatch
  );

interface IManageFeeProps extends RouteComponentProps {
  system: SystemState;
  manageFee: ReduxManageFeeState;
  navigate: <TProps extends RouteComponentProps<any>>(menuItem: IGeneratedMenuItem, ownProps: TProps) => ThunkAction<void, AppState, null, AnyAction>;
  getFeeById: (id: number) => ThunkAction<void, AppState, null, AnyAction>;
  addFee: (fee: Fee) => ThunkAction<void, AppState, null, AnyAction>;
  updateFee: (fee: Fee) => ThunkAction<void, AppState, null, AnyAction>;
  clearState: () => void;
}

interface IManageFeeState {
  settingState: boolean;
  constructing: boolean;
  errors: Array<string>;
  isNew: boolean;
  fee: Fee | any;
  referer: string;
  openSuccess: boolean | undefined;
  snackMessage: string;
}
// End Redux init

class ManageFee extends React.Component<IManageFeeProps, IManageFeeState> {
  private formRef = React.createRef<HTMLFormElement>();

  constructor(props: IManageFeeProps) {
    super(props);

    const urlParams: any = queryString.parse(this.props.location.search);

    this.state = {
      settingState: false,
      constructing: true,
      isNew: urlParams.new ? true : false,
      errors: [],
      openSuccess: false,
      snackMessage: '',
      fee: {
        source: 'Web',
        amountFixed: 0,
        amountPercent: 0,
        conditionMin: 0,
        conditionMax: 0,
      },
      referer: urlParams.referer,
    };

    if (!this.state.isNew) {
      this.props.getFeeById(urlParams.id);
    }

    this.onSaveClick = this.onSaveClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(newProps: IManageFeeProps, oldState: IManageFeeState) {
    if (oldState.constructing) {
      return {
        constructing: false,
      };
    }

    let newState: any = {};

    if (!_.isEqual(newProps?.manageFee?.fee, oldState.fee)) {
      if (oldState.settingState) {
        newState.fee = oldState.fee;
      } else {
        newState.fee = newProps.manageFee.fee;
      }
    }

    if (oldState.settingState) {
      newState.settingState = false;
    }

    return Object.keys(newState).length > 0 ? { ...oldState, ...newState } : null;
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  private handleChange(event: ChangeEvent<any>) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      fee: { ...this.state.fee, [event.target.name]: value },
      settingState: true,
    });
  }

  private onSaveClick() {
    if (this.state.fee !== null) {
      this.setState({ errors: [], settingState: true }, async () => {
        if (this.formRef.current?.checkValidity()) {
          if (this.state.isNew) {
            try {
              await this.props.addFee(this.state.fee);
              this.setState({ snackMessage: 'Fee created successfully' });
              this.setState({ openSuccess: true });
            } catch (excepcion) {
              this.setState({ snackMessage: '' });
              this.setState({ openSuccess: false });
            }
          } else {
            try {
              await this.props.updateFee(this.state.fee);
              this.setState({ snackMessage: 'Fee updated successfully' });
              this.setState({ openSuccess: true });
            } catch (excepcion) {
              this.setState({ snackMessage: '' });
              this.setState({ openSuccess: false });
            }
          }

          if (this.state.referer !== undefined && this.state.referer !== null) {
            this.props.history.push({ pathname: this.state.referer, state: { openSuccess: this.state.openSuccess, snackMessage: this.state.snackMessage } });
          }
        } else {
          const elementsWithInvalid = this?.formRef?.current?.querySelectorAll(':invalid');
          const errors: Array<string> = [];

          elementsWithInvalid?.forEach((element) => {
            errors.push((element as any).name as string); // Built in interface does not see name but it is present.;
          });

          this.setState({ errors });
        }
      });
    }
  }

  render() {
    const fee = this.state?.fee;

    return (
      <form ref={this.formRef} noValidate autoComplete="off">
        <Card className={styles.header}>
          <CardHeader title="Create Fee" />
          <div className={styles.saveButton}>
            <Button variant="contained" color="primary" onClick={this.onSaveClick}>
              Save
            </Button>
          </div>
        </Card>
        <div className={styles.container}>
          <div className={styles.columnLeft}>
            <div className={styles.row}>
              <Card className={styles.cardContainer}>
                <CardHeader title="Fee Information" className={styles.cardHeader} />
                <FormControl variant="outlined" className={styles.inputFieldSelect} required>
                  <InputLabel id="sourceLabel">Source</InputLabel>
                  <Select labelId="sourceLabel" id="source" label="Source" name="source" required value={fee?.source} onChange={this.handleChange} variant="outlined">
                    <MenuItem key={`source-counter`} value="counter">
                      Counter
                    </MenuItem>
                    <MenuItem key={`source-web`} value="website">
                      Web
                    </MenuItem>
                    <MenuItem key={`source-kiosk`} value="kiosk">
                      Kiosk
                    </MenuItem>
                    <MenuItem key={`source-phone`} value="phone">
                      Phone
                    </MenuItem>
                  </Select>
                </FormControl>

                <CurrencyTextField
                  className={styles.inputFieldSelect}
                  label="Condition Min"
                  name="conditionMin"
                  id="conditionMin"
                  variant="outlined"
                  value={fee?.conditionMin}
                  currencySymbol="$"
                  //minimumValue="0"
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  onChange={this.handleChange}
                  textAlign="left"
                />

                <CurrencyTextField
                  className={styles.inputFieldSelect}
                  label="Condition Max"
                  name="conditionMax"
                  id="conditionMax"
                  variant="outlined"
                  value={fee?.conditionMax}
                  currencySymbol="$"
                  //minimumValue="0"
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  onChange={this.handleChange}
                  textAlign="left"
                />

                <CurrencyTextField
                  className={styles.inputFieldSelect}
                  label="Amount Fixed"
                  name="amountFixed"
                  id="amountFixed"
                  variant="outlined"
                  value={fee?.amountFixed}
                  currencySymbol="$"
                  //minimumValue="0"
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  onChange={this.handleChange}
                  textAlign="left"
                />

                <CurrencyTextField
                  className={styles.inputFieldSelect}
                  label="Amount Percent"
                  name="amountPercent"
                  id="amountPercent"
                  variant="outlined"
                  value={fee?.amountPercent}
                  currencySymbol="%"
                  //minimumValue="0"
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  decimalPlaces={6}
                  onChange={this.handleChange}
                  textAlign="left"
                />
              </Card>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageFee));
