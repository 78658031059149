export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const mapperStatusToStatusDisplay = (status: String) => {
  switch (status) {
    case 'authorized':
      return 'Authorized';
    case 'held for review':
      return 'Held for Review';
    case 'captured':
      return 'Captured';

    default:
      break;
  }
};

export const mapperToUpperCase = (value: string) => {
  return value[0].toUpperCase() + value.slice(1).toLowerCase();
};
