import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../store';
import { navigate } from '../../../store/system/actions';
import WebsitePaymentLayout from '../WebsitePaymentLayout';
import styles from './WebistePaymentHelp.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const WebistePaymentHelp = (props: any) => {
  return (
    <WebsitePaymentLayout>
      <div className="w-100 py-5">
        <div className={styles.header}>
          <h1>Frequently Asked Questions</h1>
        </div>
        <div className={styles.container}>
          <p>
            <u>Why was my credit card declined?</u>
          </p>
          <p>Your credit card may be declined by our credit card processing system for a number of reasons. Only your credit card issuer or financial institution can confirm the specific reason for a declined credit card. Possible reasons include:</p>
          <ul>
            <li>Not having available credit on the card.</li>
            <li>The card has expired.</li>
            <li>The credit card information including the billing address was not entered accurately.</li>
            <li>The CVV number was not entered correctly (the 3 or 4 digit on the back of your card).</li>
            <li>As a security measure, some credit card companies reject transactions when a large number of online purchases are attempted within a short time.</li>
          </ul>

          <p>
            <u>How much do I pay?</u>
          </p>
          <p>We are a third party payment processor for the agency. For information on payment amount call the number on your ticket or citation.</p>
          <p>
            <u>Can I make a partial payment?</u>
          </p>
          <p>Partial payments and payment arrangements need to be dealt directly with the agency.</p>
          <p>Is my payment secure?</p>
        </div>
      </div>
    </WebsitePaymentLayout>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WebistePaymentHelp));
