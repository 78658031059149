import { Card, CardHeader, Snackbar, TextField } from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';
import React from 'react';
// Begin Redux init
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../store';
import { clearSuccessFail, updatePassword } from '../../store/current-user/actions';
import { UserState } from '../../store/current-user/types';
import { SystemState } from '../../store/system/types';
import BaseComponent from '../BaseComponent';
import UpdatePassword from './components/UpdatePassword';
import styles from './ManageCurrentUser.module.scss';

const mapStateToProps = (state: AppState) => ({
  user: state.currentUser,
  system: state.system,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, any>) =>
  bindActionCreators(
    {
      updatePassword,
      clearSuccessFail,
    },
    dispatch
  );

interface ManageCurrentUserProps {
  user: UserState;
  system: SystemState;
  updatePassword: (password: string) => void;
  clearSuccessFail: () => void;
}

interface ManageCurrentUserState {
  openSuccess: boolean | undefined;
  openError: boolean | undefined;
  snackMessage: string;
  severity: Color | undefined;
}

// End Redux init

class ManageCurrentUser extends BaseComponent<ManageCurrentUserProps, ManageCurrentUserState> {
  constructor(props: ManageCurrentUserProps) {
    super(props);

    this.state = {
      snackMessage: '',
      openSuccess: false,
      openError: false,
      severity: undefined,
    };
    this.handleClose = this.handleClose.bind(this);
    this.getMessage = this.getMessage.bind(this);
  }

  componentWillUnmount() {
    this.props.clearSuccessFail();
  }

  handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSuccess: false });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    const { passwordUpdatedSuccessfuly } = this.props.user;
    if (prevProps.user) {
      if (prevProps.user !== this.props.user) {
        this.getMessage(passwordUpdatedSuccessfuly);
      }
    }
  }

  getMessage = (passwordUpdatedSuccessfuly: any) => {
    if (passwordUpdatedSuccessfuly !== null && passwordUpdatedSuccessfuly === true) {
      this.setState({ openSuccess: true, snackMessage: 'Your password has been updated successfully!', severity: 'success' });
      return;
    } else if (passwordUpdatedSuccessfuly !== null) {
      this.setState({ openSuccess: true, snackMessage: 'There was an error  while updating your password! Please try again later and if this problem persists, please contact your administrator.', severity: 'error' });
      return;
    }
  };

  render() {
    const currentUser = this.props.system?.user;

    return (
      <React.Fragment>
        <div className={styles.container}>
          <div className={styles.columnLeft}>
            <div className={[styles.row, styles.totalRow].join(' ')}>
              <div>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.openSuccess} autoHideDuration={6000} onClose={this.handleClose}>
                  <Alert onClose={this.handleClose} severity={this.state.severity}>
                    {this.state.snackMessage}
                  </Alert>
                </Snackbar>
              </div>
              <Card className={styles.cardContainer}>
                <CardHeader title="My Account" className={styles.cardHeader} />
                <TextField variant="outlined" className={styles.inputFieldSelect} disabled id="firstName" label="First Name" name="firstName" value={currentUser?.firstName} onChange={this.handleInputChange} />
                <TextField variant="outlined" className={styles.inputFieldSelect} disabled id="lastName" label="LastName" name="lastName" value={currentUser?.lastName} onChange={this.handleInputChange} />
                <TextField variant="outlined" className={styles.inputFieldSelect} disabled id="email" label="Email" name="email" value={currentUser?.email} onChange={this.handleInputChange} />
                <TextField variant="outlined" className={styles.inputFieldSelect} disabled id="username" label="username" name="username" value={currentUser?.email} onChange={this.handleInputChange} />
                <UpdatePassword updatePassword={this.props.updatePassword} />
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCurrentUser);
