import { Button, Card, CardHeader, FormControlLabel, Switch, TextField } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import SimpleReactValidator from 'simple-react-validator';
import { AppState } from '../../../../store';
import { SystemState } from '../../../../store/system/types';
import { clearSuccessFail, createUser, getUser, updateUser } from '../../../../store/users/actions';
import { UserState } from '../../../../store/users/types';
import { User, UserRole } from '../../../../swagger-client';
import styles from './EditUser.module.scss';

const mapStateToProps = (state: AppState) => ({
  users: state.users,
  system: state.system,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, any>) =>
  bindActionCreators(
    {
      createUser,
      updateUser,
      getUser,
      clearSuccessFail,
    },
    dispatch
  );

interface IUserCreateProps extends RouteComponentProps {
  users: UserState;
  system: SystemState;
  getUser: (id: number) => void;
  createUser: (user: User) => void;
  updateUser: (user: User) => void;
  clearSuccessFail: () => void;
}

interface selectedRole {
  role: UserRole;
  checked: boolean;
}

interface IUserCreateState {
  errors: Array<string>;
  selectedUser: User | null;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordRepeat: string;
  active: boolean;
  organizationId: number | undefined;
  openSuccess: boolean | undefined;
  roles: Array<selectedRole>;
}

// End Redux init

class EditUser extends React.Component<IUserCreateProps, IUserCreateState> {
  protected validator: SimpleReactValidator;
  constructor(props: IUserCreateProps, validatorOptions: object | undefined = undefined) {
    super(props);

    this.state = {
      errors: [],
      selectedUser: null,
      email: '',
      password: '',
      passwordRepeat: '',
      active: true,
      firstName: '',
      lastName: '',
      openSuccess: false,
      organizationId: this.props.system.user?.organization?.id,
      roles: this.props.users.availableRoles.map((role) => {
        return {
          role,
          checked: false,
        };
      }),
    };

    // this.userSelected = this.userSelected.bind(this);
    this.validator = new SimpleReactValidator(validatorOptions);
    this.saveOrUpdate = this.saveOrUpdate.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillUnmount() {
    this.props.clearSuccessFail();
  }

  async componentWillMount() {
    const param = this.props.match.params as any;
    await this.props.getUser(param.userId);
    const user = this.props.users.user as any;
    const roles = this.props.users.availableRoles?.map((role: any) => {
      return {
        role,
        checked: true ? user.roles.find((obj: any) => obj.name === role.name) : false,
      };
    });
    this.setState({ email: user.email, firstName: user.firstName, lastName: user.lastName, active: user.active, selectedUser: this.props.users.user, roles: roles });
  }

  private async saveOrUpdate(event: any) {
    event.preventDefault();
    if (this.validator.allValid()) {
      this.props.clearSuccessFail();
      const roles = this.state.roles.filter((r) => r.checked).map((r) => r.role);
      const userToUpdate = this.state.selectedUser as any;
      userToUpdate.email = this.state.email;
      userToUpdate.firstName = this.state.firstName;
      userToUpdate.lastName = this.state.lastName;
      userToUpdate.active = this.state.active;
      userToUpdate.roles = roles;
      userToUpdate.organization = this.state.organizationId;
      try {
        await this.props.updateUser(userToUpdate);
        this.props.history.push({ pathname: '/organization_users', state: { openSuccess: true, snackMessage: 'User Update Successfully' } });
      } catch (error) {
        console.log('error', error);
      }
    }
  }

  protected handleRoleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      roles: this.state.roles.map((role) => {
        if (role.role.name === event.target.name) {
          role.checked = event.target.checked;
        }

        return role;
      }),
    });
  }

  private handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(({ [name]: value } as unknown) as any);
  }

  render() {
    const saveButton = (
      <Button variant="contained" color="primary" className={styles.continueButton} onClick={this.saveOrUpdate}>
        Save
      </Button>
    );

    const userRoles = this.state.roles?.map((role, index) => <Form.Check key={index} className={styles.inputField} type="checkbox" name={role.role.name} checked={role.checked ? role.checked : false} onChange={this.handleRoleChange} label={role.role.displayName} />);

    return (
      <>
        <Card className={styles.header}>
          <CardHeader title="Edit User" />
        </Card>
        <div className={styles.container}>
          <div className={styles.columnLeft}>
            <Card className={styles.cardContainer}>
              <CardHeader title="General Information" className={styles.cardHeader} />
              <TextField className={styles.inputFieldSelect} id="email" label="Email" name="email" error={this.state.errors.findIndex((fieldName) => fieldName === 'email') > -1} value={this.state.email} variant="outlined" required onChange={this.handleInputChange} />
              <TextField className={styles.inputFieldSelect} id="firstName" label="First Name" name="firstName" error={this.state.errors.findIndex((fieldName) => fieldName === 'firstName') > -1} value={this.state.firstName} variant="outlined" required onChange={this.handleInputChange} />
              <TextField className={styles.inputFieldSelect} id="lastName" label="LastName" name="lastName" error={this.state.errors.findIndex((fieldName) => fieldName === 'lastName') > -1} value={this.state.lastName} variant="outlined" required onChange={this.handleInputChange} />
              <FormControlLabel className={styles.inputField} control={<Switch checked={this.state.active} onChange={this.handleInputChange} name="active" color="primary" />} label="Active" />
            </Card>
          </div>
          <div className={styles.columnRight}>
            <Card className={styles.cardContainer}>
              <CardHeader title="Permissions" className={styles.cardHeader} />
              <Form.Label className={styles.inputField}>Roles</Form.Label>
              <div className={styles.rolesContainer}>{userRoles}</div>
              {saveButton}
            </Card>
          </div>
        </div>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditUser));
