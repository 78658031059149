import { Avatar, Button, Card, CardHeader, CircularProgress } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import _ from 'underscore';
import { AppState } from '../../store';
import { filterPayment } from '../../store/clerk-payment/actions';
import { navigate } from '../../store/system/actions';
import styles from './Dashboard.module.scss';
import PieChart, { Series, Label, Connector, Size, Export } from 'devextreme-react/pie-chart';
// import FolderIcon from '@material-ui/icons/Folder';
// import PageviewIcon from '@material-ui/icons/Pageview';
// import AssignmentIcon from '@material-ui/icons/Assignment';
import PersonIcon from '@material-ui/icons/Person';
import ComputerIcon from '@material-ui/icons/Computer';
import TabletIcon from '@material-ui/icons/Tablet';
import PhonetIcon from '@material-ui/icons/PhoneAndroid';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const Dashboard = (props: any) => {
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [clerckPercentage, setClerkPercentage] = useState(0);
  const [webPercentage, setWebPercentage] = useState(0);
  const [kioskPercentage, setKioskPercentage] = useState(0);
  const [phonePercentage, setPhonePercentage] = useState(0);
  const [paymentFiltred, setPaymentFiltred] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // let date = new Date();
    dateFrom.setDate(dateFrom.getDate() - 20);
    setDateFrom(dateFrom);
    const filter = { fromDate: dateFrom, toDate: dateTo };

    filterPayment(filter, props.system.user).then(async (payments) => {
      await setPaymentFiltred(payments);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const groups = _.groupBy(paymentFiltred, 'source');
    const counterLength = groups?.counter?.length;
    const websiteLength = groups?.website?.length;
    const kioskLength = groups?.kiosk?.length;
    const phoneLength = groups?.phone?.length;
    const paymentLength = paymentFiltred?.length;

    if (counterLength !== undefined) {
      setClerkPercentage((100 * counterLength) / paymentLength);
    } else {
      setClerkPercentage(0);
    }
    if (websiteLength !== undefined) {
      setWebPercentage((100 * websiteLength) / paymentLength);
    } else {
      setWebPercentage(0);
    }
    if (kioskLength !== undefined) {
      setKioskPercentage((100 * kioskLength) / paymentLength);
    } else {
      setKioskPercentage(0);
    }
    if (phoneLength !== undefined) {
      setPhonePercentage((100 * phoneLength) / paymentLength);
    } else {
      setPhonePercentage(0);
    }
  }, [paymentFiltred]);

  const onUpdateClick = async () => {
    const filter = { fromDate: dateFrom, toDate: dateTo };
    setLoading(true);
    await filterPayment(filter, props.system.user).then((payments) => {
      setPaymentFiltred(payments);
    });
    setLoading(false);
  };

  const test = paymentFiltred?.map((row: any) => {
    return {
      createdById: row.createdBy ? row.createdBy.firstName + ' ' + row.createdBy.lastName : 'System',
      productId: row.paymentItems[0]?.product?.name,
    };
  });
  const test2 = paymentFiltred?.map((row: any) => {
    return {
      amount: row.paymentItems.reduce((total: any, currentItem: { amount: any }) => (total = total + +currentItem.amount), 0),
    };
  });
  const t = test2.reduce((total: any, currentItem: { amount: any }) => (total = total + +currentItem.amount), 0);
  const groups2 = _.groupBy(test, 'createdById');
  let dataSource2 = Object.keys(groups2).map(function (key: any) {
    return { argument: key, value: groups2[key].length };
  });
  const groups3 = _.groupBy(test, 'productId');
  let dataSource3 = Object.keys(groups3).map(function (key: any) {
    return { argument: key, value: groups3[key].length };
  });

  return (
    <>
      <Card className={styles.header}>
        <CardHeader title="Dashboard" className={styles.header} />
      </Card>
      <Card className={styles.cardContainer}>
        <div className={styles.dateContainer}>
          <KeyboardDatePicker
            className={styles.dateInput}
            disableToolbar
            variant="dialog"
            inputVariant="outlined"
            format="MM/DD/YYYY"
            margin="normal"
            id="DateFrom"
            label={'Date from'}
            value={dateFrom}
            onChange={(date) => setDateFrom(date as any)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <KeyboardDatePicker
            className={styles.dateInput}
            disableToolbar
            variant="dialog"
            inputVariant="outlined"
            format="MM/DD/YYYY"
            margin="normal"
            id="dateTo"
            label={'Date To'}
            value={dateTo}
            onChange={(date) => setDateTo(date as any)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <Button variant="contained" color="primary" className={styles.dateInput} onClick={onUpdateClick}>
            Update
          </Button>
          {loading && <CircularProgress />}
        </div>
      </Card>
      <Card className={styles.cardContainer}>
        <CardHeader title="Payments" className={styles.cardHeader} />
        <div className={styles.avatarContainer}>
          <div className={styles.avatarText}>
            <span>{paymentFiltred?.length}</span>
            <span>Payments</span>
          </div>
          <div className={styles.avatarText}>
            <span>${t.toFixed(2)}</span>
            <span>Total</span>
          </div>
        </div>
      </Card>
      <div className={styles.container}>
        <div className={styles.columnLeft}>
          <CardHeader title="Payments by Employee" className={styles.cardHeader} />
          <PieChart id="pie" dataSource={dataSource2} palette="Bright">
            <Series argumentField="argument" valueField="value">
              <Label visible={true}>
                <Connector visible={true} width={1} />
              </Label>
            </Series>

            <Size width={500} />
            <Export enabled={true} />
          </PieChart>
        </div>
        <div className={styles.columnRight}>
          <Card className={[styles.cardContainer].join(' ')}>
            <CardHeader title="Payments by Product" className={styles.cardHeader} />
            <PieChart id="pie" dataSource={dataSource3} palette="Dark Moon">
              <Series argumentField="argument" valueField="value">
                <Label visible={true}>
                  <Connector visible={true} width={1} />
                </Label>
              </Series>

              <Size width={500} />
              <Export enabled={true} />
            </PieChart>
          </Card>
        </div>
      </div>
      <Card className={styles.cardContainer}>
        <CardHeader title="Payments by Source" className={styles.cardHeader} />
        <div className={styles.avatarContainer}>
          <div>
            <Avatar style={{ backgroundColor: 'green', width: '65px', height: '65px' }}>
              <PersonIcon style={{ width: '40px', height: '40px' }} />
            </Avatar>
            <div className={styles.avatarText}>
              <span className={styles.percentage}>{clerckPercentage.toFixed(1)} %</span>
              <span className={styles.percentageText}>Clerk</span>
            </div>
          </div>
          <div>
            <Avatar style={{ backgroundColor: 'green', width: '65px', height: '65px' }}>
              <ComputerIcon style={{ width: '40px', height: '40px' }} />
            </Avatar>
            <div className={styles.avatarText}>
              <span className={styles.percentage}>{webPercentage.toFixed(1)} %</span>
              <span className={styles.percentageText}>Web</span>
            </div>
          </div>
          <div>
            <Avatar style={{ backgroundColor: 'green', width: '65px', height: '65px' }}>
              <TabletIcon style={{ width: '40px', height: '40px' }} />
            </Avatar>
            <div className={styles.avatarText}>
              <span className={styles.percentage}>{kioskPercentage.toFixed(1)} %</span>
              <span className={styles.percentageText}>Kiosk</span>
            </div>
          </div>
          <div>
            <Avatar style={{ backgroundColor: 'green', width: '65px', height: '65px' }}>
              <PhonetIcon style={{ width: '40px', height: '40px' }} />
            </Avatar>
            <div className={styles.avatarText}>
              <span className={styles.percentage}>{phonePercentage.toFixed(1)} %</span>
              <span className={styles.percentageText}>Phone</span>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
