import * as types from './types';

const initialState: types.UserPreferenceState = {
  // userPreferences: [],
};

export function userPreferenceReducer(
  state = initialState,
  action: types.UserPreferenceActionTypes
): types.UserPreferenceState {
  switch (action.type) {
    // case types.GET_USER_PREFERENCE_SUCCESSFUL:
    //   return {
    //     ...state,
    //     userPreferences: action.payload,
    //   };
    // case types.CREATE_USER_PREFERENCE_SUCCESSFUL:
    //   return {
    //     ...state,
    //     userPreferences: [...state.userPreferences, action.payload],
    //   };
    // case types.UPDATE_USER_PREFERENCE_SUCCESSFUL:
    //   return {
    //     ...state,
    //     userPreferences: state.userPreferences.map((userPreference) => {
    //       if (userPreference.id === action.payload.id) {
    //         return action.payload;
    //       } else {
    //         return userPreference;
    //       }
    //     }),
    //   };
    default:
      return state;
  }
}
