import { UserRole } from "../swagger-client";
import { UserRoles } from "../models/UserRoles";
import IMenuData, { IMenuItem } from "../models/IMenuData";
import IGeneratedMenuData, { IGeneratedMenuItem } from "../models/IGeneratedMenuData";

export default class MenuService {
    public static generateMenuData(menuData: IMenuData, usersRoles: Array<UserRole>): IGeneratedMenuData {
        return { items: this.generateMenuItems(menuData.items, usersRoles) };

    }

    public static generateMenuItems(menuItems: Array<IMenuItem>, usersRoles: Array<UserRole>) : Array<IGeneratedMenuItem> {
        if (menuItems === null) {
            // Replace with our custom errors.
            throw new Error('Argument menuItems cannot be null!'); 
        }

        const newItems: Array<IGeneratedMenuItem> = [];

        menuItems.forEach((menuItem) => {
            if (this.checkRole(menuItem, usersRoles)) {
                const newItem: IGeneratedMenuItem =  {
                    name: menuItem.name,
                    title: menuItem.title,
                    icon: menuItem.icon,
                    path: menuItem.path,
                    component: menuItem.component,
                    navigationScope: menuItem.navigationScope,
                    parentNavigationScope: menuItem.parentNavigationScope,
                    items: menuItem.items ? menuItem.items.map(item => { 
                        const newSubItem : IGeneratedMenuItem = {
                        name: item.name,
                        title: item.title,
                        icon: item.icon,
                        path: item.path,
                        component: item.component,
                        navigationScope: item.navigationScope,
                        parentNavigationScope: item.parentNavigationScope,
                        items: [],
                        navOnly: item.navOnly 
                    }
                return newSubItem}): [],
                    navOnly: menuItem.navOnly
                };
    
                if (typeof menuItem.items !== 'undefined' && menuItem.items !== null) {
                    newItem.items = this.generateMenuItems(menuItem.items, usersRoles);
                }
    
                newItems.push(newItem);
            }
        });

        return newItems;
    }

    public static generateMenuItemsByName(menuData: IMenuData, usersRoles: Array<UserRole>): {[key: string]: IGeneratedMenuItem} {
        let result: {[key: string]: IGeneratedMenuItem} = {};
        
        menuData.items.forEach(item => result = {...result, ...MenuService.generateMenuItemsByNameInternal(item, usersRoles)});

        return result;
    }

    public static generateMenuItemsByPath(menuData: IMenuData, usersRoles: Array<UserRole>): {[key: string]: IGeneratedMenuItem} {
        let result: {[key: string]: IGeneratedMenuItem} = {};

        menuData.items.forEach(item => result = {...result, ...MenuService.generateMenuItemsByPathInternal(item, usersRoles)});

        return result;
    }

    private static generateMenuItemsByPathInternal(menuItem: IMenuItem, usersRoles: Array<UserRole>): {[key: string]: IGeneratedMenuItem} {
        let result: {[key: string]: IGeneratedMenuItem} = {};

        if (MenuService.checkRole(menuItem, usersRoles) && 
            menuItem?.path !== undefined && 
            menuItem?.path !== null) {

            result[menuItem.path] = menuItem as IGeneratedMenuItem;
        }

        if (menuItem.items) {
            menuItem.items.forEach(item => {
                const newItems = MenuService.generateMenuItemsByPathInternal(item, usersRoles);
                result = {...result, ...newItems};
            });
        }
        
        return result;
    }

    private static generateMenuItemsByNameInternal(menuItem: IMenuItem, usersRoles: Array<UserRole>): {[key: string]: IGeneratedMenuItem} {
        let result: {[key: string]: IGeneratedMenuItem} = {};

        if (MenuService.checkRole(menuItem, usersRoles)) {
            result[menuItem.name] = menuItem as IGeneratedMenuItem;
        }

        if (menuItem.items) {
            menuItem.items.forEach(item => {
                const newItems = MenuService.generateMenuItemsByNameInternal(item, usersRoles);
                result = {...result, ...newItems};
            });
        }
        
        return result;
    }

    public static generateMenuItemsByNavigationScope(menuData: IMenuData, usersRoles: Array<UserRole>): {[key: string]: IGeneratedMenuItem} {
        let result: {[key: string]: IGeneratedMenuItem} = {};

        menuData.items.forEach(item => result = {...result, ...MenuService.generateMenuItemsByNavigationScopeInternal(item, usersRoles)});

        return result;
    }

    private static generateMenuItemsByNavigationScopeInternal(menuItem: IMenuItem, usersRoles: Array<UserRole>): {[key: string]: IGeneratedMenuItem} {
        let result: {[key: string]: IGeneratedMenuItem} = {};

        if (MenuService.checkRole(menuItem, usersRoles) && menuItem.navigationScope !== undefined) {
            result[menuItem.navigationScope] = menuItem as IGeneratedMenuItem;
        }

        if (menuItem.items) {
            menuItem.items.forEach(item => {
                const newItems = MenuService.generateMenuItemsByNavigationScopeInternal(item, usersRoles);
                result = {...result, ...newItems};
            });
        }
        
        return result;
    }

    private static checkRole(item: IMenuItem, usersRoles: Array<UserRole>): boolean {

        return item.roles === undefined || 
        item.roles === null ||
        usersRoles.findIndex(userRole => userRole.name === UserRoles.SuperAdmin) > -1 ||
        usersRoles.some((r) => item.roles !== null && 
        (typeof item.roles !== 'undefined' && item.roles.indexOf(r.name as UserRoles) > -1));
    }
}