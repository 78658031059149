import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './CustomerInfo.module.scss';
import { AppState } from '../../../../../store';
import { navigate } from '../../../../../store/system/actions';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { Card, CardHeader } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const CustomerInfo = (props: any) => {
  return (
    <Card className={styles.cardContainer}>
      <CardHeader className={styles.cardHeader} title="Customer Information" />
      <Divider />
      <List className={styles.root}>
        <ListItem>
          <ListItemText primary="First Name" />
          <span>{props.customerInfo.firstName}</span>
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText primary="Last Name" />
          <span>{props.customerInfo.lastName}</span>
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText primary="Address" />
          <span>{props.customerInfo.address}</span>
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText primary="City" />
          <span>{props.customerInfo.city}</span>
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText primary="State" />
          <span>{props.customerInfo.state}</span>
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText primary="Zip" />
          <span>{props.customerInfo.zip}</span>
        </ListItem>
        <ListItem>
          <ListItemText primary="Country" />
          <span>{props.customerInfo.country}</span>
        </ListItem>
      </List>
    </Card>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerInfo));
