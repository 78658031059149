import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router';
import ClerkPaymentAuthorize from '../components/clerk-payment-authorize/ClerkPaymentAuthorize';
import ClerkPaymentReceipt from '../components/clerk-payment-receipt/ClerkPaymentReceipt';
import EditPayment from '../components/edit-payment/EditPayment';
import ManageCurrentUser from '../components/manage-current-user/ManageCurrentUser';
import UserCreate from '../components/user-management/components/create-user/UserCreate';
import EditUser from '../components/user-management/components/edit-user/EditUser';
import { IGeneratedMenuItem } from '../models/IGeneratedMenuData';
import { logOut } from '../store/system/actions';

export const buildRoutes = (userLoggedIn: any, items: IGeneratedMenuItem[]) => {
  const routes: Array<JSX.Element> = [<Route key={'manage-user-route'} exact path="/manage-user" component={ManageCurrentUser}></Route>];
  routes.push(<Route key={'payment-confirm'} exact path={'/payments/confirm'} component={ClerkPaymentAuthorize}></Route>);
  routes.push(<Route key={'payment-id-receipt'} exact path={'/payments/:paymentId/receipt'} component={ClerkPaymentReceipt}></Route>);
  routes.push(<Route key={'payment-id-edit'} exact path={'/payments/:paymentId/edit'} component={EditPayment}></Route>);
  routes.push(<Route key={'user-id-edit'} exact path={'/organization_users/:userId/edit'} component={EditUser}></Route>);
  routes.push(<Route key={'organization-users-create  '} exact path={'/organization_users/create'} component={UserCreate}></Route>);
  routes.push(
    <Route key={'web'} path={'/web/'}>
      <Web userLoggedIn={userLoggedIn} />
    </Route>
  );

  routes.push(
    <Route key="base" exact path="/">
      {userLoggedIn ? <Redirect key="create" to="/payments/create" /> : <Redirect key="base" to="/" />}
    </Route>
  );

  if (userLoggedIn) {
    items.forEach((item, i) => {
      buildRouteItems(routes, item);
    });
  }

  return <React.Fragment key="routes">{routes}</React.Fragment>;
};

const buildRouteItems = (routes: Array<JSX.Element>, currentMenuItem: IGeneratedMenuItem) => {
  if (currentMenuItem.path !== null) {
    routes.push(<Route key={`${currentMenuItem.name}`} exact path={currentMenuItem.path} component={currentMenuItem.component}></Route>);
  } else {
    routes.push(<Route key={`${currentMenuItem.name}`} exact component={currentMenuItem.component}></Route>);
  }

  if (currentMenuItem.items !== undefined && currentMenuItem.items !== null && currentMenuItem.items.hasOwnProperty('length') && currentMenuItem.items.length > 0) {
    currentMenuItem.items.forEach((childItem) => buildRouteItems(routes, childItem));
  }
};

const Web = (props: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.userLoggedIn) {
      dispatch(logOut());
    }
  }, []);

  return <Redirect to="/" />;
};
