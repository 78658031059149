import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import errorMessages from '../../error_messages.json';
import AxiosConfigurator from '../../services/AxiosConfigurator';
import ManageErrorType from '../../services/errorHelper';
import * as swagger from '../../swagger-client/index';
import { ApprovePayment, CancelPayment, CapturepaymentModel, CitationCaseDetailRequest, CounterPaymentModel, EditCounterPayment, EditWebsitePayment, FilterPaymentModel, MakePayment, PagedProductResults, ReferenceNumberModel, TransactionDetailResponse } from '../../swagger-client/index';
import { AppState } from '../index';
import { toggleRouteBack } from '../system/actions';
import * as typesUser from '../system/types';
import { ERROR_ACTION } from '../system/types';
import * as types from './types';

export const getProducts = (): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();
    if (state.system !== null && state.system.user !== null) {
      const productApi = new swagger.ProductApi(AxiosConfigurator.getConfig(state.system.user.token));
      let results = null;
      let token = state.system.user.token as any;

      let query: any = {
        source: 'counter',
      };

      try {
        const result = await productApi.get(token, { query });

        if ((result.data as PagedProductResults).count > 0) {
          results = (result.data as PagedProductResults).values;
        } else {
          dispatch({
            type: ERROR_ACTION,
            payload: errorMessages.clerkPaymentGetProducts,
          });
        }

        dispatch({
          type: types.GET_PRODUCTS_SUCCESSFUL,
          payload: results,
        });
      } catch (err) {
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.clerkPaymentGetProducts,
        });
      }
    }
  };
};

export const getProductsByUrl = (urlIdentifier: string): Promise<any> => {
  return new Promise(async (res, rej) => {
    const productApi = new swagger.ProductApi(AxiosConfigurator.getConfig());
    let result = null;
    try {
      result = await productApi.getProductByUrl(urlIdentifier, 'website');
      res(result);
    } catch (err) {
      rej(err);
    }
  });
};

export const addPayment = (payment: MakePayment): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState: any) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null) {
      const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig(state.system.user.token));
      let pay = null;

      try {
        pay = await paymentApi.validateCounterPayment(state.system.user.token, payment);
        dispatch({
          type: types.ADD_PAYMENT,
          payload: pay,
        });
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.addPaymentCourt + '--' + error,
        });
      }
    }
  };
};

export const validatePaymentItem = (payment: ReferenceNumberModel) => {
  const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig());
  return new Promise<any>(async (resolve, reject) => {
    await paymentApi
      .validatePaymentItem(payment)
      .then((paymentReference) => {
        resolve(paymentReference);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addPaymentWeb = async (payment: any) => {
  return new Promise<any>(async (resolve, reject) => {
    const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig());
    try {
      const pay = await paymentApi.authorizeWebsite(payment);
      resolve(pay.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const confirmPayment = (confirmpayment: CounterPaymentModel): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState: any, resolve: any) => {
    const state = getState();
    let confirm = {} as any;
    if (state.system !== null && state.system.user !== null) {
      const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        confirm = await paymentApi.authorizeCounter(state.system.user.token, confirmpayment);
        window.location.href = `#/payments/${confirm.data.id}/receipt`;
        dispatch({
          type: types.CONFIRM_PAYMENT,
          payload: confirm,
        });
      } catch (err) {
        const error = ManageErrorType(err as any);
        if (error) {
          dispatch({
            type: ERROR_ACTION,
            payload: errorMessages.confirmPaymentWebsite + ' -- ' + error,
          });
        }
      }
    }
  };
};

export const detailPayment = (detailPayment: CitationCaseDetailRequest): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState: any, resolve: any) => {
    let detail = {};
    const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig());
    try {
      detail = await paymentApi.detail(detailPayment);
      dispatch({
        type: types.DETAIL_PAYMENT,
        payload: detail,
      });
    } catch (err) {
      dispatch({
        type: types.DETAIL_PAYMENT,
        payload: {},
      });
      // const error = ManageErrorType(err as any);
      // dispatch({
      //   type: ERROR_ACTION,
      //   payload: errorMessages.getDetailPayment + '--' + error,
      // });
      throw err;
    }
  };
};

export const detailPaymentPromise = (detailPayment: CitationCaseDetailRequest): Promise<Array<TransactionDetailResponse>> => {
  return new Promise(async (resolve, reject) => {
    let detail: any = {};
    const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig());
    try {
      detail = await paymentApi.detail(detailPayment);
      resolve(detail.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const capturePayment = (paymentId: CapturepaymentModel): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState: any, resolve: any) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null) {
      const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        await paymentApi.capturePayment(state.system.user.token, paymentId);
        dispatch(toggleRouteBack());
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.capturePayment + '--' + error,
        });
        throw err;
      }
    }
  };
};

export const filterPayment = (filterPayment: any, user: any): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    // const state = getState();
    const dateTo = filterPayment.toDate ? filterPayment.toDate : undefined;
    const dateFrom = filterPayment.fromDate ? filterPayment.fromDate : undefined;
    const referenceNumber = filterPayment.referenceNumber ?? undefined;
    if (user !== null) {
      const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig(user.token));
      try {
        let filtered: any = await paymentApi.filterPayment(user.token, undefined, filterPayment.status1, filterPayment.status2, filterPayment.skip, filterPayment.limit, dateFrom, dateTo, referenceNumber);
        if (filtered.data.length > 0) {
          resolve(filtered.data);
        } else {
          resolve([]);
        }
      } catch (err) {
        const error = ManageErrorType(err as any);
        reject(error);
      }
    }
  });
};

export const filterPaymentById = (filterPayment: FilterPaymentModel, id: any): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState: any, resolve: any) => {
    const state = getState();
    let filtered = {};
    if (state.system !== null && state.system.user !== null) {
      const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig(state.system.user.token));
      try {
        filtered = await paymentApi.filterPayment(state.system.user.token, id, filterPayment.status1);
        dispatch({
          type: types.FILTER_PAYMENT_BY_ID,
          payload: filtered,
        });
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.filterPaymentById + '--' + error,
        });
      }
    }
  };
};

export const voidPayment = (paymentId: CancelPayment): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState: any, resolve: any) => {
    const state = getState();
    if (state.system !== null && state.system.user !== null) {
      const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        await paymentApi.cancel(state.system.user.token, paymentId);
        dispatch(toggleRouteBack());
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.voidPayment + '--' + error,
        });
        throw err;
      }
    }
  };
};

export const editWebsitePayment = (paymentId: EditWebsitePayment, user: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    if (user !== null) {
      const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig(user.token));

      try {
        const payment = await paymentApi.editWebsite(user.token, paymentId);
        resolve(payment);
        // dispatch(toggleRouteBack());
      } catch (err) {
        const error = ManageErrorType(err as any);
        reject(error);
        throw err;
      }
    }
  });
};

export const editCourtPayment = (paymentId: EditCounterPayment): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState: any, resolve: any) => {
    const state = getState();
    if (state.system !== null && state.system.user !== null) {
      const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        await paymentApi.editCounter(state.system.user.token, paymentId);
        dispatch(toggleRouteBack());
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.editCourtPayment + '--' + error,
        });
        throw err;
      }
    }
  };
};

export const declinePayment = (paymentId: ApprovePayment): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState: any, resolve: any) => {
    const state = getState();
    if (state.system !== null && state.system.user !== null) {
      const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        await paymentApi.declinePayment(state.system.user.token, paymentId);
        dispatch(toggleRouteBack());
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.editCourtPayment + '--' + error,
        });
      }
    }
  };
};

export const approvePayment = (paymentId: ApprovePayment): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState: any, resolve: any) => {
    const state = getState();
    if (state.system !== null && state.system.user !== null) {
      const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        await paymentApi.approvePayment(state.system.user.token, paymentId);
        dispatch(toggleRouteBack());
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.editCourtPayment + '--' + error,
        });
      }
    }
  };
};

export const getBatches = (from: string, to: string, productId: number): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState: any, resolve: any) => {
    const state = getState();
    if (state.system !== null && state.system.user !== null) {
      const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        const batches = await paymentApi.getBatch(state.system.user.token, from, to, productId);

        let sortData = await batches.data.slice().sort((a, b) => Date.parse(b.settlementTimeLocal) - Date.parse(a.settlementTimeLocal));
        dispatch({
          type: types.GET_BATCHES,
          payload: sortData,
        });
        // dispatch(toggleRouteBack());
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.editCourtPayment + '--' + error,
        });
      }
    }
  };
};

export const getTransactionDetails = (batchId: number, productId: number, user: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const paymentApi = new swagger.PaymentApi(AxiosConfigurator.getConfig(user.token));
    try {
      const transactionDetails = await paymentApi.transactionsDetail(user.token, batchId, productId);
      resolve(transactionDetails.data);
    } catch (err) {
      console.log(err);
    }
  });
};

export const clearState = (): types.IClearStateAction => {
  return {
    type: types.CLEAR_STATE,
  };
};

export const getOrganizationBySession = (): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState: any, resolve: any) => {
    const state = getState();
    if (state.system !== null && state.system.user !== null) {
      const organizationApi = new swagger.OrganizationApi(AxiosConfigurator.getConfig(state.system.user.token));

      try {
        const response = await organizationApi.getOrganizationBySession(state.system.user.token);
        let organization = response.data;
        dispatch({
          type: typesUser.UPDATE_ORGANIZATION,
          payload: organization,
        });
        dispatch(toggleRouteBack());
      } catch (err) {
        const error = ManageErrorType(err as any);
        console.log(error);
      }
    }
  };
};
