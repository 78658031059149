import { Button, Card, CardHeader, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Snackbar, Switch, TextField } from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../store';
import { addPayment, clearState, detailPayment, getOrganizationBySession, getProducts } from '../../store/clerk-payment/actions';
import { IClerkPaymentState as IClerkPaymentStateRedux } from '../../store/clerk-payment/types';
import { calculateFeeProccesing } from '../../store/manage-fee/actions';
import { IManageFeeState } from '../../store/manage-fee/types';
import { SystemState } from '../../store/system/types';
import { getAvailableRoles } from '../../store/users/actions';
import { CitationCaseDetailRequest, FeeByAmountRequest, MakePayment, MakePaymentItem } from '../../swagger-client/index';
import CustomSeparator from '../bread-crumbs/BreadCrumbs';
import styles from './ClerkPaymentCourt.module.scss';
import CreditCards from './components/CreditCards/CreditCards';
import TotalCard from './components/TotalCard/TotalCard';

const CreditCardInput = require('react-credit-card-input'); // Require because we have no type declaration.

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  clerkPayment: state.clerkPayment,
  fee: state.manageFee,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getProducts,
      addPayment,
      clearState,
      detailPayment,
      calculateFeeProccesing,
      getOrganizationBySession,
    },
    dispatch
  );

interface IClerkPaymentCourtProps extends RouteComponentProps {
  system: SystemState;
  clerkPayment: IClerkPaymentStateRedux;
  fee: IManageFeeState;

  getProducts: () => ThunkAction<void, AppState, null, AnyAction>;
  clearState: () => void;
  addPayment: (payment: MakePayment) => ThunkAction<void, AppState, null, AnyAction>;
  detailPayment: (detail_payment: CitationCaseDetailRequest) => ThunkAction<void, AppState, null, AnyAction>;
  calculateFeeProccesing: (fee: FeeByAmountRequest) => ThunkAction<void, AppState, null, AnyAction>;
  getOrganizationBySession: () => ThunkAction<void, AppState, null, AnyAction>;
}

interface IClerkPaymenCourtState {
  errors: Array<string>;
  swipedTransaction: boolean;
  ccNumber: string;
  ccExpiry: string;
  ccCvv: string;
  paymentItems: Array<MakePaymentItem> | any;
  settingState: boolean;
  subTotal: number;
  feeProcessing: number;
  total: number;
  openSuccess: boolean | undefined;
  organizationId: number | any;
  isLoading: number | any;
  isLoadingAddPayment: boolean;
  jisActive?: boolean;
  partialPayment?: boolean;
  snackMessage: string;
  openDialog: boolean;
  ccData?: string;
  severity: Color | undefined;
  searchInJIS?: string;
  changeInAmount: boolean;
}
// End Redux init

class ClerkPaymentCourt extends React.Component<IClerkPaymentCourtProps, IClerkPaymenCourtState> {
  private formRef = React.createRef<HTMLFormElement>();

  constructor(props: IClerkPaymentCourtProps) {
    super(props);

    this.state = {
      errors: [],
      ccData: '',
      settingState: false,
      swipedTransaction: this.props.system.user?.organization?.cardPresentSwipedEnabled as boolean,
      ccNumber: '',
      ccExpiry: '',
      ccCvv: '',
      organizationId: this.props.system.user?.organization?.id,
      subTotal: 0,
      feeProcessing: 0,
      total: 0,
      openSuccess: false,
      severity: undefined,
      isLoading: null,
      isLoadingAddPayment: false,
      openDialog: false,
      snackMessage: '',
      jisActive: this.props.system.user?.organization?.useJis,
      partialPayment: this.props.system.user?.organization?.acceptPartialPayment,
      paymentItems: [
        {
          liscenseNumber: '',
          productId: '',
          referenceNumber: '',
          firstName: '',
          lastName: '',
          amount: 0,
          organizationId: this.props.system.user?.organization?.id,
          productName: '',
          inJIS: true,
        },
      ],
      searchInJIS: '',
      changeInAmount: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onContinueClick = this.onContinueClick.bind(this);
    this.onAddPaymentClick = this.onAddPaymentClick.bind(this);
    this.onHandleBlurAmount = this.onHandleBlurAmount.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.cc_brand_id = this.cc_brand_id.bind(this);
    this.acceptCreditCardOrg = this.acceptCreditCardOrg.bind(this);
    this.onConfirmClick = this.onConfirmClick.bind(this);

    this.props.getProducts();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.system.user.organization) {
      if (prevProps.system.user.organization.id !== prevState.organizationId) {
        this.setState({ organizationId: prevProps.system.user.organization.id });
        this.props.getProducts();
        this.setState({ swipedTransaction: this.props.system.user?.organization?.cardPresentSwipedEnabled as boolean });
      }
    }

    if (prevProps.system.user.organization.useJis !== prevState.jisActive) {
      this.setState({ jisActive: prevProps.system.user.organization.useJis });
    }
  }

  componentDidMount() {
    this.props.getOrganizationBySession();
    getAvailableRoles();
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  private handleChange(event: ChangeEvent<any>, index: any) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (index !== null) {
      let stateCopy = Object.assign({}, this.state);
      stateCopy.paymentItems[index] = { ...this.state.paymentItems[index], [event.target.name]: value };
      this.setState(stateCopy);
    }
    if (event.target.name === 'amount') {
      const total = this.state.paymentItems.reduce((total: any, currentItem: { amount: any }) => (total = total + +currentItem.amount), 0);
      this.setState({ subTotal: total });
      this.setState({ changeInAmount: true });
    }
    if (event.target.name === 'swipedTransaction') {
      this.setState({ swipedTransaction: value });
    }
    if (event.target.name === 'ccNumber') {
      this.setState({ ccNumber: event.target.value });
    }
    if (event.target.name === 'ccExpiry') {
      this.setState({ ccExpiry: event.target.value });
    }
    if (event.target.name === 'ccCvv') {
      this.setState({ ccCvv: event.target.value });
    }
    if (event.target.name === 'ccData') {
      this.setState({ ccData: event.target.value });
    }
    if (event.target.name === 'productId') {
      const productFiltred = this.props.clerkPayment.products.filter((item: any) => item.id === this.state.paymentItems[index].productId);
      let stateCopy = Object.assign({}, this.state);
      stateCopy.paymentItems[index].productName = productFiltred[0].name;
      stateCopy.paymentItems[index] = { ...this.state.paymentItems[index], [event.target.name]: value };
      this.setState(stateCopy);
    }
    const newError = this.state.errors.filter((item: any) => item !== event.target.name);
    this.setState({ errors: newError });
  }

  renderSwipedTransction() {
    return (
      <TextField
        className={styles.inputFieldSelect}
        id="ccData"
        label="Click here and swipe a credit card"
        name="ccData"
        error={this.state.errors.findIndex((fieldName) => fieldName === 'ccData') > -1}
        required={this.state.swipedTransaction}
        onChange={(e) => this.handleChange(e, null)}
        variant="outlined"
        value={this.state.ccData}
      />
    );
  }

  renderNonSwipedTransaction(org: any) {
    const accepted = [] as any;

    if (org?.ccAcceptVisa) {
      accepted.push(org?.ccAcceptVisa);
    }
    if (org?.ccAcceptAmericanExpress) {
      accepted.push(org?.ccAcceptAmericanExpress);
    }
    if (org?.ccAcceptMasterCard) {
      accepted.push(org?.ccAcceptMasterCard);
    }
    if (org?.ccAcceptDiscover) {
      accepted.push(org?.ccAcceptDiscover);
    }

    return (
      <div className={styles.containerCreditCardInput}>
        {accepted.length > 0 && (
          <CreditCardInput
            cardNumberInputProps={{ value: this.state.ccNumber, name: 'ccNumber', onChange: (e: React.ChangeEvent<any>) => this.handleChange(e, null) }}
            cardExpiryInputProps={{ value: this.state.ccExpiry, name: 'ccExpiry', onChange: (e: React.ChangeEvent<any>) => this.handleChange(e, null) }}
            cardCVCInputProps={{ value: this.state.ccCvv, name: 'ccCvv', onChange: (e: React.ChangeEvent<any>) => this.handleChange(e, null) }}
            fieldClassName="inputCreditCard"
            className={styles.creditCardInput}
          />
        )}
      </div>
    );
  }

  onContinueClick() {
    try {
      if (this.state.paymentItems !== null) {
        if (this.formRef.current?.checkValidity() && this.state.subTotal <= 10000) {
          if (this.state.ccNumber === '' || this.state.ccCvv === '' || this.state.ccExpiry === '') {
            this.setState({ openSuccess: true, snackMessage: 'Required fields are not set. Please check the credit cards fields', severity: 'error' });
            this.setState({ isLoadingAddPayment: false });
          } else {
            const t = this.cc_brand_id(this.state.ccNumber);
            const accepted = this.acceptCreditCardOrg(t);
            if (accepted) {
              this.setState({ errors: [] }, async () => {
                const payments = {
                  orgId: this.state.organizationId,
                  strategy: 'court',
                  source: 'counter',
                  paymentItems: this.state.paymentItems,
                };
                this.setState({ isLoadingAddPayment: true });
                await this.props.addPayment(payments);
                this.setState({ isLoadingAddPayment: false });
                const pay = this.props.clerkPayment.payment;
                if (this.state.paymentItems[0].inJIS) {
                  this.props.history.push({
                    pathname: 'confirm',
                    state: {
                      propOne: this.state,
                      propTwo: pay,
                    },
                  });
                } else {
                  this.props.history.push({
                    pathname: 'confirm',
                    state: {
                      propOne: this.state,
                      propTwo: pay,
                      propTree: this.state.jisActive ? { openSuccess: true, snackMessage: 'This payment is not in JIS, check manage payments' } : null,
                    },
                  });
                }
              });
            } else {
              this.setState({ openSuccess: true, snackMessage: 'This credit card is not accepted in this organization', severity: 'error' });
            }
          }
        } else {
          const elementsWithInvalid = this?.formRef?.current?.querySelectorAll(':invalid');
          const errors: Array<string> = [];

          elementsWithInvalid?.forEach((element) => {
            errors.push((element as any).name as string);
          });

          this.setState({ errors });
          this.setState({ isLoading: null });
          this.setState({ isLoadingAddPayment: false });
        }
      }
    } catch (error) {
      this.setState({ isLoading: null });
    }
  }

  onAddPaymentClick() {
    let addFormstateCopy = Object.assign({}, this.state);
    let len = this.state.paymentItems.length;
    addFormstateCopy.paymentItems[len] = {
      liscenseNumber: '',
      productId: '',
      referenceNumber: '',
      firstName: '',
      lastName: '',
      amount: 0,
      organizationId: this.props.system.user?.organization?.id,
    };
    this.setState(addFormstateCopy);
  }

  acceptCreditCardOrg(type: any) {
    let accepts = false;
    if (type === 'Visa' && this.props.system.user?.organization?.ccAcceptVisa) {
      accepts = true;
    }
    if (type === 'AmericanExpress' && this.props.system.user?.organization?.ccAcceptAmericanExpress) {
      accepts = true;
    }
    if (type === 'Mastercard' && this.props.system.user?.organization?.ccAcceptMasterCard) {
      accepts = true;
    }
    if (type === 'Discover' && this.props.system.user?.organization?.ccAcceptDiscover) {
      accepts = true;
    }
    return accepts;
  }

  cc_brand_id(cur_val: any) {
    //JCB
    const jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$');
    // American Express
    const amex_regex = new RegExp('^3[47][0-9]{0,}$');
    // Diners Club
    const diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$');
    // Visa
    const visa_regex = new RegExp('^4[0-9]{0,}$'); //4
    // MasterCard
    const mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$');
    const maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$');
    const discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');
    cur_val = cur_val.replace(/\D/g, '');

    var sel_brand = 'unknown';
    if (cur_val.match(jcb_regex)) {
      sel_brand = 'Jcb';
    } else if (cur_val.match(amex_regex)) {
      sel_brand = 'AmericanExpress';
    } else if (cur_val.match(diners_regex)) {
      sel_brand = 'Diners_club';
    } else if (cur_val.match(visa_regex)) {
      sel_brand = 'Visa';
    } else if (cur_val.match(mastercard_regex)) {
      sel_brand = 'Mastercard';
    } else if (cur_val.match(discover_regex)) {
      sel_brand = 'Discover';
    } else if (cur_val.match(maestro_regex)) {
      if (cur_val[0] == '5') {
        sel_brand = 'Mastercard';
      } else {
        sel_brand = 'Maestro';
      }
    }

    return sel_brand;
  }

  onDeleteClick(event: ChangeEvent<any>, index: any) {
    this.setState({ ...this.state, paymentItems: this.state.paymentItems.filter((_: any, i: any) => i !== index), subTotal: this.state.subTotal - this.state.paymentItems[index].amount });
  }

  async onUpdateJisClick(event: ChangeEvent<any>, index: any) {
    this.setState({ searchInJIS: this.state.paymentItems[index].referenceNumber });
    this.setState({ changeInAmount: false });
    if (this.state.paymentItems[index].referenceNumber !== null) {
      const detailPayment = {
        caseNumber: this.state.paymentItems[index].referenceNumber,
        court: this.props.system.user?.organization?.jisInformation ? Number(this.props.system.user?.organization?.jisInformation[0]?.secondCourtId) : 0,
        caseStatus: 'Open',
      };
      try {
        this.setState({ isLoading: index });
        await this.props.detailPayment(detailPayment);
        this.setState({ isLoading: false });
      } catch (execption) {
        this.setState({ openSuccess: true, snackMessage: 'Reference number not in JIS', severity: 'error' });
        this.setState({ isLoading: null });
      }

      let detail = this.props.clerkPayment.detailPayment as any;
      let amountDue = detail?.data ? detail?.data[0]?.amountDue : 0;
      let stateCopy = Object.assign({}, this.state);
      stateCopy.paymentItems[index].amount = amountDue;
      const total = stateCopy.paymentItems.reduce((total: any, currentItem: { amount: any }) => (total = total + +currentItem.amount), 0);
      this.setState(stateCopy);
      this.setState({ isLoading: null, subTotal: total });
      const calculateAmount = {
        paymentItems: this.state.paymentItems,
        organizationId: this.state.organizationId,
        paymentSource: 'counter',
      };
      await this.props.calculateFeeProccesing(calculateAmount);
      const feeProcessingFetch = this.props.fee.feeProcessing as any;
      this.setState({ feeProcessing: feeProcessingFetch.data.feeProcessing });
      if (this.state.paymentItems[index].amount !== 0) {
        this.setState({ openSuccess: true, snackMessage: 'Amount Retrieved Successfully', severity: 'success' });
      }
    }
  }

  async onHandleBlurAmount(event: ChangeEvent<any>, index: any) {
    if (this.state.subTotal > 0) {
      const calculateAmount = {
        subTotal: this.state.subTotal,
        paymentItems: this.state.paymentItems,
        organizationId: this.state.organizationId,
        paymentSource: 'counter',
      };
      await this.props.calculateFeeProccesing(calculateAmount);
      const feeProcessingFetch = this.props.fee.feeProcessing as any;
      this.setState({ feeProcessing: feeProcessingFetch.data.feeProcessing });
    } else {
      this.setState({ feeProcessing: 0 });
    }
  }

  async onConfirmClick(index: any) {
    if (this.props.system.user?.organization?.useJis) {
      for (var i = 0; i < this.state.paymentItems.length; i++) {
        const detailPayment = {
          caseNumber: this.state.paymentItems[i].referenceNumber,
          court: this.props.system.user?.organization?.jisInformation ? Number(this.props.system.user?.organization?.jisInformation[0]?.secondCourtId) : 0,
          caseStatus: 'Open',
        };
        try {
          this.setState({ isLoadingAddPayment: true });
          await this.props.detailPayment(detailPayment);
          this.setState({ isLoadingAddPayment: false });
        } catch (execption) {
          let stateCopy = Object.assign({}, this.state);
          stateCopy.paymentItems[0].inJIS = false;
          this.setState(stateCopy);
          break;
        }
      }
      this.onContinueClick();
    } else {
      let stateCopy = Object.assign({}, this.state);
      stateCopy.paymentItems[0].inJIS = false;
      this.setState(stateCopy);
      this.onContinueClick();
    }
  }

  handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSuccess: false });
  };

  render() {
    let transacitonInputTypeToggle = null;
    const organization = this.props.system.user?.organization;
    let transacitonInput = this.state.swipedTransaction ? this.renderSwipedTransction() : this.renderNonSwipedTransaction(organization);
    const productState = this.props.clerkPayment.products;
    const total = +this.state.subTotal + +this.state.feeProcessing;
    let paymentState = this.state.paymentItems;
    const { changeInAmount } = this.state;

    if (organization?.cardPresentSwipedEnabled) {
      transacitonInputTypeToggle = <FormControlLabel control={<Switch checked={this.state.swipedTransaction} onChange={this.handleChange} name="swipedTransaction" color="primary" />} label="This is a swiped transaction" className={styles.inputField} />;
    }

    const products = productState?.map((product, index) => {
      return (
        <MenuItem key={index} className={styles.inputField} value={product.id}>
          {product.name}
        </MenuItem>
      );
    });

    const paymentsLen = paymentState.length;

    const payments = paymentState.map((p: any, index: any) => {
      const canSearchinJIS = this.state.searchInJIS !== this.state.paymentItems[index].referenceNumber || this.state.searchInJIS === '' || changeInAmount;
      return (
        <div key={index} className={[styles.row, styles.totalRow].join(' ')}>
          <Card className={styles.cardContainer}>
            <CardHeader title="Citation/Case Information" className={styles.cardHeader} />
            {index !== 0 && (
              <button className={styles.deleteButton} name="delete" onClick={(e) => this.onDeleteClick(e, index)} type="button">
                X
              </button>
            )}
            <div className={styles.inputFieldDiv}>
              <TextField
                className={styles.inputField}
                variant="outlined"
                id="referenceNumber"
                label={organization?.referenceNumberLabel}
                name="referenceNumber"
                error={this.state.errors.findIndex((fieldName) => fieldName === 'referenceNumber') > -1}
                required
                onChange={(e) => this.handleChange(e, index)}
              />
              <TextField className={styles.inputField} variant="outlined" id="liscenseNumber" label="Drivers License" name="liscenseNumber" error={this.state.errors.findIndex((fieldName) => fieldName === 'liscenseNumber') > -1} onChange={(e) => this.handleChange(e, index)} />
            </div>
            <div className={styles.inputFieldDiv}>
              <TextField className={styles.inputField} variant="outlined" id="firstName" label="First Name" name="firstName" error={this.state.errors.findIndex((fieldName) => fieldName === 'firstName') > -1} required onChange={(e) => this.handleChange(e, index)} />
              <TextField className={styles.inputField} variant="outlined" id="lastName" label="Last Name" name="lastName" error={this.state.errors.findIndex((fieldName) => fieldName === 'lastName') > -1} required onChange={(e) => this.handleChange(e, index)} />
            </div>

            <FormControl variant="outlined" required className={styles.inputFieldSelect}>
              <InputLabel id="productLabel">Product</InputLabel>
              <Select defaultValue="" error={this.state.errors.findIndex((fieldName) => fieldName === 'productId') > -1} labelId="productLabel" label="Product" id="productId" name="productId" required onChange={(e) => this.handleChange(e, index)} variant="outlined">
                {products}
              </Select>
            </FormControl>
            <TextField
              value={this.state.paymentItems[index].amount}
              className={styles.inputFieldSelect}
              variant="outlined"
              id="amount"
              label="Amount"
              name="amount"
              InputProps={{ inputProps: { min: 1, max: 10000 } }}
              type="number"
              helperText={(this.state.paymentItems[index].amount > 10000 && 'Amount must be lower than 10000') || (this.state.jisActive && 'Fetch Amount Data')}
              error={this.state.errors.findIndex((fieldName) => fieldName === 'amount') > -1 || this.state.paymentItems[index].amount > 10000 || this.state.paymentItems[index].amount < 0}
              required
              onChange={(e) => this.handleChange(e, index)}
              onBlur={(e) => this.onHandleBlurAmount(e, index)}
            />
            <div className={styles.divButton}>
              {index + 1 === paymentsLen && (
                <button color="primary" className={styles.addCitationButton} onClick={this.onAddPaymentClick} type="button">
                  Add a Citation/Case
                </button>
              )}
              {this.state.jisActive &&
                (this.state.isLoading === index ? (
                  <CircularProgress className={styles.spinnerLoading} />
                ) : (
                  <>
                    {canSearchinJIS && (
                      <>
                        <button color="primary" className={styles.addCitationButton} onClick={(e) => this.onUpdateJisClick(e, index)} type="button">
                          Retrieve Amount - JIS
                        </button>
                      </>
                    )}
                  </>
                ))}
            </div>
          </Card>
        </div>
      );
    });

    return (
      <form ref={this.formRef} noValidate autoComplete="off">
        <CardHeader className={styles.titleHeader} title="Make a Payment" />
        <Card className={styles.header}>
          <CustomSeparator menuList={['Enter Information', 'Confirm', 'Receipt']} />
        </Card>
        <div className={styles.container}>
          <div className={styles.columnLeft}>
            {payments}
            <div className={[styles.row, styles.totalRow].join(' ')}>
              <TotalCard total={total} subTotal={this.state.subTotal} feeProcessing={this.state.feeProcessing} />
            </div>
          </div>

          <div className={styles.columnRight}>
            <div className={[styles.row, styles.ccInputRow].join(' ')}>
              <Card className={[styles.cardContainer].join(' ')}>
                <CardHeader title="Credit Card" className={styles.cardHeader} />
                {transacitonInputTypeToggle}
                {transacitonInput}
                {!this.state.swipedTransaction && <CreditCards org={organization} />}

                <div className={styles.continueButton}>
                  {this.state.isLoadingAddPayment ? (
                    <CircularProgress />
                  ) : (
                    <Button variant="contained" color="secondary" onClick={this.onConfirmClick}>
                      Continue
                    </Button>
                  )}
                </div>
              </Card>
            </div>
          </div>
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.openSuccess} autoHideDuration={6000} onClose={this.handleClose}>
            <Alert onClose={this.handleClose} severity={this.state.severity}>
              {this.state.snackMessage}
            </Alert>
          </Snackbar>
        </div>
      </form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClerkPaymentCourt));
