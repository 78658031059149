import { Button } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { FilterOperation } from '../../models/FilterOperation';
import { currencyFormatter } from '../../services/currencyFormatter';
import { AppState } from '../../store';
import { deleteFee, getFeesForGrid } from '../../store/fees-grid/actions';
import { IFeesGridState as IFeesGridReduxState } from '../../store/fees-grid/types';
import { SystemState } from '../../store/system/types';
import { Fee } from '../../swagger-client';
import DataGrid from '../data-grid/DataGrid';
import styles from './FeesGrid.module.scss';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  fees: state.feesGrid,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, any>) =>
  bindActionCreators(
    {
      getFeesForGrid,
      deleteFee,
    },
    dispatch
  );

interface IFeesGridProps extends RouteComponentProps {
  fees: IFeesGridReduxState;
  system: SystemState;
  getFeesForGrid: (skip?: number | null, take?: number | null, sortOptions?: Array<{ field: string; direction: string }> | null, filterOptions?: Array<{ field: string; value: any; operation: FilterOperation }> | null) => ThunkAction<void, AppState, null, AnyAction>;

  deleteFee: (idToDelete: number) => ThunkAction<void, AppState, null, AnyAction>;
}

interface IFeesGridState {
  openSuccess: boolean | undefined;
  snackMessage: string;
}

class FeesGrid extends React.Component<IFeesGridProps, IFeesGridState> {
  constructor(props: IFeesGridProps) {
    super(props);

    this.state = {
      openSuccess: false,
      snackMessage: '',
    };

    this.onEditClicked = this.onEditClicked.bind(this);
    this.onAddClicked = this.onAddClicked.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  private onEditClicked(row: Fee) {
    this.props.history.push(`/fee?id=${row.id}&referer=fees`);
  }

  private async onDeleteClicked(row: Fee) {
    await this.props.deleteFee(row.id as number);
    this.setState({ openSuccess: true, snackMessage: 'Fee deleted successfully' });
  }

  private onAddClicked() {
    this.props.history.push(`/fee?new=true&referer=fees`);
  }

  async componentDidMount() {
    const loc = this.props.location.state as any;
    this.setState({ openSuccess: loc?.openSuccess ? loc?.openSuccess : false, snackMessage: loc?.snackMessage ? loc?.snackMessage : '' });
  }

  handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSuccess: false });
  };

  private DataGrid = DataGrid<Fee>();

  render() {
    return (
      <div className={styles.gridContainer}>
        <this.DataGrid
          cardTitle="Fees"
          preferenceGroup={'Fees'}
          rows={this.props.fees.fees}
          loadData={this.props.getFeesForGrid}
          totalRows={this.props.fees.count}
          columns={[
            {
              name: 'source',
              title: 'Source',
              type: 'select',
              options: ['Counter', 'Web', 'Kiosk', 'Phone'],
            },
            {
              name: 'conditionMin',
              title: 'Condition Minimum',
              getCellValue: (row: any, columnName: string) => currencyFormatter.format(row.conditionMin as number),
            },
            {
              name: 'conditionMax',
              title: 'Condition Max',
              getCellValue: (row: any, columnName: string) => currencyFormatter.format(row.conditionMax as number),
            },
            {
              name: 'amountFixed',
              title: 'Amount Fixed',
              getCellValue: (row: any, columnName: string) => currencyFormatter.format(row.amountFixed as number),
            },
            {
              name: 'amountPercent',
              title: 'Amount Percent',
              getCellValue: (row: any, columnName: string) => `${row.amountPercent}%`,
            },
            {
              name: 'editButton',
              title: 'Edit',
              type: 'button',
              label: () => 'Edit',
              action: this.onEditClicked,
            },
            {
              name: 'deleteButton',
              title: 'Delete',
              type: 'button',
              label: () => 'Delete',
              action: this.onDeleteClicked,
            },
          ]}
          pageSizes={[10, 25, 50, 150, 500]}
          columnWidthsGrid={180}
          // addButton={true}
          // onAddClick={this.onAddClicked}
        />
        <Button variant="contained" color="primary" className={styles.continueButton} onClick={this.onAddClicked}>
          New Fee
        </Button>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.openSuccess} autoHideDuration={6000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="success">
            {this.state.snackMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FeesGrid));
