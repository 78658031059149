import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask'

interface IEmailInputProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

export class EmailInput extends Component<IEmailInputProps> {
  render() {
    const { inputRef, ...other } = this.props;

    return (<MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={emailMask}
      placeholderChar={'\u2000'}
      showMask
      pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
    />)
  }
}

