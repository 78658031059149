import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

// Begin Redux init
import { AppState } from '../../store';

const mapStateToProps = (state: AppState) => ({
    system: state.system
});


interface INoStrategySelectedProps extends RouteComponentProps {

}

interface INoStrategySelectedState {

}
// End Redux init

class NoStrategySelected extends React.Component<INoStrategySelectedProps, INoStrategySelectedState> {

    render() {   
        return (
            <span>
                Select an organization from the user menu in the top right menu to proceed with this functionality.
            </span>
        );
    }
}

export default connect(mapStateToProps)(withRouter(NoStrategySelected));
