import { Button } from '@material-ui/core';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../store';
import { navigate } from '../../../store/system/actions';
import WebsitePaymentLayout from '../WebsitePaymentLayout';
import styles from './WebistePayment.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  manageOrg: state.manageOrg,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const WebistePayment = (props: any) => {
  let history = useHistory();
  const { urlIdentifier } = useParams<{ urlIdentifier: string }>();
  const org = useSelector((state: any) => state?.manageOrg?.orgByUrl);
  const name = org?.name;

  const onContinueClick = () => {
    history.push(`/web/${urlIdentifier}/pay`);
  };

  return (
    <WebsitePaymentLayout>
      <div className="w-100 py-3">
        <div className={styles.header}>
          <h1>Welcome</h1>
        </div>
        <div className={styles.cardContainer}>
          <p>Welcome to {name}. Click below to make a payment.</p>
          <div className={styles.container}>
            <Button variant="contained" color="primary" onClick={onContinueClick} className={styles.makePaymentButton}>
              Make a Payment Online
            </Button>
          </div>
        </div>
      </div>
    </WebsitePaymentLayout>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WebistePayment));
