import React from 'react';
import { isBrowser } from 'react-device-detect';
import { Route } from 'react-router';
import ChangePassword from '../components/change-password/ChangePassword';
import PasswordChanged from '../components/change-password/password-changed/PasswordChanged';
import ForgotPasswordEmailSent from '../components/forgot-password/forgot-password-email-sent/ForgotPasswordEmailSent';
import ForgotPassword from '../components/forgot-password/ForgotPassword';
import Login from '../components/login/Login';
import WebistePaymentConfirm from '../components/WebsitePages/website-payment-confirm/WebistePaymentConfirm';
import WebistePaymentDuplicate from '../components/WebsitePages/website-payment-duplicate/WebistePaymentDuplicate';
import WebistePaymentForm from '../components/WebsitePages/website-payment-form/WebistePaymentForm';
import WebistePaymentHelp from '../components/WebsitePages/website-payment-help/WebistePaymentHelp';
import WebistePayment from '../components/WebsitePages/website-payment/WebistePayment';
import WebistePaymentConfirmDevice from '../components/WebsitePagesDevice/website-payment-confirm/WebistePaymentConfirmDevice';
import WebistePaymentDuplicateDevice from '../components/WebsitePagesDevice/website-payment-duplicate/WebistePaymentDuplicateDevice';
import WebistePaymentFormDevice from '../components/WebsitePagesDevice/website-payment-form/WebistePaymentFormDevice';
import WebistePaymentDevice from '../components/WebsitePagesDevice/website-payment/WebistePaymentDevice';

export const LoginRoutes = () => {
  const routes: Array<JSX.Element> = [
    <Route key={'login'} exact path="/" component={Login}></Route>,

    <Route key={'forgotpassword'} exact path="/forgotpassword" component={ForgotPassword}></Route>,

    <Route key={'changepassword'} exact path="/changepassword" component={ChangePassword}></Route>,

    <Route key={'forgotpasswordemailsent'} exact path="/forgotpasswordemailsent" component={ForgotPasswordEmailSent}></Route>,

    <Route key={'passwordchanged'} exact path="/passwordchanged" component={PasswordChanged}></Route>,
  ];

  routes.push(<Route key={'website'} exact path={'/web/:urlIdentifier'} component={isBrowser ? WebistePayment : WebistePaymentDevice}></Route>);

  routes.push(<Route key={'website-id-pay'} exact path={'/web/:urlIdentifier/pay'} component={isBrowser ? WebistePaymentForm : WebistePaymentFormDevice}></Route>);

  routes.push(<Route key={'website-id-confirm'} exact path={'/web/:urlIdentifier/confirm'} component={isBrowser ? WebistePaymentConfirm : WebistePaymentConfirmDevice}></Route>);

  routes.push(<Route key={'website-help'} exact path={'/web/:urlIdentifier/help'} component={isBrowser ? WebistePaymentHelp : WebistePaymentHelp}></Route>);

  routes.push(<Route key={'website-payments'} exact path={'/web/:urlIdentifier/payments'} component={isBrowser ? WebistePaymentDuplicate : WebistePaymentDuplicateDevice}></Route>);

  return <React.Fragment>{routes}</React.Fragment>;
};
