import { Button, Card, FormGroup } from '@material-ui/core';
import React from 'react';
// Begin Redux init
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { whiteLabelStrategy } from '../../../services/whiteLabelStrategy';
import { AppState } from '../../../store';
import BaseComponent from '../../BaseComponent';
import styles from './ForgotPasswordEmailSent.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, any>) => bindActionCreators({}, dispatch);

interface IForgotPasswordProps extends RouteComponentProps {}

interface IForgotPasswordState {}
// End Redux init

class ForgotPasswordEmailSent extends BaseComponent<IForgotPasswordProps, IForgotPasswordState> {
  constructor(props: IForgotPasswordProps) {
    super(props);

    this.backToLogin = this.backToLogin.bind(this);
  }

  private backToLogin() {
    this.props.history.push('/');
  }

  render() {
    const whiteLabelValues = whiteLabelStrategy();

    return (
      <div className={styles.loginContainer}>
        <div className={styles.headerOptions}>
          <Card className={styles.loginCard} style={{ backgroundColor: whiteLabelValues.color5 }}>
            <form>
              <FormGroup row={false}>
                <img className={styles.logo} alt="Logo of company" src={whiteLabelValues.loginLogo} />
                {/* <h2 className={styles.title}>LOG IN</h2> */}
              </FormGroup>
              <FormGroup row={true} className={styles.formRow}>
                <p className={styles.titleText}>If your email exists in our system you'll receive an email shortly!</p>
                {/* <CardHeader title={"If your email exists in our system you'll receive an email shortly!"} /> */}
              </FormGroup>
              <FormGroup row={true} className={styles.buttonRow}>
                <Button style={{ backgroundColor: whiteLabelValues.color1 }} className={styles.loginViewButton} onClick={this.backToLogin}>
                  Back to Login
                </Button>
              </FormGroup>
            </form>
          </Card>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordEmailSent);
