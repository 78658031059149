import { RehydrateAction } from 'redux-persist';
import * as types from './types';

const initialState: types.IClerkPaymentState = {
  products: [],
  productsWebsite: [],
  payment: {
    source: '',
    strategy: '',
    paymentItems: [],
  },
  paymentId: {
    id: 0,
    source: '',
    firstName: '',
    lastName: '',
    paymentStatusFromGateway: '',
    paymentReferenceFromGateway: '',
    addressLine1: '',
    city: '',
    state: '',
    zip: '',
    paymentItems: [],
  },
  detailPayment: [
    {
      caseNumber: '',
    },
  ],
  paymentsFiltered: [],
  paymentFiltredById: [],
  paymentWebsite: {
    source: '',
    paymentItem: {
      referenceNumber: '',
      firstName: '',
      lastName: '',
      productId: 0,
      amount: 0,
      liscenseNumber: '',
    },
    urlIdentifier: '',
  },
  paymentsByReference: [],
  batches: [],
};

export function clerkPaymentReducer(state = initialState, action: types.ClerkPaymentActionTypes | RehydrateAction): types.IClerkPaymentState {
  switch (action.type) {
    case types.GET_PRODUCTS_SUCCESSFUL:
      return {
        ...state,
        products: action.payload,
      };
    case types.CLEAR_STATE:
      return initialState;
    case types.ADD_PAYMENT:
      return {
        ...state,
        payment: action.payload,
      };
    case types.DETAIL_PAYMENT:
      return {
        ...state,
        detailPayment: action.payload,
      };
    case types.FILTER_PAYMENT:
      return {
        ...state,
        paymentsFiltered: action.payload,
      };
    case types.FILTER_PAYMENT_BY_ID:
      return {
        ...state,
        paymentFiltredById: action.payload,
      };
    case types.ADD_PAYMENT_WEBSITE:
      return {
        ...state,
        paymentWebsite: action.payload,
      };
    case types.GET_PRODUCTS_WEBSITE:
      return {
        ...state,
        productsWebsite: action.payload,
      };
    case types.CONFIRM_PAYMENT:
      return {
        ...state,
        paymentId: action.payload,
      };
    case types.GET_PAYMENT_BY_REFERENCE:
      return {
        ...state,
        paymentsByReference: action.payload,
      };
    case types.GET_BATCHES:
      return {
        ...state,
        batches: action.payload,
      };
    case types.CAPTURE_PAYMENT:
    case types.VOID_PAYMENT:
    case types.EDIT_WEBSITE_PAYMENT:
    case types.EDIT_COUNTER_PAYMENT:
    case types.APPROVE_PAYMENT_HELD:
    case types.DECLINE_PAYMENT_HELD:
    default:
      return state;
  }
}
