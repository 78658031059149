import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import { Snackbar } from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../../../store';
import { navigate } from '../../../../store/system/actions';
import { deleteUser, getUsers } from '../../../../store/users/actions';
import { User } from '../../../../swagger-client';
import styles from './UserList.module.scss';
import ConfirmDialog from '../../../confirmDialog/ConfirmDialog';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, any>) =>
  bindActionCreators(
    {
      navigate,
      deleteUser,
      getUsers,
    },
    dispatch
  );

interface IUserListProps extends RouteComponentProps {
  users: Array<User>;
  userSelected: (user: User) => void;
  deleteUser: (id: number) => ThunkAction<void, AppState, null, AnyAction>;
  getUsers: () => void;
}

interface IUserListState {
  selectedUser: User | null;
  filteredUsers: Array<User>;
  searchValue: string;
  openSuccess: boolean | undefined;
  snackMessage: string;
  severity: Color | undefined;
  deleting: boolean;
}

class UserList extends React.Component<IUserListProps, IUserListState> {
  constructor(props: IUserListProps) {
    super(props);

    this.state = {
      selectedUser: null,
      filteredUsers: this.props.users,
      searchValue: '',
      openSuccess: false,
      snackMessage: '',
      severity: 'success',
      deleting: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
  }

  static getDerivedStateFromProps(nextProps: IUserListProps, prevState: IUserListState) {
    const { searchValue } = prevState;

    let filteredUsers: Array<User> = [];

    if (searchValue === '') {
      filteredUsers = nextProps.users;
    } else {
      filteredUsers = nextProps.users.filter((user) => user.email.toLowerCase().includes(prevState.searchValue.toLocaleLowerCase()));
    }
    return { ...prevState, filteredUsers };
  }

  handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSuccess: false });
  };

  private async onDeleteClick(event: ChangeEvent<any>, u: any) {
    await this.props.deleteUser(u.id);
    this.setState({ openSuccess: true, snackMessage: 'User deleted successfully' });
    this.props.getUsers();
  }

  private onDeleteConfirm(value: any) {
    this.setState({ deleting: value });
  }

  private onEditClick(event: ChangeEvent<any>, u: any) {
    this.props.history.push(`/organization_users/${u.id}/edit`);
  }

  private handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(({ [name]: value } as unknown) as any);
  }

  async componentDidMount() {
    const loc = this.props.location.state as any;
    this.setState({ openSuccess: loc?.openSuccess ? loc?.openSuccess : false, snackMessage: loc?.snackMessage ? loc?.snackMessage : '', severity: loc?.severity ? loc?.severity : 'success' });
  }

  render() {
    return (
      <>
        <Form className={styles.searchUser}>
          <Form.Control name="searchValue" value={this.state.searchValue} type="text" placeholder="search..." onChange={this.handleInputChange} />
        </Form>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>E-mail</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Permissions</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.filteredUsers?.map((u, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {u.email}
                  </TableCell>
                  <TableCell align="left">{u.firstName + ' ' + u.lastName}</TableCell>
                  <TableCell align="left">
                    {u.roles?.map((role, index) => (
                      <Form.Check key={index} className={styles.inputField} type="checkbox" name={role.name} checked={true} label={role.displayName} />
                    ))}
                  </TableCell>
                  <TableCell align="left">{u.active === true ? 'Active' : 'Disabled'}</TableCell>
                  <TableCell align="left">
                    <IconButton onClick={(e) => this.onEditClick(e, u)}>
                      <CreateIcon color="primary" />
                    </IconButton>
                    <IconButton onClick={() => this.onDeleteConfirm(true)}>
                      <DeleteOutlinedIcon color="secondary" />
                    </IconButton>
                    <ConfirmDialog title="Delete this user?" open={this.state.deleting} setOpen={this.onDeleteConfirm} onConfirm={(e: any) => this.onDeleteClick(e, u)}>
                      Are you sure you want to delete this user?
                    </ConfirmDialog>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.openSuccess} autoHideDuration={6000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity={this.state.severity}>
            {this.state.snackMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserList));
