import React from 'react';
import { Form } from 'react-bootstrap';
import { TextField, Button } from '@material-ui/core';
import BaseComponent from '../../BaseComponent';
import styles from './UpdatePassword.module.scss';

interface IUpdatePasswordProps {
  updatePassword: (password: string) => void;
}

interface IUpdatePasswordState {
  password: '';
  passwordRepeat: '';
}

class UpdatePassword extends BaseComponent<IUpdatePasswordProps, IUpdatePasswordState> {
  constructor(props: IUpdatePasswordProps) {
    super(props, {
      messages: {
        in: 'Passwords must match!',
      },
    });

    this.state = {
      password: '',
      passwordRepeat: '',
    };

    this.updatePassword = this.updatePassword.bind(this);
  }

  private async updatePassword(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (this.validator.allValid()) {
      await this.props.updatePassword(this.state.password);

      this.setState({
        password: '',
        passwordRepeat: '',
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    return (
      <Form noValidate onSubmit={this.updatePassword} className={styles.cardContainer}>
        <TextField variant="outlined" className={styles.inputFieldSelect} name="password" id="password" label="Password" type="password" value={this.state.password} onChange={this.handleInputChange} />
        <TextField variant="outlined" className={styles.inputFieldSelect} name="passwordRepeat" id="passwordRepeat" label="Re-Enter Password" type="password" value={this.state.passwordRepeat} onChange={this.handleInputChange} />
        <div className={styles.continueButton}>
          <Button variant="contained" color="secondary" type="submit">
            Save
          </Button>
        </div>
      </Form>
    );
  }
}

export default UpdatePassword;
