import { Button, Card, CardHeader, FormGroup } from '@material-ui/core';
import React from 'react';
// Begin Redux init
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { whiteLabelStrategy } from '../../../services/whiteLabelStrategy';
import { AppState } from '../../../store';
import BaseComponent from '../../BaseComponent';
import styles from './PasswordChanged.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, any>) => bindActionCreators({}, dispatch);

interface IPasswordChangedProps extends RouteComponentProps {}

interface IPasswordChangeState {}
// End Redux init

class PasswordChanged extends BaseComponent<IPasswordChangedProps, IPasswordChangeState> {
  constructor(props: IPasswordChangedProps) {
    super(props);

    this.backToLogin = this.backToLogin.bind(this);
  }

  private backToLogin() {
    this.props.history.push('/');
  }

  render() {
    const whiteLabelValues = whiteLabelStrategy();

    return (
      <div className={styles.loginContainer}>
        <div className={styles.headerOptions}>
          <Card className={styles.loginCard}>
            <form>
              <FormGroup row={true}>
                <img alt={'Company logo'} className={styles.logo} src={whiteLabelValues.loginLogo} />
              </FormGroup>
              <FormGroup row={true}>
                <CardHeader title={'Your password has been changed!'} />
              </FormGroup>
              <FormGroup row={true}>
                <Button onClick={this.backToLogin}>Back to Login</Button>
              </FormGroup>
            </form>
          </Card>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChanged);
