import { Link, Paper } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import BaseComponent from '../BaseComponent';
import styles from './ErrorModal.module.scss';

interface IErrorModalProps {
  errors: Array<string>;
  dismissOnClick: () => any;
}

interface IErrorModalState {}

class ErrorModal extends BaseComponent<IErrorModalProps, IErrorModalState> {
  render() {
    const errors = this?.props?.errors?.map((error) => <li className={styles.errorText}>{error}</li>);

    return (
      <Modal open={true} className={styles.errorModal}>
        <Paper>
          <div className={styles.errorsContainer}>
            <div className={styles.logoContainer}>
              <ErrorIcon fontSize="large" className={styles.errorIcon} />
            </div>
            <div className={styles.detailsAndButtonsContainer}>
              <p className={styles.errorTitle}>You've found a problem.</p>
              <div className={styles.errorDetailsContainer}>
                <ul>{errors}</ul>
              </div>
              <p className={styles.errorText}>Please notify the team so we can look into solving this.</p>
              <div className={styles.buttonContainer}>
                <Link
                  className={styles.confirmButton}
                  href="#"
                  onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                    event.preventDefault();
                    this.props.dismissOnClick();
                  }}
                >
                  Ok
                </Link>
              </div>
            </div>
          </div>
        </Paper>
      </Modal>
    );
  }
}

export default ErrorModal;
