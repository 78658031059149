import { Action } from 'redux';
import { Organization, PagedOrganizationResults } from '../../swagger-client';

export const GET_ALL_ORGS = 'GET_ALL_ORGS_MANAGE_ORGS';
export const GET_ALL_ORGS_SUCCESSFUL = 'GET_ALL_ORGS_SUCCESSFUL_MANAGE_ORGS';

// Action types

export interface GetAllOrgsSuccessful extends Action {
  type: typeof GET_ALL_ORGS_SUCCESSFUL;
  payload: PagedOrganizationResults;
}

export type ManageOrgsActionTypes = GetAllOrgsSuccessful;

// Store state

export interface IManageOrgsState {
  orgs: Array<Organization>;
  orgCount: number;
}
