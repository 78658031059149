import * as types from './types';
import { Dispatch, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../index';
import * as swagger from '../../swagger-client';
import AxiosConfigurator from '../../services/AxiosConfigurator';
import { FilterOperation } from '../../models/FilterOperation';
import { ERROR_ACTION } from '../system/types';
import errorMessages from '../../error_messages.json';
import ManageErrorType from '../../services/errorHelper';

export const getAllOrganizations = (skip?: number | null, take?: number | null, sortOptions?: Array<{ field: string; direction: string }> | null, filterOptions?: Array<{ field: string; value: any; operation: FilterOperation }> | null): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<any>, getState) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null) {
      const orgApi = new swagger.OrganizationApi(AxiosConfigurator.getConfig(state.system.user.token));

      let query: any = {};

      if (typeof skip !== 'undefined' && skip !== null) {
        query.skip = skip;
      }
      if (typeof take !== 'undefined' && take !== null) {
        query.take = take;
      }
      if (typeof sortOptions !== 'undefined' && sortOptions !== null && sortOptions.hasOwnProperty('length') && sortOptions.length > 0) {
        query.sortOptions = window.btoa(JSON.stringify(sortOptions));
      }
      if (typeof filterOptions !== 'undefined' && filterOptions !== null && filterOptions.hasOwnProperty('length') && filterOptions.length > 0) {
        query.filterOptions = window.btoa(JSON.stringify(filterOptions));
      }

      try {
        const result = await orgApi.get(state.system.user.token as any, {
          query,
        });

        dispatch({
          type: types.GET_ALL_ORGS_SUCCESSFUL,
          payload: result.data,
        });
      } catch(err) {
        const error = ManageErrorType(err as any)
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.manageOrgs + '--' + error,
        });
      }
    }
  };
};
