import { Action } from 'redux';
import IGeneratedMenuData, { IGeneratedMenuItem } from '../../models/IGeneratedMenuData';
import { LoggedInUser } from '../../models/LoggedInUser';
import { Organization, Product, User, UserRole } from '../../swagger-client';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL_SYSTEM';
export const LOGIN_FAILED = 'LOGIN_FAILED_SYSTEM';
export const LOG_OUT = 'LOG_OUT_SYSTEM';
export const GET_MENU_STATUS_SUCCESSFUL = 'GET_MENU_STATUS_SUCCESSFUL_SYSTEM';
export const ACTIVE_VIEW = 'ACTIVE_VIEW_SYSTEM';
export const UPDATE_NAVIGATION_CURRENT_MENU_ITEM = 'UPDATE_NAVIGATION_CURRENT_MENU_ITEM_SYSTEM';
export const ERROR_ACTION = 'ERROR_ACTION_SYSTEM';
export const CLEAR_ERRORS_ACTION = 'CLEAR_ERRORS_ACTION_SYSTEM';
export const TOGGLE_ROUTE_BACK = 'TOGGLE_ROUTE_BACK_SYSTEM';
export const INCREMENT_MY_ISSUES_COUNT = 'INCREMENT_MY_ISSUES_COUNT_SYSTEM';
export const DECREMENT_MY_ISSUES_COUNT = 'DECREMENT_MY_ISSUES_COUNT_SYSTEM';
export const SEND_PASSWORD_RESET_EMAIL_SUCCESSFUL = 'SEND_PASSWORD_RESET_EMAIL_SUCCESSFUL_SYSTEM';
export const VALIDATE_PASSWORD_RESET_TOKEN_SUCCESSFUL = 'VALIDATE_PASSWORD_RESET_TOKEN_SUCCESSFUL_SYSTEM';
export const CHANGE_PASSWORD_SUCCESSFUL = 'CHANGE_PASSWORD_SUCCESSFUL_SYSTEM';
export const ACTIVATE_ORG_FOR_SUPER_USER = 'ACTIVATE_ORG_FOR_SUPER_USER_SUCCESSFUL_SYSTEM';
export const ADD_PRODUCT = 'ADD_PRODUCT_SYSTEM';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT_SYSTEM';
export const CLEAR_STATE = 'CLEAR_STATE_LOGIN';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATIONS_USER = 'UPDATE_ORGANIZATIONS_USER';


// Action types

export interface LoginSuccessAction extends Action {
  type: typeof LOGIN_SUCCESSFUL;
  payload: {
    id: number;
    token: string;
    email: string;
    firstName: string;
    lastName: string;
    organizationId: number;
    organization: Organization;
    organizations: Array<Organization>;
    roles: Array<UserRole>;
  };
}

export interface ChangeOrganizationSuccessAction extends Action {
  type: typeof UPDATE_ORGANIZATION;
  payload: {
    organization: Organization;
  };
}

export interface ChangeOrganizationsUser extends Action {
  type: typeof UPDATE_ORGANIZATIONS_USER;
  payload: { organization: Organization };
}

export interface LoginFailedAction extends Action {
  type: typeof LOGIN_FAILED;
}

export interface LogOutAction extends Action {
  type: typeof LOG_OUT;
}

export interface ActiveViewAction extends Action {
  type: typeof ACTIVE_VIEW;
  payload: string;
}

export interface UpdateNavigationCurrentMenuItemAction extends Action {
  type: typeof UPDATE_NAVIGATION_CURRENT_MENU_ITEM;
  payload: IGeneratedMenuItem;
}

export interface ErrorAction extends Action {
  type: typeof ERROR_ACTION;
  payload: string;
}

export interface ClearErrorsAction extends Action {
  type: typeof CLEAR_ERRORS_ACTION;
}

export interface IncrementMyIssuesCount extends Action {
  type: typeof INCREMENT_MY_ISSUES_COUNT;
}

export interface DecrementMyIssuesCount extends Action {
  type: typeof DECREMENT_MY_ISSUES_COUNT;
}

export interface SendPasswordResetEmailSuccessful extends Action {
  type: typeof SEND_PASSWORD_RESET_EMAIL_SUCCESSFUL;
}

export interface ValidatePasswordResetTokenSuccessful extends Action {
  type: typeof VALIDATE_PASSWORD_RESET_TOKEN_SUCCESSFUL;
}

export interface ChangePasswordSuccessful extends Action {
  type: typeof CHANGE_PASSWORD_SUCCESSFUL;
  payload: 'Your password has been changed!';
}

export interface ToggleRouteBack extends Action {
  type: typeof TOGGLE_ROUTE_BACK;
}

export interface ActivateOrgForSuperUser extends Action {
  type: typeof ACTIVATE_ORG_FOR_SUPER_USER;
  payload: User;
}

export interface UpdateProduct extends Action {
  type: typeof UPDATE_PRODUCT;
  payload: Product;
}

export interface UpdateOrganization extends Action {
  type: typeof UPDATE_ORGANIZATION;
  payload: Organization;
}
export interface UpdateOrganizationsUser extends Action {
  type: typeof UPDATE_ORGANIZATIONS_USER;
  payload: Organization;
}

export interface AddProduct extends Action {
  type: typeof ADD_PRODUCT;
  payload: Product;
}
export interface IClearStateAction extends Action {
  type: typeof CLEAR_STATE;
}

export type SystemActionTypes =
  | LoginSuccessAction
  | ChangeOrganizationSuccessAction
  | LoginFailedAction
  | LogOutAction
  | UpdateNavigationCurrentMenuItemAction
  | ErrorAction
  | ClearErrorsAction
  | ToggleRouteBack
  | IncrementMyIssuesCount
  | DecrementMyIssuesCount
  | SendPasswordResetEmailSuccessful
  | ValidatePasswordResetTokenSuccessful
  | ChangePasswordSuccessful
  | ActivateOrgForSuperUser
  | UpdateProduct
  | AddProduct
  | ActiveViewAction
  | IClearStateAction
  | UpdateOrganization
  | ChangeOrganizationsUser;

// Store state

export interface SystemState {
  user: LoggedInUser | null;
  userLoggedIn: boolean;
  loginFailed: boolean;
  menuData: IGeneratedMenuData;
  activeView: string;
  menuDataByName: { [key: string]: IGeneratedMenuItem };
  menuDataByPath: { [key: string]: IGeneratedMenuItem };
  menuDataByNavigationScope: { [key: string]: IGeneratedMenuItem };
  navigationHistory: Array<IGeneratedMenuItem>;
  errors: Array<string>;
  routeBack: boolean;
  passwordResetMessage?: string;
  products: Array<Product> | undefined;
}
