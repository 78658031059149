import moment, { Moment } from "moment";

export const dateTimeFormatter = (date: string | Date | Moment, addHours: number = 0,  unknownResponse = 'Unknown') => {

    const result =  moment(date).add(addHours, 'hours').format('MM/DD/YYYY h:mm:ss A');

    if (result === 'Invalid date') {
        return unknownResponse;
    } else {
        return result;
    }
}
