import dotenv from 'dotenv';
import { IWhiteLabelDetails } from '../models/IWhiteLabelDetails';
import { WhiteLabels } from '../models/WhiteLabels';

dotenv.config();

export function whiteLabelStrategy(): IWhiteLabelDetails {
  const baseUrl = window.location.host;
  switch (baseUrl) {
    case 'civpay.com':
      return {
        whiteLabel: WhiteLabels.CivPay,
        title: 'Civ Pay',
        logo: 'logos/mps-logo-eagle-white.png',
        loginLogo: 'logos/mps-logo-eagle.png',
        favicon: 'civpay-favicon.ico',
        color1: '#3b3888',
        color2: '#823061',
        color3: '#E52C2C',
        color4: '#49A03A',
        color5: '#F5F5F5',
        color6: '#333333',
        loginBackgroundColor: 'linear-gradient(140deg, rgba(229,44,44,1) 28%, rgba(43,57,144,1) 100%);',
      };
    case 'localhost:3005':
      return {
        whiteLabel: WhiteLabels.CivPay,
        title: 'Civ Pay',
        logo: 'logos/mps-logo-eagle-white.png',
        loginLogo: 'logos/mps-logo-eagle.png',
        favicon: 'civpay-favicon.ico',
        color1: '#3b3888',
        color2: '#823061',
        color3: '#E52C2C',
        color4: '#49A03A',
        color5: '#F5F5F5',
        color6: '#333333',
        loginBackgroundColor: 'linear-gradient(140deg, rgba(229,44,44,1) 28%, rgba(43,57,144,1) 100%)',
      }; // TODO: Switch based on .env
    case 'mps-dev-front-alb-1294748988.us-east-1.elb.amazonaws.com':
      return {
        whiteLabel: WhiteLabels.CivPay,
        title: 'Civ Pay',
        logo: 'logos/mps-logo-eagle-white.png',
        loginLogo: 'logos/mps-logo-eagle.png',
        favicon: 'civpay-favicon.ico',
        color1: '#3b3888',
        color2: '#823061',
        color3: '#E52C2C',
        color4: '#49A03A',
        color5: '#F5F5F5',
        color6: '#333333',
        loginBackgroundColor: 'linear-gradient(140deg, rgba(229,44,44,1) 28%, rgba(43,57,144,1) 100%)',
      };
    case 'mps-prod-front-alb-1936301749.us-east-1.elb.amazonaws.com':
      return {
        whiteLabel: WhiteLabels.CivPay,
        title: 'Civ Pay',
        logo: 'logos/mps-logo-eagle-white.png',
        loginLogo: 'logos/mps-logo-eagle.png',
        favicon: 'civpay-favicon.ico',
        color1: '#3b3888',
        color2: '#823061',
        color3: '#E52C2C',
        color4: '#49A03A',
        color5: '#F5F5F5',
        color6: '#333333',
        loginBackgroundColor: 'linear-gradient(140deg, rgba(229,44,44,1) 28%, rgba(43,57,144,1) 100%)',
      };
    default:
      return {
        whiteLabel: WhiteLabels.CivPay,
        title: 'Civ Pay',
        logo: 'logos/mps-logo-eagle-white.png',
        loginLogo: 'logos/mps-logo-eagle.png',
        favicon: 'civpay-favicon.ico',
        color1: '#3b3888',
        color2: '#823061',
        color3: '#E52C2C',
        color4: '#49A03A',
        color5: '#F5F5F5',
        color6: '#333333',
        loginBackgroundColor: 'linear-gradient(140deg, rgba(229,44,44,1) 28%, rgba(43,57,144,1) 100%)',
      };
    // throw new Error('White label strategy not found for URL.');
  }
}
