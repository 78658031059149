import { Button, Card, CardHeader, Checkbox, FormControl, FormGroup, InputLabel, MenuItem, Select } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import reportData from '../../data/reports.json';
import { currencyFormatter, mapperStatusToStatusDisplay, mapperToUpperCase } from '../../services/currencyFormatter';
import { AppState } from '../../store';
import { approvePayment, clearState, declinePayment, filterPayment, filterPaymentById } from '../../store/clerk-payment/actions';
// Begin Redux init
import { IClerkPaymentState as IClerkPaymentStateRedux } from '../../store/clerk-payment/types';
import { navigate } from '../../store/system/actions';
import { SystemState } from '../../store/system/types';
import { ApprovePayment, FilterPaymentModel, Payment } from '../../swagger-client';
import DataGrid from '../data-grid/DataGrid';
import styles from './ManageReports.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  clerkPayment: state.clerkPayment,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
      filterPayment,
      clearState,
      approvePayment,
      declinePayment,
      filterPaymentById,
    },
    dispatch
  );

interface IReportsGridProps extends RouteComponentProps {
  system: SystemState;
  clerkPayment: IClerkPaymentStateRedux;
  clearState: () => void;
  // filterPayment: (filterPayment: FilterPaymentModel) => ThunkAction<void, AppState, null, AnyAction>;
  approvePayment: (paymentId: ApprovePayment) => ThunkAction<void, AppState, null, AnyAction>;
  declinePayment: (paymentId: ApprovePayment) => ThunkAction<void, AppState, null, AnyAction>;
  filterPaymentById: (filterPayment: FilterPaymentModel, id?: any) => ThunkAction<void, AppState, null, AnyAction>;
  // declinePayment: (paymentId: ApprovePayment) => ThunkAction<void, AppState, null, AnyAction>;
}

interface IReportsGridState {
  report: string;
  c: any;
  paymentsFiltered: any[];
  isLoading: boolean;
  loading: boolean;
  dateFrom: Date | any;
  dateTo: Date | any;
  dataSend: any;
}
// End Redux init

class ManageReports extends React.Component<IReportsGridProps, IReportsGridState> {
  constructor(props: IReportsGridProps) {
    super(props);

    this.state = {
      report: '',
      c: [],
      paymentsFiltered: [],
      isLoading: false,
      loading: false,
      dateFrom: new Date(),
      dateTo: new Date(),
      dataSend: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.onApproveClicked = this.onApproveClicked.bind(this);
    this.onDeclinedClicked = this.onDeclinedClicked.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onReceiptClicked = this.onReceiptClicked.bind(this);
  }

  componentDidMount() {
    this.setState({ report: 'Settled' });
    let dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 7);
    this.setState({ dateFrom: dateFrom });
    const filter = { status1: 'Settled', fromDate: dateFrom, toDate: new Date() };
    filterPayment(filter, this.props.system.user).then((payments) => {
      this.setState({ paymentsFiltered: payments, isLoading: false });
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.report !== this.state.report) {
      let filter = {};
      if (this.state.report === 'CapturedAuthorized') {
        filter = { status1: 'Captured', status2: 'Authorized', fromDate: this.state.dateFrom, toDate: this.state.dateTo };
        this.setState({ c: ['name', 'product', 'gatewayResponse', 'declineButton', 'approveButton', 'inJIS', 'paidInJIS'] });
      } else {
        filter = { status1: this.state.report, fromDate: this.state.dateFrom, toDate: this.state.dateTo };
      }
      if (this.state.report === 'Settled') {
        this.setState({ c: ['gatewayResponse', 'createdById', 'status', 'declineButton', 'approveButton'] });
      }
      if (this.state.report === 'HeldForReview') {
        this.setState({ c: ['name', 'product', 'gatewayResponse', 'inJIS', 'paidInJIS'] });
      }
      if (this.state.report === 'Declined_voided' || this.state.report === 'Cancel') {
        this.setState({ c: ['source', 'product', 'createdById', 'status', 'declineButton', 'approveButton', 'inJIS', 'paidInJIS'] });
      }
      if (this.state.report === 'Authorized') {
        this.setState({ c: ['name', 'product', 'gatewayResponse', 'declineButton', 'approveButton', 'inJIS', 'paidInJIS'] });
      }
      this.setState({ isLoading: true });
      filterPayment(filter, this.props.system.user)
        .then((payments) => {
          this.setState({ paymentsFiltered: payments });
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    }
  }

  private async onApproveClicked(row: Payment) {
    const payment = { paymentId: row.id } as ApprovePayment;
    this.setState({ isLoading: true });
    await this.props.approvePayment(payment);
    const filter = { status1: 'HeldForReview', fromDate: this.state.dateFrom, toDate: this.state.dateTo };
    this.setState({ isLoading: false });
    filterPayment(filter, this.props.system.user).then((payments) => {
      this.setState({ paymentsFiltered: payments });
    });
  }

  private async onDeclinedClicked(row: Payment) {
    const payment = { paymentId: row.id } as ApprovePayment;
    this.setState({ isLoading: true });
    await this.props.declinePayment(payment);
    const filter = { status1: 'HeldForReview', fromDate: this.state.dateFrom, toDate: this.state.dateTo };
    this.setState({ isLoading: false });
    filterPayment(filter, this.props.system.user).then((payments) => {
      this.setState({ paymentsFiltered: payments });
    });
  }

  private handleChange(event: ChangeEvent<any>) {
    if (event.target.name === 'reportId') {
      this.setState({ report: event.target.value });
    }
  }

  private async onUpdateClick() {
    let filter = {};
    if (this.state.report === 'CapturedAuthorized') {
      filter = { status1: 'Captured', status2: 'Authorized', fromDate: this.state.dateFrom, toDate: this.state.dateTo };
    } else {
      filter = { status1: this.state.report, fromDate: this.state.dateFrom, toDate: this.state.dateTo };
    }
    this.setState({ isLoading: true });
    await filterPayment(filter, this.props.system.user)
      .then((payments: Array<Payment>) => {
        this.setState({ paymentsFiltered: payments });
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
    this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    this.props.clearState();
  }
  private onReceiptClicked(row: Payment) {
    this.props.filterPaymentById({}, row.id);
    this.props.history.push(`/payments/${row.id}/receipt`);
  }

  private DataGrid = DataGrid<Payment>();

  render() {
    let filtred = this.state.paymentsFiltered as any;
    let date_string = null;
    const dataSend = filtred?.map((row: any) => {
      date_string = row.date.toString();
      date_string = moment(date_string).tz('America/New_York').format('MM/DD/YYYY hh:mm A z');
      let source = row.source;
      let name = source === 'phone' ? (row.firstName ? `Phone: ${row.firstName}` : `Phone: undefined`) : row.paymentItems[0]?.firstName + ' ' + row.paymentItems[0]?.lastName;
      return {
        id: row.id,
        date: date_string,
        name: name,
        source: row.source,
        referenceNumber: row.paymentItems[0]?.referenceNumber,
        createdById: row.createdBy ? row.createdBy.firstName + ' ' + row.createdBy.lastName : 'System',
        product: row.paymentItems.length > 0 && row.paymentItems[0].product && row.paymentItems[0].product.name,
        feeAmount: row.feeAmount,
        subTotal: row.subTotal,
        total: Number(row.subTotal),
        status: row.status,
        cardLast: row.cardLast,
        paidInJIS: row.paymentItems.length > 0 && row.paymentItems[0].paidInJIS,
        inJIS: row.paymentItems.length > 0 && row.paymentItems[0].inJIS,
      };
    });

    const reports = reportData?.map((report, index) => {
      return (
        <MenuItem key={index} className={styles.inputField} value={report.id}>
          {report.name}
        </MenuItem>
      );
    });

    let columns: Array<any> = [
      { name: 'date', title: 'Date' },
      {
        name: 'name',
        title: 'Name',
        getCellValue: (row: any, columnName: string) => {
          return mapperToUpperCase(row.name);
        },
      },
      { name: 'product', title: 'Product' },
      {
        name: 'status',
        title: 'Status',
        getCellValue: (row: any, columnName: string) => {
          return mapperStatusToStatusDisplay(row.status);
        },
      },
      {
        name: 'citation',
        title: 'Citation/Case',
        type: 'link',
        label: (row: any) => {
          return row.referenceNumber;
        },
        action: this.onReceiptClicked,
      },
      // { name: 'referenceNumber', title: 'Citation/Case' },
      { name: 'cardLast', title: 'Card Last 4 digits' },
      { name: 'createdById', title: 'Created by' },
      {
        name: 'source',
        title: 'Source',
        type: 'select',
        options: ['Counter', 'Website', 'Kiosk', 'Phone'],
        getCellValue: (row: any, columnName: string) => {
          return mapperToUpperCase(row.source);
        },
      },
      {
        name: 'total',
        title: 'Total',
        getCellValue: (row: any, columnName: string) => {
          return currencyFormatter.format(row.total as number);
        },
      },
      {
        name: 'inJIS',
        title: 'In JIS',
        type: 'bool',
        getCellValue: (row: any, columnName: string) => {
          return (
            <FormGroup>
              <Checkbox disabled checked={row.inJIS} />
            </FormGroup>
          );
        },
      },
      {
        name: 'paidInJIS',
        title: 'Paid in JIS',
        type: 'bool',
        getCellValue: (row: any, columnName: string) => {
          return (
            <FormGroup>
              <Checkbox disabled checked={row.paidInJIS} />
            </FormGroup>
          );
        },
      },
      {
        name: 'declineButton',
        title: 'Decline',
        type: 'button',
        label: () => 'Decline',
        action: this.onDeclinedClicked,
      },
      {
        name: 'approveButton',
        title: 'Approve',
        type: 'button',
        label: () => 'Approve',
        action: this.onApproveClicked,
      },
    ];

    const exportColumns = columns.filter((column) => column.type !== 'bool');

    return (
      <>
        <CardHeader className={styles.titleHeader} title="Reports" />
        <Card className={styles.cardContainer}>
          <Card className={styles.cardContainer}>
            <div className={styles.dateContainer}>
              <FormControl variant="outlined" required className={styles.dateInput}>
                <InputLabel id="reportLabel">Choose report</InputLabel>
                <Select labelId="reportLabel" label="Choose Report" id="reportId" name="reportId" required onChange={(e) => this.handleChange(e)} variant="outlined" value={this.state.report}>
                  {reports}
                </Select>
              </FormControl>

              <KeyboardDatePicker
                className={styles.dateInput}
                disableToolbar
                variant="dialog"
                inputVariant="outlined"
                format="MM/DD/YYYY"
                margin="normal"
                id="DateFrom"
                label={'Date from'}
                value={this.state.dateFrom}
                onChange={(date) => this.setState({ dateFrom: date })}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                className={styles.dateInput}
                disableToolbar
                variant="dialog"
                inputVariant="outlined"
                format="MM/DD/YYYY"
                margin="normal"
                id="dateTo"
                label={'Date To'}
                value={this.state.dateTo}
                onChange={(date) => this.setState({ dateTo: date })}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <Button variant="contained" color="primary" className={styles.dateInput} onClick={this.onUpdateClick}>
                Update
              </Button>
            </div>
          </Card>
          <div className={styles.gridContainer}>
            <this.DataGrid
              preferenceGroup={'Payments'}
              cardTitle={'Payments'}
              rows={dataSend ? dataSend : []}
              totalRows={dataSend ? dataSend.length : 10}
              columns={columns}
              exportColumns={exportColumns}
              pageSizes={[25, 50, 100, 250, 1000]}
              loading={this.state.isLoading}
              searchEnable={true}
              hiddenColumnNames={this.state.c}
              disableColumnSelection={false}
              uncontrolledColumnFiltering
              uncontrolledColumnSorting
              disableColumnChooser
              columnWidthsGrid={180}
            />
          </div>
        </Card>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageReports));
