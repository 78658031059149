/* tslint:disable */
/* eslint-disable */
/**
 * Card Network API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * A payment id
 * @export
 * @interface ApprovePayment
 */
export interface ApprovePayment {
    /**
     * 
     * @type {number}
     * @memberof ApprovePayment
     */
    paymentId: number;
}
/**
 * 
 * @export
 * @interface BaseEntity
 */
export interface BaseEntity {
    /**
     * Internal identifier of entity.
     * @type {number}
     * @memberof BaseEntity
     */
    id?: number;
}
/**
 * Batch
 * @export
 * @interface Batch
 */
export interface Batch {
    /**
     * 
     * @type {number}
     * @memberof Batch
     */
    batchId: number;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    settlementTimeUTC: string;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    settlementTimeLocal: string;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    settlementState: string;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    paymentMethod: string;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    marketType: string;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    product: string;
}
/**
 * BillinInformation
 * @export
 * @interface BillinInformation
 */
export interface BillinInformation {
    /**
     * Swiped
     * @type {boolean}
     * @memberof BillinInformation
     */
    isSwiped: boolean;
    /**
     * Swiped
     * @type {string}
     * @memberof BillinInformation
     */
    swipedNumber?: string;
    /**
     * track1
     * @type {string}
     * @memberof BillinInformation
     */
    track1?: string;
    /**
     * track2
     * @type {string}
     * @memberof BillinInformation
     */
    track2?: string;
    /**
     * 
     * @type {CardInformation}
     * @memberof BillinInformation
     */
    cardInformation?: CardInformation;
}
/**
 * BillinInformationWebsite
 * @export
 * @interface BillinInformationWebsite
 */
export interface BillinInformationWebsite {
    /**
     * First name
     * @type {string}
     * @memberof BillinInformationWebsite
     */
    firstName: string;
    /**
     * Last name
     * @type {string}
     * @memberof BillinInformationWebsite
     */
    lastName: string;
    /**
     * Address
     * @type {string}
     * @memberof BillinInformationWebsite
     */
    address: string;
    /**
     * City
     * @type {string}
     * @memberof BillinInformationWebsite
     */
    city: string;
    /**
     * state
     * @type {string}
     * @memberof BillinInformationWebsite
     */
    state: string;
    /**
     * zip
     * @type {string}
     * @memberof BillinInformationWebsite
     */
    zip: string;
    /**
     * country
     * @type {string}
     * @memberof BillinInformationWebsite
     */
    country: string;
}
/**
 * Cancel a payment
 * @export
 * @interface CancelPayment
 */
export interface CancelPayment {
    /**
     * 
     * @type {number}
     * @memberof CancelPayment
     */
    paymentId: number;
}
/**
 * A Transaction Detail
 * @export
 * @interface CapturepaymentModel
 */
export interface CapturepaymentModel {
    /**
     * 
     * @type {number}
     * @memberof CapturepaymentModel
     */
    paymentId: number;
}
/**
 * CardInformation
 * @export
 * @interface CardInformation
 */
export interface CardInformation {
    /**
     * credit card number 
     * @type {string}
     * @memberof CardInformation
     */
    cardNumber: string;
    /**
     * Expiration date
     * @type {string}
     * @memberof CardInformation
     */
    expiration: string;
    /**
     * cvv
     * @type {string}
     * @memberof CardInformation
     */
    cvv: string;
}
/**
 * ChangeLogin.
 * @export
 * @interface ChangeLogin
 */
export interface ChangeLogin {
    /**
     * Organization id
     * @type {number}
     * @memberof ChangeLogin
     */
    organization: number;
}
/**
 * A Transaction Detail
 * @export
 * @interface CitationCaseDetailRequest
 */
export interface CitationCaseDetailRequest {
    /**
     * 
     * @type {number}
     * @memberof CitationCaseDetailRequest
     */
    court: number;
    /**
     * 
     * @type {string}
     * @memberof CitationCaseDetailRequest
     */
    caseNumber: string;
    /**
     * 
     * @type {number}
     * @memberof CitationCaseDetailRequest
     */
    amountDue?: number;
}
/**
 * Represents a post request for getting a fee by order total (amount).
 * @export
 * @interface ConfirmPaymentModel
 */
export interface ConfirmPaymentModel {
    /**
     * Payment Id
     * @type {number}
     * @memberof ConfirmPaymentModel
     */
    paymentId: number;
}
/**
 * ContactInformation
 * @export
 * @interface ContactInformation
 */
export interface ContactInformation {
    /**
     * Phone number
     * @type {string}
     * @memberof ContactInformation
     */
    phoneNumber: string;
    /**
     * email
     * @type {string}
     * @memberof ContactInformation
     */
    email: string;
    /**
     * First name
     * @type {boolean}
     * @memberof ContactInformation
     */
    sendConfirmation: boolean;
}
/**
 * A payment for a court.
 * @export
 * @interface CounterPaymentModel
 */
export interface CounterPaymentModel {
    /**
     * Identifier of payment
     * @type {number}
     * @memberof CounterPaymentModel
     */
    id?: number;
    /**
     * Identifier of organization this payment is being made for.
     * @type {number}
     * @memberof CounterPaymentModel
     */
    orgId?: number;
    /**
     * Identifier of organization this payment is being made for.
     * @type {string}
     * @memberof CounterPaymentModel
     */
    source: string;
    /**
     * 
     * @type {Array<MakePaymentItem>}
     * @memberof CounterPaymentModel
     */
    paymentItems: Array<MakePaymentItem>;
    /**
     * 
     * @type {string}
     * @memberof CounterPaymentModel
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof CounterPaymentModel
     */
    feeProcessing?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterPaymentModel
     */
    subTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterPaymentModel
     */
    total?: number;
    /**
     * 
     * @type {BillinInformation}
     * @memberof CounterPaymentModel
     */
    billingInformation?: BillinInformation;
}
/**
 * Court
 * @export
 * @interface Court
 */
export interface Court {
    /**
     * 
     * @type {string}
     * @memberof Court
     */
    courtCode: string;
    /**
     * 
     * @type {string}
     * @memberof Court
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Court
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Court
     */
    courtTenants: Array<string>;
}
/**
 * CourtKeyModel
 * @export
 * @interface CourtKeyModel
 */
export interface CourtKeyModel {
    /**
     * 
     * @type {number}
     * @memberof CourtKeyModel
     */
    courtKey: number;
    /**
     * 
     * @type {string}
     * @memberof CourtKeyModel
     */
    courtName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourtKeyModel
     */
    courtTenants: Array<string>;
}
/**
 * A payment for a court.
 * @export
 * @interface CourtPayment
 */
export interface CourtPayment {
    /**
     * Identifier of organization this payment is being made for.
     * @type {number}
     * @memberof CourtPayment
     */
    orgId: number;
    /**
     * Citation number.
     * @type {string}
     * @memberof CourtPayment
     */
    citationNumber: string;
    /**
     * Drivers liscense number.
     * @type {string}
     * @memberof CourtPayment
     */
    driversLiscense?: string;
    /**
     * Vehicle plate number.
     * @type {string}
     * @memberof CourtPayment
     */
    vehiclePlate?: string;
    /**
     * Customer first name.
     * @type {string}
     * @memberof CourtPayment
     */
    customerFirstName?: string;
    /**
     * Customer last name.
     * @type {string}
     * @memberof CourtPayment
     */
    customerLastName?: string;
    /**
     * Product customer is paying for.
     * @type {number}
     * @memberof CourtPayment
     */
    productId: number;
    /**
     * Product amount if product is variable price.
     * @type {string}
     * @memberof CourtPayment
     */
    amount?: string;
    /**
     * Track data if card is swiped.
     * @type {string}
     * @memberof CourtPayment
     */
    cardSwipeData?: string;
    /**
     * Card number if card not present.
     * @type {string}
     * @memberof CourtPayment
     */
    cardNumber?: string;
    /**
     * Card expiration if card not present.
     * @type {string}
     * @memberof CourtPayment
     */
    cardExpiration?: string;
    /**
     * Card cvc if card not present.
     * @type {string}
     * @memberof CourtPayment
     */
    cardCVC?: string;
}
/**
 * CourtTenant
 * @export
 * @interface CourtTenant
 */
export interface CourtTenant {
    /**
     * 
     * @type {string}
     * @memberof CourtTenant
     */
    courtTenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourtTenant
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CourtTenant
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof CourtTenant
     */
    areaCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CourtTenant
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CourtTenant
     */
    timezone?: string;
}
/**
 * CourtTenentInfo
 * @export
 * @interface CourtTenentInfo
 */
export interface CourtTenentInfo {
    /**
     * 
     * @type {string}
     * @memberof CourtTenentInfo
     */
    courtTenantId: string;
    /**
     * 
     * @type {string}
     * @memberof CourtTenentInfo
     */
    name: string;
}
/**
 * A payment for a court.
 * @export
 * @interface EditCounterPayment
 */
export interface EditCounterPayment {
    /**
     * Identifier of payment
     * @type {number}
     * @memberof EditCounterPayment
     */
    id?: number;
    /**
     * Identifier of organization this payment is being made for.
     * @type {string}
     * @memberof EditCounterPayment
     */
    source: string;
    /**
     * 
     * @type {Array<EditPaymentItem>}
     * @memberof EditCounterPayment
     */
    paymentItems: Array<EditPaymentItem>;
}
/**
 * 
 * @export
 * @interface EditPaymentItem
 */
export interface EditPaymentItem {
    /**
     * 
     * @type {number}
     * @memberof EditPaymentItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EditPaymentItem
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof EditPaymentItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof EditPaymentItem
     */
    lastName: string;
    /**
     * 
     * @type {number}
     * @memberof EditPaymentItem
     */
    amount: number;
}
/**
 * A payment for a court.
 * @export
 * @interface EditWebsitePayment
 */
export interface EditWebsitePayment {
    /**
     * Identifier of payment
     * @type {number}
     * @memberof EditWebsitePayment
     */
    id?: number;
    /**
     * Identifier of organization this payment is being made for.
     * @type {string}
     * @memberof EditWebsitePayment
     */
    source: string;
    /**
     * 
     * @type {EditPaymentItem}
     * @memberof EditWebsitePayment
     */
    paymentItem: EditPaymentItem;
}
/**
 * Entity get request.
 * @export
 * @interface EntityGetRequest
 */
export interface EntityGetRequest {
    /**
     * Paging number to skip from start of results.
     * @type {number}
     * @memberof EntityGetRequest
     */
    skip?: number;
    /**
     * Paging number to take after skip of results.
     * @type {number}
     * @memberof EntityGetRequest
     */
    take?: number;
    /**
     * Sort Options
     * @type {string}
     * @memberof EntityGetRequest
     */
    sortOptions?: string;
    /**
     * Filter Options
     * @type {string}
     * @memberof EntityGetRequest
     */
    filterOptions?: string;
    /**
     * Should we load related objects?
     * @type {boolean}
     * @memberof EntityGetRequest
     */
    eagerRelations?: boolean;
    /**
     * Source
     * @type {string}
     * @memberof EntityGetRequest
     */
    source?: string;
    /**
     * Id of entity to delete.
     * @type {number}
     * @memberof EntityGetRequest
     */
    id?: number;
}
/**
 * Id and display name used for combo boxes and searches.
 * @export
 * @interface EntityNameId
 */
export interface EntityNameId {
    /**
     * Id of entity
     * @type {number}
     * @memberof EntityNameId
     */
    id?: number;
    /**
     * Display name of entity
     * @type {string}
     * @memberof EntityNameId
     */
    name: string;
}
/**
 * An Event
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * Id of event
     * @type {number}
     * @memberof Event
     */
    id?: number;
    /**
     * Name of the entity this event is for
     * @type {string}
     * @memberof Event
     */
    entityName: string;
    /**
     * Internal identifier of entity this event is for
     * @type {number}
     * @memberof Event
     */
    entityId: number;
    /**
     * JSON payload value of this event
     * @type {string}
     * @memberof Event
     */
    value: string;
    /**
     * Type of this event
     * @type {string}
     * @memberof Event
     */
    type: string;
    /**
     * Id of user that was responsible for this events creation.
     * @type {number}
     * @memberof Event
     */
    userId: number;
    /**
     * 
     * @type {User}
     * @memberof Event
     */
    user?: User;
}
/**
 * Civ Pay fees that are charges for using or services
 * @export
 * @interface Fee
 */
export interface Fee {
    /**
     * Id
     * @type {number}
     * @memberof Fee
     */
    id?: number;
    /**
     * source of fee. such as \"counter\", \"kiosk\", etc..
     * @type {string}
     * @memberof Fee
     */
    source: string;
    /**
     * percentage fee. if number is not in between condition min and max, apply this fee
     * @type {number}
     * @memberof Fee
     */
    amountFixed: number;
    /**
     * Middle initial.
     * @type {number}
     * @memberof Fee
     */
    amountPercent?: number;
    /**
     * Min for this fee to apply
     * @type {number}
     * @memberof Fee
     */
    conditionMin: number;
    /**
     * Max for this fee to apply
     * @type {number}
     * @memberof Fee
     */
    conditionMax?: number;
    /**
     * 
     * @type {Organization}
     * @memberof Fee
     */
    organization?: Organization;
    /**
     * Id of organization this is related to.
     * @type {number}
     * @memberof Fee
     */
    organizationId?: number;
}
/**
 * Represents a post request for getting a fee by order total (amount).
 * @export
 * @interface FeeByAmountRequest
 */
export interface FeeByAmountRequest {
    /**
     * 
     * @type {Array<MakePaymentItem>}
     * @memberof FeeByAmountRequest
     */
    paymentItems: Array<MakePaymentItem>;
    /**
     * Id of organization that this fee is for
     * @type {number}
     * @memberof FeeByAmountRequest
     */
    organizationId?: number;
    /**
     * Id of organization that this fee is for
     * @type {string}
     * @memberof FeeByAmountRequest
     */
    urlIdentifier?: string;
    /**
     * where this payment is being made. Kiosk, website, etc.
     * @type {string}
     * @memberof FeeByAmountRequest
     */
    paymentSource: string;
}
/**
 * 
 * @export
 * @interface FilterOptions
 */
export interface FilterOptions {
    /**
     * Field to filter on
     * @type {string}
     * @memberof FilterOptions
     */
    field: string;
    /**
     * Value to filter by
     * @type {object}
     * @memberof FilterOptions
     */
    value: object;
    /**
     * Filter operation
     * @type {string}
     * @memberof FilterOptions
     */
    operation: string;
}
/**
 * Filter payment
 * @export
 * @interface FilterPaymentModel
 */
export interface FilterPaymentModel {
    /**
     * Payment id
     * @type {number}
     * @memberof FilterPaymentModel
     */
    id?: number;
    /**
     * Payment souce
     * @type {string}
     * @memberof FilterPaymentModel
     */
    source?: string;
    /**
     * Payment status
     * @type {string}
     * @memberof FilterPaymentModel
     */
    status1?: string;
    /**
     * Payment status
     * @type {string}
     * @memberof FilterPaymentModel
     */
    status2?: string;
    /**
     * Skip length
     * @type {number}
     * @memberof FilterPaymentModel
     */
    skip?: number;
    /**
     * Limit length
     * @type {number}
     * @memberof FilterPaymentModel
     */
    limit?: number;
    /**
     * Payment citation case
     * @type {string}
     * @memberof FilterPaymentModel
     */
    referenceNumber?: string;
}
/**
 * 
 * @export
 * @interface FindUser
 */
export interface FindUser {
    /**
     * 
     * @type {number}
     * @memberof FindUser
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FindUser
     */
    email?: string;
}
/**
 * Arguments for a forgot password request
 * @export
 * @interface ForgotConfirmPassword
 */
export interface ForgotConfirmPassword {
    /**
     * token
     * @type {string}
     * @memberof ForgotConfirmPassword
     */
    token: string;
    /**
     * password
     * @type {string}
     * @memberof ForgotConfirmPassword
     */
    password: string;
    /**
     * Confirm passowrd
     * @type {string}
     * @memberof ForgotConfirmPassword
     */
    confirmPassword: string;
}
/**
 * Arguments for a forgot password request
 * @export
 * @interface ForgotPassword
 */
export interface ForgotPassword {
    /**
     * email
     * @type {string}
     * @memberof ForgotPassword
     */
    email: string;
}
/**
 * Forgot Password Tokens
 * @export
 * @interface ForgotPasswordToken
 */
export interface ForgotPasswordToken {
    /**
     * Token received from mailgun for email validation
     * @type {string}
     * @memberof ForgotPasswordToken
     */
    token: string;
}
/**
 * An jis information by organization.
 * @export
 * @interface JisInformation
 */
export interface JisInformation {
    /**
     * Id of information
     * @type {number}
     * @memberof JisInformation
     */
    id?: number;
    /**
     * firstCourtId of organization.
     * @type {string}
     * @memberof JisInformation
     */
    firstCourtId?: string;
    /**
     * secondCourtId of organization.
     * @type {string}
     * @memberof JisInformation
     */
    secondCourtId?: string;
    /**
     * registerId of organization.
     * @type {string}
     * @memberof JisInformation
     */
    registerId?: string;
    /**
     * organizationId of organization.
     * @type {number}
     * @memberof JisInformation
     */
    organizationId?: number;
    /**
     * organizationId of organization.
     * @type {string}
     * @memberof JisInformation
     */
    courtName?: string;
}
/**
 * Represents an attempt at authentication with user credentials.
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * Email address of user. Used to identify user for login
     * @type {string}
     * @memberof Login
     */
    email: string;
    /**
     * Email address of user. Used to identify user for login
     * @type {string}
     * @memberof Login
     */
    password: string;
}
/**
 * Containst JWT reulting from a successful login.
 * @export
 * @interface LoginSuccess
 */
export interface LoginSuccess {
    /**
     * Internal identifier of user.
     * @type {number}
     * @memberof LoginSuccess
     */
    id: number;
    /**
     * Email address of user.
     * @type {string}
     * @memberof LoginSuccess
     */
    email: string;
    /**
     * JWT Token
     * @type {string}
     * @memberof LoginSuccess
     */
    token: string;
    /**
     * User first name.
     * @type {string}
     * @memberof LoginSuccess
     */
    firstName: string;
    /**
     * User last name.
     * @type {string}
     * @memberof LoginSuccess
     */
    lastName: string;
    /**
     * Users organization.
     * @type {number}
     * @memberof LoginSuccess
     */
    organizationId: number;
    /**
     * Organization user belongs to.
     * @type {Array<Organization>}
     * @memberof LoginSuccess
     */
    organizations: Array<Organization>;
    /**
     * 
     * @type {Organization}
     * @memberof LoginSuccess
     */
    organization?: Organization;
    /**
     * Roles user belongs to.
     * @type {Array<UserRole>}
     * @memberof LoginSuccess
     */
    roles?: Array<UserRole>;
}
/**
 * 
 * @export
 * @interface MakePayment
 */
export interface MakePayment {
    /**
     * 
     * @type {number}
     * @memberof MakePayment
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MakePayment
     */
    strategy: string;
    /**
     * 
     * @type {string}
     * @memberof MakePayment
     */
    source: string;
    /**
     * 
     * @type {Array<MakePaymentItem>}
     * @memberof MakePayment
     */
    paymentItems: Array<MakePaymentItem>;
    /**
     * 
     * @type {number}
     * @memberof MakePayment
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface MakePaymentItem
 */
export interface MakePaymentItem {
    /**
     * 
     * @type {number}
     * @memberof MakePaymentItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentItem
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof MakePaymentItem
     */
    liscenseNumber: string;
    /**
     * 
     * @type {number}
     * @memberof MakePaymentItem
     */
    productId: number;
    /**
     * 
     * @type {Product}
     * @memberof MakePaymentItem
     */
    product?: Product;
    /**
     * 
     * @type {number}
     * @memberof MakePaymentItem
     */
    amount: number;
    /**
     * 
     * @type {boolean}
     * @memberof MakePaymentItem
     */
    inJIS?: boolean;
}
/**
 * An organization represents a customer that is using our platform.
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * Id of event
     * @type {number}
     * @memberof Organization
     */
    id?: number;
    /**
     * Name of organization.
     * @type {string}
     * @memberof Organization
     */
    name?: string;
    /**
     * Description used on reciepts, etc.
     * @type {string}
     * @memberof Organization
     */
    description?: string;
    /**
     * Address line 1
     * @type {string}
     * @memberof Organization
     */
    addressLine1: string;
    /**
     * Address line 2
     * @type {string}
     * @memberof Organization
     */
    addressLine2?: string;
    /**
     * Address city
     * @type {string}
     * @memberof Organization
     */
    city: string;
    /**
     * Address state
     * @type {string}
     * @memberof Organization
     */
    state: string;
    /**
     * Address zip
     * @type {string}
     * @memberof Organization
     */
    zip: string;
    /**
     * Contact phone number 1
     * @type {string}
     * @memberof Organization
     */
    phone1: string;
    /**
     * Contact fax number 1
     * @type {string}
     * @memberof Organization
     */
    fax1?: string;
    /**
     * Contact email
     * @type {string}
     * @memberof Organization
     */
    email: string;
    /**
     * Public website URL
     * @type {string}
     * @memberof Organization
     */
    website?: string;
    /**
     * IVR Phone Number
     * @type {string}
     * @memberof Organization
     */
    ivrPhone?: string;
    /**
     * Public website URL
     * @type {boolean}
     * @memberof Organization
     */
    ivrRepEnabled?: boolean;
    /**
     * Public website URL
     * @type {string}
     * @memberof Organization
     */
    ivrHoursText?: string;
    /**
     * IVR rep phone
     * @type {string}
     * @memberof Organization
     */
    ivrRepPhone?: string;
    /**
     * IVR hours enabled
     * @type {boolean}
     * @memberof Organization
     */
    ivrHoursEnabled?: boolean;
    /**
     * IVR voice name
     * @type {string}
     * @memberof Organization
     */
    ivrVoice?: string;
    /**
     * IVR welcome audio file
     * @type {string}
     * @memberof Organization
     */
    ivrWelcomeAudioFile?: string;
    /**
     * URI of logo
     * @type {string}
     * @memberof Organization
     */
    logo?: string;
    /**
     * Examples, customer Id, transaction Id...
     * @type {string}
     * @memberof Organization
     */
    referenceNumberLabel: string;
    /**
     * Value of url parameter used to identify the target organization for inbound customers.
     * @type {string}
     * @memberof Organization
     */
    urlIdentifier: string;
    /**
     * Are we in test mode?
     * @type {boolean}
     * @memberof Organization
     */
    testMode: boolean;
    /**
     * Does organization accept Visa?
     * @type {boolean}
     * @memberof Organization
     */
    ccAcceptVisa: boolean;
    /**
     * Do organization accept Master Card?
     * @type {boolean}
     * @memberof Organization
     */
    ccAcceptMasterCard: boolean;
    /**
     * Do organization accept American Express?
     * @type {boolean}
     * @memberof Organization
     */
    ccAcceptAmericanExpress: boolean;
    /**
     * Do organization accept Discover?
     * @type {boolean}
     * @memberof Organization
     */
    ccAcceptDiscover: boolean;
    /**
     * Does organization accept card swipes for card present transactions?
     * @type {boolean}
     * @memberof Organization
     */
    cardPresentSwipedEnabled: boolean;
    /**
     * What email addresses do we send the reveipts to if any?
     * @type {string}
     * @memberof Organization
     */
    sendReceiptToEmailAddresses?: string;
    /**
     * Do we authorize, or do we auth and capture?
     * @type {string}
     * @memberof Organization
     */
    settlementType: string;
    /**
     * Strategy identifier.
     * @type {string}
     * @memberof Organization
     */
    strategy: string;
    /**
     * products of this org.
     * @type {Array<Product>}
     * @memberof Organization
     */
    products?: Array<Product>;
    /**
     * payments of this org.
     * @type {Array<Payment>}
     * @memberof Organization
     */
    payments?: Array<Payment>;
    /**
     * court code of this org.
     * @type {string}
     * @memberof Organization
     */
    courtCode?: string;
    /**
     * useJis of this org.
     * @type {boolean}
     * @memberof Organization
     */
    useJis?: boolean;
    /**
     * Accept partial payment
     * @type {boolean}
     * @memberof Organization
     */
    acceptPartialPayment?: boolean;
    /**
     * Accept jis information.
     * @type {Array<JisInformation>}
     * @memberof Organization
     */
    jisInformation?: Array<JisInformation>;
}
/**
 * Paged results of armored carriers query.
 * @export
 * @interface PagedEventResults
 */
export interface PagedEventResults {
    /**
     * Count of results.
     * @type {number}
     * @memberof PagedEventResults
     */
    count: number;
    /**
     * Results.
     * @type {Array<Event>}
     * @memberof PagedEventResults
     */
    values: Array<Event>;
}
/**
 * Paged results of fee query.
 * @export
 * @interface PagedFeeResults
 */
export interface PagedFeeResults {
    /**
     * Count of results.
     * @type {number}
     * @memberof PagedFeeResults
     */
    count: number;
    /**
     * Results.
     * @type {Array<Fee>}
     * @memberof PagedFeeResults
     */
    values: Array<Fee>;
}
/**
 * Paged results of organization query.
 * @export
 * @interface PagedOrganizationResults
 */
export interface PagedOrganizationResults {
    /**
     * Count of results.
     * @type {number}
     * @memberof PagedOrganizationResults
     */
    count: number;
    /**
     * Results.
     * @type {Array<Organization>}
     * @memberof PagedOrganizationResults
     */
    values: Array<Organization>;
}
/**
 * Paged results of payment items query.
 * @export
 * @interface PagedPaymentItemResults
 */
export interface PagedPaymentItemResults {
    /**
     * Count of results.
     * @type {number}
     * @memberof PagedPaymentItemResults
     */
    count: number;
    /**
     * Results.
     * @type {Array<PaymentItem>}
     * @memberof PagedPaymentItemResults
     */
    values: Array<PaymentItem>;
}
/**
 * Paged results of payment query.
 * @export
 * @interface PagedPaymentResults
 */
export interface PagedPaymentResults {
    /**
     * Count of results.
     * @type {number}
     * @memberof PagedPaymentResults
     */
    count: number;
    /**
     * Results.
     * @type {Array<Payment>}
     * @memberof PagedPaymentResults
     */
    values: Array<Payment>;
}
/**
 * Paged results of products query.
 * @export
 * @interface PagedProductResults
 */
export interface PagedProductResults {
    /**
     * Count of results.
     * @type {number}
     * @memberof PagedProductResults
     */
    count: number;
    /**
     * Results.
     * @type {Array<Product>}
     * @memberof PagedProductResults
     */
    values: Array<Product>;
}
/**
 * A payment.
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * Internal identifier of entity.
     * @type {number}
     * @memberof Payment
     */
    id?: number;
    /**
     * 
     * @type {RecurringPayment}
     * @memberof Payment
     */
    recurringPayment?: RecurringPayment;
    /**
     * Id of recurring payment
     * @type {number}
     * @memberof Payment
     */
    recurringPaymentId?: number;
    /**
     * Payment status recieved from gateway.
     * @type {string}
     * @memberof Payment
     */
    paymentStatusFromGateway: string;
    /**
     * Identifier of payment on gatway.
     * @type {string}
     * @memberof Payment
     */
    paymentReferenceFromGateway: string;
    /**
     * First name.
     * @type {string}
     * @memberof Payment
     */
    firstName: string;
    /**
     * Last name.
     * @type {string}
     * @memberof Payment
     */
    lastName: string;
    /**
     * Middle initial.
     * @type {string}
     * @memberof Payment
     */
    middleInitial?: string;
    /**
     * Address line 1.
     * @type {string}
     * @memberof Payment
     */
    addressLine1: string;
    /**
     * Address Line 2 (optional).
     * @type {string}
     * @memberof Payment
     */
    addressLine2?: string;
    /**
     * City.
     * @type {string}
     * @memberof Payment
     */
    city: string;
    /**
     * State.
     * @type {string}
     * @memberof Payment
     */
    state: string;
    /**
     * Zip.
     * @type {string}
     * @memberof Payment
     */
    zip: string;
    /**
     * Payment gatway used to process order.
     * @type {string}
     * @memberof Payment
     */
    gateway?: string;
    /**
     * Products.
     * @type {Array<string>}
     * @memberof Payment
     */
    orderProducts?: Array<string>;
    /**
     * Id of organization.
     * @type {number}
     * @memberof Payment
     */
    organizationId?: number;
    /**
     * message from gateway.
     * @type {string}
     * @memberof Payment
     */
    message?: string;
    /**
     * Total.
     * @type {number}
     * @memberof Payment
     */
    subTotal?: number;
    /**
     * Total.
     * @type {number}
     * @memberof Payment
     */
    feeAmount?: number;
    /**
     * JSON object that contains details about the payment.
     * @type {object}
     * @memberof Payment
     */
    details?: object;
    /**
     * status payment.
     * @type {string}
     * @memberof Payment
     */
    status?: string;
    /**
     * source payment.
     * @type {string}
     * @memberof Payment
     */
    source?: string;
    /**
     * PaymentItems
     * @type {Array<string>}
     * @memberof Payment
     */
    paymentItems?: Array<string>;
    /**
     * createdBy
     * @type {number}
     * @memberof Payment
     */
    createdById?: number;
    /**
     * cardLast
     * @type {string}
     * @memberof Payment
     */
    cardLast?: string;
    /**
     * IvrCallSid
     * @type {string}
     * @memberof Payment
     */
    ivrCallSid?: string;
    /**
     * ivrBillingPhone
     * @type {string}
     * @memberof Payment
     */
    ivrBillingPhone?: string;
}
/**
 * An item on a payment.
 * @export
 * @interface PaymentItem
 */
export interface PaymentItem {
    /**
     * Id of order product
     * @type {number}
     * @memberof PaymentItem
     */
    id?: number;
    /**
     * Quantity of product on order.
     * @type {number}
     * @memberof PaymentItem
     */
    quantity: number;
    /**
     * Name 
     * @type {string}
     * @memberof PaymentItem
     */
    firstName: string;
    /**
     * Lastname
     * @type {string}
     * @memberof PaymentItem
     */
    lastName: string;
    /**
     * Amount
     * @type {number}
     * @memberof PaymentItem
     */
    amount: number;
    /**
     * 
     * @type {Product}
     * @memberof PaymentItem
     */
    product?: Product;
    /**
     * Id of product on this payment item.
     * @type {number}
     * @memberof PaymentItem
     */
    productId?: number;
    /**
     * 
     * @type {Payment}
     * @memberof PaymentItem
     */
    payment?: Payment;
    /**
     * payment that this item is on.
     * @type {string}
     * @memberof PaymentItem
     */
    paymentReferenceFromGateway?: string;
    /**
     * payment that this item is on.
     * @type {string}
     * @memberof PaymentItem
     */
    paymentStatusFromGateway?: string;
    /**
     * Id of payment this item is on
     * @type {number}
     * @memberof PaymentItem
     */
    paymentId?: number;
    /**
     * Id of organization.
     * @type {number}
     * @memberof PaymentItem
     */
    organizationId?: number;
    /**
     * Id of organization.
     * @type {string}
     * @memberof PaymentItem
     */
    referenceNumber?: string;
    /**
     * Status of payment
     * @type {boolean}
     * @memberof PaymentItem
     */
    approved?: boolean;
    /**
     * Liscense number
     * @type {string}
     * @memberof PaymentItem
     */
    liscenseNumber?: string;
    /**
     * Is in JIS
     * @type {boolean}
     * @memberof PaymentItem
     */
    inJIS?: boolean;
    /**
     * paid in JIS
     * @type {boolean}
     * @memberof PaymentItem
     */
    paidInJIS?: boolean;
}
/**
 * A product defined for an organazition to be payed for.
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * Id of product
     * @type {number}
     * @memberof Product
     */
    id?: number;
    /**
     * Product name.
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * Product descripton.
     * @type {string}
     * @memberof Product
     */
    description?: string;
    /**
     * Price of product.
     * @type {number}
     * @memberof Product
     */
    price?: number;
    /**
     * Boolean if this products price can be changed.
     * @type {boolean}
     * @memberof Product
     */
    variablePrice: boolean;
    /**
     * Id of organization.
     * @type {number}
     * @memberof Product
     */
    organizationId?: number;
    /**
     * show in counter view.
     * @type {boolean}
     * @memberof Product
     */
    isCounter?: boolean;
    /**
     * show in web view
     * @type {boolean}
     * @memberof Product
     */
    isWeb?: boolean;
    /**
     * show in counter view.
     * @type {boolean}
     * @memberof Product
     */
    is_default?: boolean;
}
/**
 * Arguments for a receipt email request
 * @export
 * @interface ReceiptEmailArgs
 */
export interface ReceiptEmailArgs {
    /**
     * email
     * @type {string}
     * @memberof ReceiptEmailArgs
     */
    email: string;
    /**
     * payment confirmation number from processor
     * @type {string}
     * @memberof ReceiptEmailArgs
     */
    confirmationNumber: string;
}
/**
 * Recurring Payments
 * @export
 * @interface RecurringPayment
 */
export interface RecurringPayment {
    /**
     * Id of recurring payment
     * @type {number}
     * @memberof RecurringPayment
     */
    id?: number;
    /**
     * Name of this subscription. Example: Bail payment, ticket payment, etc.
     * @type {string}
     * @memberof RecurringPayment
     */
    name: string;
    /**
     * For a unit of days, use an integer between 7 and 365, inclusive. For a unit of months, use an integer between 1 and 12
     * @type {number}
     * @memberof RecurringPayment
     */
    scheduleLength: number;
    /**
     * Unit of this schedule. months or days
     * @type {string}
     * @memberof RecurringPayment
     */
    scheduleUnit: string;
    /**
     * total amount of payments that will be made
     * @type {number}
     * @memberof RecurringPayment
     */
    totalPayments: number;
    /**
     * amount to be charged each time a payment is processed
     * @type {number}
     * @memberof RecurringPayment
     */
    amount: number;
    /**
     * card number to be charges
     * @type {string}
     * @memberof RecurringPayment
     */
    cardNumber: string;
    /**
     * expiration date for the card
     * @type {string}
     * @memberof RecurringPayment
     */
    expirationDate: string;
    /**
     * first name for billing
     * @type {string}
     * @memberof RecurringPayment
     */
    firstName: string;
    /**
     * last name for billing
     * @type {string}
     * @memberof RecurringPayment
     */
    lastName: string;
    /**
     * last name for billing
     * @type {string}
     * @memberof RecurringPayment
     */
    authorizeRefId: string;
    /**
     * 
     * @type {Organization}
     * @memberof RecurringPayment
     */
    organization: Organization;
    /**
     * Id of organization this is related to.
     * @type {number}
     * @memberof RecurringPayment
     */
    organizationId?: number;
}
/**
 * Reference number
 * @export
 * @interface ReferenceNumberModel
 */
export interface ReferenceNumberModel {
    /**
     * 
     * @type {string}
     * @memberof ReferenceNumberModel
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceNumberModel
     */
    urlIdentifier: string;
}
/**
 * A Transaction Detail
 * @export
 * @interface SettledBatchesRequest
 */
export interface SettledBatchesRequest {
    /**
     * 
     * @type {number}
     * @memberof SettledBatchesRequest
     */
    productId: number;
    /**
     * 
     * @type {string}
     * @memberof SettledBatchesRequest
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof SettledBatchesRequest
     */
    to: string;
}
/**
 * 
 * @export
 * @interface SortOptions
 */
export interface SortOptions {
    /**
     * Field to sort by
     * @type {string}
     * @memberof SortOptions
     */
    field: string;
    /**
     * Order to sort by
     * @type {string}
     * @memberof SortOptions
     */
    direction: string;
}
/**
 * A Transaction Detail
 * @export
 * @interface TransactionDetailResponse
 */
export interface TransactionDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof TransactionDetailResponse
     */
    court?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    self?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    kind?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    caseDocketId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    courtTenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    caseYear?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    caseNumber: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    caseTypeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    caseType?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    caseTypeLink?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    entitlement?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    caseStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    casePublicStatus?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionDetailResponse
     */
    participants?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    participantsLink?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    judgeName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    judge?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    judgeLink?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    prosecutor?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    prosecutorLink?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    referee?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    refereeLink?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    fileDate?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionDetailResponse
     */
    amountDue?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionDetailResponse
     */
    amountPaid?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    issuingAgency?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    issuingOfficial?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    issuingAgencySecond?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    issuingOfficialSecond?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionDetailResponse
     */
    offenseLocation?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    accidentType?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    victim?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    criminalTrackingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    policeIncidentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    transactionControlNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    crossReferenceNumber?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionDetailResponse
     */
    charges?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    chargesLink?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    nextHearing?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    nextHearingLink?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    caseAge?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    caseAgeLink?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionDetailResponse
     */
    petitions?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    petitionsLink?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    warrantStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    probationStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    probationOfficer?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    probationOfficerNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    prisonCase?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    juryDemandFiled?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    omitFromCaseload?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    modifiedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    disposition?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    dispositionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    closedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailResponse
     */
    caseTrackingId?: string;
}
/**
 * User
 * @export
 * @interface User
 */
export interface User {
    /**
     * Internal identifier
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * Email address of user. Used to identify user for login
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * Password. Used to identify user for login
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * First name of user.
     * @type {string}
     * @memberof User
     */
    firstName: string;
    /**
     * First name of user.
     * @type {string}
     * @memberof User
     */
    lastName: string;
    /**
     * Indicates whether or not a user can login
     * @type {boolean}
     * @memberof User
     */
    active?: boolean;
    /**
     * Roles user belongs to.
     * @type {Array<UserRole>}
     * @memberof User
     */
    roles?: Array<UserRole>;
    /**
     * Id of organization user belongs to.
     * @type {number}
     * @memberof User
     */
    organizationId?: number;
    /**
     * 
     * @type {Organization}
     * @memberof User
     */
    organization?: Organization;
    /**
     * Organization user belongs to.
     * @type {Array<string>}
     * @memberof User
     */
    organizations?: Array<string>;
}
/**
 * A role to be associated to a user. This determines their access to API resources.
 * @export
 * @interface UserRole
 */
export interface UserRole {
    /**
     * Internal identifier
     * @type {number}
     * @memberof UserRole
     */
    id: number;
    /**
     * Role name.
     * @type {string}
     * @memberof UserRole
     */
    name: string;
    /**
     * Human friendly name.
     * @type {string}
     * @memberof UserRole
     */
    displayName: string;
    /**
     * Users with this role.
     * @type {Array<User>}
     * @memberof UserRole
     */
    users?: Array<User>;
}
/**
 * A payment for a court.
 * @export
 * @interface WebsitePaymentModel
 */
export interface WebsitePaymentModel {
    /**
     * Identifier of payment
     * @type {number}
     * @memberof WebsitePaymentModel
     */
    id?: number;
    /**
     * Identifier of organization this payment is being made for.
     * @type {number}
     * @memberof WebsitePaymentModel
     */
    orgId?: number;
    /**
     * Identifier of organization this payment is being made for.
     * @type {string}
     * @memberof WebsitePaymentModel
     */
    source: string;
    /**
     * 
     * @type {MakePaymentItem}
     * @memberof WebsitePaymentModel
     */
    paymentItem: MakePaymentItem;
    /**
     * 
     * @type {string}
     * @memberof WebsitePaymentModel
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof WebsitePaymentModel
     */
    feeProcessing?: number;
    /**
     * 
     * @type {BillinInformationWebsite}
     * @memberof WebsitePaymentModel
     */
    billingInformation?: BillinInformationWebsite;
    /**
     * 
     * @type {ContactInformation}
     * @memberof WebsitePaymentModel
     */
    contactInformation?: ContactInformation;
    /**
     * 
     * @type {CardInformation}
     * @memberof WebsitePaymentModel
     */
    cardInformation?: CardInformation;
    /**
     * 
     * @type {number}
     * @memberof WebsitePaymentModel
     */
    subTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof WebsitePaymentModel
     */
    total?: number;
    /**
     * 
     * @type {string}
     * @memberof WebsitePaymentModel
     */
    urlIdentifier: string;
}

/**
 * CronJobsApi - axios parameter creator
 * @export
 */
export const CronJobsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Clear phone payment faileds
         * @summary Clear phone payment faileds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearUnderpayments: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cron/clearUnderpayments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate payments
         * @summary Validate payments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTokenJIS: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cron/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Load court key to cache
         * @summary Load court key to cache.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCourtKeys: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cron/courtKeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate payments
         * @summary Validate payments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePaymentsInJis: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cron`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CronJobsApi - functional programming interface
 * @export
 */
export const CronJobsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CronJobsApiAxiosParamCreator(configuration)
    return {
        /**
         * Clear phone payment faileds
         * @summary Clear phone payment faileds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearUnderpayments(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearUnderpayments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validate payments
         * @summary Validate payments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTokenJIS(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTokenJIS(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Load court key to cache
         * @summary Load court key to cache.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadCourtKeys(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadCourtKeys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validate payments
         * @summary Validate payments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePaymentsInJis(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePaymentsInJis(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CronJobsApi - factory interface
 * @export
 */
export const CronJobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CronJobsApiFp(configuration)
    return {
        /**
         * Clear phone payment faileds
         * @summary Clear phone payment faileds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearUnderpayments(options?: any): AxiosPromise<void> {
            return localVarFp.clearUnderpayments(options).then((request) => request(axios, basePath));
        },
        /**
         * Validate payments
         * @summary Validate payments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTokenJIS(options?: any): AxiosPromise<void> {
            return localVarFp.generateTokenJIS(options).then((request) => request(axios, basePath));
        },
        /**
         * Load court key to cache
         * @summary Load court key to cache.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCourtKeys(options?: any): AxiosPromise<void> {
            return localVarFp.loadCourtKeys(options).then((request) => request(axios, basePath));
        },
        /**
         * Validate payments
         * @summary Validate payments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePaymentsInJis(options?: any): AxiosPromise<void> {
            return localVarFp.validatePaymentsInJis(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CronJobsApi - object-oriented interface
 * @export
 * @class CronJobsApi
 * @extends {BaseAPI}
 */
export class CronJobsApi extends BaseAPI {
    /**
     * Clear phone payment faileds
     * @summary Clear phone payment faileds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronJobsApi
     */
    public clearUnderpayments(options?: any) {
        return CronJobsApiFp(this.configuration).clearUnderpayments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate payments
     * @summary Validate payments.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronJobsApi
     */
    public generateTokenJIS(options?: any) {
        return CronJobsApiFp(this.configuration).generateTokenJIS(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Load court key to cache
     * @summary Load court key to cache.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronJobsApi
     */
    public loadCourtKeys(options?: any) {
        return CronJobsApiFp(this.configuration).loadCourtKeys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate payments
     * @summary Validate payments.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronJobsApi
     */
    public validatePaymentsInJis(options?: any) {
        return CronJobsApiFp(this.configuration).validatePaymentsInJis(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create an event
         * @summary Takes in whole event model
         * @param {Event} body whole event model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (body: Event, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('create', 'body', body)
            const localVarPath = `/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get events
         * @summary Get events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an event
         * @summary Takes in whole event model
         * @param {Event} body whole event model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (body: Event, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('update', 'body', body)
            const localVarPath = `/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
    return {
        /**
         * create an event
         * @summary Takes in whole event model
         * @param {Event} body whole event model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(body: Event, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get events
         * @summary Get events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedEventResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an event
         * @summary Takes in whole event model
         * @param {Event} body whole event model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(body: Event, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventApiFp(configuration)
    return {
        /**
         * create an event
         * @summary Takes in whole event model
         * @param {Event} body whole event model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(body: Event, options?: any): AxiosPromise<void> {
            return localVarFp.create(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get events
         * @summary Get events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: any): AxiosPromise<PagedEventResults> {
            return localVarFp.get(options).then((request) => request(axios, basePath));
        },
        /**
         * Update an event
         * @summary Takes in whole event model
         * @param {Event} body whole event model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(body: Event, options?: any): AxiosPromise<void> {
            return localVarFp.update(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * create an event
     * @summary Takes in whole event model
     * @param {Event} body whole event model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public create(body: Event, options?: any) {
        return EventApiFp(this.configuration).create(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get events
     * @summary Get events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public get(options?: any) {
        return EventApiFp(this.configuration).get(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an event
     * @summary Takes in whole event model
     * @param {Event} body whole event model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public update(body: Event, options?: any) {
        return EventApiFp(this.configuration).update(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeeApi - axios parameter creator
 * @export
 */
export const FeeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Soft delete a fee.
         * @param {string} xAuthToken 
         * @param {number} id Id of fee to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (xAuthToken: string, id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('_delete', 'xAuthToken', xAuthToken)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/fee`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a fee
         * @summary Takes in whole fee model
         * @param {string} xAuthToken 
         * @param {Fee} body whole fee model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (xAuthToken: string, body: Fee, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('create', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('create', 'body', body)
            const localVarPath = `/fee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get fees
         * @summary Get fees by organization Id.
         * @param {string} xAuthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (xAuthToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('get', 'xAuthToken', xAuthToken)
            const localVarPath = `/fee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a fee
         * @summary Takes in whole fee model
         * @param {string} xAuthToken 
         * @param {Fee} body whole fee model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (xAuthToken: string, body: Fee, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('update', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('update', 'body', body)
            const localVarPath = `/fee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeeApi - functional programming interface
 * @export
 */
export const FeeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeeApiAxiosParamCreator(configuration)
    return {
        /**
         * Soft delete a fee.
         * @param {string} xAuthToken 
         * @param {number} id Id of fee to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(xAuthToken: string, id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(xAuthToken, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a fee
         * @summary Takes in whole fee model
         * @param {string} xAuthToken 
         * @param {Fee} body whole fee model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(xAuthToken: string, body: Fee, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get fees
         * @summary Get fees by organization Id.
         * @param {string} xAuthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(xAuthToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedFeeResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(xAuthToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a fee
         * @summary Takes in whole fee model
         * @param {string} xAuthToken 
         * @param {Fee} body whole fee model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(xAuthToken: string, body: Fee, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeeApi - factory interface
 * @export
 */
export const FeeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeeApiFp(configuration)
    return {
        /**
         * Soft delete a fee.
         * @param {string} xAuthToken 
         * @param {number} id Id of fee to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(xAuthToken: string, id: number, options?: any): AxiosPromise<void> {
            return localVarFp._delete(xAuthToken, id, options).then((request) => request(axios, basePath));
        },
        /**
         * create a fee
         * @summary Takes in whole fee model
         * @param {string} xAuthToken 
         * @param {Fee} body whole fee model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(xAuthToken: string, body: Fee, options?: any): AxiosPromise<void> {
            return localVarFp.create(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get fees
         * @summary Get fees by organization Id.
         * @param {string} xAuthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(xAuthToken: string, options?: any): AxiosPromise<PagedFeeResults> {
            return localVarFp.get(xAuthToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a fee
         * @summary Takes in whole fee model
         * @param {string} xAuthToken 
         * @param {Fee} body whole fee model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(xAuthToken: string, body: Fee, options?: any): AxiosPromise<void> {
            return localVarFp.update(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeeApi - object-oriented interface
 * @export
 * @class FeeApi
 * @extends {BaseAPI}
 */
export class FeeApi extends BaseAPI {
    /**
     * Soft delete a fee.
     * @param {string} xAuthToken 
     * @param {number} id Id of fee to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeeApi
     */
    public _delete(xAuthToken: string, id: number, options?: any) {
        return FeeApiFp(this.configuration)._delete(xAuthToken, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a fee
     * @summary Takes in whole fee model
     * @param {string} xAuthToken 
     * @param {Fee} body whole fee model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeeApi
     */
    public create(xAuthToken: string, body: Fee, options?: any) {
        return FeeApiFp(this.configuration).create(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get fees
     * @summary Get fees by organization Id.
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeeApi
     */
    public get(xAuthToken: string, options?: any) {
        return FeeApiFp(this.configuration).get(xAuthToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a fee
     * @summary Takes in whole fee model
     * @param {string} xAuthToken 
     * @param {Fee} body whole fee model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeeApi
     */
    public update(xAuthToken: string, body: Fee, options?: any) {
        return FeeApiFp(this.configuration).update(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeeAmountApi - axios parameter creator
 * @export
 */
export const FeeAmountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Calculate fee proccesing
         * @summary Calculate fee proccesing
         * @param {FeeByAmountRequest} body whole fee model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFeeProccesing: async (body: FeeByAmountRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('calculateFeeProccesing', 'body', body)
            const localVarPath = `/feeAmount/calc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get fee amount
         * @summary Takes in whole fee model
         * @param {FeeByAmountRequest} body whole fee model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeeByAmount: async (body: FeeByAmountRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getFeeByAmount', 'body', body)
            const localVarPath = `/feeAmount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeeAmountApi - functional programming interface
 * @export
 */
export const FeeAmountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeeAmountApiAxiosParamCreator(configuration)
    return {
        /**
         * Calculate fee proccesing
         * @summary Calculate fee proccesing
         * @param {FeeByAmountRequest} body whole fee model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateFeeProccesing(body: FeeByAmountRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateFeeProccesing(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get fee amount
         * @summary Takes in whole fee model
         * @param {FeeByAmountRequest} body whole fee model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeeByAmount(body: FeeByAmountRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeeByAmount(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeeAmountApi - factory interface
 * @export
 */
export const FeeAmountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeeAmountApiFp(configuration)
    return {
        /**
         * Calculate fee proccesing
         * @summary Calculate fee proccesing
         * @param {FeeByAmountRequest} body whole fee model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFeeProccesing(body: FeeByAmountRequest, options?: any): AxiosPromise<void> {
            return localVarFp.calculateFeeProccesing(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get fee amount
         * @summary Takes in whole fee model
         * @param {FeeByAmountRequest} body whole fee model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeeByAmount(body: FeeByAmountRequest, options?: any): AxiosPromise<void> {
            return localVarFp.getFeeByAmount(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeeAmountApi - object-oriented interface
 * @export
 * @class FeeAmountApi
 * @extends {BaseAPI}
 */
export class FeeAmountApi extends BaseAPI {
    /**
     * Calculate fee proccesing
     * @summary Calculate fee proccesing
     * @param {FeeByAmountRequest} body whole fee model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeeAmountApi
     */
    public calculateFeeProccesing(body: FeeByAmountRequest, options?: any) {
        return FeeAmountApiFp(this.configuration).calculateFeeProccesing(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get fee amount
     * @summary Takes in whole fee model
     * @param {FeeByAmountRequest} body whole fee model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeeAmountApi
     */
    public getFeeByAmount(body: FeeByAmountRequest, options?: any) {
        return FeeAmountApiFp(this.configuration).getFeeByAmount(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ForgotPasswordApi - axios parameter creator
 * @export
 */
export const ForgotPasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Post forgot password
         * @summary Post user email to send email for forgot password
         * @param {ForgotPassword} body Forgot Password Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (body: ForgotPassword, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('forgotPassword', 'body', body)
            const localVarPath = `/auth/forgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST Validate Token
         * @summary POST to this route with the token in the body to validate it
         * @param {ForgotConfirmPassword} body Forgot Password Token Validation Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateToken: async (body: ForgotConfirmPassword, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('validateToken', 'body', body)
            const localVarPath = `/auth/forgotPasswordValidation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForgotPasswordApi - functional programming interface
 * @export
 */
export const ForgotPasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForgotPasswordApiAxiosParamCreator(configuration)
    return {
        /**
         * Post forgot password
         * @summary Post user email to send email for forgot password
         * @param {ForgotPassword} body Forgot Password Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(body: ForgotPassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST Validate Token
         * @summary POST to this route with the token in the body to validate it
         * @param {ForgotConfirmPassword} body Forgot Password Token Validation Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateToken(body: ForgotConfirmPassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateToken(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForgotPasswordApi - factory interface
 * @export
 */
export const ForgotPasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForgotPasswordApiFp(configuration)
    return {
        /**
         * Post forgot password
         * @summary Post user email to send email for forgot password
         * @param {ForgotPassword} body Forgot Password Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(body: ForgotPassword, options?: any): AxiosPromise<void> {
            return localVarFp.forgotPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * POST Validate Token
         * @summary POST to this route with the token in the body to validate it
         * @param {ForgotConfirmPassword} body Forgot Password Token Validation Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateToken(body: ForgotConfirmPassword, options?: any): AxiosPromise<void> {
            return localVarFp.validateToken(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForgotPasswordApi - object-oriented interface
 * @export
 * @class ForgotPasswordApi
 * @extends {BaseAPI}
 */
export class ForgotPasswordApi extends BaseAPI {
    /**
     * Post forgot password
     * @summary Post user email to send email for forgot password
     * @param {ForgotPassword} body Forgot Password Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgotPasswordApi
     */
    public forgotPassword(body: ForgotPassword, options?: any) {
        return ForgotPasswordApiFp(this.configuration).forgotPassword(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST Validate Token
     * @summary POST to this route with the token in the body to validate it
     * @param {ForgotConfirmPassword} body Forgot Password Token Validation Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgotPasswordApi
     */
    public validateToken(body: ForgotConfirmPassword, options?: any) {
        return ForgotPasswordApiFp(this.configuration).validateToken(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Login Organization
         * @summary Change organization session.
         * @param {string} xAuthToken 
         * @param {ChangeLogin} body Login attempt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOrganization: async (xAuthToken: string, body: ChangeLogin, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('changeOrganization', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('changeOrganization', 'body', body)
            const localVarPath = `/auth/login/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Post login
         * @summary Login as user with email and password.
         * @param {Login} body Login attempt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (body: Login, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('login', 'body', body)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * Login Organization
         * @summary Change organization session.
         * @param {string} xAuthToken 
         * @param {ChangeLogin} body Login attempt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeOrganization(xAuthToken: string, body: ChangeLogin, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeOrganization(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Post login
         * @summary Login as user with email and password.
         * @param {Login} body Login attempt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(body: Login, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * Login Organization
         * @summary Change organization session.
         * @param {string} xAuthToken 
         * @param {ChangeLogin} body Login attempt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOrganization(xAuthToken: string, body: ChangeLogin, options?: any): AxiosPromise<LoginSuccess> {
            return localVarFp.changeOrganization(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Post login
         * @summary Login as user with email and password.
         * @param {Login} body Login attempt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(body: Login, options?: any): AxiosPromise<LoginSuccess> {
            return localVarFp.login(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * Login Organization
     * @summary Change organization session.
     * @param {string} xAuthToken 
     * @param {ChangeLogin} body Login attempt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public changeOrganization(xAuthToken: string, body: ChangeLogin, options?: any) {
        return LoginApiFp(this.configuration).changeOrganization(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Post login
     * @summary Login as user with email and password.
     * @param {Login} body Login attempt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public login(body: Login, options?: any) {
        return LoginApiFp(this.configuration).login(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create
         * @param {string} xAuthToken 
         * @param {Organization} body Organization model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (xAuthToken: string, body: Organization, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('create', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('create', 'body', body)
            const localVarPath = `/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Organization by id
         * @summary Get organization by id.
         * @param {string} id 
         * @param {string} [xAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: async (id: string, xAuthToken?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findById', 'id', id)
            const localVarPath = `/organization/id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get organization by urlIdentifier
         * @summary Get organization by urlIdentifier.
         * @param {string} [urlIdentifier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUrlIdentifier: async (urlIdentifier?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/organization/urlidentifier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (urlIdentifier !== undefined) {
                localVarQueryParameter['urlIdentifier'] = urlIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find case in JIS
         * @summary Find case in JIS.
         * @param {string} xAuthToken 
         * @param {string} [_case] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCaseJIS: async (xAuthToken: string, _case?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('findCaseJIS', 'xAuthToken', xAuthToken)
            const localVarPath = `/organization/jis/caseNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_case !== undefined) {
                localVarQueryParameter['case'] = _case;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Organization
         * @summary Get all organizations.
         * @param {string} [xAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (xAuthToken?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get courts keys
         * @summary Get all courts keys.
         * @param {string} [xAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourtKey: async (xAuthToken?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/organization/courtsKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get courts
         * @summary Get all courts.
         * @param {string} name 
         * @param {string} [xAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourtsByName: async (name: string, xAuthToken?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getCourtsByName', 'name', name)
            const localVarPath = `/organization/courts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find organization by session
         * @summary Find organization by session.
         * @param {string} xAuthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationBySession: async (xAuthToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('getOrganizationBySession', 'xAuthToken', xAuthToken)
            const localVarPath = `/organization/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update
         * @summary Organization model
         * @param {string} xAuthToken 
         * @param {Organization} body Take organization model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (xAuthToken: string, body: Organization, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('update', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('update', 'body', body)
            const localVarPath = `/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * Create
         * @param {string} xAuthToken 
         * @param {Organization} body Organization model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(xAuthToken: string, body: Organization, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Organization by id
         * @summary Get organization by id.
         * @param {string} id 
         * @param {string} [xAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findById(id: string, xAuthToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedOrganizationResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findById(id, xAuthToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get organization by urlIdentifier
         * @summary Get organization by urlIdentifier.
         * @param {string} [urlIdentifier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByUrlIdentifier(urlIdentifier?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedOrganizationResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByUrlIdentifier(urlIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find case in JIS
         * @summary Find case in JIS.
         * @param {string} xAuthToken 
         * @param {string} [_case] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCaseJIS(xAuthToken: string, _case?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedOrganizationResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCaseJIS(xAuthToken, _case, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Organization
         * @summary Get all organizations.
         * @param {string} [xAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(xAuthToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedOrganizationResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(xAuthToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get courts keys
         * @summary Get all courts keys.
         * @param {string} [xAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourtKey(xAuthToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourtKeyModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourtKey(xAuthToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get courts
         * @summary Get all courts.
         * @param {string} name 
         * @param {string} [xAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourtsByName(name: string, xAuthToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Court>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourtsByName(name, xAuthToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find organization by session
         * @summary Find organization by session.
         * @param {string} xAuthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationBySession(xAuthToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationBySession(xAuthToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update
         * @summary Organization model
         * @param {string} xAuthToken 
         * @param {Organization} body Take organization model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(xAuthToken: string, body: Organization, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * Create
         * @param {string} xAuthToken 
         * @param {Organization} body Organization model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(xAuthToken: string, body: Organization, options?: any): AxiosPromise<Organization> {
            return localVarFp.create(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Organization by id
         * @summary Get organization by id.
         * @param {string} id 
         * @param {string} [xAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById(id: string, xAuthToken?: string, options?: any): AxiosPromise<PagedOrganizationResults> {
            return localVarFp.findById(id, xAuthToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get organization by urlIdentifier
         * @summary Get organization by urlIdentifier.
         * @param {string} [urlIdentifier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUrlIdentifier(urlIdentifier?: string, options?: any): AxiosPromise<PagedOrganizationResults> {
            return localVarFp.findByUrlIdentifier(urlIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * Find case in JIS
         * @summary Find case in JIS.
         * @param {string} xAuthToken 
         * @param {string} [_case] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCaseJIS(xAuthToken: string, _case?: string, options?: any): AxiosPromise<PagedOrganizationResults> {
            return localVarFp.findCaseJIS(xAuthToken, _case, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Organization
         * @summary Get all organizations.
         * @param {string} [xAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(xAuthToken?: string, options?: any): AxiosPromise<PagedOrganizationResults> {
            return localVarFp.get(xAuthToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get courts keys
         * @summary Get all courts keys.
         * @param {string} [xAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourtKey(xAuthToken?: string, options?: any): AxiosPromise<Array<CourtKeyModel>> {
            return localVarFp.getCourtKey(xAuthToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get courts
         * @summary Get all courts.
         * @param {string} name 
         * @param {string} [xAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourtsByName(name: string, xAuthToken?: string, options?: any): AxiosPromise<Array<Court>> {
            return localVarFp.getCourtsByName(name, xAuthToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Find organization by session
         * @summary Find organization by session.
         * @param {string} xAuthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationBySession(xAuthToken: string, options?: any): AxiosPromise<Organization> {
            return localVarFp.getOrganizationBySession(xAuthToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Update
         * @summary Organization model
         * @param {string} xAuthToken 
         * @param {Organization} body Take organization model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(xAuthToken: string, body: Organization, options?: any): AxiosPromise<Organization> {
            return localVarFp.update(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * Create
     * @param {string} xAuthToken 
     * @param {Organization} body Organization model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public create(xAuthToken: string, body: Organization, options?: any) {
        return OrganizationApiFp(this.configuration).create(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Organization by id
     * @summary Get organization by id.
     * @param {string} id 
     * @param {string} [xAuthToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public findById(id: string, xAuthToken?: string, options?: any) {
        return OrganizationApiFp(this.configuration).findById(id, xAuthToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get organization by urlIdentifier
     * @summary Get organization by urlIdentifier.
     * @param {string} [urlIdentifier] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public findByUrlIdentifier(urlIdentifier?: string, options?: any) {
        return OrganizationApiFp(this.configuration).findByUrlIdentifier(urlIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find case in JIS
     * @summary Find case in JIS.
     * @param {string} xAuthToken 
     * @param {string} [_case] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public findCaseJIS(xAuthToken: string, _case?: string, options?: any) {
        return OrganizationApiFp(this.configuration).findCaseJIS(xAuthToken, _case, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Organization
     * @summary Get all organizations.
     * @param {string} [xAuthToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public get(xAuthToken?: string, options?: any) {
        return OrganizationApiFp(this.configuration).get(xAuthToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get courts keys
     * @summary Get all courts keys.
     * @param {string} [xAuthToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getCourtKey(xAuthToken?: string, options?: any) {
        return OrganizationApiFp(this.configuration).getCourtKey(xAuthToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get courts
     * @summary Get all courts.
     * @param {string} name 
     * @param {string} [xAuthToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getCourtsByName(name: string, xAuthToken?: string, options?: any) {
        return OrganizationApiFp(this.configuration).getCourtsByName(name, xAuthToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find organization by session
     * @summary Find organization by session.
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganizationBySession(xAuthToken: string, options?: any) {
        return OrganizationApiFp(this.configuration).getOrganizationBySession(xAuthToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update
     * @summary Organization model
     * @param {string} xAuthToken 
     * @param {Organization} body Take organization model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public update(xAuthToken: string, body: Organization, options?: any) {
        return OrganizationApiFp(this.configuration).update(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * approve transaction 
         * @summary approve transaction 
         * @param {string} xAuthToken 
         * @param {ApprovePayment} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvePayment: async (xAuthToken: string, body: ApprovePayment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('approvePayment', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('approvePayment', 'body', body)
            const localVarPath = `/payment/approve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authorize a payment
         * @summary Authorize a payment
         * @param {string} xAuthToken 
         * @param {CounterPaymentModel} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeCounter: async (xAuthToken: string, body: CounterPaymentModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('authorizeCounter', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('authorizeCounter', 'body', body)
            const localVarPath = `/payment/confirm/counter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authorize a website payment
         * @summary Authorize a website payment
         * @param {WebsitePaymentModel} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeWebsite: async (body: WebsitePaymentModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('authorizeWebsite', 'body', body)
            const localVarPath = `/payment/confirm/website`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * cancel a draft payment
         * @summary cancel  a draft payment
         * @param {string} xAuthToken 
         * @param {CancelPayment} body id draft payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel: async (xAuthToken: string, body: CancelPayment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('cancel', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('cancel', 'body', body)
            const localVarPath = `/payment/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Capture transaction 
         * @summary Capture transaction 
         * @param {string} xAuthToken 
         * @param {CapturepaymentModel} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        capturePayment: async (xAuthToken: string, body: CapturepaymentModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('capturePayment', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('capturePayment', 'body', body)
            const localVarPath = `/payment/capture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * decline transaction 
         * @summary decline transaction 
         * @param {string} xAuthToken 
         * @param {ApprovePayment} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declinePayment: async (xAuthToken: string, body: ApprovePayment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('declinePayment', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('declinePayment', 'body', body)
            const localVarPath = `/payment/decline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find transaction detail
         * @summary Find transaction detail
         * @param {CitationCaseDetailRequest} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detail: async (body: CitationCaseDetailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('detail', 'body', body)
            const localVarPath = `/payment/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit payment
         * @summary Edit payment
         * @param {string} xAuthToken 
         * @param {EditCounterPayment} body edit payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCounter: async (xAuthToken: string, body: EditCounterPayment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('editCounter', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('editCounter', 'body', body)
            const localVarPath = `/payment/edit/counter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit payment
         * @summary Edit payment
         * @param {string} xAuthToken 
         * @param {EditWebsitePayment} body edit website payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWebsite: async (xAuthToken: string, body: EditWebsitePayment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('editWebsite', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('editWebsite', 'body', body)
            const localVarPath = `/payment/edit/website`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter payment
         * @summary Filter payment
         * @param {string} xAuthToken 
         * @param {number} [id] 
         * @param {string} [status1] 
         * @param {string} [status2] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterPayment: async (xAuthToken: string, id?: number, status1?: string, status2?: string, skip?: number, limit?: number, fromDate?: string, toDate?: string, referenceNumber?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('filterPayment', 'xAuthToken', xAuthToken)
            const localVarPath = `/payment/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (status1 !== undefined) {
                localVarQueryParameter['status1'] = status1;
            }

            if (status2 !== undefined) {
                localVarQueryParameter['status2'] = status2;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find transactions detail
         * @summary Find transactions detail
         * @param {string} xAuthToken 
         * @param {string} from 
         * @param {string} to 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatch: async (xAuthToken: string, from: string, to: string, productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('getBatch', 'xAuthToken', xAuthToken)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getBatch', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getBatch', 'to', to)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getBatch', 'productId', productId)
            const localVarPath = `/payment/transactions/batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find transactions detail
         * @summary Find transactions detail
         * @param {string} xAuthToken 
         * @param {number} batchId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsDetail: async (xAuthToken: string, batchId: number, productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('transactionsDetail', 'xAuthToken', xAuthToken)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('transactionsDetail', 'batchId', batchId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('transactionsDetail', 'productId', productId)
            const localVarPath = `/payment/transactions/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate a counter payment
         * @summary Validate a counter payment
         * @param {string} xAuthToken 
         * @param {CounterPaymentModel} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCounterPayment: async (xAuthToken: string, body: CounterPaymentModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('validateCounterPayment', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('validateCounterPayment', 'body', body)
            const localVarPath = `/payment/validate/counter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find duplicate
         * @summary Find duplicate
         * @param {ReferenceNumberModel} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePaymentItem: async (body: ReferenceNumberModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('validatePaymentItem', 'body', body)
            const localVarPath = `/payment/paymentItem/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * approve transaction 
         * @summary approve transaction 
         * @param {string} xAuthToken 
         * @param {ApprovePayment} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approvePayment(xAuthToken: string, body: ApprovePayment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApprovePayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approvePayment(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authorize a payment
         * @summary Authorize a payment
         * @param {string} xAuthToken 
         * @param {CounterPaymentModel} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeCounter(xAuthToken: string, body: CounterPaymentModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeCounter(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authorize a website payment
         * @summary Authorize a website payment
         * @param {WebsitePaymentModel} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeWebsite(body: WebsitePaymentModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeWebsite(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * cancel a draft payment
         * @summary cancel  a draft payment
         * @param {string} xAuthToken 
         * @param {CancelPayment} body id draft payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancel(xAuthToken: string, body: CancelPayment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancel(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Capture transaction 
         * @summary Capture transaction 
         * @param {string} xAuthToken 
         * @param {CapturepaymentModel} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async capturePayment(xAuthToken: string, body: CapturepaymentModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CapturepaymentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.capturePayment(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * decline transaction 
         * @summary decline transaction 
         * @param {string} xAuthToken 
         * @param {ApprovePayment} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declinePayment(xAuthToken: string, body: ApprovePayment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApprovePayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.declinePayment(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find transaction detail
         * @summary Find transaction detail
         * @param {CitationCaseDetailRequest} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detail(body: CitationCaseDetailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detail(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit payment
         * @summary Edit payment
         * @param {string} xAuthToken 
         * @param {EditCounterPayment} body edit payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editCounter(xAuthToken: string, body: EditCounterPayment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editCounter(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit payment
         * @summary Edit payment
         * @param {string} xAuthToken 
         * @param {EditWebsitePayment} body edit website payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editWebsite(xAuthToken: string, body: EditWebsitePayment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editWebsite(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Filter payment
         * @summary Filter payment
         * @param {string} xAuthToken 
         * @param {number} [id] 
         * @param {string} [status1] 
         * @param {string} [status2] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterPayment(xAuthToken: string, id?: number, status1?: string, status2?: string, skip?: number, limit?: number, fromDate?: string, toDate?: string, referenceNumber?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterPayment(xAuthToken, id, status1, status2, skip, limit, fromDate, toDate, referenceNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find transactions detail
         * @summary Find transactions detail
         * @param {string} xAuthToken 
         * @param {string} from 
         * @param {string} to 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBatch(xAuthToken: string, from: string, to: string, productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Batch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBatch(xAuthToken, from, to, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find transactions detail
         * @summary Find transactions detail
         * @param {string} xAuthToken 
         * @param {number} batchId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsDetail(xAuthToken: string, batchId: number, productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsDetail(xAuthToken, batchId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validate a counter payment
         * @summary Validate a counter payment
         * @param {string} xAuthToken 
         * @param {CounterPaymentModel} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateCounterPayment(xAuthToken: string, body: CounterPaymentModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateCounterPayment(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find duplicate
         * @summary Find duplicate
         * @param {ReferenceNumberModel} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePaymentItem(body: ReferenceNumberModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePaymentItem(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * approve transaction 
         * @summary approve transaction 
         * @param {string} xAuthToken 
         * @param {ApprovePayment} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvePayment(xAuthToken: string, body: ApprovePayment, options?: any): AxiosPromise<ApprovePayment> {
            return localVarFp.approvePayment(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Authorize a payment
         * @summary Authorize a payment
         * @param {string} xAuthToken 
         * @param {CounterPaymentModel} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeCounter(xAuthToken: string, body: CounterPaymentModel, options?: any): AxiosPromise<Payment> {
            return localVarFp.authorizeCounter(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Authorize a website payment
         * @summary Authorize a website payment
         * @param {WebsitePaymentModel} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeWebsite(body: WebsitePaymentModel, options?: any): AxiosPromise<void> {
            return localVarFp.authorizeWebsite(body, options).then((request) => request(axios, basePath));
        },
        /**
         * cancel a draft payment
         * @summary cancel  a draft payment
         * @param {string} xAuthToken 
         * @param {CancelPayment} body id draft payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel(xAuthToken: string, body: CancelPayment, options?: any): AxiosPromise<void> {
            return localVarFp.cancel(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Capture transaction 
         * @summary Capture transaction 
         * @param {string} xAuthToken 
         * @param {CapturepaymentModel} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        capturePayment(xAuthToken: string, body: CapturepaymentModel, options?: any): AxiosPromise<CapturepaymentModel> {
            return localVarFp.capturePayment(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * decline transaction 
         * @summary decline transaction 
         * @param {string} xAuthToken 
         * @param {ApprovePayment} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declinePayment(xAuthToken: string, body: ApprovePayment, options?: any): AxiosPromise<ApprovePayment> {
            return localVarFp.declinePayment(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Find transaction detail
         * @summary Find transaction detail
         * @param {CitationCaseDetailRequest} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detail(body: CitationCaseDetailRequest, options?: any): AxiosPromise<TransactionDetailResponse> {
            return localVarFp.detail(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit payment
         * @summary Edit payment
         * @param {string} xAuthToken 
         * @param {EditCounterPayment} body edit payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCounter(xAuthToken: string, body: EditCounterPayment, options?: any): AxiosPromise<void> {
            return localVarFp.editCounter(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit payment
         * @summary Edit payment
         * @param {string} xAuthToken 
         * @param {EditWebsitePayment} body edit website payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWebsite(xAuthToken: string, body: EditWebsitePayment, options?: any): AxiosPromise<void> {
            return localVarFp.editWebsite(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter payment
         * @summary Filter payment
         * @param {string} xAuthToken 
         * @param {number} [id] 
         * @param {string} [status1] 
         * @param {string} [status2] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [referenceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterPayment(xAuthToken: string, id?: number, status1?: string, status2?: string, skip?: number, limit?: number, fromDate?: string, toDate?: string, referenceNumber?: string, options?: any): AxiosPromise<void> {
            return localVarFp.filterPayment(xAuthToken, id, status1, status2, skip, limit, fromDate, toDate, referenceNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * Find transactions detail
         * @summary Find transactions detail
         * @param {string} xAuthToken 
         * @param {string} from 
         * @param {string} to 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBatch(xAuthToken: string, from: string, to: string, productId: number, options?: any): AxiosPromise<Array<Batch>> {
            return localVarFp.getBatch(xAuthToken, from, to, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Find transactions detail
         * @summary Find transactions detail
         * @param {string} xAuthToken 
         * @param {number} batchId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsDetail(xAuthToken: string, batchId: number, productId: number, options?: any): AxiosPromise<TransactionDetailResponse> {
            return localVarFp.transactionsDetail(xAuthToken, batchId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Validate a counter payment
         * @summary Validate a counter payment
         * @param {string} xAuthToken 
         * @param {CounterPaymentModel} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCounterPayment(xAuthToken: string, body: CounterPaymentModel, options?: any): AxiosPromise<void> {
            return localVarFp.validateCounterPayment(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Find duplicate
         * @summary Find duplicate
         * @param {ReferenceNumberModel} body whole payment model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePaymentItem(body: ReferenceNumberModel, options?: any): AxiosPromise<void> {
            return localVarFp.validatePaymentItem(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * approve transaction 
     * @summary approve transaction 
     * @param {string} xAuthToken 
     * @param {ApprovePayment} body whole payment model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public approvePayment(xAuthToken: string, body: ApprovePayment, options?: any) {
        return PaymentApiFp(this.configuration).approvePayment(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authorize a payment
     * @summary Authorize a payment
     * @param {string} xAuthToken 
     * @param {CounterPaymentModel} body whole payment model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public authorizeCounter(xAuthToken: string, body: CounterPaymentModel, options?: any) {
        return PaymentApiFp(this.configuration).authorizeCounter(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authorize a website payment
     * @summary Authorize a website payment
     * @param {WebsitePaymentModel} body whole payment model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public authorizeWebsite(body: WebsitePaymentModel, options?: any) {
        return PaymentApiFp(this.configuration).authorizeWebsite(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * cancel a draft payment
     * @summary cancel  a draft payment
     * @param {string} xAuthToken 
     * @param {CancelPayment} body id draft payment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public cancel(xAuthToken: string, body: CancelPayment, options?: any) {
        return PaymentApiFp(this.configuration).cancel(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Capture transaction 
     * @summary Capture transaction 
     * @param {string} xAuthToken 
     * @param {CapturepaymentModel} body whole payment model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public capturePayment(xAuthToken: string, body: CapturepaymentModel, options?: any) {
        return PaymentApiFp(this.configuration).capturePayment(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * decline transaction 
     * @summary decline transaction 
     * @param {string} xAuthToken 
     * @param {ApprovePayment} body whole payment model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public declinePayment(xAuthToken: string, body: ApprovePayment, options?: any) {
        return PaymentApiFp(this.configuration).declinePayment(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find transaction detail
     * @summary Find transaction detail
     * @param {CitationCaseDetailRequest} body whole payment model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public detail(body: CitationCaseDetailRequest, options?: any) {
        return PaymentApiFp(this.configuration).detail(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit payment
     * @summary Edit payment
     * @param {string} xAuthToken 
     * @param {EditCounterPayment} body edit payment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public editCounter(xAuthToken: string, body: EditCounterPayment, options?: any) {
        return PaymentApiFp(this.configuration).editCounter(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit payment
     * @summary Edit payment
     * @param {string} xAuthToken 
     * @param {EditWebsitePayment} body edit website payment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public editWebsite(xAuthToken: string, body: EditWebsitePayment, options?: any) {
        return PaymentApiFp(this.configuration).editWebsite(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter payment
     * @summary Filter payment
     * @param {string} xAuthToken 
     * @param {number} [id] 
     * @param {string} [status1] 
     * @param {string} [status2] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [referenceNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public filterPayment(xAuthToken: string, id?: number, status1?: string, status2?: string, skip?: number, limit?: number, fromDate?: string, toDate?: string, referenceNumber?: string, options?: any) {
        return PaymentApiFp(this.configuration).filterPayment(xAuthToken, id, status1, status2, skip, limit, fromDate, toDate, referenceNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find transactions detail
     * @summary Find transactions detail
     * @param {string} xAuthToken 
     * @param {string} from 
     * @param {string} to 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getBatch(xAuthToken: string, from: string, to: string, productId: number, options?: any) {
        return PaymentApiFp(this.configuration).getBatch(xAuthToken, from, to, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find transactions detail
     * @summary Find transactions detail
     * @param {string} xAuthToken 
     * @param {number} batchId 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public transactionsDetail(xAuthToken: string, batchId: number, productId: number, options?: any) {
        return PaymentApiFp(this.configuration).transactionsDetail(xAuthToken, batchId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate a counter payment
     * @summary Validate a counter payment
     * @param {string} xAuthToken 
     * @param {CounterPaymentModel} body whole payment model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public validateCounterPayment(xAuthToken: string, body: CounterPaymentModel, options?: any) {
        return PaymentApiFp(this.configuration).validateCounterPayment(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find duplicate
     * @summary Find duplicate
     * @param {ReferenceNumberModel} body whole payment model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public validatePaymentItem(body: ReferenceNumberModel, options?: any) {
        return PaymentApiFp(this.configuration).validatePaymentItem(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Soft delete a product.
         * @param {string} xAuthToken 
         * @param {number} id Id of product to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (xAuthToken: string, id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('_delete', 'xAuthToken', xAuthToken)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a product
         * @summary Takes in whole product model
         * @param {string} xAuthToken 
         * @param {Product} body whole product model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (xAuthToken: string, body: Product, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('create', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('create', 'body', body)
            const localVarPath = `/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get products
         * @summary Get products by organization Id.
         * @param {string} xAuthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (xAuthToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('get', 'xAuthToken', xAuthToken)
            const localVarPath = `/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get products by url
         * @summary Get products by url.
         * @param {string} [urlIdentifier] 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductByUrl: async (urlIdentifier?: string, source?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/urlIdentifier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (urlIdentifier !== undefined) {
                localVarQueryParameter['urlIdentifier'] = urlIdentifier;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a product
         * @summary Takes in whole product model
         * @param {string} xAuthToken 
         * @param {Product} body whole product model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (xAuthToken: string, body: Product, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('update', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('update', 'body', body)
            const localVarPath = `/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * Soft delete a product.
         * @param {string} xAuthToken 
         * @param {number} id Id of product to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(xAuthToken: string, id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(xAuthToken, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a product
         * @summary Takes in whole product model
         * @param {string} xAuthToken 
         * @param {Product} body whole product model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(xAuthToken: string, body: Product, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get products
         * @summary Get products by organization Id.
         * @param {string} xAuthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(xAuthToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedProductResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(xAuthToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get products by url
         * @summary Get products by url.
         * @param {string} [urlIdentifier] 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductByUrl(urlIdentifier?: string, source?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductByUrl(urlIdentifier, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a product
         * @summary Takes in whole product model
         * @param {string} xAuthToken 
         * @param {Product} body whole product model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(xAuthToken: string, body: Product, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * Soft delete a product.
         * @param {string} xAuthToken 
         * @param {number} id Id of product to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(xAuthToken: string, id: number, options?: any): AxiosPromise<void> {
            return localVarFp._delete(xAuthToken, id, options).then((request) => request(axios, basePath));
        },
        /**
         * create a product
         * @summary Takes in whole product model
         * @param {string} xAuthToken 
         * @param {Product} body whole product model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(xAuthToken: string, body: Product, options?: any): AxiosPromise<void> {
            return localVarFp.create(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get products
         * @summary Get products by organization Id.
         * @param {string} xAuthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(xAuthToken: string, options?: any): AxiosPromise<PagedProductResults> {
            return localVarFp.get(xAuthToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get products by url
         * @summary Get products by url.
         * @param {string} [urlIdentifier] 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductByUrl(urlIdentifier?: string, source?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getProductByUrl(urlIdentifier, source, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a product
         * @summary Takes in whole product model
         * @param {string} xAuthToken 
         * @param {Product} body whole product model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(xAuthToken: string, body: Product, options?: any): AxiosPromise<void> {
            return localVarFp.update(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * Soft delete a product.
     * @param {string} xAuthToken 
     * @param {number} id Id of product to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public _delete(xAuthToken: string, id: number, options?: any) {
        return ProductApiFp(this.configuration)._delete(xAuthToken, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a product
     * @summary Takes in whole product model
     * @param {string} xAuthToken 
     * @param {Product} body whole product model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public create(xAuthToken: string, body: Product, options?: any) {
        return ProductApiFp(this.configuration).create(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get products
     * @summary Get products by organization Id.
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public get(xAuthToken: string, options?: any) {
        return ProductApiFp(this.configuration).get(xAuthToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get products by url
     * @summary Get products by url.
     * @param {string} [urlIdentifier] 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductByUrl(urlIdentifier?: string, source?: string, options?: any) {
        return ProductApiFp(this.configuration).getProductByUrl(urlIdentifier, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a product
     * @summary Takes in whole product model
     * @param {string} xAuthToken 
     * @param {Product} body whole product model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public update(xAuthToken: string, body: Product, options?: any) {
        return ProductApiFp(this.configuration).update(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TwilioApi - axios parameter creator
 * @export
 */
export const TwilioApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gather step twillio
         * @param {string} step 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatherStep: async (step: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'step' is not null or undefined
            assertParamExists('gatherStep', 'step', step)
            const localVarPath = `/twilio/gather`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Init twillio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        init: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/twilio/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get start twillio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        start: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/twilio/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TwilioApi - functional programming interface
 * @export
 */
export const TwilioApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TwilioApiAxiosParamCreator(configuration)
    return {
        /**
         * Gather step twillio
         * @param {string} step 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gatherStep(step: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gatherStep(step, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Init twillio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async init(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.init(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get start twillio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async start(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.start(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TwilioApi - factory interface
 * @export
 */
export const TwilioApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TwilioApiFp(configuration)
    return {
        /**
         * Gather step twillio
         * @param {string} step 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gatherStep(step: string, options?: any): AxiosPromise<void> {
            return localVarFp.gatherStep(step, options).then((request) => request(axios, basePath));
        },
        /**
         * Init twillio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        init(options?: any): AxiosPromise<void> {
            return localVarFp.init(options).then((request) => request(axios, basePath));
        },
        /**
         * Get start twillio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        start(options?: any): AxiosPromise<void> {
            return localVarFp.start(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TwilioApi - object-oriented interface
 * @export
 * @class TwilioApi
 * @extends {BaseAPI}
 */
export class TwilioApi extends BaseAPI {
    /**
     * Gather step twillio
     * @param {string} step 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioApi
     */
    public gatherStep(step: string, options?: any) {
        return TwilioApiFp(this.configuration).gatherStep(step, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Init twillio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioApi
     */
    public init(options?: any) {
        return TwilioApiFp(this.configuration).init(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get start twillio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwilioApi
     */
    public start(options?: any) {
        return TwilioApiFp(this.configuration).start(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create User
         * @summary Create a new user.
         * @param {string} xAuthToken 
         * @param {User} body User to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (xAuthToken: string, body: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('createUser', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createUser', 'body', body)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete User
         * @summary Delete an existing user.
         * @param {string} xAuthToken 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (xAuthToken: string, id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('deleteUser', 'xAuthToken', xAuthToken)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find user
         * @summary Find user.
         * @param {string} xAuthToken 
         * @param {number} [id] 
         * @param {any} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (xAuthToken: string, id?: number, email?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('getUser', 'xAuthToken', xAuthToken)
            const localVarPath = `/user/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get users
         * @summary Get all users.
         * @param {string} xAuthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (xAuthToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('getUsers', 'xAuthToken', xAuthToken)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all users by organization
         * @summary Get all users by organization.
         * @param {string} xAuthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByOrganization: async (xAuthToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('getUsersByOrganization', 'xAuthToken', xAuthToken)
            const localVarPath = `/user/byOrganization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update User
         * @summary Update an existing user.
         * @param {string} xAuthToken 
         * @param {User} body User to update with updated data. If password included, password for user will be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (xAuthToken: string, body: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuthToken' is not null or undefined
            assertParamExists('updateUser', 'xAuthToken', xAuthToken)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateUser', 'body', body)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Create User
         * @summary Create a new user.
         * @param {string} xAuthToken 
         * @param {User} body User to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(xAuthToken: string, body: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete User
         * @summary Delete an existing user.
         * @param {string} xAuthToken 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(xAuthToken: string, id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(xAuthToken, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find user
         * @summary Find user.
         * @param {string} xAuthToken 
         * @param {number} [id] 
         * @param {any} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(xAuthToken: string, id?: number, email?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(xAuthToken, id, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get users
         * @summary Get all users.
         * @param {string} xAuthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(xAuthToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(xAuthToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all users by organization
         * @summary Get all users by organization.
         * @param {string} xAuthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersByOrganization(xAuthToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersByOrganization(xAuthToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update User
         * @summary Update an existing user.
         * @param {string} xAuthToken 
         * @param {User} body User to update with updated data. If password included, password for user will be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(xAuthToken: string, body: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(xAuthToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Create User
         * @summary Create a new user.
         * @param {string} xAuthToken 
         * @param {User} body User to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(xAuthToken: string, body: User, options?: any): AxiosPromise<User> {
            return localVarFp.createUser(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete User
         * @summary Delete an existing user.
         * @param {string} xAuthToken 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(xAuthToken: string, id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(xAuthToken, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Find user
         * @summary Find user.
         * @param {string} xAuthToken 
         * @param {number} [id] 
         * @param {any} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(xAuthToken: string, id?: number, email?: any, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getUser(xAuthToken, id, email, options).then((request) => request(axios, basePath));
        },
        /**
         * Get users
         * @summary Get all users.
         * @param {string} xAuthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(xAuthToken: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getUsers(xAuthToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all users by organization
         * @summary Get all users by organization.
         * @param {string} xAuthToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByOrganization(xAuthToken: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getUsersByOrganization(xAuthToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Update User
         * @summary Update an existing user.
         * @param {string} xAuthToken 
         * @param {User} body User to update with updated data. If password included, password for user will be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(xAuthToken: string, body: User, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(xAuthToken, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Create User
     * @summary Create a new user.
     * @param {string} xAuthToken 
     * @param {User} body User to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(xAuthToken: string, body: User, options?: any) {
        return UserApiFp(this.configuration).createUser(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete User
     * @summary Delete an existing user.
     * @param {string} xAuthToken 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(xAuthToken: string, id: number, options?: any) {
        return UserApiFp(this.configuration).deleteUser(xAuthToken, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find user
     * @summary Find user.
     * @param {string} xAuthToken 
     * @param {number} [id] 
     * @param {any} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(xAuthToken: string, id?: number, email?: any, options?: any) {
        return UserApiFp(this.configuration).getUser(xAuthToken, id, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get users
     * @summary Get all users.
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(xAuthToken: string, options?: any) {
        return UserApiFp(this.configuration).getUsers(xAuthToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all users by organization
     * @summary Get all users by organization.
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsersByOrganization(xAuthToken: string, options?: any) {
        return UserApiFp(this.configuration).getUsersByOrganization(xAuthToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update User
     * @summary Update an existing user.
     * @param {string} xAuthToken 
     * @param {User} body User to update with updated data. If password included, password for user will be updated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(xAuthToken: string, body: User, options?: any) {
        return UserApiFp(this.configuration).updateUser(xAuthToken, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserRolesApi - axios parameter creator
 * @export
 */
export const UserRolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get All
         * @summary Returns all user roles available for assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRolesApi - functional programming interface
 * @export
 */
export const UserRolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRolesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get All
         * @summary Returns all user roles available for assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRoles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRole>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserRolesApi - factory interface
 * @export
 */
export const UserRolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRolesApiFp(configuration)
    return {
        /**
         * Get All
         * @summary Returns all user roles available for assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoles(options?: any): AxiosPromise<Array<UserRole>> {
            return localVarFp.getUserRoles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRolesApi - object-oriented interface
 * @export
 * @class UserRolesApi
 * @extends {BaseAPI}
 */
export class UserRolesApi extends BaseAPI {
    /**
     * Get All
     * @summary Returns all user roles available for assignment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public getUserRoles(options?: any) {
        return UserRolesApiFp(this.configuration).getUserRoles(options).then((request) => request(this.axios, this.basePath));
    }
}


