import { Button, Card, CardHeader, FormControlLabel, Switch, TextField } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import _ from 'lodash';
import * as queryString from 'query-string';
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IGeneratedMenuItem } from '../../models/IGeneratedMenuData';
// Begin Redux init
import { AppState } from '../../store';
import { addProduct, clearState, getProductById, updateProduct } from '../../store/manage-product/actions';
import { IManageProductState as ReduxManageProductState } from '../../store/manage-product/types';
import { navigate } from '../../store/system/actions';
import { SystemState } from '../../store/system/types';
import { Product } from '../../swagger-client';
import styles from './ManageProduct.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  manageProduct: state.manageProduct,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
      getProductById,
      addProduct,
      updateProduct,
      clearState,
    },
    dispatch
  );

interface IManageProductProps extends RouteComponentProps {
  system: SystemState;
  manageProduct: ReduxManageProductState;
  navigate: <TProps extends RouteComponentProps<any>>(menuItem: IGeneratedMenuItem, ownProps: TProps) => ThunkAction<void, AppState, null, AnyAction>;
  getProductById: (id: number) => ThunkAction<void, AppState, null, AnyAction>;
  addProduct: (product: Product) => ThunkAction<void, AppState, null, AnyAction>;
  updateProduct: (product: Product) => ThunkAction<void, AppState, null, AnyAction>;
  clearState: () => void;
}

interface IManageProductState {
  settingState: boolean;
  constructing: boolean;
  errors: Array<string>;
  isNew: boolean;
  product: Product | any;
  referer: string;
  openSuccess: boolean | undefined;
  snackMessage: string;
}
// End Redux init

class ManageProduct extends React.Component<IManageProductProps, IManageProductState> {
  private formRef = React.createRef<HTMLFormElement>();

  constructor(props: IManageProductProps) {
    super(props);

    const urlParams: any = queryString.parse(this.props.location.search);

    this.state = {
      settingState: false,
      constructing: true,
      isNew: urlParams.new ? true : false,
      errors: [],
      openSuccess: false,
      snackMessage: '',
      product: {
        name: '',
        description: '',
        variablePrice: false,
        price: 0,
        organizationId: this.props.system.user?.organization?.id,
        isWeb: false,
        isCounter: false,
        transactionAccount: { primaryKey: '', transactionKey: '' },
      },
      referer: urlParams.referer,
    };

    if (!this.state.isNew) {
      this.props.getProductById(urlParams.id);
    }

    this.onSaveClick = this.onSaveClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeKeys = this.handleChangeKeys.bind(this);
  }

  static getDerivedStateFromProps(newProps: IManageProductProps, oldState: IManageProductState) {
    if (oldState.constructing) {
      return {
        constructing: false,
      };
    }

    let newState: any = {};

    if (!_.isEqual(newProps?.manageProduct?.product, oldState.product)) {
      if (oldState.settingState) {
        newState.product = oldState.product;
      } else {
        newState.product = newProps.manageProduct.product;
      }
    }

    if (oldState.settingState) {
      newState.settingState = false;
    }

    return Object.keys(newState).length > 0 ? { ...oldState, ...newState } : null;
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  private handleChange(event: ChangeEvent<any>) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      product: { ...this.state.product, [event.target.name]: value },
      settingState: true,
    });
  }

  private handleChangeKeys(event: ChangeEvent<any>) {
    if (event.target.name === 'primaryKey')
      this.setState({
        product: { ...this.state.product, transactionAccount: { ...this.state.product.transactionAccount, primaryKey: event.target.value } },
        settingState: true,
      });
    if (event.target.name === 'transactionKey')
      this.setState({
        product: { ...this.state.product, transactionAccount: { ...this.state.product.transactionAccount, transactionKey: event.target.value } },
        settingState: true,
      });
  }

  private onSaveClick() {
    if (this.state.product !== null) {
      this.setState({ errors: [], settingState: true }, async () => {
        if (this.formRef.current?.checkValidity()) {
          if (this.state.isNew) {
            try {

              await this.props.addProduct(this.state.product);
              this.setState({ snackMessage: 'Product created successfully' });
              this.setState({ openSuccess: true });
            } catch (excepcion) {
              this.setState({ snackMessage: '' });
              this.setState({ openSuccess: false });
            }
          } else {
            try {
              await this.props.updateProduct(this.state.product);
              this.setState({ snackMessage: 'Product updated successfully' });
              this.setState({ openSuccess: true });
            } catch (excepcion) {
              this.setState({ snackMessage: '' });
              this.setState({ openSuccess: false });
            }
          }

          if (this.state.referer !== undefined && this.state.referer !== null) {
            this.props.history.push({ pathname: this.state.referer, state: { openSuccess: true, snackMessage: this.state.snackMessage } });
          }
        } else {
          const elementsWithInvalid = this?.formRef?.current?.querySelectorAll(':invalid');
          const errors: Array<string> = [];

          elementsWithInvalid?.forEach((element) => {
            errors.push((element as any).name as string); // Built in interface does not see name but it is present.;
          });

          this.setState({ errors });
        }
      });
    }
  }

  render() {
    const product = this.state?.product;

    return (
      <form ref={this.formRef} noValidate autoComplete="off">
        <Card className={styles.header}>
          <CardHeader title="Create Product" />
          <div className={styles.saveButton}>
            <Button variant="contained" color="primary" onClick={this.onSaveClick}>
              Save
            </Button>
          </div>
        </Card>
        <div className={styles.container}>
          <div className={styles.columnLeft}>
            <div className={styles.row}>
              <Card className={styles.cardContainer}>
                <CardHeader title="Product Information" className={styles.cardHeader} />
                <TextField className={styles.inputFieldSelect} variant="outlined" id="name" label="Name" name="name" error={this.state.errors.findIndex((fieldName) => fieldName === 'name') > -1} required value={product?.name} onChange={this.handleChange} />
                <TextField
                  className={styles.inputFieldSelect}
                  variant="outlined"
                  id="description"
                  label="Description"
                  name="description"
                  multiline
                  rowsMax={4}
                  error={this.state.errors.findIndex((fieldName) => fieldName === 'description') > -1}
                  value={product?.description}
                  onChange={this.handleChange}
                />
                <FormControlLabel className={styles.inputFieldSelect} control={<Switch checked={product?.variablePrice} onChange={this.handleChange} name="variablePrice" color="primary" />} label="Variable Price" />
                <CurrencyTextField
                  className={styles.inputFieldSelect}
                  label="Condition Min"
                  name="conditionMin"
                  id="conditionMin"
                  variant="outlined"
                  value={product?.price}
                  currencySymbol="$"
                  //minimumValue="0"
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  onChange={this.handleChange}
                  textAlign="left"
                />
                <FormControlLabel className={styles.inputFieldSelect} control={<Switch checked={product?.isWeb} onChange={this.handleChange} name="isWeb" color="primary" />} label="Is web" />
                <FormControlLabel className={styles.inputFieldSelect} control={<Switch checked={product?.isCounter} onChange={this.handleChange} name="isCounter" color="primary" />} label="Is counter" />
              </Card>
            </div>
          </div>
          <div className={styles.columnRight}>
            <Card className={styles.cardContainer}>
              <CardHeader title="Product Keys" className={styles.cardHeader} />

              <TextField
                className={styles.inputFieldSelect}
                required
                variant="outlined"
                id="primaryKey"
                label="Primary Key"
                name="primaryKey"
                error={this.state.errors.findIndex((fieldName) => fieldName === 'primaryKey') > -1}
                value={product?.transactionAccount?.primaryKey}
                onChange={this.handleChangeKeys}
              />

              <TextField
                className={styles.inputFieldSelect}
                variant="outlined"
                id="transactionKey"
                label="Transaction Key"
                name="transactionKey"
                required
                error={this.state.errors.findIndex((fieldName) => fieldName === 'transactionKey') > -1}
                value={product?.transactionAccount?.transactionKey}
                onChange={this.handleChangeKeys}
              />
            </Card>
          </div>
        </div>
      </form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageProduct));
