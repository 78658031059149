import { Action } from 'redux';
import { Fee } from '../../swagger-client';

export const GET_FEE_DETAIL_SUCCESSFUL = 'GET_FEE_DETAIL_SUCCESSFUL_MANAGE_FEE';
export const ADD_FEE_DETAIL_SUCCESSFUL = 'ADD_FEE_DETAIL_SUCCESSFUL_MANAGE_FEE';
export const UPDATE_FEE_DETAIL_SUCCESSFUL = 'UPDATE_FEE_DETAIL_SUCCESSFUL_MANAGE_FEE';
export const CLEAR_STATE = 'CLEAR_STATE_MANAGE_FEE';
export const GET_CALCULATE_FEE_PROCESSING = 'GET_CALCULATE_FEE_PROCESSING';
// Action types

export interface IGetFeeDetailSuccessful extends Action {
  type: typeof GET_FEE_DETAIL_SUCCESSFUL;
  payload: Fee;
}

export interface IAddFeeDetailSuccessful extends Action {
  type: typeof ADD_FEE_DETAIL_SUCCESSFUL;
  payload: Fee;
}

export interface IUpdateFeeDetailSuccessful extends Action {
  type: typeof UPDATE_FEE_DETAIL_SUCCESSFUL;
  payload: Fee;
}

export interface IGetCalulateFeeProcessing extends Action {
  type: typeof GET_CALCULATE_FEE_PROCESSING;
  payload: number;
}

export interface IClearStateAction extends Action {
  type: typeof CLEAR_STATE;
}

export type ManageFeeActionTypes = IGetFeeDetailSuccessful | IAddFeeDetailSuccessful | IUpdateFeeDetailSuccessful | IClearStateAction | IGetCalulateFeeProcessing;

// Store state

export interface IManageFeeState {
  fee: Fee | null;
  feeProcessing: number;
}
