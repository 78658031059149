import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import errorMessages from '../../error_messages.json';
import AxiosConfigurator from '../../services/AxiosConfigurator';
import ManageErrorType from '../../services/errorHelper';
import * as swagger from '../../swagger-client';
import { PagedProductResults, Product } from '../../swagger-client';
import { AppState } from '../index';
import { toggleRouteBack } from '../system/actions';
import { ERROR_ACTION } from '../system/types';
import * as types from './types';

export const getProductById = (productId: number): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null) {
      const productApi = new swagger.ProductApi(AxiosConfigurator.getConfig(state.system.user.token));
      let product = null;
      let token = state.system.user.token as any;

      let query: any = {
        filterOptions: window.btoa(
          JSON.stringify([
            {
              field: 'id',
              value: productId,
              operation: 'equal',
            },
          ])
        ),
      };

      try {
        const result = await productApi.get(token, { query });

        if ((result.data as PagedProductResults).count > 0) {
          product = (result.data as PagedProductResults).values[0];
        } else {
          dispatch({
            type: ERROR_ACTION,
            payload: errorMessages.getProduct,
          });
        }

        dispatch({
          type: types.GET_PRODUCT_DETAIL_SUCCESSFUL,
          payload: product,
        });
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.getProduct + '--' + error,
        });
      }
    }
  };
};

export const updateProduct = (product: Product): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();
    if (state.system !== null && state.system.user !== null) {
      const productApi = new swagger.ProductApi(AxiosConfigurator.getConfig(state.system.user.token));

      let token = state.system.user.token as any;
      try {
        await productApi.update(token, product);

        dispatch(toggleRouteBack());
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.updateProduct + '--' + error,
        });
        throw err;
      }
    }
  };
};

export const addProduct = (product: Product): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();
    if (state.system !== null && state.system.user !== null) {
      let token = state.system.user.token as any;
      const productApi = new swagger.ProductApi(AxiosConfigurator.getConfig(state.system.user.token));
      if (product.price !== undefined) {
        let price = product.price.toString();
        product.price = Number(price.replace(',', ''));
      }
      try {
        await productApi.create(token, product);
        dispatch(toggleRouteBack());
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.addProduct + '--' + error,
        });
        throw err;
      }
    }
  };
};

export const clearState = (): types.IClearStateAction => {
  return {
    type: types.CLEAR_STATE,
  };
};
