//Dependencies
import React from 'react';
//Bootstrap
import Alert from 'react-bootstrap/Alert';
//Models
import { VariantEnum } from '../../models/VariantEnum';
//Components
import BaseComponent from '../BaseComponent';

interface IAlertMessageProps {
  shown: boolean;
  variant: VariantEnum;
  message: string;
}

interface IAlertMessageState {}

class AlertMessage extends BaseComponent<IAlertMessageProps, IAlertMessageState> {
  render() {
    let alert = null;

    if (this.props.shown) {
      alert = <Alert variant={this.props.variant}>{this.props.message}</Alert>;
    }

    return alert;
  }
}

export default AlertMessage;
