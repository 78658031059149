import { Button, Card, CardHeader, CircularProgress, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import ManageErrorType from '../../../services/errorHelper';
import { AppState } from '../../../store';
import { addPaymentWeb } from '../../../store/clerk-payment/actions';
import { navigate } from '../../../store/system/actions';
import CustomSeparator from '../../bread-crumbs/BreadCrumbs';
import WebsitePaymentLayout from '../WebsitePaymentLayout';
import styles from './WebistePaymentDuplicate.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  clerkPayment: state.clerkPayment,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const WebistePaymentDuplicate = (props: any) => {
  let history = useHistory();

  const { urlIdentifier } = useParams<{ urlIdentifier: string }>();
  const [paymentsByReference, setPaymentsByReference] = useState([]);
  const [isLoadingAddPayment, setIsLoadingAddPayment] = useState(false);
  const [stateDuplicate, setStateDuplicate] = useState([{ referenceNumber: '', amount: 0, date: null }]);
  const [openError, setOpenError] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  } as any;

  useEffect(() => {
    setPaymentsByReference(props.clerkPayment.paymentsByReference.data);
    if (props.location.state) {
      setStateDuplicate([props.location.state.propOne]);
    }
  }, []);

  useEffect(() => {
    if (props.location.state === undefined) {
      history.push(`/web/${urlIdentifier}`);
    }
  }, []);

  const handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  const rows = paymentsByReference?.map((payment: any) => {
    return {
      referenceNumber: payment.referenceNumber,
      amount: payment.amount,
      date: payment.createdAt,
    };
  });

  function ccyFormat(num: number) {
    return `${num.toFixed(2)}`;
  }

  const onCancelClick = () => {
    history.push(`/web/${urlIdentifier}`);
  };

  const onSubmitPaymentClick = async () => {
    setIsLoadingAddPayment(true);
    try {
      const pay = await addPaymentWeb(props.location.state.propOne);
      setIsLoadingAddPayment(false);
      history.push({
        pathname: `/web/${urlIdentifier}/confirm`,
        state: {
          propOne: pay,
        },
      });
    } catch (error) {
      const err = ManageErrorType(error as any);
      setSnackMessage(err);
      setOpenError(true);
      setIsLoadingAddPayment(false);
    }
  };

  const formatDate = (date: string) => {
    let date_string = new Date().toDateString();
    date_string = moment(date_string).tz('America/New_York').format('MM/DD/YYYY hh:mm');
    if (date) {
      let today = date === '' ? new Date().toLocaleDateString('en-US') : moment(date.split('T')[0]).format('MM/DD/YYYY');

      date_string = today;
      date_string = moment(date_string).tz('America/New_York').format('MM/DD/YYYY hh:mm');
    }
    return date_string;
  };

  return (
    <WebsitePaymentLayout>
      <Card className={styles.header}>
        <CardHeader title="Make a Payment Online" />
      </Card>
      <Card className={styles.header}>
        <CustomSeparator menuList={['Enter Information', 'Possible Duplicate']} />
      </Card>
      <div className={styles.container}>
        <Card className={styles.cardContainer}>
          <div className={styles.headerText}>
            <h3>Recent payments have been submitted for this citation.</h3>
          </div>
          <Card className={styles.subCardContainer}>
            <CardHeader title="Similar payments are listed below" className={styles.cardHeader} />
            <TableContainer>
              <Table className={styles.table} aria-label="spanning table">
                <TableHead className={styles.tableHeader}>
                  <TableRow>
                    <TableCell align="left">Citation/Case</TableCell>
                    <TableCell align="left">Amount</TableCell>
                    <TableCell align="left">Date/Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row: any) => (
                    <TableRow key={row.referenceNumber}>
                      <TableCell align="left">{row.referenceNumber}</TableCell>
                      <TableCell align="left">{`$ ${ccyFormat(+row.amount)}`}</TableCell>
                      <TableCell align="left">{formatDate(row.date)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Card className={styles.subCardContainer}>
            <CardHeader title="Your ticket information" className={styles.cardHeader} />
            <TableContainer>
              <Table className={styles.table} aria-label="spanning table">
                <TableHead className={styles.tableHeader}>
                  <TableRow>
                    <TableCell align="left">Citation/Case</TableCell>
                    <TableCell align="left">Amount</TableCell>
                    <TableCell align="left">Date/Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stateDuplicate[0].date !== null &&
                    stateDuplicate?.map((row: any) => (
                      <TableRow key={row.paymentItem.referenceNumber}>
                        <TableCell align="left">{row.paymentItem.referenceNumber}</TableCell>
                        <TableCell align="left">{`$ ${ccyFormat(+row.paymentItem.amount)}`}</TableCell>
                        <TableCell align="left">{formatDate(row.date)}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Card>
        <div className={styles.text}>
          <span>Click here if you would like to submit this payment.</span>
        </div>
        <div className={styles.Buttons}>
          <div className={styles.button}>
            <Button variant="contained" onClick={onCancelClick}>
              Cancel
            </Button>
          </div>
          {isLoadingAddPayment ? (
            <CircularProgress />
          ) : (
            <div className={styles.button}>
              <Button variant="contained" color="primary" onClick={onSubmitPaymentClick}>
                Submit
              </Button>
            </div>
          )}
        </div>
        <div className={styles.text}>
          <span>Please note, this is NOT proof of payment, but rather proof that this transaction was successfully forwarded to Sample District Court. Please alow 24 28 hours to court to adknowlage payment For any further questions or concerns, please contact the court directly.</span>
        </div>
      </div>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {snackMessage}
        </Alert>
      </Snackbar>
    </WebsitePaymentLayout>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WebistePaymentDuplicate));
