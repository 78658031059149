import { Action } from 'redux';
import { Product } from '../../swagger-client';

export const GET_PRODUCT_DETAIL_SUCCESSFUL = 'GET_PRODUCT_DETAIL_SUCCESSFUL_MANAGE_PRODUCT';
export const ADD_PRODUCT_DETAIL_SUCCESSFUL = 'ADD_PRODUCT_DETAIL_SUCCESSFUL_MANAGE_PRODUCT';
export const UPDATE_PRODUCT_DETAIL_SUCCESSFUL = 'UPDATE_PRODUCT_DETAIL_SUCCESSFUL_MANAGE_PRODUCT';
export const CLEAR_STATE = 'CLEAR_STATE_MANAGE_PRODUCT';
// Action types

export interface IGetProductDetailSuccessful extends Action {
  type: typeof GET_PRODUCT_DETAIL_SUCCESSFUL;
  payload: Product;
}

export interface IAddProductDetailSuccessful extends Action {
  type: typeof ADD_PRODUCT_DETAIL_SUCCESSFUL;
  payload: Product;
}

export interface IUpdateProductDetailSuccessful extends Action {
  type: typeof UPDATE_PRODUCT_DETAIL_SUCCESSFUL;
  payload: Product;
}

export interface IClearStateAction extends Action {
  type: typeof CLEAR_STATE;
}

export type ManageProductActionTypes = IGetProductDetailSuccessful | IAddProductDetailSuccessful | IUpdateProductDetailSuccessful | IClearStateAction;

// Store state

export interface IManageProductState {
  product: Product | null;
}
