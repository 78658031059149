import Dashboard from '../components/dashboard/Dashboard';
import ExportReport from '../components/export-report/ExportReport';
import FeesGrid from '../components/fees-grid/FeesGrid';
import Help from '../components/help/Help';
import ManageCurrentUser from '../components/manage-current-user/ManageCurrentUser';
import ManageFee from '../components/manage-fee/ManageFee';
import ManageOrg from '../components/manage-org/ManageOrg';
import ManageOrgs from '../components/manage-orgs/ManageOrgs';
import ManagePayments from '../components/manage-payments/ManagePayments';
import ManageProduct from '../components/manage-product/ManageProduct';
import ManageReports from '../components/manage-reports/ManageReports';
import NoStrategySelected from '../components/no-strategy-selected/NoStrategySelected';
import ProductsGrid from '../components/products-grid/ProductsGrid';
import UserManagement from '../components/user-management/UserManagement';
import WebistePayment from '../components/WebsitePages/website-payment/WebistePayment';
import IMenu from '../models/IMenuData';
import { LoggedInUser } from '../models/LoggedInUser';
import { Strategies } from '../models/Strategies';
import { UserRoles } from '../models/UserRoles';
import { ClerkPaymentStrategy } from './ClerkPaymentStrategy';

function getMenuItems(user: LoggedInUser): IMenu {
  const strategy = user?.organization?.strategy as Strategies;

  let productsComponent: any = ProductsGrid;
  let feesComponent: any = FeesGrid;
  if (user?.organization === undefined || user?.organization === null) {
    productsComponent = NoStrategySelected;
    feesComponent = NoStrategySelected;
  }
  const org = user?.organization?.name;

  return {
    items: [
      {
        name: 'clerkpayment',
        title: 'Make a Payment',
        path: '/payments/create',
        component: ClerkPaymentStrategy.strategise(strategy),
        roles: [UserRoles.Admin, UserRoles.AcceptPayment, UserRoles.ManagePayments],
        items: null,
        icon: 'CreditCardIcon',
      },
      {
        name: 'managepayments',
        title: 'Manage Payments',
        path: '/payments',
        component: ManagePayments,
        roles: [UserRoles.Admin, UserRoles.ManagePayments],
        items: null,
        icon: 'CreateIcon',
      },
      {
        name: 'reports',
        title: 'Reports',
        path: null,
        component: undefined,
        roles: [UserRoles.Admin, UserRoles.Reporting, UserRoles.ManagePayments],
        icon: 'TrendingUpIcon',
        items: [
          {
            name: 'reportsExpand',
            title: 'Reports',
            path: '/reports',
            component: ManageReports,
            roles: [UserRoles.Admin, UserRoles.Reporting, UserRoles.ManagePayments],
            icon: 'TrendingUpIcon',
            items: [],
          },
          {
            name: 'exportRepots',
            title: 'Export Settlement Report',
            path: '/reports/export',
            component: ExportReport,
            roles: [UserRoles.Admin, UserRoles.Reporting, UserRoles.ManagePayments],
            icon: 'TrendingUpIcon',
            items: [],
          },
          {
            name: 'dashboard',
            title: 'Dashboard',
            path: '/reports/dashboard',
            component: Dashboard,
            roles: [UserRoles.Admin, UserRoles.Reporting, UserRoles.ManagePayments],
            icon: 'TrendingUpIcon',
            items: [],
          },
        ],
      },
      {
        name: 'help',
        title: 'Help',
        path: '/help',
        component: Help,
        roles: [UserRoles.Admin, UserRoles.AcceptPayment, UserRoles.ManagePayments, UserRoles.Reporting],
        items: null,
        icon: 'HelpOutlineIcon',
      },
      {
        name: 'organizations',
        title: 'Manage Organizations',
        path: '/organizations',
        component: ManageOrgs,
        roles: [UserRoles.SuperAdmin],
        items: null,
        navOnly: true,
      },
      {
        name: 'organization',
        title: 'Manage Organization',
        path: '/organization',
        component: ManageOrg,
        roles: [UserRoles.SuperAdmin],
        items: null,
        navOnly: true,
      },
      {
        name: 'products',
        title: 'Manage Products',
        path: '/products',
        component: productsComponent,
        roles: [UserRoles.SuperAdmin, UserRoles.Admin],
        items: null,
        navOnly: true,
      },
      {
        name: 'product',
        title: 'Manage Product',
        path: '/product',
        component: ManageProduct,
        roles: [UserRoles.SuperAdmin, UserRoles.Admin],
        items: null,
        navOnly: true,
      },
      {
        name: 'fees',
        title: 'Manage Fees',
        path: '/fees',
        component: feesComponent,
        roles: [UserRoles.SuperAdmin, UserRoles.Admin],
        items: null,
        navOnly: true,
      },
      {
        name: 'fee',
        title: 'Manage Fee',
        path: '/fee',
        component: ManageFee,
        roles: [UserRoles.SuperAdmin, UserRoles.Admin],
        items: null,
        navOnly: true,
      },
      {
        name: 'user',
        title: 'Users',
        path: '/organization_users',
        component: UserManagement,
        roles: [UserRoles.AcceptPayment, UserRoles.Admin],
        items: null,
        navOnly: true,
      },
      {
        name: 'manageUser',
        title: 'Manage user',
        path: '/account',
        component: ManageCurrentUser,
        roles: [UserRoles.Admin, UserRoles.AcceptPayment, UserRoles.Reporting, UserRoles.ManagePayments],
        items: null,
        navOnly: true,
      },
      {
        name: 'website',
        title: 'Website',
        path: `/web/${org}`,
        component: WebistePayment,
        roles: [UserRoles.SuperAdmin, UserRoles.Admin],
        items: null,
        navOnly: true,
      },
    ],
  };
}

export default getMenuItems;
