import React from 'react';
import { TableCell, Input, Select, MenuItem, Checkbox, ListItemText } from "@material-ui/core";
import styles from './SelectFilterCell.module.scss';
import { FilterOperation } from '../../../../models/FilterOperation';

const SelectCellBase = ({ filter, children, onFilter, ...restProps }: any) => {
  const { column } = restProps;

  return (
    <TableCell >
      <div className={styles.filterCell}>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={filter ? filter.value : []}
          onChange={e => {
            onFilter(e.target.value && (e.target.value as any).length > 0 ? { value: e.target.value, operation: FilterOperation.OrEquals } : null);
          }}
          input={<Input />}
          renderValue={(selected) => (selected as string[]).join(', ')}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
              },
            },
          }}
          className={styles.selectFilterCombo}
        >
          // RWN
          {column.options.map((option: string) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={(() => {
                let checked = false;

                if (filter) {
                  checked = (filter as any).value.findIndex((value: string) => value === option) > -1;
                }

                return checked;
              })()} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </div>
    </TableCell>
  );
}

export const SelectFilterCell = (props: any) => (
  <SelectCellBase {...props} />
);