import { Box, CardHeader, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../../store';
import { navigate } from '../../../../../store/system/actions';
import styles from './TermAndConditions.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const TermAndConditions = () => {
  return (
    <>
      <CardHeader title="Terms and Conditions" className={styles.cardHeader} />
      <div style={{ overflow: 'auto', textOverflow: 'ellipsis', width: '100%', height: '100px', margin: '10px' }}>
        <Box border={1} padding={'10px'} borderColor="white" bgcolor={'#e0e0e0'} borderRadius="borderRadius">
          <Typography paragraph>
            CONVENIENCE FEE Your agency has partnered with a third party service provider to provide you with convenient online payment services via credit card debit card or electronic check payments. IN ORDER TO USE THIS SERVICE YOU MAY HAVE TO PAY A NON-REFUNDABLE CONVENIENCE FEE IN ADDITION TO
            THE AMOUNT(S) OWED TO YOUR PAYEE. Please note that the service provider (not your Payee) will appear as the merchant of record next to your payment on your bank or credit card statement. ACCESSIBILITY This service is accessible through the Internet. In order to use this service you will
            need a personal computer access to the Internet with an Internet service provider and a web browser which supports this service. ACCURACY OF YOUR INFORMATION AND BILLING; COMPLETION OF PAYMENT You are solely responsible for providing accurate and complete information to use this service
            and for confirming any amount(s) owed to your Payee. Completion of your online payment is contingent upon both (1) the authorization of payment by your credit card company (or financial institution with respect to debit cards and electronic check payments) and (2) acceptance of your
            payment. If the service provider is unable to process your payment the service provider will attempt to notify you at least once using the contact information you have provided. If your payment is not processed or authorized by your credit card company or financial institution (as
            applicable) or your payment is not accepted by your Payee your payment liability shall remain outstanding and unpaid and you will be responsible for any penalties, late fees, interest charges and any other amounts assessed by your Payee. DISCLAIMER; LIMITATION OF LIABILITY YOUR USE OF
            THE SERVICE IS AT YOUR OWN RISK. THIS SERVICE IS PROVIDED AS-IS AND AS AVAILABLE WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES. The service provider shall not be liable for any direct or indirect incidental, special, punitive or consequential damages that result in any way from your use of
            or inability to use this service or that result from mistakes, omissions, interruptions, deletion of files, errors, defects, delays in operation or transmission or any failure of performance. ACCEPTANCE By clicking the ACCEPT TERMS button you agree to these terms and to pay the
            non-refundable convenience fee charged by the service provider for this service.
          </Typography>
        </Box>
      </div>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TermAndConditions));
