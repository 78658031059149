import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../store';
import { navigate } from '../../store/system/actions';
import styles from './TableApp.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
    },
    dispatch
  );

const TableApp = (props: any) => {
  function ccyFormat(num: number) {
    return `${num.toFixed(2)}`;
  }
  return (
    <TableContainer>
      <Table className={styles.table} aria-label="spanning table">
        <TableHead className={styles.tableHeader}>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Citation/Case</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell align="center">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row: any, index: any) => (
            <TableRow key={index}>
              <TableCell>{row.productId ? row.productId : ''}</TableCell>
              <TableCell>{row.referenceNumber ? row.referenceNumber : ''}</TableCell>
              <TableCell>{row.firstName ? row.firstName : ''}</TableCell>
              <TableCell>{row.lastName ? row.lastName : ''}</TableCell>
              <TableCell align="right">{`$ ${ccyFormat(+row.amount)}` ? `$ ${ccyFormat(+row.amount)}` : 0}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={4} />
            <TableCell align="right">Subtotal: {`$ ${ccyFormat(+props.invoiceSubtotal)}`}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4} />
            <TableCell align="right">Processing Fee: {`$ ${ccyFormat(+props.invoiceTaxes)}`}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4} />
            <TableCell align="right">Total: {`$ ${ccyFormat(+props.invoiceSubtotal + +props.invoiceTaxes)}`}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TableApp));
