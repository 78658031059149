import { Button, Card, CardHeader, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import errorMessages from '../../error_messages.json';
import { AppState } from '../../store';
import { editCourtPayment, editWebsitePayment, filterPaymentById, voidPayment } from '../../store/clerk-payment/actions';
import { navigate } from '../../store/system/actions';
import { ERROR_ACTION } from '../../store/system/types';
import { EditWebsitePayment } from '../../swagger-client';
import CustomTableCell from './components/CustomTableCell/CustomTableCell';
import styles from './EditPayment.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  clerkPayment: state.clerkPayment,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
      filterPaymentById,
      voidPayment,
      editCourtPayment,
    },
    dispatch
  );

const EditPayment = (props: any) => {
  const { paymentId } = useParams<{ paymentId: string }>();
  const [rows, setRows] = useState([]);
  const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
  const [invoiceTaxes, setInvoiceTaxes] = useState(0);
  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [paymentItemId, setPaymentItemId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [amount, setAmount] = useState(0);
  const [source, setSource] = useState('');
  const [productId, setProductId] = useState(0);
  const [isLoadingVoid, setIsloadingVoid] = useState(false);
  const [isLoadingUpdate, setIsloadingUpdate] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    selectTableCell: {
      width: 60,
    },
    tableCell: {
      width: 130,
      height: 40,
    },
    input: {
      width: 130,
      height: 40,
    },
  }));
  const classes = useStyles();
  let history = useHistory();

  const payfiltered = useSelector((state: any) => state.clerkPayment.paymentFiltredById);

  useEffect(() => {
    const filter = { status: 'Authorized', rowLength: 25 };
    props.filterPaymentById(filter, paymentId);
  }, []);

  useEffect(() => {
    if (payfiltered.data) {
      setLoading(false);
      const rows = payfiltered?.data[0].paymentItems?.map((payment: { id: string; productId: string; referenceNumber: string; firstName: string; lastName: string; amount: number }) => {
        return createRow(payment.id, payment.productId, payment.referenceNumber, payment.firstName, payment.lastName, payment.amount);
      });
      const invoiceSubtotal = payfiltered?.data[0].subTotal;
      const invoiceTaxes = payfiltered?.data[0].feeAmount;
      const invoiceTotal = payfiltered?.data[0].subTotal + payfiltered?.data[0].feeAmount;
      const source = payfiltered?.data[0].source;
      const product = payfiltered?.data[0].paymentItems[0].productId;
      setRows(rows);
      setInvoiceSubtotal(invoiceSubtotal);
      setInvoiceTaxes(invoiceTaxes);
      setInvoiceTotal(invoiceTotal);
      setReferenceNumber(rows[0].referenceNumber);
      setPaymentItemId(rows[0].id);
      setFirstName(rows[0].firstName);
      setLastName(rows[0].lastName);
      setAmount(rows[0].amount);
      setSource(source);
      setProductId(product);
    }
  }, [payfiltered]);

  function createRow(id: string, productId: string, referenceNumber: string, firstName: string, lastName: string, amount: number) {
    return { id, productId, referenceNumber, firstName, lastName, amount };
  }

  const onChange = (e: any, row: any) => {
    if (e.target.name === 'referenceNumber') {
      setReferenceNumber(e.target.value);
    }
    if (e.target.name === 'firstName') {
      setFirstName(e.target.value);
    }
    if (e.target.name === 'lastName') {
      setLastName(e.target.value);
    }
    if (e.target.name === 'amount') {
      setAmount(e.target.value);
    }
  };

  function ccyFormat(num: number) {
    return `${num.toFixed(2)}`;
  }

  const onVoidClick = async () => {
    const payment = { paymentId: paymentId };
    setIsloadingVoid(true);
    try {
      await props.voidPayment(payment);
      history.push({ pathname: '/payments', state: { openSuccess: true, snackMessage: 'Payment voided successfully' } });
    } catch (err) {}
    setIsloadingVoid(false);
    history.push({ pathname: '/payments', state: { openSuccess: false, snackMessage: '' } });
  };

  const onUpdateClick = async () => {
    if (source === 'counter') {
      const counterPayment = {
        id: paymentId,
        source: source,
        paymentItems: [
          {
            id: Number(paymentItemId),
            referenceNumber: referenceNumber,
            firstName: firstName,
            lastName: lastName,
            productId: productId,
            amount: amount,
          },
        ],
      };
      try {
        setIsloadingUpdate(true);
        await props.editCourtPayment(counterPayment);
        setIsloadingUpdate(false);
        history.push({ pathname: '/payments', state: { openSuccess: true, snackMessage: 'Payment edited successfully' } });
      } catch (error) {
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.editWebsitePayment + '--' + error,
        });
      }
      history.push({ pathname: '/payments', state: { openSuccess: false, snackMessage: '' } });
    }
    if (source === 'website') {
      const websitePayment: EditWebsitePayment = {
        id: Number(paymentId),
        source: source,
        paymentItem: {
          id: Number(paymentItemId),
          referenceNumber: referenceNumber,
          firstName: firstName,
          lastName: lastName,
          amount: amount,
        },
      };
      try {
        await editWebsitePayment(websitePayment, props.system.user);
        history.push({ pathname: '/payments', state: { openSuccess: true, snackMessage: 'Payment edited successfully' } });
      } catch (error) {
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.editWebsitePayment + '--' + error,
        });
      }
      history.push({ pathname: '/payments', state: { openSuccess: false, snackMessage: '' } });
    }
  };

  return (
    <>
      <Card className={styles.header}>
        <CardHeader title="Edit Payment" />
      </Card>
      <Card className={styles.cardContainer}>
        <Table className={classes.table} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Citation/Case</TableCell>
              <TableCell align="left">First Name</TableCell>
              <TableCell align="left">Last Name</TableCell>
              <TableCell align="left">Product</TableCell>
              <TableCell align="left">Amount</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row: any, index: any) => (
              <TableRow key={index}>
                <CustomTableCell disabled={loading} row={row} value={referenceNumber} name={'referenceNumber'} onChange={(e: any) => onChange(e, row)} />
                <CustomTableCell disabled={loading} row={row} value={firstName} name={'firstName'} onChange={(e: any) => onChange(e, row)} />
                <CustomTableCell disabled={loading} row={row} value={lastName} name={'lastName'} onChange={(e: any) => onChange(e, row)} />
                <TableCell>{row.productId}</TableCell>
                <TableCell>{row.amount}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={4} align="right" />
              <TableCell>Subtotal</TableCell>
              <TableCell align="right">{`$ ${ccyFormat(+invoiceSubtotal)}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} align="right" />
              <TableCell>Tax</TableCell>
              <TableCell align="right">{`$ ${ccyFormat(+invoiceTaxes)}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} align="right" />
              <TableCell>Total</TableCell>
              <TableCell align="right">{`$ ${ccyFormat(+invoiceTotal)}`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className={styles.cancelButton}>
          {isLoadingVoid ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" color="primary" className={styles.voidButton} onClick={onVoidClick}>
              Void
            </Button>
          )}
          {isLoadingUpdate ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" color="secondary" className={styles.continueButton} onClick={onUpdateClick}>
              Update
            </Button>
          )}
        </div>
      </Card>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditPayment));
