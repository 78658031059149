import SimpleReactValidator from "simple-react-validator";
import React from "react";

class BaseComponent<TProps, TState> extends React.Component<TProps, TState> {
  protected validator: SimpleReactValidator;

  constructor(props: TProps, validatorOptions: object | undefined = undefined) {
    super(props);

    this.validator = new SimpleReactValidator(validatorOptions);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  protected handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value } as unknown as TState);
  }
}

export default BaseComponent;
