import { Button, Card, CardHeader } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../store';
import { navigate } from '../../store/system/actions';
import { SystemState } from '../../store/system/types';
import { clearSuccessFail, createUser, getUsers, updateUser } from '../../store/users/actions';
import { UserState } from '../../store/users/types';
import { User, UserRole } from '../../swagger-client';
import UserList from './components/user-list/UserList';
import styles from './UserManagement.module.scss';
const mapStateToProps = (state: AppState) => ({
  users: state.users,
  system: state.system,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, any>) =>
  bindActionCreators(
    {
      createUser,
      navigate,
      updateUser,
      getUsers,
      clearSuccessFail,
    },
    dispatch
  );

interface IUserManagementProps extends RouteComponentProps {
  users: UserState;
  system: SystemState;
  getUsers: () => void;
  createUser: (user: User) => void;
  updateUser: (user: User) => void;
  clearSuccessFail: () => void;
}

interface selectedRole {
  role: UserRole;
  checked: boolean;
}

interface IUserManagementState {
  errors: Array<string>;
  selectedUser: User | null;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  active: boolean;
  organizationId: number;
  roles: Array<selectedRole>;
}

// End Redux init

class UserManagment extends React.Component<IUserManagementProps, IUserManagementState> {
  constructor(props: IUserManagementProps) {
    super(props);

    this.state = {
      errors: [],
      selectedUser: null,
      email: '',
      password: '',
      active: true,
      firstName: '',
      lastName: '',
      organizationId: 0,
      roles: this.props.users.availableRoles.map((role: any) => {
        return {
          role,
          checked: false,
        };
      }),
    };

    this.props.getUsers();

    this.userSelected = this.userSelected.bind(this);
    this.newUserClick = this.newUserClick.bind(this);
    // this.saveOrUpdate = this.saveOrUpdate.bind(this);
    // this.handleRoleChange = this.handleRoleChange.bind(this);
  }

  componentWillUnmount() {
    this.props.clearSuccessFail();
  }

  userSelected(user: User) {
    this.setState({
      selectedUser: user,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      active: user.active as boolean,
      password: '',
      roles: this.props.users.availableRoles.map((role: any) => {
        return {
          role,
          checked: typeof user.roles !== 'undefined' && user.roles !== null && user.roles.findIndex((r) => r.name === role.name) > -1,
        };
      }),
    });
  }

  private newUserClick() {
    this.props.clearSuccessFail();
    this.setState({
      selectedUser: null,
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      active: true,
    });
    this.props.history.push('/organization_users/create');
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.columnLeft}>
          <div className={[styles.row, styles.totalRow].join(' ')}>
            <Card className={[styles.cardContainer].join(' ')}>
              <CardHeader title="Manage Users" className={styles.cardHeader} />
              <UserList userSelected={this.userSelected} users={this.props.users.users} />
              <Button variant="contained" color="primary" className={styles.continueButton} onClick={this.newUserClick}>
                New user
              </Button>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserManagment));
